import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

  export default class CustomerAddActivityView extends Component{
    constructor(props) {
      super(props);
      this.state={
        currItem:{},
        districtItemAll:[],
        currDistrictA:null,currDistrictB:null,currDistrictC:null,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SYSTEM_DISTRICT_URL,null,['key','get',0],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({districtItemAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    submitItem(){
        let currDistrict = {
            currDistrictA:this.state.currDistrictA,
            currDistrictB:this.state.currDistrictB,
            currDistrictC:this.state.currDistrictC,
        };
        this.props.successEvent(this.state.currItem,currDistrict);
    }

    render(){
        return(<div style={{width:'98.5%',height:'100%'}}>
            <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{width:70}}>{stringUtil.language(448)}</span>
                <input style={styles.phoneInputText} placeholder={stringUtil.language(93)} onChange={(event)=>{
                    this.state.currItem.noteName = event.target.value;
                    this.submitItem();
                }}/>
            </div>
            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:70}}>{stringUtil.language(468)}</span>
                    <select style={styles.phoneInputText} onChange={(event)=>{
                        this.state.currItem.warning = JSON.parse(event.target.value).warning;
                        this.submitItem();
                        this.setState({currItem:this.state.currItem});
                        }}>
                            <option style={{display:'none'}}></option>
                        {[{warning:0,name:stringUtil.language(1074)},{warning:1,name:stringUtil.language(1075)},{warning:2,name:stringUtil.language(1076)},{warning:3,name:stringUtil.language(1077)},{warning:4,name:stringUtil.language(1078)}].map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                    </select>
                </div>
                {this.state.currItem.warning==1?<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:60,color:'#F00',textAlign:'right'}}>{stringUtil.language(912)}</span>
                    <input style={arithUtil.stylesAdd(styles.textInput,{width:80,color:'#F00'})} type='number' onChange={(event)=>{
                        this.state.currItem.quota = arithUtil.yuanToFen(event.target.value);
                        this.submitItem();
                    }}/><span style={{color:'#F00'}}> {stringUtil.currency(1)}</span>
                </div>:<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}/>}
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:70}}>{stringUtil.language(1048)+stringUtil.language(1155)}</span>
                <input style={styles.phoneInputText} placeholder={stringUtil.language(1010)+stringUtil.language(1155)+'..'} onChange={(event)=>{
                    this.state.currItem.code = event.target.value;
                    this.submitItem();
                }}/>
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:70}}>{stringUtil.language(382)}</span>
                <input style={styles.phoneInputText} type='number' placeholder={stringUtil.language(56)} onChange={(event)=>{
                    this.state.currItem.mobile = event.target.value;
                    this.submitItem();
                }}/>
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:70}}>{stringUtil.language(469)}</span>
                <input style={styles.phoneInputText} placeholder={stringUtil.language(61)} onChange={(event)=>{
                    this.state.currItem.cardId = event.target.value;
                    this.submitItem();
                }}/>
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:70}}>{stringUtil.language(470)}</span>
                <select style={styles.selectStyle} onChange={(event)=>{
                    this.state.currDistrictA = JSON.parse(event.target.value);
                    if (this.state.currDistrictA==null||this.state.currDistrictA.districts.length==0){
                        this.state.currDistrictB=null;this.state.currDistrictC=null;
                    }
                    this.submitItem();
                    this.setState({currDistrictA:this.state.currDistrictA});
                }}>
                    <option style={{display:'none'}}></option>
                    {this.state.districtItemAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                    })}
                </select>
                <select style={styles.selectStyle} onChange={(event)=>{
                    this.state.currDistrictB = JSON.parse(event.target.value);
                    if (this.state.currDistrictB==null||this.state.currDistrictB.districts.length==0) {
                    this.state.currDistrictC=null;
                    }
                    this.submitItem();
                    this.setState({currDistrictB:this.state.currDistrictB});
                }}>
                    <option style={{display:'none'}}></option>
                    {(this.state.currDistrictA!=null?this.state.currDistrictA.districts:[]).map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                    })}
                </select>
                <select style={styles.selectStyle} onChange={(event)=>{
                    this.state.currDistrictC = JSON.parse(event.target.value);
                    this.submitItem();
                }}>
                    <option style={{display:'none'}}></option>
                    {(this.state.currDistrictB!=null?this.state.currDistrictB.districts:[]).map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                    })}
                </select>
            </div>
            <div style={{minHeight:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:70}}>{stringUtil.language(387)}</span>
                <input style={arithUtil.stylesAdd(styles.phoneInputText,{width:450})} placeholder={stringUtil.language(58)} onChange={(event)=>{
                    this.state.currItem.address = event.target.value;
                    this.submitItem();
                }}/>
            </div>
        </div>)
    }
}

const styles = {
    phoneInputText:{
        width:200,
        height:35,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:5,
        color:'#808080',
        padding:0,
        paddingLeft:5,
    },
    textInput:{
        height:35,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:5,
        textAlign:'center',
        textAlignVertical:'center'
    },
    selectStyle:{
        width: '150px',
        height: '25px',
        borderStyle:'solid',
        borderColor:'#D3D3D3',
        borderWidth:1,
        borderRadius: '2px',
        paddingLeft: '5px',
        fontSize: '12px'
    },
};