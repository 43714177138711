import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

export default class TabSettingStallCommodity extends Component{
  constructor(props){
    super(props);
    this.state={
      weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
      searchs:{
        keySearch:'',
        commodityTypeId:-1,
        commodityTypeName:stringUtil.language(389),
        targetId:-1,
        targetType:-1,//0代销，1stringUtil.language(1051)，2炒货
        targetName:stringUtil.language(425)
      },
      copyCommoditys:[],
      commoditysAll:[],
      commodityTypesAll:[],
      currShopCommodityItem:{},
      targetsAll:[],
      currCommodityTypeItem:{},
      currCommodityTypeSearchItem:{},
      systemCommoditysAll:[],
      costsAll:[],
      typeShowPop:false,
      infoShowPop:false,
      addShowPop:false,addBasketShowPop:false,addShowPopIndex:0,//初始化组{stringUtil.language(1173)}，清空值
      currCommodityLevelItem:{},
      addMultipleShowPop:false,addMultipleShowPopIndex:0,
      editShowPop:false,
      delShowPop:false,
      selectDiglogTagSysCom:false,
      selectDiglogTagComIte:false,
      selectDiglogTagEntCost:false,
      maskModalTag:false,
      costs:[],
      selectDiglogTagEntCostE:false,
    };
    if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
  }
  componentDidMount(){
    stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            let ownersAll = successResponse.data;
            for(let i=0;i<ownersAll.length;i++){
                this.state.targetsAll.push({targetType:0,targetId:ownersAll[i].id,targetName:ownersAll[i].noteName});
            }
            this.state.targetsAll.push({targetType:1,targetId:localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).id:0,targetName:stringUtil.language(426)});
            stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
                if (successResponse.status==200) {
                    let workersAll = successResponse.data;
                    for(let i=0;i<workersAll.length;i++){
                        this.state.targetsAll.push({targetType:2,targetId:workersAll[i].id,targetName:workersAll[i].noteName});
                    }
                    this.setState({targetsAll:this.state.targetsAll});
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:1},['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.setState({costsAll:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    this.initShopCommoditys();
    this.initShopCommodityTypes();
  }
  initShopCommoditys(){
      stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.setState({commoditysAll:successResponse.data});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initShopCommodityTypes(){
      stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.commodityTypesAll= successResponse.data;
              this.setState({commodityTypesAll: this.state.commodityTypesAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initSystemCommoditys(successEvent){
      if (this.state.systemCommoditysAll==null || this.state.systemCommoditysAll.length==0) {
          stringUtil.httpRequest(urlUtil.SYSTEM_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
              if (successResponse.status==200) {
                  this.state.systemCommoditysAll = successResponse.data;
                  this.state.systemCommoditysAll.unshift({id:0,name:stringUtil.language(763)});
                  successEvent(0);
              }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
          },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      }else{successEvent(1);}
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',display:'flex',flexDirection:'column',position:'relative'}}>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingBottom:5,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#D3D3D3'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{width:230,position:'relative'}}>
                    <input style={arithUtil.stylesAdd(styles.inputText,{width:230})} placeholder={stringUtil.language(32)} onChange={(event)=>{this.state.searchs.keySearch=event.target.value;this.setState({});}}/>
                    <i className="iconfontIndexCss" style={{fontSize:18,color:'#D3D3D3',position:'absolute',right:0,top:5}}>&#xe62c;</i>
                </div>
                <div style={{width:230,marginLeft:15}}>
                    <Select showSearch defaultValue={stringUtil.language(425)} style={{width:230}} onChange={(value)=>{
                        this.state.searchs.targetId = JSON.parse(value).targetId;
                        this.state.searchs.targetType = JSON.parse(value).targetType;
                        this.state.searchs.targetName = JSON.parse(value).targetName;
                        this.setState({});
                    }}><option value={JSON.stringify({targetType:-1,targetId:-1,targetName:stringUtil.language(425)})}>{stringUtil.language(425)}</option>
                        {this.state.targetsAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.targetName}</option>)
                        })}
                    </Select>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                    this.setState({typeShowPop:!this.state.typeShowPop});
                }}><i className="iconfontIndexCss" style={{fontSize:10,color:'#A9A9A9'}}>&#xe603;</i>&nbsp;{this.state.currCommodityTypeSearchItem.name?this.state.currCommodityTypeSearchItem.name:stringUtil.language(765)}
                  <i className="iconfontIndexCss" style={{fontSize:18,color:'#40883E'}}>&#xe63a;</i>
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',position:'relative'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.setState({ownerCopyShowPop:true});
                    }}><i className="iconfontIndexCss" style={{fontSize:18,color:'#A9A9A9'}}>&#xe6ea;</i>&nbsp;{stringUtil.language(764)}
                    </div>
                    {this.state.ownerCopyShowPop?<div style={{width:170,height:arithUtil.ProportionTo(document.body.clientHeight,0.6),borderStyle:'solid',borderColor:'#696969',borderWidth:1,backgroundColor:'#FFF',display:'flex',flexDirection:'column',position:'absolute',top:20,right:0,paddingLeft:5}}>
                        <div style={{height:40,display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                            <span style={{color:'#F00'}}>{stringUtil.language(176)}？</span>
                            <div style={{width:'40px',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.setState({ownerCopyShowPop:false});}}>
                                <span style={{color:'#F00',fontSize:25}}>×</span>
                            </div>
                        </div>
                        <input style={{height:40,paddingLeft:5,borderTopStyle:'solid',borderTopColor:'#A9A9A9',borderTopWidth:1,borderBottomStyle:'solid',borderBottomColor:'#A9A9A9',borderBottomWidth:1}} onChange={(event)=>{
                            this.state.targetsDatas=[];
                            if(event.target.value){
                                for(let i=0;i<(this.state.targetsAll?this.state.targetsAll:[]).length;i++){
                                    if((this.state.targetsAll[i].targetName).indexOf(event.target.value)!=-1){
                                        this.state.targetsDatas.push(this.state.targetsAll[i]);
                                    }
                                }
                            }else{this.state.targetsDatas=this.state.targetsAll;}
                            this.setState({targetsDatas:this.state.targetsDatas});
                        }} placeholder={stringUtil.language(32)}/>
                        <div style={{display:'flex',flexGrow:1,overflow:'auto'}}><div>
                            {(this.state.targetsDatas?this.state.targetsDatas:this.state.targetsAll).map((item,key)=>{
                                return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                    let choiceIds=[];
                                    for(let key=0;key<this.state.commoditysAll.length;key++){let item=this.state.commoditysAll[key];
                                        if ((item.name.indexOf(this.state.searchs.keySearch)==-1)&&((item.code?item.code:'').indexOf(this.state.searchs.keySearch)==-1)) continue;
                                        if (this.state.currCommodityTypeSearchItem.id!=null&&this.state.currCommodityTypeSearchItem.id!=-1&&this.state.currCommodityTypeSearchItem.id!=item.shopCommodityTypeId) continue;
                                        if (this.state.searchs.targetId!=null&&this.state.searchs.targetId!=-1&&this.state.searchs.targetId!=item.targetId) continue;
                                        if(item.isChoiceChenpinglong)choiceIds.push(item.id);
                                    }
                                    if(choiceIds.length==0){ToastBox.error(stringUtil.language(190));return;}
                                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_COPY_URL,{ids:choiceIds,targetId:item.targetId,targetType:item.targetType},['json','post',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            ToastBox.success(stringUtil.language(388));
                                            this.state.ownerCopyShowPop=false;
                                            this.initShopCommoditys();
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }}><span>{stringUtil.subStr(item.targetName,9)}</span></div>)
                            })}
                        </div></div>
                    </div>:''}
                </div>
                {otherUtil.authorityFindCode("010301")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20,cursor:'pointer'}} onClick={()=>{
                    this.state.currShopCommodityItem = {};
                    this.state.costs = [];
                    this.initSystemCommoditys((response)=>{//targetType:-1,targetId:-1
                        this.setState({addShowPopIndex:this.state.addShowPopIndex+1,addShowPop:!this.state.addShowPop});
                    });
                }}>
                  <i className="iconfontIndexCss" style={{fontSize:18,color:'#A9A9A9'}}>&#xe601;</i>&nbsp;{stringUtil.language(391)}
                </div>:<div style={{position:'absolute'}}/>}
                {otherUtil.authorityFindCode("010301")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20,cursor:'pointer'}} onClick={()=>{
                    this.state.currShopCommodityItem = {};
                    this.setState({addShowPopIndex:this.state.addShowPopIndex+1,addBasketShowPop:!this.state.addBasketShowPop});
                }}>
                  <i className="iconfontIndexCss" style={{fontSize:18,color:'#A9A9A9'}}>&#xe601;</i>&nbsp;{stringUtil.language(1157)}
                </div>:<div style={{position:'absolute'}}/>}
                {otherUtil.authorityFindCode("010302")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20,cursor:'pointer'}} onClick={()=>{
                    this.state.choiceCommodityMultiple=[];
                    for(let key=0;key<this.state.commoditysAll.length;key++){let item=this.state.commoditysAll[key];
                        if ((item.name.indexOf(this.state.searchs.keySearch)==-1)&&((item.code?item.code:'').indexOf(this.state.searchs.keySearch)==-1)) continue;
                        if (this.state.currCommodityTypeSearchItem.id!=null&&this.state.currCommodityTypeSearchItem.id!=-1&&this.state.currCommodityTypeSearchItem.id!=item.shopCommodityTypeId) continue;
                        if (this.state.searchs.targetId!=null&&this.state.searchs.targetId!=-1&&this.state.searchs.targetId!=item.targetId) continue;
                        if(item.isChoiceChenpinglong)this.state.choiceCommodityMultiple.push(item);
                    }
                    if(this.state.choiceCommodityMultiple.length==0){ToastBox.error(stringUtil.language(190));return;}
                    this.state.costsDatas=JSON.parse(JSON.stringify(this.state.costsAll));
                    this.setState({addMultipleShowPopIndex:this.state.addMultipleShowPopIndex+1,addMultipleShowPop:!this.state.addMultipleShowPop});
                }}><i className="iconfontIndexCss" style={{fontSize:18,color:'#A9A9A9'}}>&#xe601;</i>&nbsp;{stringUtil.language(766)}
                </div>:<div style={{position:'absolute'}}/>}
                {otherUtil.authorityFindCode("010302")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20,cursor:'pointer'}} onClick={()=>{
                    this.state.choiceCommodityMultiple=[];
                    for(let key=0;key<this.state.commoditysAll.length;key++){let item=this.state.commoditysAll[key];
                        if ((item.name.indexOf(this.state.searchs.keySearch)==-1)&&((item.code?item.code:'').indexOf(this.state.searchs.keySearch)==-1)) continue;
                        if (this.state.currCommodityTypeSearchItem.id!=null&&this.state.currCommodityTypeSearchItem.id!=-1&&this.state.currCommodityTypeSearchItem.id!=item.shopCommodityTypeId) continue;
                        if (this.state.searchs.targetId!=null&&this.state.searchs.targetId!=-1&&this.state.searchs.targetId!=item.targetId) continue;
                        if(item.isChoiceChenpinglong)this.state.choiceCommodityMultiple.push(JSON.parse(JSON.stringify(item)));
                    }
                    if(this.state.choiceCommodityMultiple.length==0){ToastBox.error(stringUtil.language(190));return;}
                    this.state.typeMultiples={};
                    this.setState({addMultipleShowPopIndex:this.state.addMultipleShowPopIndex+1,typeMultipleShowPop:true});
                }}><i className="iconfontIndexCss" style={{fontSize:18,color:'#A9A9A9'}}>&#xe601;</i>&nbsp;{stringUtil.language(1251)+stringUtil.language(1252)}
                </div>:<div style={{position:'absolute'}}/>}
            </div>
          </div>
          {(()=>{
              this.state.commodityOthers=[];
              for(let i=0;i<this.state.commoditysAll.length;i++){
                  if(this.state.commoditysAll[i].costs){
                      for(let j=0;j<this.state.commoditysAll[i].costs.length;j++){
                          let isHave=0;
                          for(let k=0;k<this.state.commodityOthers.length;k++){
                              if(this.state.commoditysAll[i].costs[j].costClauseId==this.state.commodityOthers[k].costClauseId){isHave=1;break;}
                          }
                          if(!isHave)this.state.commodityOthers.push(this.state.commoditysAll[i].costs[j]);
                      }
                  }
              }
          })()}
          <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
            {(()=>{
                this.state.isAllSelect=1;
                for(let key=0;key<this.state.commoditysAll.length;key++){let item=this.state.commoditysAll[key];
                    if ((item.name.indexOf(this.state.searchs.keySearch)==-1)&&((item.code?item.code:'').indexOf(this.state.searchs.keySearch)==-1)) continue;
                    if (this.state.currCommodityTypeSearchItem.id!=null&&this.state.currCommodityTypeSearchItem.id!=-1&&this.state.currCommodityTypeSearchItem.id!=item.shopCommodityTypeId) continue;
                    if (this.state.searchs.targetId!=null&&this.state.searchs.targetId!=-1&&this.state.searchs.targetId!=item.targetId) continue;
                    if(!item.isChoiceChenpinglong)this.state.isAllSelect=0;
                }
            })()}
            {this.state.isAllSelect?<i className="iconfontIndexCss" style={{width:50,fontSize:18,color:'#52A056',textAlign:'center',cursor:'pointer'}} onClick={()=>{//stringUtil.language(1036)==>不stringUtil.language(1036)
                for(let key=0;key<this.state.commoditysAll.length;key++){let item=this.state.commoditysAll[key];
                    if ((item.name.indexOf(this.state.searchs.keySearch)==-1)&&((item.code?item.code:'').indexOf(this.state.searchs.keySearch)==-1)) continue;
                    if (this.state.currCommodityTypeSearchItem.id!=null&&this.state.currCommodityTypeSearchItem.id!=-1&&this.state.currCommodityTypeSearchItem.id!=item.shopCommodityTypeId) continue;
                    if (this.state.searchs.targetId!=null&&this.state.searchs.targetId!=-1&&this.state.searchs.targetId!=item.targetId) continue;
                    this.state.commoditysAll[key].isChoiceChenpinglong=0;
                }
                this.setState({commoditysAll:this.state.commoditysAll});
            }}>&#xe698;</i>:<i className="iconfontIndexCss" style={{width:50,fontSize:18,color:'#A9A9A9',textAlign:'center',cursor:'pointer'}} onClick={()=>{//不stringUtil.language(1036)==>stringUtil.language(1036)
                for(let key=0;key<this.state.commoditysAll.length;key++){let item=this.state.commoditysAll[key];
                    if ((item.name.indexOf(this.state.searchs.keySearch)==-1)&&((item.code?item.code:'').indexOf(this.state.searchs.keySearch)==-1)) continue;
                    if (this.state.currCommodityTypeSearchItem.id!=null&&this.state.currCommodityTypeSearchItem.id!=-1&&this.state.currCommodityTypeSearchItem.id!=item.shopCommodityTypeId) continue;
                    if (this.state.searchs.targetId!=null&&this.state.searchs.targetId!=-1&&this.state.searchs.targetId!=item.targetId) continue;
                    this.state.commoditysAll[key].isChoiceChenpinglong=1;
                }
                this.setState({commoditysAll:this.state.commoditysAll});
            }}>&#xe6a7;</i>}
            <span style={{width:0,flexGrow:2,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(768)}</span>
            <span style={{width:0,flexGrow:3.5,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(767)}</span>
            <span style={{width:0,flexGrow:1.5,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1105)}</span>
            <span style={{width:0,flexGrow:1,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1252)}</span>
            <span style={{width:0,flexGrow:1.5,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1273)}</span>
            <span style={{width:50,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1046)}</span>
            <span style={{width:0,flexGrow:1,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1024)}</span>
            <span style={{width:0,flexGrow:1,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1025)}</span>
            {(this.state.commodityOthers?this.state.commodityOthers:[]).map((item,key)=>{
                return(<span style={{width:0,flexGrow:1.5,color:'#FFA500',fontSize:11,textAlign:'center'}} key={key}>{item.costClauseName}</span>)
            })}
            <span style={{width:50,color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1026)}</span>
          </div>
          <div style={{display:'flex',flexGrow:1,flexDirection:'column',overflow:'auto'}}>
            {(()=>{this.state.colorIndex=-1;})()}
            {this.state.commoditysAll.map((item,key)=>{
                if ((item.name.indexOf(this.state.searchs.keySearch)==-1)&&((item.code?item.code:'').indexOf(this.state.searchs.keySearch)==-1)) return;
                if (this.state.currCommodityTypeSearchItem.id!=null&&this.state.currCommodityTypeSearchItem.id!=-1&&this.state.currCommodityTypeSearchItem.id!=item.shopCommodityTypeId) return;
                if (this.state.searchs.targetId!=null&&this.state.searchs.targetId!=-1&&this.state.searchs.targetId!=item.targetId) return;
                this.state.colorIndex=this.state.colorIndex+1;
                let levels='';
                for(let i=0;i<item.levels.length;i++){
                    levels=levels+item.levels[i].name;
                    if(i!=item.levels.length-1)levels=levels+';';
                }
                return(<div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:this.state.colorIndex%2==0?'#FFF':'rgb(255,250,205)'}} key={key}>
                    {item.isChoiceChenpinglong?<i className="iconfontIndexCss" style={{width:50,fontSize:18,color:'#52A056',textAlign:'center',cursor:'pointer'}} onClick={()=>{//选==>不选
                        this.state.commoditysAll[key].isChoiceChenpinglong=0;
                        this.setState({commoditysAll:this.state.commoditysAll});
                    }}>&#xe698;</i>:<i className="iconfontIndexCss" style={{width:50,fontSize:18,color:'#A9A9A9',textAlign:'center',cursor:'pointer'}} onClick={()=>{//不选==>选
                        this.state.commoditysAll[key].isChoiceChenpinglong=1;
                        this.setState({commoditysAll:this.state.commoditysAll});
                    }}>&#xe6a7;</i>}
                    <div style={{width:0,flexGrow:2,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <span style={{color:'#52A056',fontSize:15,fontWeight:'bold'}}>{item.code}</span>
                        <span style={{width:'100%',color:'#A9A9A9',fontSize:10,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetType==1?stringUtil.language(426):item.targetName}</span>
                    </div>
                    <div style={{width:0,flexGrow:3.5,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>
                        <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <span style={{width:'100%',color:'#52A056',fontSize:15,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.name}</span>
                            <span style={{color:'#A9A9A9',fontSize:10}}>{item.systemCommodityId==0?stringUtil.language(763):item.systemCommodityName}</span>
                        </div>
                    </div>
                    <span style={{width:0,flexGrow:1.5,color:'#52A056',fontSize:15,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.specs}</span>
                    <span style={{width:0,flexGrow:1,color:'#52A056',fontSize:13,textAlign:'center'}}>{item.shopCommodityTypeName}</span>
                    <span style={{width:0,flexGrow:1.5,color:'#52A056',fontSize:13,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{levels}</span>
                    <span style={{width:50,color:'#52A056',fontSize:13,textAlign:'center'}}>{item.unitMaster==null?'':item.unitMaster}</span>
                    <span style={{width:0,flexGrow:1,color:'#52A056',fontSize:13,textAlign:'center'}}>{arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>
                    <span style={{width:0,flexGrow:1,color:'#52A056',fontSize:13,textAlign:'center'}}>{arithUtil.keToWeight(item.reduce,this.state.weightMode)}</span>
                    {(this.state.commodityOthers?this.state.commodityOthers:[]).map((itema,keya)=>{
                        let tempVal='';
                        if(item.costs){
                            for(let i=0;i<item.costs.length;i++){
                                if(itema.costClauseId==item.costs[i].costClauseId){
                                    tempVal=item.costs[i].costClauseCalMode==0?arithUtil.fenToYuan(item.costs[i].money,2)+'/'+stringUtil.language(1173):item.costs[i].costClauseCalMode==1?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.costs[i].money,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172)):item.costs[i].costClauseCalMode==2?item.costs[i].money+'%':arithUtil.fenToYuan(item.costs[i].money)+stringUtil.currency(1);
                                    break;
                                }
                            }
                        }
                        return(<span style={{width:0,flexGrow:1.5,color:'#52A056',fontSize:13,textAlign:'center'}} key={keya}>{tempVal}</span>)
                    })}
                    <i className="iconfontIndexCss" style={{width:50,fontSize:18,color:'#A9A9A9',textAlign:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.currShopCommodityItem = JSON.parse(JSON.stringify(item));
                        this.setState({infoShowPop:!this.state.infoShowPop});
                    }}>&#xe63a;</i>
                </div>)
            })}
          </div>
          {/* <div style={{display:'flex',flexGrow:1,flexDirection:'row',overflow:'auto'}}>
          <div style={{height:((this.state.commoditysAll.length).div(4,0)).mul(70),display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
              {this.state.commoditysAll.map((item,key)=>{
                  if ((item.name.indexOf(this.state.searchs.keySearch)==-1)&&((item.code?item.code:'').indexOf(this.state.searchs.keySearch)==-1)) return;
                  if (this.state.currCommodityTypeSearchItem.id!=null&&this.state.currCommodityTypeSearchItem.id!=-1&&this.state.currCommodityTypeSearchItem.id!=item.shopCommodityTypeId) return;
                  if (this.state.searchs.targetId!=null&&this.state.searchs.targetId!=-1&&this.state.searchs.targetId!=item.targetId) return;
                  return(<div style={{width:200,height:70,display:'flex',flexDirection:'row',alignItems:'center',borderWidth:1,borderColor:'#DCDCDC',borderRadius:5,margin:5,paddingLeft:3,cursor:'pointer'}} key={key} onClick={()=>{
                      this.state.currShopCommodityItem = JSON.parse(JSON.stringify(item));
                      this.setState({infoShowPop:!this.state.infoShowPop});
                  }}>
                      <img style={{width:'55px',height:'55px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title='{stringUtil.language(84)}'/>
                      <div style={{marginLeft:5}}>
                          <span style={{color:'#52A056',fontSize:15,fontWeight:'bold'}}>{stringUtil.subStr(item.name,7)}</span><br/>
                          <span style={{color:'#A9A9A9',fontSize:11}}>{item.systemCommodityName==null?'stringUtil.language(763)':item.systemCommodityName}/{item.shopCommodityTypeName==null?'stringUtil.language(854)':item.shopCommodityTypeName}</span><br/>
                          <span style={{color:'#A9A9A9',fontSize:11}}>{item.targetType==1?'stringUtil.language(426)':stringUtil.subStr(item.targetName,10)}</span>
                      </div>
                  </div>)
              })}
          </div>
          </div> */}
            <MaskModal title={stringUtil.language(766)+stringUtil.language(765)} visible={this.state.typeMultipleShowPop?true:false} width={500} height={arithUtil.ProportionTo(document.body.clientHeight,0.75)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10,overflow:'auto'}} key={this.state.addMultipleShowPopIndex}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span style={{lineHeight:'30px',fontSize:14}}>{stringUtil.language(1158)}：{(()=>{let resultStr='';if(this.state.choiceCommodityMultiple==null)this.state.choiceCommodityMultiple=[];
                            for(let i=0;i<this.state.choiceCommodityMultiple.length;i++){
                                resultStr=resultStr+this.state.choiceCommodityMultiple[i].name;
                                if(i!=this.state.choiceCommodityMultiple.length-1)resultStr=resultStr+'，';
                            }
                            return stringUtil.subStr(resultStr,100);
                        })()}</span>
                        <span style={{lineHeight:'30px',color:'#F00',fontSize:14,fontWeight:'bold'}}>[{stringUtil.language(1182)}{this.state.choiceCommodityMultiple?this.state.choiceCommodityMultiple.length:0}{stringUtil.language(989)}]</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(765)}：</span>
                        <select style={{width:200,height:30,borderColor:'#d0d0d0',borderStyle:'solid',borderWidth:1,borderRadius:2}} onChange={(event)=>{
                            this.state.typeMultiples.shopCommodityTypeId = JSON.parse(event.target.value).id;
                        }}><option value={JSON.stringify({id:0,name:stringUtil.language(854)})}>{stringUtil.language(854)}</option>
                            {(this.state.commodityTypesAll?this.state.commodityTypesAll:[]).map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                        </select>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if(!this.state.typeMultiples||!this.state.typeMultiples.shopCommodityTypeId){
                        this.state.typeMultiples.shopCommodityTypeId=0;
                    }
                    this.setState({typeMultipleShowPop: false},()=>{
                        let result = {ids:[],shopCommodityTypeId:this.state.typeMultiples.shopCommodityTypeId};
                        if(this.state.choiceCommodityMultiple&&this.state.choiceCommodityMultiple.length>0){
                            for(let i=0;i<this.state.choiceCommodityMultiple.length;i++){
                                if(this.state.choiceCommodityMultiple[i].isChoiceChenpinglong)result.ids.push(this.state.choiceCommodityMultiple[i].id);
                            }
                        }
                        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_MULTIPLE_URL,result,['json','put',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                this.initShopCommoditys();
                                ToastBox.success(stringUtil.language(388));
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    });
                }} closeBnEvents={() => {this.setState({typeMultipleShowPop: false})}}/>
            <MaskModal title={stringUtil.language(47)} visible={this.state.addMultipleShowPop} width={650} height={arithUtil.ProportionTo(document.body.clientHeight,0.75)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10,overflow:'auto'}} key={this.state.addMultipleShowPopIndex}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span style={{lineHeight:'30px',fontSize:14}}>{stringUtil.language(1158)}：{(()=>{let resultStr='';if(this.state.choiceCommodityMultiple==null)this.state.choiceCommodityMultiple=[];
                            for(let i=0;i<this.state.choiceCommodityMultiple.length;i++){
                                resultStr=resultStr+this.state.choiceCommodityMultiple[i].name;
                                if(i!=this.state.choiceCommodityMultiple.length-1)resultStr=resultStr+'，';
                            }
                            return stringUtil.subStr(resultStr,100);
                        })()}</span>
                        <span style={{lineHeight:'30px',color:'#F00',fontSize:14,fontWeight:'bold'}}>[{stringUtil.language(1182)}{this.state.choiceCommodityMultiple?this.state.choiceCommodityMultiple.length:0}{stringUtil.language(989)}]</span>
                    </div>
                    {(this.state.costsDatas?this.state.costsDatas:[]).map((item,key)=>{
                        return(<div style={{width:'100%',height:45,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:15,paddingRight:10}} key={key}>
                            <span style={{width:'30%',color:'#808080'}}>{key+1+'、'+item.name}</span>
                            <span style={{width:'15%',color:'#808080'}}>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                            <span style={{width:'15%',color:'#808080'}}>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                            {item.calMode==0?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='0' onChange={(event)=>{
                                        this.state.costsDatas[key].money = arithUtil.yuanToFen(event.target.value);
                                }}/>
                                <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                            item.calMode==1?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='0' onChange={(event)=>{
                                        this.state.costsDatas[key].money = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                }}/>
                                <span style={{color:'#D2855D'}}> {this.state.weightMode==0?stringUtil.currency(1)+'/'+stringUtil.language(1023):stringUtil.currency(1)+'/'+stringUtil.language(1172)}</span></div>:
                            item.calMode==2?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='0' onChange={(event)=>{
                                        this.state.costsDatas[key].money = parseFloat(event.target.value);
                                }}/>
                                <span style={{color:'#D2855D'}}> %</span></div>:
                            <div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='0' onChange={(event)=>{
                                        this.state.costsDatas[key].money = arithUtil.yuanToFen(event.target.value);
                                }}/></div>}
                        </div>)
                    })}
                </div>}
                okBnEvents={(event) => {
                    this.setState({addMultipleShowPop: false},()=>{
                        let commodityCostMultipleVO = {ids:[],commodityCosts:[]};
                        if(this.state.choiceCommodityMultiple&&this.state.choiceCommodityMultiple.length>0){
                            for(let i=0;i<this.state.choiceCommodityMultiple.length;i++){
                                if(this.state.choiceCommodityMultiple[i].isChoiceChenpinglong)commodityCostMultipleVO.ids.push(this.state.choiceCommodityMultiple[i].id);
                            }
                        }
                        if(this.state.costsDatas&&this.state.costsDatas.length>0){
                            for(let i=0;i<this.state.costsDatas.length;i++){
                                if(this.state.costsDatas[i].money)commodityCostMultipleVO.commodityCosts.push({
                                    costClauseId:this.state.costsDatas[i].id,
                                    money:this.state.costsDatas[i].money,
                                });
                            }
                        }
                        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_COST_MULTIPLE_URL,commodityCostMultipleVO,['json','put',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                this.initShopCommoditys();
                                ToastBox.success(stringUtil.language(388));
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    });
                }} closeBnEvents={() => {this.setState({addMultipleShowPop: false})}}/>
            <MaskModal visible={this.state.infoShowPop} title={stringUtil.language(771)} width={550} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{height:'100%',display:'flex',flexDirection:'column',paddingLeft:10,paddingRight:10}}>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(767)}：{this.state.currShopCommodityItem.name}</span>
                        <span>{stringUtil.language(620)}：{this.state.currShopCommodityItem.targetType==1?stringUtil.language(426):this.state.currShopCommodityItem.targetName}</span>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(775)}：{this.state.currShopCommodityItem.systemCommodityId==0?stringUtil.language(763):this.state.currShopCommodityItem.systemCommodityName}</span>
                        <span>{stringUtil.language(770)}：{this.state.currShopCommodityItem.unitSlave==null?'':this.state.currShopCommodityItem.unitSlave}，{stringUtil.language(769)}：{this.state.currShopCommodityItem.unitMaster==null?'':this.state.currShopCommodityItem.unitMaster}</span>
                    </div>
                    <div style={{height:20,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(1152)}：{this.state.currShopCommodityItem.wholesaleMode?stringUtil.language(1115):stringUtil.language(1114)}</span>
                        <span>1{stringUtil.language(253)}{this.state.currShopCommodityItem.unitScale}{stringUtil.language(769)}</span>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            {stringUtil.language(863)}{arithUtil.keToWeight(this.state.currShopCommodityItem.standard,this.state.weightMode)} {this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}/{this.state.currShopCommodityItem.unitMaster}，{stringUtil.language(864)}{arithUtil.keToWeight(this.state.currShopCommodityItem.reduce,this.state.weightMode)} {this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}/{this.state.currShopCommodityItem.unitMaster}
                        </div>
                        <span>{stringUtil.language(765)}：{this.state.currShopCommodityItem.shopCommodityTypeName==null?stringUtil.language(854):this.state.currShopCommodityItem.shopCommodityTypeName}</span>
                    </div>
                    <div style={{height:25,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(772)}：{this.state.currShopCommodityItem.code}</span>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1105)}：{this.state.currShopCommodityItem.specs?this.state.currShopCommodityItem.specs:'--'}</span>
                            {this.state.currShopCommodityItem.systemCommodityId==0?<span style={{height:'25px',lineHeight:'25px',color:'#52A056',borderBottomStyle:'solid',borderBottomColor:'#52A056',borderBottomWidth:1,marginLeft:20,cursor:'pointer'}} onClick={()=>{
                                this.state.currShopCommodityItemRes=JSON.parse(JSON.stringify(this.state.currShopCommodityItem));
                                this.setState({infoShowPop:false,commodityResShowPop:!this.state.commodityResShowPop});
                            }}>{stringUtil.language(773)}</span>:''}
                        </div>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(1272)}：</span>
                        <div style={{width:80,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currCommodityLevelItem=JSON.parse(JSON.stringify({}));
                            this.setState({addViewIndex:(this.state.addViewIndex?this.state.addViewIndex:0)+1,addLevelShowPop:true});
                        }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#009A61'}}>&#xe601;</i></div>
                    </div>
                    <div style={{height:0,flexGrow:1,display:'flex',flexDirection:'row',alignContent:'flex-start',flexWrap:'wrap',overflow:'auto'}}>
                        {this.state.currShopCommodityItem.levels!=null && this.state.currShopCommodityItem.levels.length>0?this.state.currShopCommodityItem.levels.map((item,key)=>{
                            return(<div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:0,flexGrow:1,color:'#808080'}}>{key+1+"、"+item.name}</span>
                                <div style={{width:80,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currCommodityLevelItem = JSON.parse(JSON.stringify(item));
                                    this.setState({editLevelShowPop:true});
                                }}><span style={{color:'#228B22'}}>{stringUtil.language(1092)}</span></div>
                                <div style={{width:80,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currCommodityLevelItem = JSON.parse(JSON.stringify(item));
                                    this.setState({delLevelShowPop:true});
                                }}><span style={{color:'#F00'}}>{stringUtil.language(1113)}</span></div>
                            </div>)
                        }):<div/>}
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        {stringUtil.language(89)}
                    </div>
                    <div style={{height:0,flexGrow:1,display:'flex',flexDirection:'row',alignContent:'flex-start',flexWrap:'wrap',overflow:'auto'}}>
                        {this.state.currShopCommodityItem.costs!=null && this.state.currShopCommodityItem.costs.length>0?this.state.currShopCommodityItem.costs.map((item,key)=>{
                            return(<div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:15,paddingRight:10}} key={key}>
                                <span style={{width:'32%',color:'#808080'}}>{key+1+"、"+item.costClauseName}</span>
                                <span style={{width:'27%',color:'#808080'}}>{(item.costClausePayee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                <span style={{width:'17%',color:'#808080'}}>{item.costClauseCalMode==0?stringUtil.language(868):item.costClauseCalMode==1?stringUtil.language(869):item.costClauseCalMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                <span style={{width:'24%',color:'#D2855D'}}>{item.costClauseCalMode==0?arithUtil.fenToYuan(item.money,2)+stringUtil.currency(1)+'/'+stringUtil.language(1173):item.costClauseCalMode==1?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money,2),0,this.state.weightMode)+stringUtil.currency(1)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172)):item.costClauseCalMode==2?item.money+'%':arithUtil.fenToYuan(item.money)}</span>
                            </div>)
                        }):<div/>}
                    </div>
                </div>} okBnTitle={stringUtil.language(1092)} closeBnTitle={stringUtil.language(1113)} closeBnColor={'#F00'}
                okBnEvents={(event) => {
                    this.state.costs = this.state.currShopCommodityItem.costs;
                    this.setState({infoShowPop: false});
                    if(otherUtil.authorityFindCode("010302")!=null){
                        this.initSystemCommoditys((response)=>{
                            if (response==0) this.setState({systemCommoditysAll:this.state.systemCommoditysAll,editShowPop:!this.state.editShowPop});
                            else this.setState({editShowPop:!this.state.editShowPop});
                        });
                    }else ToastBox.error(stringUtil.language(321));
                }} closeBnEvents={() => {
                    this.setState({infoShowPop: false});
                    if(otherUtil.authorityFindCode("010303")!=null)this.setState({delShowPop:!this.state.delShowPop});
                    else ToastBox.error(stringUtil.language(321));
                }} closeBnTopEvents={()=>{this.setState({infoShowPop: false})}}/>
            <MaskModal visible={this.state.delLevelShowPop?true:false} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            {stringUtil.language(505)+stringUtil.language(1273)}？
                        </div>}
                okBnEvents={(event) => {
                    this.state.delLevelShowPop=false;
                    this.state.infoShowPop=false;
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_LEVEL_URL+'/'+this.state.currCommodityLevelItem.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommoditys();
                            ToastBox.success(stringUtil.language(530));
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({delLevelShowPop: false})}}/>
            <MaskModal visible={this.state.editLevelShowPop?true:false} title={stringUtil.language(1092)+stringUtil.language(1273)} width={320} height={arithUtil.ProportionTo(document.body.clientHeight,0.3)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.currCommodityLevelItem.id}>
                        <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',marginTop:20}}>
                            {stringUtil.language(1273)}：<input style={arithUtil.stylesAdd(styles.inputText,{width:200})} defaultValue={this.state.currCommodityLevelItem.name} onChange={(event)=>{
                                this.state.currCommodityLevelItem.name = event.target.value;
                            }} placeholder={stringUtil.language(1010)+stringUtil.language(1273)}/>
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    if (this.state.currCommodityLevelItem.name==null || this.state.currCommodityLevelItem.name=='') {
                        ToastBox.error(stringUtil.language(1010)+stringUtil.language(1273));return;
                    }
                    this.state.editLevelShowPop=false;
                    this.state.infoShowPop=false;
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_LEVEL_URL+'/'+this.state.currCommodityLevelItem.id,{name:this.state.currCommodityLevelItem.name},['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommoditys();
                            ToastBox.success(stringUtil.language(530));
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({editLevelShowPop: false})}}/>
            <MaskModal visible={this.state.addLevelShowPop?true:false} title={stringUtil.language(1129)+stringUtil.language(1273)} width={320} height={arithUtil.ProportionTo(document.body.clientHeight,0.3)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.addViewIndex?this.state.addViewIndex:0}>
                        <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',marginTop:20}}>
                            {stringUtil.language(1273)}：<input style={arithUtil.stylesAdd(styles.inputText,{width:200})} onChange={(event)=>{
                                this.state.currCommodityLevelItem.name = event.target.value;
                            }} placeholder={stringUtil.language(1010)+stringUtil.language(1273)}/>
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    if (this.state.currCommodityLevelItem.name==null || this.state.currCommodityLevelItem.name=='') {
                        ToastBox.error(stringUtil.language(1010)+stringUtil.language(1273));return;
                    }
                    this.state.addLevelShowPop=false;
                    this.state.infoShowPop=false;
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_LEVEL_URL,{
                        name:this.state.currCommodityLevelItem.name,
                        shopCommodityId:this.state.currShopCommodityItem.id,
                    },['key','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommoditys();
                            ToastBox.success(stringUtil.language(530));
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({addLevelShowPop: false})}}/>
            <MaskModal visible={this.state.commodityResShowPop} title={this.state.currShopCommodityItem.name+stringUtil.language(1103)+'/'+(this.state.currShopCommodityItem.unitMaster?this.state.currShopCommodityItem.unitMaster:stringUtil.language(1173))} width={350} height={arithUtil.ProportionTo(document.body.clientHeight,0.7)}
                content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                    {(this.state.currShopCommodityItemRes&&this.state.currShopCommodityItemRes.members?this.state.currShopCommodityItemRes.members:[]).map((item,key)=>{
                        return(<div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingRight:10}} key={key}>
                            <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <i className="iconfontIndexCss" style={{width:40,height:'100%',fontSize:15,color:'#F00',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currShopCommodityItemRes.members.splice(key,1);
                                    this.setState({currShopCommodityItemRes:this.state.currShopCommodityItemRes});
                                }}>&#xe612;</i>
                                <span>{(key+1)+'.'+stringUtil.subStr(item.memberName,8)}</span>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <input style={{width:100,height:'30px',lineHeight:'30px',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,textAlign:'center'}} defaultValue={item.weight?arithUtil.keToWeight(item.weight,this.state.weightMode):''} type='number' onChange={(event)=>{
                                    this.state.currShopCommodityItemRes.members[key].weight = event.target.value?arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode):0;
                                }} placeholder='0'/>
                                <span style={{fontSize:10}}> {this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}/{this.state.currShopCommodityItemRes.unitMaster?this.state.currShopCommodityItemRes.unitMaster:stringUtil.language(1173)}</span>
                            </div>
                        </div>)
                    })}
                    <div style={{width:100,height:40,display:'flex',justifyContent:'center',alignItems:'center',marginTop:10,cursor:'pointer'}} onClick={()=>{
                        this.setState({commodityResChoiceShowPop:!this.state.commodityResChoiceShowPop});
                    }}><i className="iconfontIndexCss" style={{fontSize:25,color:'#FF8C00'}}>&#xe601;</i>
                    </div>
                </div>} okBnEvents={(event) => {
                    let shopCommodityMembers = [];
                    for(let i=0;i<(this.state.currShopCommodityItemRes&&this.state.currShopCommodityItemRes.members?this.state.currShopCommodityItemRes.members:[]).length;i++){
                        if(!this.state.currShopCommodityItemRes.members[i].weight){ToastBox.error(stringUtil.language(999)+this.state.currShopCommodityItemRes.members[i].memberName+stringUtil.language(1000)+'！');return;}
                        shopCommodityMembers.push({
                            memberId:this.state.currShopCommodityItemRes.members[i].memberId,
                            weight:this.state.currShopCommodityItemRes.members[i].weight
                        });
                    }
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_MEMBER_URL+'/'+this.state.currShopCommodityItemRes.id,shopCommodityMembers,['json','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(778));
                            this.state.commodityResShowPop=false;
                            this.state.currShopCommodityItem.members=this.state.currShopCommodityItemRes.members;
                            this.state.infoShowPop=true;
                            this.initShopCommoditys();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({ commodityResShowPop: false});}}/>
            <MaskModal title={stringUtil.language(392)} visible={this.state.commodityResChoiceShowPop} width={650} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'11%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                                this.state.keySearch = event.target.value;
                                if(this.state.keySearch=='')this.setState({commodityItemDatas:this.state.commoditysAll});
                            }} placeholder={stringUtil.language(32)}/>&nbsp;&nbsp;
                            <span style={{width:arithUtil.pxToDp(50),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.state.commodityItemDatas = [];
                                if (this.state.keySearch!=null&&this.state.keySearch!='') {
                                    for(let i=0;i<this.state.commoditysAll.length;i++){
                                        if ((this.state.commoditysAll[i].name).indexOf(this.state.keySearch)!=-1) {
                                            this.state.commodityItemDatas.push(this.state.commoditysAll[i]);
                                        }
                                    }
                                }else{this.state.commodityItemDatas = this.state.commoditysAll;}
                                this.setState({commodityItemDatas:this.state.commodityItemDatas});
                            }}>{stringUtil.language(1034)}</span>
                        </div>
                    </div>
                    <div style={{height:'89%',overflow:'auto'}}>
                        {(this.state.commodityItemDatas?this.state.commodityItemDatas:this.state.commoditysAll).map((item,key)=>{
                            return(<div key={key} style={{height:55,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                                this.state.currShopCommodityItemRes.members.push({
                                    memberId:item.id,
                                    memberName:item.name,
                                    weight:0
                                });
                                this.setState({commodityResChoiceShowPop:false});
                            }}><img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>
                                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',marginLeft:8}}>
                                    <span style={{fontSize:17}}>{item.name}</span>
                                    <span style={{color:'#A9A9A9',fontSize:10}}>{item.targetType==1?stringUtil.language(426):item.targetName}</span>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>} closeBnTopEvents={() => {this.setState({ commodityResChoiceShowPop: false, })}}/>
            <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            {stringUtil.language(505)}{this.state.currShopCommodityItem.name}？
                        </div>}
                okBnEvents={(event) => {
                    this.setState({delShowPop: false});
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL+'/'+this.state.currShopCommodityItem.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommoditys();
                            ToastBox.success(stringUtil.language(530));
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
            <MaskModal visible={this.state.editShowPop} title={stringUtil.language(774)} width={arithUtil.pxToDp(650)} height={arithUtil.ProportionTo(document.body.clientHeight,0.75)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.currShopCommodityItem.id}>
                    <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(767)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(165)})} defaultValue={this.state.currShopCommodityItem.name} placeholder={stringUtil.language(174)} onChange={(event)=>{
                              this.state.currShopCommodityItem.name = event.target.value;
                            }}/>
                        </div>{stringUtil.language(620)}：{this.state.currShopCommodityItem.targetType==1?stringUtil.language(426):this.state.currShopCommodityItem.targetName}
                    </div>
                    <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(775)}&nbsp;&nbsp;{this.state.currShopCommodityItem.systemCommodityId!=0?
                            <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(165)})} onChange={(event)=>{
                                this.state.currShopCommodityItem.systemCommodityId = JSON.parse(event.target.value).id;
                                this.state.currShopCommodityItem.systemCommodityName = JSON.parse(event.target.value).name;
                              }}>
                              <option style={{display:'none'}}></option>
                              {this.state.systemCommoditysAll.map((item,key)=>{
                                if (item.id!=0) {
                                    return(<option value={JSON.stringify(item)} selected={this.state.currShopCommodityItem.systemCommodityId==item.id?'selected':''} key={key}>{item.name}</option>)
                                }
                              })}
                            </select>:stringUtil.language(763)}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(765)}&nbsp;&nbsp;<select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(125)})} onChange={(event)=>{
                                this.state.currShopCommodityItem.shopCommodityTypeId = JSON.parse(event.target.value).id;
                                this.state.currShopCommodityItem.shopCommodityTypeName = JSON.parse(event.target.value).name;
                              }}>
                              <option style={{display:'none'}}></option>
                              {this.state.commodityTypesAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} selected={this.state.currShopCommodityItem.shopCommodityTypeId==item.id?'selected':''} key={key}>{item.name}</option>)
                              })}
                            </select>
                        </div>
                    </div>
                    <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div>{stringUtil.language(770)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(40),textAlign:'center'})} defaultValue={this.state.currShopCommodityItem.unitSlave?this.state.currShopCommodityItem.unitSlave:''} placeholder={stringUtil.language(1173)} onChange={(event)=>{
                            this.state.currShopCommodityItem.unitSlave = event.target.value;
                        }}/>，{stringUtil.language(769)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(40),textAlign:'center'})} defaultValue={this.state.currShopCommodityItem.unitMaster?this.state.currShopCommodityItem.unitMaster:''} placeholder={stringUtil.language(1173)} onChange={(event)=>{
                                this.state.currShopCommodityItem.unitMaster = event.target.value;
                                this.refs.editStandardMasterRef.innerHTML=this.state.currShopCommodityItem.unitMaster?this.state.currShopCommodityItem.unitMaster:stringUtil.language(1173);
                                this.refs.editReduceMasterRef.innerHTML=this.state.currShopCommodityItem.unitMaster?this.state.currShopCommodityItem.unitMaster:stringUtil.language(1173);
                            }}/></div>
                        <div>1{stringUtil.language(253)}<input style={arithUtil.stylesAdd(styles.inputText,{width:50})} defaultValue={this.state.currShopCommodityItem.unitScale?this.state.currShopCommodityItem.unitScale:''} type='number' placeholder='0' onChange={(event)=>{
                            this.state.currShopCommodityItem.unitScale = parseFloat(event.target.value);
                        }}/>{stringUtil.language(769)}</div>
                    </div>
                    <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                {stringUtil.language(776)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:50})} defaultValue={this.state.currShopCommodityItem.standard?arithUtil.keToWeight(this.state.currShopCommodityItem.standard,this.state.weightMode):''} type='number' placeholder='0' onChange={(event)=>{
                                    this.state.currShopCommodityItem.standard = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                }}/>&nbsp;{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}/
                                <span ref='editStandardMasterRef'>{this.state.currShopCommodityItem.unitMaster?this.state.currShopCommodityItem.unitMaster:stringUtil.language(1173)}</span>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                ，{stringUtil.language(777)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:50})} defaultValue={this.state.currShopCommodityItem.reduce?arithUtil.keToWeight(this.state.currShopCommodityItem.reduce,this.state.weightMode):''} type='number' placeholder='0' onChange={(event)=>{
                                    this.state.currShopCommodityItem.reduce = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                }}/>&nbsp;{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}/
                                <span ref='editReduceMasterRef'>{this.state.currShopCommodityItem.unitMaster?this.state.currShopCommodityItem.unitMaster:stringUtil.language(1173)}</span>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(1152)}&nbsp;&nbsp;<select style={arithUtil.stylesAdd(styles.inputText,{width:80})} onChange={(event)=>{
                                this.state.currShopCommodityItem.wholesaleMode = JSON.parse(event.target.value).index;
                              }}>
                              {[{index:0,name:stringUtil.language(1114)},{index:1,name:stringUtil.language(1115)}].map((item,key)=>{
                                return(<option value={JSON.stringify(item)} selected={this.state.currShopCommodityItem.wholesaleMode==item.index?'selected':''} key={key}>{item.name}</option>)
                              })}
                            </select>
                        </div>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(772)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:165,textAlign:'center'})} defaultValue={this.state.currShopCommodityItem.code} placeholder={stringUtil.language(772)+'..'} onChange={(event)=>{
                                this.state.currShopCommodityItem.code = event.target.value;
                            }}/>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(1105)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:165,textAlign:'center'})} defaultValue={this.state.currShopCommodityItem.specs} placeholder={stringUtil.language(1105)+'..'} onChange={(event)=>{
                                this.state.currShopCommodityItem.specs = event.target.value;
                            }}/>
                        </div>
                    </div>
                    <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        {stringUtil.language(177)}<i className="iconfontIndexCss" style={{fontSize:30,color:'#C0C0C0',cursor:'pointer'}} onClick={()=>{
                            this.setState({selectDiglogTagEntCostE: !this.state.selectDiglogTagEntCostE});
                        }}>&#xe601;</i>
                        <MaskModal title={stringUtil.language(33)} visible={this.state.selectDiglogTagEntCostE} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                            content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                                    {(this.state.costsAll?this.state.costsAll:[]).map((item,key)=>{
                                    if (this.state.currShopCommodityItem.targetType==0 || (this.state.currShopCommodityItem.targetType!=0&&item.payee!=0)) {
                                        return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                            this.state.costs.push({
                                                costClauseId:item.id,
                                                costClauseName:item.name,
                                                costClausePayee:item.payee,
                                                costClauseCalMode:item.calMode,
                                                money:0
                                            });
                                            this.setState({selectDiglogTagEntCostE:false});
                                        }}>
                                            <span>{item.name}</span>
                                            <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                                            <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                            <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                        </div>);
                                    }
                                    })}
                                </div>}
                            closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCostE:false});}}/>
                    </div>
                    <div style={{position:'absolute',left:0,top:230,right:0,bottom:40,display:'flex',flexDirection:'row',flexWrap:'wrap',overflow:'auto'}}>
                        {this.state.costs.map((item,key)=>{
                            return(<div style={{width:'100%',height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:15,paddingRight:10}} key={key}>
                                <span style={{width:'30%',color:'#808080'}}>{key+1+'、'+item.costClauseName}</span>
                                <span style={{width:'15%',color:'#808080'}}>{(item.costClausePayee==0?stringUtil.language(1049):item.costClausePayee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                <span style={{width:'15%',color:'#808080'}}>{item.costClauseCalMode==0?stringUtil.language(868):item.costClauseCalMode==1?stringUtil.language(869):item.costClauseCalMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                {item.costClauseCalMode==0?<div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' defaultValue={this.state.costs[key].money!=null?arithUtil.fenToYuan(this.state.costs[key].money,2):0} onChange={(event)=>{
                                         this.state.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                    }}/>
                                    <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                item.costClauseCalMode==1?<div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' defaultValue={this.state.costs[key].money!=null?arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.costs[key].money,2),0,this.state.weightMode):0} onChange={(event)=>{
                                         this.state.costs[key].money = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                    }}/>
                                    <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}{this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172)}</span></div>:
                                item.costClauseCalMode==2?<div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' defaultValue={this.state.costs[key].money!=null?this.state.costs[key].money:0} onChange={(event)=>{
                                         this.state.costs[key].money = parseFloat(event.target.value);
                                    }}/>
                                    <span style={{color:'#D2855D'}}> %</span></div>:
                                <div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' defaultValue={this.state.costs[key].money!=null?arithUtil.fenToYuan(this.state.costs[key].money):0} onChange={(event)=>{
                                         this.state.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                    }}/></div>}
                                <i className="iconfontIndexCss" style={{width:'10%',fontSize:25,color:'#F00',textAlign:'center',textAlignVertical:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.costs.splice(key,1);
                                    this.setState({costs:this.state.costs});
                                }}>&#xe612;</i>
                            </div>)
                        })}
                    </div>
                    <div style={{width:'100%',height:40,position:'absolute',bottom:0,display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                        <div style={{width:'35%',height:'35px',color:this.state.currShopCommodityItem.wholesaleMode!=1?'#696969':'#A9A9A9',backgroundColor:this.state.currShopCommodityItem.wholesaleMode!=1?'#FFC80E':'#D3D3D0',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currShopCommodityItem.wholesaleMode=0;
                            this.setState({currShopCommodityItem:this.state.currShopCommodityItem});
                        }}>{stringUtil.language(254)}</div>
                        <div style={{width:'35%',height:'35px',color:this.state.currShopCommodityItem.wholesaleMode==1?'#696969':'#A9A9A9',backgroundColor:this.state.currShopCommodityItem.wholesaleMode==1?'#FFC80E':'#D3D3D0',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currShopCommodityItem.wholesaleMode=1;
                            this.setState({currShopCommodityItem:this.state.currShopCommodityItem});
                        }}>{stringUtil.language(255)}</div>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currShopCommodityItem.name==null||this.state.currShopCommodityItem.name=='') {
                        ToastBox.error(stringUtil.language(78)+'！');return;
                    }
                    if (this.state.currShopCommodityItem.systemCommodityId==null){
                        ToastBox.error(stringUtil.language(48)+'！');return;
                    }
                    if (this.state.currShopCommodityItem.unitMaster==null || this.state.currShopCommodityItem.unitMaster=='') {
                        this.state.currShopCommodityItem.unitMaster = stringUtil.language(1173);
                    }
                    if (this.state.costs.length>0) {
                        this.state.currShopCommodityItem.costs = this.state.costs;
                    }
                    let shopCommodityUpdateVo = {costs:[]};
                    shopCommodityUpdateVo.name = this.state.currShopCommodityItem.name;
                    shopCommodityUpdateVo.code = this.state.currShopCommodityItem.code;
                    shopCommodityUpdateVo.specs = this.state.currShopCommodityItem.specs;
                    shopCommodityUpdateVo.wholesaleMode = this.state.currShopCommodityItem.wholesaleMode?this.state.currShopCommodityItem.wholesaleMode:0;
                    shopCommodityUpdateVo.standard = this.state.currShopCommodityItem.standard!=null?this.state.currShopCommodityItem.standard:0;
                    shopCommodityUpdateVo.reduce = this.state.currShopCommodityItem.reduce!=null?this.state.currShopCommodityItem.reduce:0;
                    shopCommodityUpdateVo.unitMaster = this.state.currShopCommodityItem.unitMaster;
                    shopCommodityUpdateVo.unitSlave = this.state.currShopCommodityItem.unitSlave;
                    shopCommodityUpdateVo.unitScale = this.state.currShopCommodityItem.unitScale!=null?this.state.currShopCommodityItem.unitScale:0;
                    shopCommodityUpdateVo.systemCommodityId = this.state.currShopCommodityItem.systemCommodityId;
                    shopCommodityUpdateVo.shopCommodityTypeId = this.state.currShopCommodityItem.shopCommodityTypeId!=null?this.state.currShopCommodityItem.shopCommodityTypeId:0;
                    if(this.state.currShopCommodityItem.costs!=null&&this.state.currShopCommodityItem.costs.length>0){
                        for(let i=0;i<this.state.currShopCommodityItem.costs.length;i++){
                            let costsTemp = {};
                            costsTemp.costClauseId = this.state.currShopCommodityItem.costs[i].costClauseId;
                            costsTemp.money = this.state.currShopCommodityItem.costs[i].money;
                            shopCommodityUpdateVo.costs.push(costsTemp);
                        }
                    }
                    this.setState({editShowPop: false});
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL+'/'+this.state.currShopCommodityItem.id,shopCommodityUpdateVo,['json','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommoditys();
                            ToastBox.success(stringUtil.language(778)+'！');
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({editShowPop: false})}}/>
            <MaskModal visible={this.state.addBasketShowPop} title={stringUtil.language(779)} width={450} height={300}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.addShowPopIndex}>
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(767)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:200})} defaultValue={this.state.currShopCommodityItem.name?this.state.currShopCommodityItem.name:''} placeholder={stringUtil.language(174)} onChange={(event)=>{
                            this.state.currShopCommodityItem.name = event.target.value;
                        }}/>
                    </div>
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(620)}&nbsp;&nbsp;<Select showSearch style={{width:200}} onChange={(event)=>{
                                this.state.currShopCommodityItem.targetId = JSON.parse(event).targetId;
                                this.state.currShopCommodityItem.targetType = JSON.parse(event).targetType;
                                this.state.currShopCommodityItem.targetName = JSON.parse(event).targetName;
                                if (this.state.costs!=null&&this.state.costs.length>0) {
                                    for(let i=0;i<this.state.costs.length;i++){
                                        let itemTemp = this.state.costs[i];
                                        if ((this.state.currShopCommodityItem.targetType==1||this.state.currShopCommodityItem.targetType==2) && itemTemp.payee==0) {
                                            this.state.costs.splice(i,1);
                                        }
                                    }
                                }
                              }}>
                              <option style={{display:'none'}}></option>
                              {this.state.targetsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.targetName}</option>)
                              })}
                        </Select>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(772)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:200,textAlign:'center'})} defaultValue={this.state.currShopCommodityItem.code} placeholder={stringUtil.language(772)+".."} onChange={(event)=>{
                            this.state.currShopCommodityItem.code = event.target.value;
                        }}/>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currShopCommodityItem.name==null||this.state.currShopCommodityItem.name=='') {
                        ToastBox.error(stringUtil.language(78)+'！');return;
                    }
                    if (this.state.currShopCommodityItem.targetId==null||this.state.currShopCommodityItem.targetId==0) {
                        ToastBox.error(stringUtil.language(178)+'！');return;
                    }
                    let shopCommodityInsertVo = {costs:[]};
                    shopCommodityInsertVo.name = this.state.currShopCommodityItem.name;
                    shopCommodityInsertVo.code = this.state.currShopCommodityItem.code;
                    shopCommodityInsertVo.specs = this.state.currShopCommodityItem.specs;
                    shopCommodityInsertVo.wholesaleMode = 1;
                    shopCommodityInsertVo.targetType = this.state.currShopCommodityItem.targetType;
                    shopCommodityInsertVo.targetId = this.state.currShopCommodityItem.targetId;
                    shopCommodityInsertVo.standard = 0;
                    shopCommodityInsertVo.reduce = 0;
                    shopCommodityInsertVo.unitMaster = '个';
                    shopCommodityInsertVo.unitSlave = '个';
                    shopCommodityInsertVo.unitScale = 0;
                    shopCommodityInsertVo.systemCommodityId = -1;
                    shopCommodityInsertVo.shopCommodityTypeId = 0;
                    this.setState({addBasketShowPop: false});
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL,shopCommodityInsertVo,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommoditys();
                            ToastBox.success(stringUtil.language(388));
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({addBasketShowPop: false})}}/>
            <MaskModal visible={this.state.addShowPop} title={stringUtil.language(739)} width={arithUtil.pxToDp(650)} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex}>
                    <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(767)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(165)})} defaultValue={this.state.currShopCommodityItem.name?this.state.currShopCommodityItem.name:''} placeholder={stringUtil.language(174)} onChange={(event)=>{
                              this.state.currShopCommodityItem.name = event.target.value;
                            }}/>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(620)}&nbsp;&nbsp;<Select showSearch style={{width:200}} onChange={(event)=>{
                                this.state.currShopCommodityItem.targetId = JSON.parse(event).targetId;
                                this.state.currShopCommodityItem.targetType = JSON.parse(event).targetType;
                                this.state.currShopCommodityItem.targetName = JSON.parse(event).targetName;
                                if (this.state.costs!=null&&this.state.costs.length>0) {
                                    for(let i=0;i<this.state.costs.length;i++){
                                        let itemTemp = this.state.costs[i];
                                        if ((this.state.currShopCommodityItem.targetType==1||this.state.currShopCommodityItem.targetType==2) && itemTemp.payee==0) {
                                            this.state.costs.splice(i,1);
                                        }
                                    }
                                }
                              }}>
                              <option style={{display:'none'}}></option>
                              {this.state.targetsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.targetName}</option>)
                              })}
                            </Select>
                        </div>
                    </div>
                    <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(775)}&nbsp;&nbsp; <div style={{width:160,height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,paddingLeft:5,paddingRight:5}} onClick={(event)=>{
                                this.setState({selectDiglogTagSysCom:true});
                            }}><span>{this.state.currShopCommodityItem.systemCommodityName?this.state.currShopCommodityItem.systemCommodityName:''}</span><i className="iconfontIndexCss" style={{fontSize:9,color:'#A9A9A9'}}>&#xe603;</i>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(765)}&nbsp;&nbsp;<select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(125)})} onChange={(event)=>{
                                this.state.currShopCommodityItem.shopCommodityTypeId = JSON.parse(event.target.value).id;
                                this.state.currShopCommodityItem.shopCommodityTypeName = JSON.parse(event.target.value).name;
                              }}>
                              <option style={{display:'none'}}></option>
                              {this.state.commodityTypesAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                              })}
                            </select>
                        </div>
                    </div>
                    <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div>{stringUtil.language(770)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(40),textAlign:'center'})} placeholder={stringUtil.language(1173)} onChange={(event)=>{
                            this.state.currShopCommodityItem.unitSlave = event.target.value;
                        }}/>，{stringUtil.language(769)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(40),textAlign:'center'})} placeholder={stringUtil.language(1173)} onChange={(event)=>{
                            this.state.currShopCommodityItem.unitMaster = event.target.value;
                            this.refs.addStandardMasterRef.innerHTML=this.state.currShopCommodityItem.unitMaster?this.state.currShopCommodityItem.unitMaster:stringUtil.language(1173);
                            this.refs.addReduceMasterRef.innerHTML=this.state.currShopCommodityItem.unitMaster?this.state.currShopCommodityItem.unitMaster:stringUtil.language(1173);
                        }}/></div>
                        <div>1{stringUtil.language(253)}<input style={arithUtil.stylesAdd(styles.inputText,{width:50})} type='number' placeholder='0' onChange={(event)=>{
                            this.state.currShopCommodityItem.unitScale = parseFloat(event.target.value);
                        }}/>{stringUtil.language(769)}</div>
                    </div>
                    <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(776)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:50})} type='number' placeholder='0' onChange={(event)=>{
                                this.state.currShopCommodityItem.standard = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                            }}/>&nbsp;{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}/
                            <span ref='addStandardMasterRef'>{stringUtil.language(1173)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(777)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:50})} type='number' placeholder='0' onChange={(event)=>{
                                this.state.currShopCommodityItem.reduce = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                            }}/>&nbsp;{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}/
                            <span ref='addReduceMasterRef'>{stringUtil.language(1173)}</span>
                        </div>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(772)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:165,textAlign:'center'})} placeholder={stringUtil.language(772)+'..'} onChange={(event)=>{
                                this.state.currShopCommodityItem.code = event.target.value;
                            }}/>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {stringUtil.language(1105)}&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:165,textAlign:'center'})} placeholder={stringUtil.language(1105)+'..'} onChange={(event)=>{
                                this.state.currShopCommodityItem.specs = event.target.value;
                            }}/>
                        </div>
                    </div>
                    <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        {stringUtil.language(177)}<i className="iconfontIndexCss" style={{fontSize:30,color:'#C0C0C0',cursor:'pointer'}} onClick={()=>{
                            if (this.state.currShopCommodityItem.targetId!=null) {
                                this.setState({selectDiglogTagEntCost:true});
                            }else{ToastBox.error(stringUtil.language(178)+'！');}
                        }}>&#xe601;</i>
                        <MaskModal title={stringUtil.language(33)} visible={this.state.selectDiglogTagEntCost} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                            content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                                    {(this.state.costsAll?this.state.costsAll:[]).map((item,key)=>{
                                    if (this.state.currShopCommodityItem.targetType==0 || (this.state.currShopCommodityItem.targetType!=0&&item.payee!=0)) {
                                        return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} onClick={()=>{
                                            this.state.costs.push({
                                                costClauseId:item.id,
                                                costClauseName:item.name,
                                                costClausePayee:item.payee,
                                                costClauseCalMode:item.calMode,
                                                money:0
                                            });
                                            this.setState({selectDiglogTagEntCost:false});
                                        }}>
                                            <span>{item.name}</span>
                                            <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                                            <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                            <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                        </div>);
                                    }
                                })}
                            </div>}
                            closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCost:false});}}/>
                    </div>
                    <div style={{position:'absolute',left:0,top:230,right:0,bottom:40,display:'flex',flexDirection:'row',flexWrap:'wrap',overflow:'auto'}}>
                        {this.state.costs.map((item,key)=>{
                            return(<div style={{width:'100%',height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:15,paddingRight:10}} key={key}>
                                <span style={{width:'30%',color:'#808080'}}>{key+1+'、'+item.costClauseName}</span>
                                <span style={{width:'15%',color:'#808080'}}>{(item.costClausePayee==0?stringUtil.language(1049):item.costClausePayee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                <span style={{width:'15%',color:'#808080'}}>{item.costClauseCalMode==0?stringUtil.language(868):item.costClauseCalMode==1?stringUtil.language(869):item.costClauseCalMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                {item.costClauseCalMode==0?<div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='0' onChange={(event)=>{
                                         this.state.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                    }}/>
                                    <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                item.costClauseCalMode==1?<div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='0' onChange={(event)=>{
                                         this.state.costs[key].money = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                    }}/>
                                    <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}{this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172)}</span></div>:
                                item.costClauseCalMode==2?<div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='0' onChange={(event)=>{
                                         this.state.costs[key].money = parseFloat(event.target.value);
                                    }}/>
                                    <span style={{color:'#D2855D'}}> %</span></div>:
                                <div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(70)})} type='number' placeholder='0' onChange={(event)=>{
                                         this.state.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                    }}/></div>}
                                <i className="iconfontIndexCss" style={{width:'10%',fontSize:25,color:'#F00',textAlign:'center',textAlignVertical:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.costs.splice(key,1);
                                    this.setState({costs:this.state.costs});
                                }}>&#xe612;</i>
                            </div>)
                        })}
                    </div>
                    <div style={{width:'100%',height:40,position:'absolute',bottom:0,display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                        <div style={{width:'35%',height:'35px',color:this.state.currShopCommodityItem.wholesaleMode!=1?'#696969':'#A9A9A9',backgroundColor:this.state.currShopCommodityItem.wholesaleMode!=1?'#FFC80E':'#D3D3D0',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currShopCommodityItem.wholesaleMode=0;
                            this.setState({currShopCommodityItem:this.state.currShopCommodityItem});
                        }}>{stringUtil.language(254)}</div>
                        <div style={{width:'35%',height:'35px',color:this.state.currShopCommodityItem.wholesaleMode==1?'#696969':'#A9A9A9',backgroundColor:this.state.currShopCommodityItem.wholesaleMode==1?'#FFC80E':'#D3D3D0',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currShopCommodityItem.wholesaleMode=1;
                            this.setState({currShopCommodityItem:this.state.currShopCommodityItem});
                        }}>{stringUtil.language(255)}</div>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currShopCommodityItem.name==null||this.state.currShopCommodityItem.name=='') {
                        ToastBox.error(stringUtil.language(78)+'！');return;
                    }
                    if (this.state.currShopCommodityItem.targetId==null||this.state.currShopCommodityItem.targetId==0) {
                        ToastBox.error(stringUtil.language(178)+'！');return;
                    }
                    if (this.state.currShopCommodityItem.systemCommodityId==null){
                        ToastBox.error(stringUtil.language(48)+'！');return;
                    }
                    if (this.state.currShopCommodityItem.unitMaster==null || this.state.currShopCommodityItem.unitMaster=='') this.state.currShopCommodityItem.unitMaster = stringUtil.language(1173);
                    if (this.state.currShopCommodityItem.unitSlave==null || this.state.currShopCommodityItem.unitSlave=='') this.state.currShopCommodityItem.unitSlave = stringUtil.language(1173);
                    if (this.state.costs.length>0) {
                        this.state.currShopCommodityItem.costs = this.state.costs;
                    }
                    let shopCommodityInsertVo = {costs:[]};
                    shopCommodityInsertVo.name = this.state.currShopCommodityItem.name;
                    shopCommodityInsertVo.code = this.state.currShopCommodityItem.code;
                    shopCommodityInsertVo.specs = this.state.currShopCommodityItem.specs;
                    shopCommodityInsertVo.wholesaleMode = this.state.currShopCommodityItem.wholesaleMode?this.state.currShopCommodityItem.wholesaleMode:0;
                    shopCommodityInsertVo.targetType = this.state.currShopCommodityItem.targetType;
                    shopCommodityInsertVo.targetId = this.state.currShopCommodityItem.targetId;
                    shopCommodityInsertVo.standard = this.state.currShopCommodityItem.standard!=null?this.state.currShopCommodityItem.standard:0;
                    shopCommodityInsertVo.reduce = this.state.currShopCommodityItem.reduce!=null?this.state.currShopCommodityItem.reduce:0;
                    shopCommodityInsertVo.unitMaster = this.state.currShopCommodityItem.unitMaster;
                    shopCommodityInsertVo.unitSlave = this.state.currShopCommodityItem.unitSlave;
                    shopCommodityInsertVo.unitScale = this.state.currShopCommodityItem.unitScale!=null?this.state.currShopCommodityItem.unitScale:0;
                    shopCommodityInsertVo.systemCommodityId = this.state.currShopCommodityItem.systemCommodityId;
                    shopCommodityInsertVo.shopCommodityTypeId = this.state.currShopCommodityItem.shopCommodityTypeId!=null?this.state.currShopCommodityItem.shopCommodityTypeId:0;
                    if(this.state.currShopCommodityItem.costs!=null&&this.state.currShopCommodityItem.costs.length>0){
                        for(let i=0;i<this.state.currShopCommodityItem.costs.length;i++){
                            let costsTemp = {};
                            costsTemp.costClauseId = this.state.currShopCommodityItem.costs[i].costClauseId;
                            costsTemp.money = this.state.currShopCommodityItem.costs[i].money;
                            shopCommodityInsertVo.costs.push(costsTemp);
                        }
                    }
                    this.setState({addShowPop: false});
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL,shopCommodityInsertVo,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommoditys();
                            ToastBox.success(stringUtil.language(388));
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({addShowPop: false})}}/>
            <MaskModal title={stringUtil.language(392)} visible={this.state.selectDiglogTagSysCom} width={arithUtil.ProportionTo(document.body.clientWidth,0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'11%',display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <input style={arithUtil.stylesAdd(styles.inputText,{width:250})} id='sysComSearch' onChange={(event)=>{
                            this.state.keySearchSys = event.target.value;
                            if(this.state.keySearchSys==null||this.state.keySearchSys==''){
                                this.setState({systemCommoditysData:this.state.systemCommoditysAll},()=>{(document.getElementById("sysComSearch")).focus();});
                            }
                        }} placeholder={stringUtil.language(32)}/>&nbsp;&nbsp;
                        <span style={{width:arithUtil.pxToDp(50),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.systemCommoditysData = [];
                            for(let i=0;i<this.state.systemCommoditysAll.length;i++){
                                let commodityItem = this.state.systemCommoditysAll[i];
                                if (this.state.keySearchSys!=null&&(commodityItem.name.indexOf(this.state.keySearchSys)!=-1)) {
                                    this.state.systemCommoditysData.push(commodityItem);
                                }
                            }
                            this.setState({systemCommoditysData:this.state.systemCommoditysData},()=>{(document.getElementById("sysComSearch")).focus();});
                        }}>{stringUtil.language(1034)}</span>
                    </div>
                    <div style={{height:'89%',overflow:'auto'}}>
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        {(this.state.systemCommoditysData?this.state.systemCommoditysData:this.state.systemCommoditysAll).map((item,key)=>{
                            return(<div key={key} style={{width:90,height:120,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#C0C0C0',borderWidth: 1,margin:2,borderRadius:3,cursor:'pointer'}} onClick={()=>{
                                this.state.currShopCommodityItem.systemCommodityId = item.id;
                                this.state.currShopCommodityItem.systemCommodityName = item.name;
                                this.state.currShopCommodityItem.name = item.name;
                                this.setState({selectDiglogTagSysCom:false});
                            }}>
                                <img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>
                                <span style={{fontSize:15}}>{item.name}</span>
                            </div>)
                        })}
                    </div>
                    </div>
                </div>} closeBnTopEvents={() => {this.setState({selectDiglogTagSysCom:false})}}/>
            <MaskModal visible={this.state.typeShowPop} title={stringUtil.language(765)} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.7)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'80%',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1,overflow:'auto'}}>
                        <div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currCommodityTypeSearchItem = JSON.parse(JSON.stringify({id:-1,name:stringUtil.language(389)}));
                            this.setState({typeShowPop:false});
                        }}>{stringUtil.language(389)}</div>
                        <div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currCommodityTypeSearchItem = JSON.parse(JSON.stringify({id:0,name:stringUtil.language(854)}));
                            this.setState({typeShowPop:false});
                        }}>{stringUtil.language(854)}</div>
                        {this.state.commodityTypesAll.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                                <span style={{cursor:'pointer'}} onClick={()=>{
                                    this.state.currCommodityTypeSearchItem = JSON.parse(JSON.stringify(item));
                                    this.setState({typeShowPop:false});
                                }}>{item.name}</span>
                                <div style={{display:'flex',flexDirection:'row'}}>
                                    {otherUtil.authorityFindCode("010305")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#228B22',cursor:'pointer'}} onClick={()=>{
                                        this.state.currCommodityTypeItem = JSON.parse(JSON.stringify(item));
                                        this.setState({
                                            currCommodityTypeItem:this.state.currCommodityTypeItem,
                                            editTypeShowPop:!this.state.editTypeShowPop
                                        })
                                    }}>&#xe63a;</i>:''}
                                    {otherUtil.authorityFindCode("010306")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#F00',marginLeft:arithUtil.pxToDp(15),cursor:'pointer'}} onClick={()=>{
                                        this.state.currCommodityTypeItem = JSON.parse(JSON.stringify(item));
                                        this.setState({maskModalTag:!this.state.maskModalTag})
                                    }}>&#xe612;</i>:''}
                                </div>
                            </div>)
                        })}
                    </div>
                    {otherUtil.authorityFindCode("010304")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(5)}}>
                        <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} placeholder={stringUtil.language(79)} onChange={(event)=>{
                            this.state.currCommodityTypeItem = {name:event.target.value};
                        }}/>
                        <span style={{width:arithUtil.pxToDp(65),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#d2855d',marginLeft:3,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL,{name:this.state.currCommodityTypeItem.name},['key','post',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    this.initShopCommodityTypes();
                                    ToastBox.success(stringUtil.language(388));
                                    this.setState({currCommodityTypeItem:{}});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>{stringUtil.language(1129)}</span>
                    </div>:''}
                </div>} closeBnTopEvents={()=>{this.setState({typeShowPop:false});}}/>
            <MaskModal visible={this.state.editTypeShowPop} title={stringUtil.language(780)} width={arithUtil.pxToDp(320)} height={arithUtil.ProportionTo(document.body.clientHeight,0.3)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.currCommodityTypeItem.id}>
                        <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                            分类&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} defaultValue={this.state.currCommodityTypeItem.name} placeholder={stringUtil.language(79)} onChange={(event)=>{
                                this.state.currCommodityTypeItem.name = event.target.value;
                            }}/>
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    if (this.state.currCommodityTypeItem.name==null || this.state.currCommodityTypeItem.name=='') {
                        ToastBox.error(stringUtil.language(1010)+'分类名！');return;
                    }
                    this.setState({editTypeShowPop: false});
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL+'/'+this.state.currCommodityTypeItem.id,{name:this.state.currCommodityTypeItem.name},['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommodityTypes();
                            ToastBox.success(stringUtil.language(388));
                            this.setState({currCommodityTypeItem:{}});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({currCommodityTypeItem:{},editTypeShowPop: false})}}/>
            <MaskModal visible={this.state.maskModalTag} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            {stringUtil.language(179)}？
                        </div>}
                okBnEvents={(event) => {
                    this.setState({maskModalTag:false})
                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL+'/'+this.state.currCommodityTypeItem.id,this.state.currShopCommodityItem,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initShopCommodityTypes();
                            ToastBox.success(stringUtil.language(530));
                            this.setState({currCommodityTypeItem:{}});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({maskModalTag:false})}}/>
      </div>)
  }
}

const styles = {
  inputText:{
      height:30,
      borderColor:'#d0d0d0',
      borderStyle:'solid',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:5
  },
};