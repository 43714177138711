import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';

export default class TabContact extends Component{
  constructor(props){
    super(props);
    this.state={
      roleIndex:0,
      customersAll:[],
      customers:null,
      currCustomerItem:null,//当前选中的stringUtil.language(1048)
      ownersAll:[],
      owners:null,
      currOwnerItem:null,//当前选中的stringUtil.language(1049)
      suppliersAll:[],
      suppliers:null,
      currSupplierItem:null,//当前选中的stringUtil.language(921)
      workers:null,
      currWorkerItem:null,//当前选中的stringUtil.language(1031)
      companysAll:[],
      companys:null,
      currCompanyItem:null,//当前选中的stringUtil.language(1046)
      showInitArrPop:false,showInitArrPops:false,showInitArrPopss:false,showInitArrPopsss:false,
      delCustomerShowPop:false,
      delOwnerShowPop:false,
      delSupplierShowPop:false,
      delWorkerShowPop:false,
      workerReplyPop:false,
      showInitaccountsPop: false,
      initArrearsAll:[],
      currInitArrearserItem:{},
      searchCustomer:{
        warning:-1,
        keySearch:'',
        sort:0,//0金额排序，名称字母排序
      },
      searchOwner:{},
      searchSupplier:{},
      warehousesAll:[],
      searchCompany:{},
      workerWarehouseDO:null,
      keyTagVal:-1,
    };
  }
  componentDidMount(){
    stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_ARREARS_SHOP_URL,null,['key','get',1],(successResponse)=>{//放在首stringUtil.language(1199)，获取stringUtil.language(1030)设置信息
        if (successResponse.status==200) {
            this.state.customerArrearShop=successResponse.data;
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
    this.state.owners=[];
    this.state.suppliers=[];
    this.state.workers=[];
    this.state.companys=[];
    this.initCustomersData();
    // this.initOwnersData();
    // this.initSuppliersData();
    // this.initWorkersData();
    // this.initCompanysData();
  }
  initCustomersData(){
    stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
          this.state.customersAll = successResponse.data;
          this.searchCustomer();
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initOwnersData(){
    stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
          this.state.ownersAll = successResponse.data;
          this.searchOwner();
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initSuppliersData(){
    stringUtil.httpRequest(urlUtil.OAUTH_SUPPLIER_URL,{state:1},['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
          this.state.suppliersAll = successResponse.data;
          this.searchSupplier();
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initWorkersData(){
    stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        this.state.workers=successResponse.data;
        if(this.state.customers&&this.state.owners&&this.state.suppliers);this.setState({workers:this.state.workers});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initCompanysData(){
    stringUtil.httpRequest(urlUtil.OAUTH_COMPANY_URL,null,['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        this.state.companysAll = successResponse.data;
        this.searchCompany();
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  searchCustomer(){
    let customersTemp = [];this.state.totalArrears=0;
    for(let i=0;i<this.state.customersAll.length;i++){
      let customerItem = JSON.parse(JSON.stringify(this.state.customersAll[i]));
      if (this.state.searchCustomer.warning==null || this.state.searchCustomer.warning==-1 || this.state.searchCustomer.warning==customerItem.warning) {
        if(this.state.searchCustomer.keySearch==null || (customerItem.noteName).indexOf(this.state.searchCustomer.keySearch)!=-1){
          this.state.totalArrears=(this.state.totalArrears).add(customerItem.arrears);
          customersTemp.push(customerItem);
        }
      }
    }
    if(this.state.searchCustomer.sort==0){//金额排序
      customersTemp = customersTemp.sort((param1, param2)=>{
        if(param2.type==1)return 1;
        return ((param2.arrears).sub(param1.arrears));
      });
    }else if(this.state.searchCustomer.sort==1){//名称字母排序
      customersTemp = customersTemp.sort(function compareFunction(param1, param2) {
        if(param2.type==1)return 1;
        return (param1.noteName).localeCompare(param2.noteName,"zh");
      });
    }
    this.state.customers=customersTemp;
    if(this.state.owners&&this.state.suppliers&&this.state.workers&&this.state.companys)this.setState({customers:customersTemp});
  }
  searchOwner(){
    let ownersTemp = [];
    for(let i=0;i<this.state.ownersAll.length;i++){
      let ownerItem = JSON.parse(JSON.stringify(this.state.ownersAll[i]));
      if(this.state.searchOwner.keySearch==null || (ownerItem.noteName).indexOf(this.state.searchOwner.keySearch)!=-1){
        ownersTemp.push(ownerItem);
      }
    }
    this.state.owners=ownersTemp;
    if(this.state.customers&&this.state.suppliers&&this.state.workers&&this.state.companys)this.setState({owners:ownersTemp});
  }
  searchSupplier(){
    let suppliersTemp = [];
    for(let i=0;i<this.state.suppliersAll.length;i++){
      let supplierItem = JSON.parse(JSON.stringify(this.state.suppliersAll[i]));
      if(this.state.searchSupplier.keySearch==null || (supplierItem.noteName).indexOf(this.state.searchSupplier.keySearch)!=-1){
        suppliersTemp.push(supplierItem);
      }
    }
    this.state.suppliers=suppliersTemp;
    if(this.state.customers&&this.state.owners&&this.state.workers&&this.state.companys)this.setState({suppliers:suppliersTemp});
  }
  searchCompany(){
    let companysTemp = [];
    for(let i=0;i<this.state.companysAll.length;i++){
        let companyTemp = JSON.parse(JSON.stringify(this.state.companysAll[i]));
        if(this.state.searchCompany.keySearch==null || (companyTemp.noteName).indexOf(this.state.searchCompany.keySearch)!=-1)companysTemp.push(companyTemp);
    }
    this.state.companys=companysTemp;
    if(this.state.customers&&this.state.owners&&this.state.workers&&this.state.suppliers)this.setState({companys:companysTemp});
  }

  render(){
      // if(this.state.customers==null||this.state.owners==null||this.state.suppliers==null||this.state.workers==null||this.state.companys==null)return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:'0.5%',backgroundColor:'#FFF',position:'relative'}}>
      //   <span style={{color:'#52A056',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(26)}</span>
      // </div>);
      if(this.state.customers==null)return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:'0.5%',backgroundColor:'#FFF',position:'relative'}}>
        <span style={{color:'#52A056',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(26)}</span>
      </div>);
      return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',padding:'0.5%'}}>
        <div style={{width:'30%',height:'100%',backgroundColor:'#FFF',borderRadius:5,marginRight:arithUtil.pxToDp(5)}}>
          <div style={styles.roleTab}>
            <span style={arithUtil.stylesAdd(styles.roleTabItem,this.state.roleIndex==0?{borderTopLeftRadius:5,backgroundColor:'#52A056'}:{borderTopLeftRadius:5})} onClick={()=>{
              this.state.roleIndex = 0;
              this.setState({roleIndex:this.state.roleIndex});
            }}>{stringUtil.language(1048)}</span>
            <span style={arithUtil.stylesAdd(styles.roleTabItem,this.state.roleIndex==1?{backgroundColor:'#52A056'}:{})} onClick={()=>{
              this.state.roleIndex = 1;
              if(!this.state.ownersAll||this.state.ownersAll.length==0)this.initOwnersData();
              else this.setState({roleIndex:this.state.roleIndex});
            }}>{stringUtil.language(1049)}</span>
            <span style={arithUtil.stylesAdd(styles.roleTabItem,this.state.roleIndex==2?{backgroundColor:'#52A056'}:{})} onClick={()=>{
              this.state.roleIndex = 2;
              if(!this.state.suppliersAll||this.state.suppliersAll.length==0)this.initSuppliersData();
              else this.setState({roleIndex:this.state.roleIndex});
            }}>{stringUtil.language(921)}</span>
            <span style={arithUtil.stylesAdd(styles.roleTabItem,this.state.roleIndex==3?{backgroundColor:'#52A056'}:{})} onClick={()=>{
              this.state.roleIndex = 3;
              if(!this.state.workers||this.state.workers.length==0)this.initWorkersData();
              else this.setState({roleIndex:this.state.roleIndex});
            }}>{stringUtil.language(1031)}</span>
            <span style={arithUtil.stylesAdd(styles.roleTabItem,this.state.roleIndex==4?{borderTopRightRadius:5,backgroundColor:'#52A056'}:{borderTopRightRadius:5})} onClick={()=>{
              this.state.roleIndex = 4;
              if(!this.state.companysAll||this.state.companysAll.length==0)this.initCompanysData();
              else this.setState({roleIndex:this.state.roleIndex});
            }}>{stringUtil.language(1046)}</span>
          </div>
          {this.state.roleIndex==0?<div style={{width:'100%',height:'92%'}}>
            <div style={{width:'96.5%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(5),marginRight:arithUtil.pxToDp(5),marginTop:2}}>
                <select style={arithUtil.stylesAdd(styles.inputText,{width:90,paddingLeft:0,fontSize:14})} onChange={(event)=>{
                      this.state.searchCustomer.warning = event.target.value;
                      this.searchCustomer();
                  }}><option value={-1}>{stringUtil.language(222)}</option><option value={0}>{stringUtil.language(631)}</option>
                  <option value={1}>{stringUtil.language(632)}</option><option value={2}>{stringUtil.language(458)}</option>
                  <option value={3}>{stringUtil.language(633)}</option><option value={4}>{stringUtil.language(634)}</option>
                </select>
                <input style={arithUtil.stylesAdd(styles.inputText,{display:'flex',flexGrow:1,fontSize:14,marginLeft:2})} placeholder={stringUtil.language(164)} onChange={(event)=>{
                    this.state.searchCustomer.keySearch = event.target.value;
                    this.searchCustomer();
                }}/>
                <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                  <span style={{fontSize:10,color:'#F00',marginBottom:arithUtil.pxToDp(1)}}>￥</span>
                  <span style={{fontSize:17,color:'#F00',fontWeight:'bold'}}>{this.state.totalArrears?arithUtil.fenToYuan(this.state.totalArrears):0}</span>
                </div>
            </div>
            {/* {(()=>{
              let debtTotal = 0;
              for(let i=0;i<this.state.customers.length;i++){
                if(this.state.customers[i].isDel==0&&this.state.customers[i].arrears!=0) debtTotal = debtTotal.add(this.state.customers[i].arrears);
              }
              return(<span style={{color:'#F00'}}>{arithUtil.fenToYuan(debtTotal)}{stringUtil.currency(1)}</span>)
            })()} */}
            <div style={{height:'80%',overflow:'auto'/*stringUtil.language(1129)滚动*/}}>
              {this.state.customers.map((item,key)=>{
                if (item.isDel==1) return;
                if (this.state.currCustomerItem==null&&key==0) {
                  this.state.currCustomerItem = JSON.parse(JSON.stringify(item));
                }else if(this.state.currCustomerItem!=null&&item.id==this.state.currCustomerItem.id){
                  this.state.currCustomerItem = JSON.parse(JSON.stringify(item));
                }
                return(<div style={arithUtil.stylesAdd(styles.roleItem,{paddingRight:arithUtil.pxToDp(20),position:'relative'})} key={key} onClick={()=>{
                    this.state.currCustomerItem = JSON.parse(JSON.stringify(item));
                    this.setState({currCustomerItem:this.state.currCustomerItem});
                  }}>
                    {item.type==0?<div style={{position:'absolute',right:2,top:-10}}>
                        <i className="iconfontIndexCss" style={{fontSize:'35px',color:item.warning==0?'#52A056':item.warning==1?'#D2855D':'#F00'}}>&#xe653;</i>
                        <span style={{color:'#FFF',fontSize:6,position:'absolute',right:2,top:12,transform:['rotate(45deg)']}}>{item.warning==0?stringUtil.language(1100):item.warning==1?'stringUtil.language(1187)意':'stringUtil.language(1076)'}</span>
                    </div>:<div style={{position:'absolute',right:2,top:2}}/>}
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <img style={{width:'35px',height:'35px',borderRadius:17.5}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                      <div style={{marginLeft:8,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <span style={{fontSize:17}}>{stringUtil.subStr(item.noteName,8)}</span>
                        {item.mobile!=null?<span style={{color:'#FF8C00',fontSize:10}}>{item.mobile?'☏:'+item.mobile:''}</span>:<div />}
                      </div>
                    </div>
                    {item.arrears!=0?<div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                      <span style={{fontSize:10,color:'#F00',marginBottom:arithUtil.pxToDp(1)}}>￥</span>
                      <span style={{fontSize:17,color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                    </div>:<div/>}
                  </div>);
              })}
            </div>
          </div>:<div/>}
          {this.state.roleIndex==1?<div style={{width:'100%',height:'92%'}}>
            {(()=>{this.state.totalOwnerInfo={debt:0,noPay:0};
              for(let i=0;i<this.state.owners.length;i++){
                this.state.totalOwnerInfo.debt=(this.state.totalOwnerInfo.debt).add(this.state.owners[i].customerArrears);
                this.state.totalOwnerInfo.noPay=(this.state.totalOwnerInfo.noPay).add(this.state.owners[i].arrears);
              }
            })()}
            <div style={{width:'96.5%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:5,marginRight:5,marginTop:2}}>
                <input style={arithUtil.stylesAdd(styles.inputText,{display:'flex',flexGrow:1,fontSize:14})} placeholder={stringUtil.language(164)} onChange={(event)=>{
                    this.state.searchOwner.keySearch = event.target.value;
                    this.searchOwner();
                }}/>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginRight:5,marginLeft:5}}>
                  <span style={{fontSize:15,color:'#F00',fontWeight:'bold'}}>{this.state.customerArrearShop?arithUtil.fenToYuan((this.state.customerArrearShop).round()):0}</span>
                  <span style={{color:'#50A572',fontSize:8,whiteSpace:'nowrap'}}>{stringUtil.language(336)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginRight:5,marginLeft:5}}>
                  <span style={{fontSize:15,color:'#F00',fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.totalOwnerInfo.debt).round())}</span>
                  <span style={{color:'#50A572',fontSize:8,whiteSpace:'nowrap'}}>{stringUtil.language(337)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginLeft:5}}>
                  <span style={{fontSize:15,color:'#F00',fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.totalOwnerInfo.noPay).round())}</span>
                  <span style={{color:'#50A572',fontSize:8,whiteSpace:'nowrap'}}>{stringUtil.language(338)}</span>
                </div>
            </div>
            <div style={{height:'80%',overflow:'auto'}}>
              {this.state.owners.map((item,key)=>{
                if (item.isDel==1) return;
                if (this.state.currOwnerItem==null&&key==0) {
                  this.state.currOwnerItem = JSON.parse(JSON.stringify(item));
                }else if(this.state.currOwnerItem!=null&&item.id==this.state.currOwnerItem.id){
                  this.state.currOwnerItem = JSON.parse(JSON.stringify(item));
                }
                return(<div style={styles.roleItem} key={key} onClick={()=>{
                    this.state.currOwnerItem = JSON.parse(JSON.stringify(item));
                    this.setState({currOwnerItem:this.state.currOwnerItem});
                  }}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <img style={{width:'35px',height:'35px',borderRadius:17.5}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                      <div style={{marginLeft:8,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <span style={{fontSize:17}}>{stringUtil.subStr(item.noteName,8)}</span>
                        {item.mobile!=null?<span style={{color:'#FF8C00',fontSize:10}}>{item.mobile?'☏:'+item.mobile:''}</span>:<div />}
                      </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                      <span style={{fontSize:10,color:'#F00',marginBottom:arithUtil.pxToDp(1)}}>￥</span>
                      <span style={{fontSize:17,color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                    </div>
                  </div>);
              })}
            </div>
          </div>:<div/>}
          {this.state.roleIndex==2?<div style={{width:'100%',height:'92%'}}>
            {(()=>{this.state.totalSupplierInfo={noPay:0};
                for(let i=0;i<this.state.suppliers.length;i++){
                    this.state.totalSupplierInfo.noPay=(this.state.totalSupplierInfo.noPay).add(this.state.suppliers[i].arrears);
                }
            })()}
            <div style={{width:'96.5%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:5,marginRight:5,marginTop:2}}>
                <input style={arithUtil.stylesAdd(styles.inputText,{display:'flex',flexGrow:1,fontSize:14})} placeholder={stringUtil.language(164)} onChange={(event)=>{
                    this.state.searchSupplier.keySearch = event.target.value;
                    this.searchSupplier();
                }}/>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginLeft:5}}>
                  <span style={{fontSize:15,color:'#F00',fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.totalSupplierInfo.noPay).round())}</span>
                  <span style={{color:'#50A572',fontSize:8}}>{stringUtil.language(1127)}</span>
                </div>
            </div>
            <div style={{height:'80%',overflow:'auto'}}>
              {this.state.suppliers.map((item,key)=>{
                if (item.isDel==1) return;
                if (this.state.currSupplierItem==null&&key==0) {
                  this.state.currSupplierItem = JSON.parse(JSON.stringify(item));
                }else if(this.state.currSupplierItem!=null&&item.id==this.state.currSupplierItem.id){
                  this.state.currSupplierItem = JSON.parse(JSON.stringify(item));
                }
                return(<div style={styles.roleItem} key={key} onClick={()=>{
                    this.state.currSupplierItem = JSON.parse(JSON.stringify(item));
                    this.setState({currSupplierItem:this.state.currSupplierItem});
                  }}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <img style={{width:'35px',height:'35px',borderRadius:17.5}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                      <div style={{marginLeft:8,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <span style={{fontSize:17}}>{stringUtil.subStr(item.noteName,8)}</span>
                        {item.mobile!=null?<span style={{color:'#FF8C00',fontSize:10}}>{item.mobile?'☏:'+item.mobile:''}</span>:<div />}
                      </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                      <span style={{fontSize:10,color:'#F00',marginBottom:arithUtil.pxToDp(1)}}>￥</span>
                      <span style={{fontSize:17,color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                    </div>
                  </div>);
              })}
            </div>
          </div>:<div/>}
          {this.state.roleIndex==3?<div style={{height:'80%',marginTop:2,overflow:'auto'}}>
              {this.state.workers.map((item,key)=>{
                if (item.state==1) {//stringUtil.language(937)
                  if (this.state.currWorkerItem==null&&key==0) {
                    this.state.currWorkerItem = JSON.parse(JSON.stringify(item));
                    this.state.isHavaFirst = 1;
                  }else if(this.state.currWorkerItem!=null&&item.id==this.state.currWorkerItem.id){
                    this.state.currWorkerItem = JSON.parse(JSON.stringify(item));
                    this.state.isHavaFirst = 1;
                  }
                  return(<div style={styles.roleItem} key={key} onClick={()=>{
                      this.state.currWorkerItem = JSON.parse(JSON.stringify(item));
                      this.setState({currWorkerItem:this.state.currWorkerItem});
                    }}>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <img style={{width:'35px',height:'35px',borderRadius:17.5}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                        <div style={{marginLeft:8,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                          <span style={{fontSize:17}}>{stringUtil.subStr(item.noteName,8)}</span>
                          {item.mobile!=null?<span style={{color:'#FF8C00',fontSize:10}}>{item.mobile?'☏:'+item.mobile:''}</span>:<div />}
                        </div>
                      </div>
                      <span style={{color:'#F00'}}>{stringUtil.language(279)}</span>
                    </div>);
                }
              })}
              {this.state.workers.map((item,key)=>{
                if (item.state==0) {//stringUtil.language(1100)
                  if (this.state.currWorkerItem==null&&this.state.isHavaFirst!=1&&key==0) {
                    this.state.currWorkerItem = JSON.parse(JSON.stringify(item));
                    this.state.isHavaFirst = null;
                  }else if(this.state.currWorkerItem!=null&&this.state.isHavaFirst!=1&&item.id==this.state.currWorkerItem.id){
                    this.state.currWorkerItem = JSON.parse(JSON.stringify(item));
                    this.state.isHavaFirst = null;
                  }
                  return(<div style={styles.roleItem} key={key} onClick={()=>{
                    this.state.currWorkerItem=JSON.parse(JSON.stringify(item));
                    stringUtil.httpRequest(urlUtil.OAUTH_WORKER_WAREHOUSE_URL+'/'+this.state.currWorkerItem.id,null,['key','get',1],(successResponse)=>{
                      if (successResponse.status==200) {
                        this.setState({workerWarehouseDO:successResponse.data});
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    if(this.state.warehousesAll==null||this.state.warehousesAll.length==0){
                      stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                          this.setState({warehousesAll:successResponse.data});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }
                  }}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <img style={{width:'35px',height:'35px',borderRadius:17.5}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                      <div style={{marginLeft:8,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <span style={{fontSize:17}}>{stringUtil.subStr(item.noteName,8)}</span>
                        {item.mobile!=null?<span style={{color:'#FF8C00',fontSize:10}}>{item.mobile?'☏:'+item.mobile:''}</span>:<div />}
                      </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                      <span style={{fontSize:10,color:'#F00',marginBottom:arithUtil.pxToDp(1)}}>￥</span>
                      <span style={{fontSize:17,color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                    </div>
                  </div>);
                }
              })}
          </div>:<div/>}
          {this.state.roleIndex==4?<div style={{width:'100%',height:'92%'}}>
            <div style={{width:'96.5%',marginLeft:arithUtil.pxToDp(5),marginRight:arithUtil.pxToDp(5),marginTop:2}}>
              <input style={arithUtil.stylesAdd(styles.inputText,{fontSize:14})} placeholder={stringUtil.language(32)} onChange={(event)=>{
                    this.state.searchCompany.keySearch = event.target.value;
                    this.searchCompany();
                }}/>
            </div>
            <div style={{height:'80%',overflow:'auto'}}>
              {this.state.companys?this.state.companys.map((item,key)=>{
                if (item.isDel==1) return;
                if (this.state.currCompanyItem==null&&key==0) {
                  this.state.currCompanyItem = JSON.parse(JSON.stringify(item));
                }else if(this.state.currCompanyItem!=null&&item.id==this.state.currCompanyItem.id){
                  this.state.currCompanyItem = JSON.parse(JSON.stringify(item));
                }
                return(<div style={styles.roleItem} key={key} onClick={()=>{
                    this.setState({currCompanyItem:JSON.parse(JSON.stringify(item))});
                  }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <img style={{width:'35px',height:'35px',borderRadius:17.5}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                      <div style={{marginLeft:8,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <span style={{fontSize:17}}>{stringUtil.subStr(item.noteName,8)}</span>
                        {item.mobile!=null?<span style={{color:'#FF8C00',fontSize:10}}>{item.mobile?'☏:'+item.mobile:''}</span>:<div />}
                      </div>
                    </div>
                  </div>);
              }):''}
            </div>
          </div>:<div/>}
          {this.state.roleIndex==3?<div style={{display:'flex',flexDirection:'row',position:'absolute',left:'28%',bottom:20}}>
            <div style={{width:arithUtil.pxToDp(140),height:arithUtil.pxToDp(30),borderRadius:arithUtil.pxToDp(20),backgroundColor:'#F4A460',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DeleteContactActivity',mainStacks:[
                {title:stringUtil.language(1128),navigatePage:''},
                {title:stringUtil.language(223),navigatePage:'DeleteContactActivity',paramsPage:{roleIndex:this.state.roleIndex}}
              ]}});
            }}>
              <span style={{color:'#FFF'}}>{stringUtil.language(972)}</span>
            </div>
          </div>:<div style={{display:'flex',flexDirection:'row',position:'absolute',left:'28%',bottom:20}}>
            <div style={{width:arithUtil.pxToDp(70),height:arithUtil.pxToDp(30),borderTopLeftRadius:arithUtil.pxToDp(20),borderBottomLeftRadius:arithUtil.pxToDp(20),backgroundColor:'#228B22',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              if ((otherUtil.authorityFindCode("010501")!=null)&&(this.state.roleIndex==0)) {
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CustomerAddActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(635),navigatePage:'CustomerAddActivity',paramsPage:stringUtil.language(635)+'参数'}
                ]}});
              }else if ((otherUtil.authorityFindCode("010701")!=null)&&(this.state.roleIndex==1)) {
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OwnerAddActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(636),navigatePage:'OwnerAddActivity',paramsPage:stringUtil.language(636)+'参数'}
                ]}});
              }else if ((otherUtil.authorityFindCode("010601")!=null)&&(this.state.roleIndex==2)) {
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SupplierAddActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(339),navigatePage:'SupplierAddActivity',paramsPage:stringUtil.language(339)+'参数'}
                ]}});
              }else if (this.state.roleIndex==4){
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CompanyAddActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(637),navigatePage:'CompanyAddActivity',paramsPage:stringUtil.language(637)+'参数'}
                ]}});
              }else{ToastBox.error(stringUtil.language(224))}
            }}>
              <span style={{color:'#FFF'}}>{stringUtil.language(1129)}</span>
            </div>
            <div style={{width:arithUtil.pxToDp(70),height:arithUtil.pxToDp(30),borderTopRightRadius:arithUtil.pxToDp(20),borderBottomRightRadius:arithUtil.pxToDp(20),backgroundColor:'#F4A460',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              if (this.state.roleIndex==0) {//stringUtil.language(1048)
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DeleteContactActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(223),navigatePage:'DeleteContactActivity',paramsPage:{roleIndex:this.state.roleIndex}}
                ]}});
              }else if (this.state.roleIndex==1) {//stringUtil.language(1049)
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DeleteContactActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(223),navigatePage:'DeleteContactActivity',paramsPage:{roleIndex:this.state.roleIndex}}
                ]}});
              }else if (this.state.roleIndex==2) {//stringUtil.language(921)
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DeleteContactActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(223),navigatePage:'DeleteContactActivity',paramsPage:{roleIndex:this.state.roleIndex}}
                ]}});
              }else{ToastBox.error(stringUtil.language(224))}
            }}>
              <span style={{color:'#FFF'}}>{stringUtil.language(1130)}</span>
            </div>
          </div>}
        </div>
        <div style={{width:'85%',height:'100%',backgroundColor:'#FFF',borderRadius:5}}>
          {this.state.roleIndex==0&&this.state.currCustomerItem!=null?<div style={{padding:arithUtil.pxToDp(40)}}>
              {(otherUtil.authorityFindCode("010502")!=null&&this.state.currCustomerItem.type==0)?<div style={{position:'absolute',right:7,top:'10%',cursor:'pointer'}} onClick={()=>{
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CustomerUpdateActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(638),navigatePage:'CustomerUpdateActivity',paramsPage:this.state.currCustomerItem}
                  ]}});
              }}>
                  <i className="iconfontIndexCss" style={{fontSize:'50px',color:'#52A056'}}>&#xe653;</i>
                  <span style={{color:'#FFF',fontSize:12,position:'absolute',right:6,top:'25%',transform: ['rotate(45deg)']}}>{stringUtil.language(1092)}</span>
              </div>:<div />}
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{width:80,height:80,borderStyle:'solid',borderWidth:1,borderColor:'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <img style={{width:'72px',height:'72px'}} loading="lazy" src={arithUtil.imageShow(this.state.currCustomerItem.headIcon)} title={stringUtil.language(84)}/>
                </div>
                <div>
                  <span style={{fontSize:24,marginLeft:15}}>{this.state.currCustomerItem.code?'('+this.state.currCustomerItem.code+')':''}{this.state.currCustomerItem.noteName}</span><br/>
                  {this.state.currCustomerItem.type==0?<span style={{color:'#F00',marginLeft:15}}>{this.state.currCustomerItem.lastWholesaleTime!=0?arithUtil.getDateDiff(arithUtil.timeToNumber(arithUtil.getCurrDateTime()),this.state.currCustomerItem.lastWholesaleTime)+stringUtil.language(1191):stringUtil.language(1190)}{stringUtil.language(974)},{this.state.currCustomerItem.lastWholesaleTime!=0?arithUtil.getDateDiff(arithUtil.timeToNumber(arithUtil.getCurrDateTime()),this.state.currCustomerItem.lastCollectTime)+stringUtil.language(1191):stringUtil.language(1190)}{stringUtil.language(973)},{stringUtil.language(1066)}{arithUtil.fenToYuan(this.state.currCustomerItem.quota)}</span>:<div/>}
                </div>
              </div>
              {(()=>{
                if (this.state.currCustomerItem.type==0) {
                  return(<div>
                  {!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).seeCustomerInfo&&this.state.currCustomerItem.mobile?<div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center',marginTop:25}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(382)}</span>
                    <span style={{fontSize:14}}>{this.state.currCustomerItem.mobile}</span>
                  </div>:<div/>}
                  {!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).seeCustomerInfo&&this.state.currCustomerItem.cardId?<div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(469)}</span>
                    <span style={{fontSize:14}}>{this.state.currCustomerItem.cardId}</span>
                  </div>:<div/>}
                  {!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).seeCustomerInfo&&this.state.currCustomerItem.adname?<div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(470)}</span>
                    <span style={{fontSize:14}}>{this.state.currCustomerItem.adname}</span>
                  </div>:<div/>}
                  {!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).seeCustomerInfo&&this.state.currCustomerItem.address?<div style={{minHeight:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(387)}</span>
                    <span style={{fontSize:14}}>{this.state.currCustomerItem.address}</span>
                  </div>:<div/>}
                </div>)
                }
              })()}
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(639)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currCustomerItem.initArrears)}{stringUtil.currency(1)}</span>
                {otherUtil.authorityFindCode("010502")!=null&&this.state.currCustomerItem.type==0?<span style={{color:'#D2855D',marginLeft:arithUtil.pxToDp(40),fontSize:15,fontWeight:'bold',textDecorationLine:'underline',cursor:'pointer'}} onClick={()=>{
                  stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_ARREARS_DETAILED_URL,{customerId:this.state.currCustomerItem.id},['key','get',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          this.state.initArrearsAll = successResponse.data;
                          this.setState({
                            initArrearsAll:this.state.initArrearsAll,
                            showInitaccountsPop: !this.state.showInitaccountsPop
                          });
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }}>{stringUtil.language(640)}</span>:<div/>}
              </div>
              <MaskModal visible={this.state.showInitaccountsPop} title={stringUtil.language(639)} height={arithUtil.ProportionTo(document.body.clientHeight,0.7)}
                  content={<div style={{height:'100%',overflow:'auto'}}>
                    {this.state.initArrearsAll.map((item,key)=>{
                      return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={key}>
                        <span style={{color:item.initArrears!=0?'#F00':'#696969'}}>{item.targetType==1?stringUtil.language(426):stringUtil.subStr(item.targetName,9)}</span>
                        <span style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100),color:'#F00',fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderBottomWidth:0,cursor:'pointer'})} onClick={()=>{
                            this.state.currInitArrearserItem = JSON.parse(JSON.stringify(item));
                            this.setState({showInitArrPop:!this.state.showInitArrPop});
                        }}>{arithUtil.fenToYuan(item.initArrears)}</span>
                      </div>)
                    })}
                  </div>}closeBnTopEvents={() => {this.setState({ showInitaccountsPop: false, });}}/>
              <MaskModal visible={this.state.showInitArrPop} title={stringUtil.language(1092)+'【'+this.state.currInitArrearserItem.targetName+'】'+stringUtil.language(639)} height={arithUtil.pxToDp(200)} context={this}
                content={<div style={{height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} key={this.state.currInitArrearserItem.targetId}><input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(200),color:'#F00',fontWeight:'bold'})} type='number' placeholder={stringUtil.language(71)} defaultValue={this.state.currInitArrearserItem.initArrears!=0?arithUtil.fenToYuan(this.state.currInitArrearserItem.initArrears):''} onChange={(event)=>{
                  this.state.currInitArrearserItem.initArrears = event.target.value;
                }}/></div>}
                okBnEvents={(event) => {
                    this.setState({showInitArrPop: false});
                    stringUtil.httpRequest(urlUtil.CUSTOMER_ARREARS_INIT_URL,{
                      customerId:this.state.currCustomerItem.id,
                      targetType:this.state.currInitArrearserItem.targetType,
                      targetId:this.state.currInitArrearserItem.targetId,
                      initArrears:arithUtil.yuanToFen(this.state.currInitArrearserItem.initArrears)
                    },['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_ARREARS_DETAILED_URL,{customerId:this.state.currCustomerItem.id},['key','get',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    this.state.initArrearsAll = successResponse.data;
                                    this.setState({initArrearsAll:this.state.initArrearsAll});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                            this.initCustomersData();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({showInitArrPop: false});}}/>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(641)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currCustomerItem.arrears)}{stringUtil.currency(1)}</span>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                {otherUtil.authorityFindCode("010504")!=null?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BusinessDetailsActivityCustomer',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(642),navigatePage:'BusinessDetailsActivityCustomer',paramsPage:{currContactItem:this.state.currCustomerItem}}
                  ]}});
                }}>{stringUtil.language(642)}</span>:<div style={{position:'absolute'}}/>}
                {otherUtil.authorityFindCode("010503")!=null&&this.state.currCustomerItem.type==0?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#F00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:arithUtil.pxToDp(10)}} onClick={()=>{
                  this.setState({delCustomerShowPop:!this.state.delCustomerShowPop});
                }}>{stringUtil.language(643)}</span>:<div style={{position:'absolute'}}/>}
              </div>
            </div>:<div />}
          {this.state.roleIndex==1&&this.state.currOwnerItem!=null?<div style={{padding:arithUtil.pxToDp(40)}}>
              {otherUtil.authorityFindCode("010702")!=null?<div style={{position:'absolute',right:7,top:'10%',cursor:'pointer'}} onClick={()=>{
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OwnerUpdateActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(644),navigatePage:'OwnerUpdateActivity',paramsPage:this.state.currOwnerItem}
                  ]}});
              }}>
                  <i className="iconfontIndexCss" style={{fontSize:'50px',color:'#52A056'}}>&#xe653;</i>
                  <span style={{color:'#FFF',fontSize:12,position:'absolute',right:6,top:'25%',transform: ['rotate(45deg)']}}>{stringUtil.language(1092)}</span>
              </div>:<div />}
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{width:arithUtil.pxToDp(80),height:arithUtil.pxToDp(80),borderStyle:'solid',borderWidth:1,borderColor:'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <img style={{width:'72px',height:'72px'}} loading="lazy" src={arithUtil.imageShow(this.state.currOwnerItem.headIcon)} title={stringUtil.language(84)}/>
                </div>
                <span style={{fontSize:24,marginLeft:arithUtil.pxToDp(15)}}>{this.state.currOwnerItem.noteName}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center',marginTop:25}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(382)}</span>
                <span style={{fontSize:14}}>{this.state.currOwnerItem.mobile}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(469)}</span>
                <span style={{fontSize:14}}>{this.state.currOwnerItem.cardId}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(470)}</span>
                <span style={{fontSize:14}}>{this.state.currOwnerItem.adname}</span>
              </div>
              <div style={{minHeight:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(387)}</span>
                <span style={{fontSize:14}}>{this.state.currOwnerItem.address}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(639)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currOwnerItem.initArrears)}{stringUtil.currency(1)}</span>
                {otherUtil.authorityFindCode("010702")!=null?<span style={{color:'#D2855D',marginLeft:arithUtil.pxToDp(40),fontSize:15,fontWeight:'bold',textDecorationLine:'underline',cursor:'pointer'}} onClick={()=>{
                  this.state.currInitArrearserItem = JSON.parse(JSON.stringify(this.state.currOwnerItem));
                  this.setState({showInitArrPops:!this.state.showInitArrPops});
                }}>{stringUtil.language(640)}</span>:<div/>}
                <MaskModal visible={this.state.showInitArrPops} title={stringUtil.language(225)} height={arithUtil.pxToDp(200)} context={this}
                  content={<div style={{height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} key={this.state.currInitArrearserItem.id}><input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(200),color:'#F00',fontWeight:'bold'})} type='number' placeholder={stringUtil.language(154)} defaultValue={this.state.currInitArrearserItem.initArrears!=0?arithUtil.fenToYuan(this.state.currInitArrearserItem.initArrears):''} onChange={(event)=>{
                    this.state.currInitArrearserItem.initArrears = event.target.value;
                  }}/></div>}
                  okBnEvents={(event) => {
                      this.setState({showInitArrPops: false});
                      stringUtil.httpRequest(urlUtil.OWNER_ARREARS_INIT_URL,{
                        ownerId:this.state.currOwnerItem.id,
                        initArrears:arithUtil.yuanToFen(this.state.currInitArrearserItem.initArrears)
                      },['key','put',1],(successResponse)=>{
                          if (successResponse.status==200) {
                              ToastBox.success(stringUtil.language(388));
                              this.initOwnersData();
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }} closeBnEvents={() => {this.setState({showInitArrPops: false});}}/>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(645)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currOwnerItem.arrears)}{stringUtil.currency(1)}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(646)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currOwnerItem.customerArrears)}{stringUtil.currency(1)}</span>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                  {otherUtil.authorityFindCode("010704")!=null?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BusinessDetailsActivityOwner',mainStacks:[
                      {title:stringUtil.language(1128),navigatePage:''},
                      {title:stringUtil.language(642),navigatePage:'BusinessDetailsActivityOwner',paramsPage:{currContactItem:this.state.currOwnerItem}}
                    ]}});
                  }}>{stringUtil.language(642)}</span>:<div style={{position:'absolute'}}/>}
                  {otherUtil.authorityFindCode("010703")!=null?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#F00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:arithUtil.pxToDp(10)}} onClick={()=>{
                    this.setState({delOwnerShowPop:!this.state.delOwnerShowPop});
                  }}>{stringUtil.language(647)}</span>:<div style={{position:'absolute'}}/>}
              </div>
            </div>:<div />}
          {this.state.roleIndex==2&&this.state.currSupplierItem!=null?<div style={{padding:arithUtil.pxToDp(40)}}>
              {otherUtil.authorityFindCode("010602")!=null?<div style={{position:'absolute',right:7,top:'10%',cursor:'pointer'}} onClick={()=>{
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SupplierUpdateActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(340),navigatePage:'SupplierUpdateActivity',paramsPage:this.state.currSupplierItem}
                  ]}});
              }}>
                  <i className="iconfontIndexCss" style={{fontSize:'50px',color:'#52A056'}}>&#xe653;</i>
                  <span style={{color:'#FFF',fontSize:12,position:'absolute',right:6,top:'25%',transform: ['rotate(45deg)']}}>{stringUtil.language(1092)}</span>
              </div>:<div />}
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{width:arithUtil.pxToDp(80),height:arithUtil.pxToDp(80),borderStyle:'solid',borderWidth:1,borderColor:'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <img style={{width:'72px',height:'72px'}} loading="lazy" src={arithUtil.imageShow(this.state.currSupplierItem.headIcon)} title={stringUtil.language(84)}/>
                </div>
                <span style={{fontSize:24,marginLeft:arithUtil.pxToDp(15)}}>{this.state.currSupplierItem.noteName}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center',marginTop:25}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(382)}</span>
                <span style={{fontSize:14}}>{this.state.currSupplierItem.mobile}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(469)}</span>
                <span style={{fontSize:14}}>{this.state.currSupplierItem.cardId}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(470)}</span>
                <span style={{fontSize:14}}>{this.state.currSupplierItem.adname}</span>
              </div>
              <div style={{minHeight:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(387)}</span>
                <span style={{fontSize:14}}>{this.state.currSupplierItem.address}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(639)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currSupplierItem.initArrears)}{stringUtil.currency(1)}</span>
                {otherUtil.authorityFindCode("010602")!=null?<span style={{color:'#D2855D',marginLeft:arithUtil.pxToDp(40),fontSize:15,fontWeight:'bold',textDecorationLine:'underline',cursor:'pointer'}} onClick={()=>{
                  this.state.currInitArrearserItem = JSON.parse(JSON.stringify(this.state.currSupplierItem));
                  this.setState({showInitArrPopss:!this.state.showInitArrPopss});
                }}>{stringUtil.language(640)}</span>:<div/>}
                <MaskModal visible={this.state.showInitArrPopss} title={stringUtil.language(225)} height={arithUtil.pxToDp(200)} context={this}
                  content={<div style={{height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} key={this.state.currInitArrearserItem.id}><input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(200),color:'#F00',fontWeight:'bold'})} type='number' placeholder={stringUtil.language(154)} defaultValue={this.state.currInitArrearserItem.initArrears!=0?arithUtil.fenToYuan(this.state.currInitArrearserItem.initArrears):''} onChange={(event)=>{
                    this.state.currInitArrearserItem.initArrears = event.target.value;
                  }}/></div>}
                  okBnEvents={(event) => {
                      this.setState({showInitArrPopss: false});
                      stringUtil.httpRequest(urlUtil.SUPPIER_ARREARS_INIT_URL,{
                        supplierId:this.state.currSupplierItem.id,
                        initArrears:arithUtil.yuanToFen(this.state.currInitArrearserItem.initArrears)
                      },['key','put',1],(successResponse)=>{
                          if (successResponse.status==200) {
                              ToastBox.success(stringUtil.language(388));
                              this.initSuppliersData();
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }} closeBnEvents={() => {this.setState({showInitArrPopss: false});}}/>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(645)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currSupplierItem.arrears)}{stringUtil.currency(1)}</span>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                  {otherUtil.authorityFindCode("010604")!=null?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BusinessDetailsActivitySupplier',mainStacks:[
                      {title:stringUtil.language(1128),navigatePage:''},
                      {title:stringUtil.language(642),navigatePage:'BusinessDetailsActivitySupplier',paramsPage:{currContactItem:this.state.currSupplierItem}}
                    ]}});
                  }}>{stringUtil.language(642)}</span>:<div style={{position:'absolute'}}/>}
                  {otherUtil.authorityFindCode("010603")!=null?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#F00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:arithUtil.pxToDp(10)}} onClick={()=>{
                    this.setState({delSupplierShowPop:!this.state.delSupplierShowPop});
                  }}>{stringUtil.language(341)}</span>:<div style={{position:'absolute'}}/>}
              </div>
            </div>:<div />}
          {this.state.roleIndex==3&&this.state.currWorkerItem!=null?<div style={{padding:arithUtil.pxToDp(40)}}>
              {(otherUtil.authorityFindCode("010201")!=null&&this.state.currWorkerItem.state!=1)?<div style={{position:'absolute',right:7,top:'10%',cursor:'pointer'}} onClick={()=>{
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WorkerUpdateActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(648),navigatePage:'WorkerUpdateActivity',paramsPage:this.state.currWorkerItem}
                  ]}});
              }}>
                  <i className="iconfontIndexCss" style={{fontSize:'50px',color:'#52A056'}}>&#xe653;</i>
                  <span style={{color:'#FFF',fontSize:12,position:'absolute',right:6,top:'25%',transform: ['rotate(45deg)']}}>{stringUtil.language(1092)}</span>
              </div>:<div />}
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{width:arithUtil.pxToDp(80),height:arithUtil.pxToDp(80),borderStyle:'solid',borderWidth:1,borderColor:'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <img style={{width:'72px',height:'72px'}} loading="lazy" src={arithUtil.imageShow(this.state.currWorkerItem.headIcon)} title={stringUtil.language(84)}/>
                </div>
                <span style={{fontSize:24,marginLeft:arithUtil.pxToDp(15)}}>{this.state.currWorkerItem.noteName}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center',marginTop:25}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(382)}</span>
                <span style={{fontSize:14}}>{this.state.currWorkerItem.mobile}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(469)}</span>
                <span style={{fontSize:14}}>{this.state.currWorkerItem.cardId}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:100,fontSize:18}}>{stringUtil.language(1220)}</span>
                {!this.state.currWorkerItem.editWarehouse?<div style={{width:80,height:30,backgroundColor:'#009A61',borderColor:'#009A61',borderStyle:'solid',borderWidth:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <span style={{color:'#FFF',fontSize:12}}>{stringUtil.language(1218)}</span>
                </div>:<div style={{width:80,height:30,backgroundColor:'transparent',borderColor:'#808080',borderStyle:'solid',borderWidth:1,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                  this.state.currWorkerItem.editWarehouse=0;
                  stringUtil.httpRequest(urlUtil.OAUTH_WORKER_EDIT_WAREHOUSE_URL+'/'+this.state.currWorkerItem.id,{editWarehouse:this.state.currWorkerItem.editWarehouse},['key','put',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          ToastBox.success(stringUtil.language(551));
                          this.initWorkersData();
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }}><span style={{color:'#696969',fontSize:12}}>{stringUtil.language(1218)}</span>
                </div>}
                {this.state.currWorkerItem.editWarehouse?<div style={{width:80,height:30,backgroundColor:'#009A61',borderColor:'#009A61',borderStyle:'solid',borderWidth:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <span style={{color:'#FFF',fontSize:12}}>{stringUtil.language(1219)}</span>
                </div>:<div style={{width:80,height:30,backgroundColor:'transparent',borderColor:'#808080',borderStyle:'solid',borderWidth:1,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                  this.state.currWorkerItem.editWarehouse=1;
                  stringUtil.httpRequest(urlUtil.OAUTH_WORKER_EDIT_WAREHOUSE_URL+'/'+this.state.currWorkerItem.id,{editWarehouse:this.state.currWorkerItem.editWarehouse},['key','put',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          ToastBox.success(stringUtil.language(551));
                          this.initWorkersData();
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }}><span style={{color:'#696969',fontSize:12}}>{stringUtil.language(1219)}</span>
                </div>}
              </div>
              {(localStorage.getItem("currentStallItem")==null||JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0)&&this.state.warehousesAll.length>0?<div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:100,fontSize:18}}>{stringUtil.language(649)}</span>
                <select style={{width:200,height:30,fontSize:14,borderWidth:0}} onChange={(event)=>{
                  stringUtil.httpRequest(urlUtil.OAUTH_WORKER_WAREHOUSE_URL+'/'+this.state.currWorkerItem.id,{warehouseId:JSON.parse(event.target.value).id},['key','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                      ToastBox.success(stringUtil.language(388));
                      if(this.state.workerWarehouseDO==null){this.state.workerWarehouseDO={
                        workerId:this.state.currWorkerItem.id,
                        warehouseId:JSON.parse(event.target.value).id,
                        warehouseName:JSON.parse(event.target.value).name
                      }}else{
                        this.state.workerWarehouseDO.warehouseId=JSON.parse(event.target.value).id
                        this.state.workerWarehouseDO.warehouseName=JSON.parse(event.target.value).name;
                      }
                      this.setState({workerWarehouseDO:this.state.workerWarehouseDO});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }}><option style={{display:'none'}}>{stringUtil.language(975)}</option>
                  {this.state.warehousesAll.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} selected={this.state.workerWarehouseDO&&this.state.workerWarehouseDO.warehouseId==item.id?'selected':''} key={key}>{stringUtil.subStr(item.name,15)}</option>)
                  })}
                </select>
              </div>:''}
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(639)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currWorkerItem.initArrears)}{stringUtil.currency(1)}</span>
                {otherUtil.authorityFindCode("010202")!=null&&this.state.currWorkerItem.state!=1?<span style={{color:'#D2855D',marginLeft:arithUtil.pxToDp(40),fontSize:15,fontWeight:'bold',textDecorationLine:'underline',cursor:'pointer'}} onClick={()=>{
                  this.state.currInitArrearserItem = JSON.parse(JSON.stringify(this.state.currWorkerItem));
                  this.setState({showInitArrPopsss:!this.state.showInitArrPopsss});
                }}>{stringUtil.language(640)}</span>:<div/>}
                <MaskModal visible={this.state.showInitArrPopsss} title={stringUtil.language(225)} height={arithUtil.pxToDp(200)} context={this}
                  content={<div style={{height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} key={this.state.currInitArrearserItem.id}><input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(200),color:'#F00',fontWeight:'bold'})} type='number' placeholder={stringUtil.language(154)} defaultValue={this.state.currInitArrearserItem.initArrears!=0?arithUtil.fenToYuan(this.state.currInitArrearserItem.initArrears):''} onChange={(event)=>{
                    this.state.currInitArrearserItem.initArrears = event.target.value;
                  }}/></div>}
                  okBnEvents={(event) => {
                      this.setState({showInitArrPopsss: false});
                      stringUtil.httpRequest(urlUtil.WORKER_ARREARS_INIT_URL,{
                        workerId:this.state.currWorkerItem.id,
                        initArrears:arithUtil.yuanToFen(this.state.currInitArrearserItem.initArrears)
                      },['key','put',1],(successResponse)=>{
                          if (successResponse.status==200) {
                              ToastBox.success(stringUtil.language(388));
                              this.initWorkersData();
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }} closeBnEvents={() => {this.setState({showInitArrPopsss: false});}}/>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(645)}</span>
                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currWorkerItem.arrears)}{stringUtil.currency(1)}</span>
              </div>
              {otherUtil.authorityFindCode("010201")!=null&&this.state.currWorkerItem.state==1?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                  <span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.setState({workerReplyPop: !this.state.workerReplyPop});
                  }}>{stringUtil.language(226)}</span>
                <MaskModal title={stringUtil.language(226)} visible={this.state.workerReplyPop} content={<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {stringUtil.language(15)}
                          </div>} okBnTitle={stringUtil.language(1131)} closeBnTitle={stringUtil.language(1132)}
                  okBnEvents={(event) => {
                    this.setState({workerReplyPop: false});
                    stringUtil.httpRequest(urlUtil.WORKER_HANDLE_URL,{
                      workerId:this.state.currWorkerItem.id,
                      sign:0,
                    },['key','put',1],(successResponse)=>{
                      if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(227));
                        this.initWorkersData();
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }} closeBnEvents={() => {
                    this.setState({workerReplyPop: false});
                    stringUtil.httpRequest(urlUtil.WORKER_HANDLE_URL,{
                      workerId:this.state.currWorkerItem.id,
                      sign:1,
                    },['key','put',1],(successResponse)=>{
                      if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(228));
                        this.initWorkersData();
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }} closeBnTopEvents={() => {this.setState({workerReplyPop: false});}}/>
              </div>:<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                  {otherUtil.authorityFindCode("010203")!=null?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BusinessDetailsActivityWorker',mainStacks:[
                      {title:stringUtil.language(1128),navigatePage:''},
                      {title:stringUtil.language(642),navigatePage:'BusinessDetailsActivityWorker',paramsPage:{currContactItem:this.state.currWorkerItem}}
                    ]}});
                  }}>{stringUtil.language(642)}</span>:<div style={{position:'absolute'}}/>}
                  {otherUtil.authorityFindCode("010202")!=null?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#808080',borderRadius:5,backgroundColor:'#DCDCDC',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:arithUtil.pxToDp(10)}} onClick={()=>{
                    if (this.state.currWorkerItem!=null) {
                      this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'AuthoritySettingActivity',mainStacks:[
                        {title:stringUtil.language(1128),navigatePage:''},
                        {title:this.state.currWorkerItem.noteName+stringUtil.language(650),navigatePage:'AuthoritySettingActivity',paramsPage:{currWorkerItem:this.state.currWorkerItem,ownersAll:this.state.owners}}
                      ]}});
                    }
                  }}>权限{stringUtil.language(1092)}</span>:<div style={{position:'absolute'}}/>}
                  {otherUtil.authorityFindCode("010201")!=null&&this.state.currWorkerItem.state!=1?<span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#F00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:arithUtil.pxToDp(10)}} onClick={()=>{
                    this.setState({delWorkerShowPop:!this.state.delWorkerShowPop});
                  }}>{stringUtil.language(651)}</span>:<div style={{position:'absolute'}}/>}
              </div>}
            </div>:<div />}
          {this.state.roleIndex==4&&this.state.currCompanyItem!=null?<div style={{padding:arithUtil.pxToDp(40)}}>
              {otherUtil.authorityFindCode("010602")!=null?<div style={{position:'absolute',right:7,top:'10%',cursor:'pointer'}} onClick={()=>{
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CompanyUpdateActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(652),navigatePage:'CompanyUpdateActivity',paramsPage:this.state.currCompanyItem}
                  ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'50px',color:'#52A056'}}>&#xe653;</i>
                <span style={{color:'#FFF',fontSize:12,position:'absolute',right:6,top:'25%',transform: ['rotate(45deg)']}}>{stringUtil.language(1092)}</span>
              </div>:<div />}
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{width:80,height:80,borderStyle:'solid',borderWidth:1,borderColor:'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <img style={{width:'72px',height:'72px'}} loading="lazy" src={arithUtil.imageShow(this.state.currCompanyItem.headIcon)} title={stringUtil.language(84)}/>
                </div>
                <span style={{fontSize:24,marginLeft:arithUtil.pxToDp(15)}}>{this.state.currCompanyItem.noteName}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',alignItems:'center',marginTop:25}}>
                <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(382)}</span>
                <span style={{fontSize:14}}>{this.state.currCompanyItem.mobile}</span>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                  <span style={{width:120,height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#F00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:10}} onClick={()=>{
                    this.setState({delCompanyShowPop:!this.state.delCompanyShowPop});
                  }}>{stringUtil.language(653)}</span>
              </div>
            </div>:<div />}
        </div>
        <MaskModal visible={this.state.delCustomerShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(109)}
                </div>}
            okBnEvents={(event) => {
                this.setState({delCustomerShowPop: false});
                stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL+'/'+this.state.currCustomerItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.state.currCustomerItem=null;
                        this.initCustomersData();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delCustomerShowPop: false})}}/>
        <MaskModal visible={this.state.delOwnerShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(110)}
                </div>}
            okBnEvents={(event) => {
                this.setState({delOwnerShowPop: false});
                stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL+'/'+this.state.currOwnerItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.state.currOwnerItem=null;
                        this.initOwnersData();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delOwnerShowPop: false})}}/>
        <MaskModal visible={this.state.delSupplierShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(72)}
                </div>}
            okBnEvents={(event) => {
                this.setState({delSupplierShowPop: false});
                stringUtil.httpRequest(urlUtil.OAUTH_SUPPLIER_URL+'/'+this.state.currSupplierItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.state.currSupplierItem=null;
                        this.initSuppliersData();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delSupplierShowPop: false})}}/>
        <MaskModal visible={this.state.delCompanyShowPop?true:false} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {stringUtil.language(111)}
                </div>}
            okBnEvents={(event) => {
                this.setState({delCompanyShowPop: false});
                stringUtil.httpRequest(urlUtil.OAUTH_COMPANY_URL+'/'+this.state.currCompanyItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.state.currCompanyItem=null;
                        this.initCompanysData();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delCompanyShowPop: false})}}/>
        <MaskModal visible={this.state.delWorkerShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(112)}
                </div>}
            okBnEvents={(event) => {
                this.setState({delWorkerShowPop: false});
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_FIRE_URL,{workerId:this.state.currWorkerItem.id},['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.state.currWorkerItem=null;
                        this.initWorkersData();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delWorkerShowPop: false})}}/>
      </div>)
  }
}

const styles = {
  roleTab:{
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    flexWrap:'nowrap',
  },
  roleTabItem:{
    width:'20%',
    height:arithUtil.pxToDp(35),
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:17,
    fontWeight:'bold',
    color:'#FFF',
    backgroundColor:'#000',
    cursor:'pointer'
  },
  inputText:{
    width:'99%',
    height:arithUtil.pxToDp(30),
    borderStyle:'solid',
    borderColor:'#d0d0d0',
    borderWidth:1,
    borderRadius:2,
    padding:0,
    paddingLeft:arithUtil.pxToDp(5)
  },
  textInput:{
      height:arithUtil.pxToDp(30),
      borderBottomStyle:'solid',
      borderBottomWidth:1,
      borderBottomColor:'#d0d0d0',
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5),
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
  },
  searchTwoRow:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    marginLeft:arithUtil.pxToDp(8),
    marginRight:arithUtil.pxToDp(8),
  },
  searchSort:{
    width:arithUtil.pxToDp(60),
    height:arithUtil.pxToDp(30),
    borderStyle:'solid',
    borderColor:'#d0d0d0',
    borderWidth:1,
    borderRadius:30,
    padding:0,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center'
  },
  roleItem:{
    height:45,
    margin:5,
    borderStyle:'solid',
    borderColor:'#DCDCDC',
    borderWidth: 1,
    borderRadius:5,
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    paddingLeft:arithUtil.pxToDp(8),
    paddingRight:arithUtil.pxToDp(8),
    cursor:'pointer'
  },
};