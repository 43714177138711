import React,{Component} from 'react';
import {PropTypes} from 'prop-types';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import urlUtil from '../utils/urlUtil.js';
import MaskModal from '../components/MaskModal';
import ToastBox from '../components/ToastBox';

export default class TabTop extends Component {
    constructor(props){
        super(props);
        this.state={
            exitShowPop:false,
            warehousesAll:[],
        };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({warehousesAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_WORKER_WAREHOUSE_URL+'/'+(!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).shopRole?'0':(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).workerId),null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({workerWarehouseDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={arithUtil.stylesAdd(styles.container,{height:this.props.viewHeight})}>
            <div style={styles.leftInfo}>
                {localStorage.getItem("currentStallItem")!=null&&JSON.parse(localStorage.getItem("currentStallItem")).logo!=null?<img style={{width:'40px',height:'40px'}} loading="lazy" src={arithUtil.imageShow(JSON.parse(localStorage.getItem("currentStallItem")).logo,2)} title={stringUtil.language(84)}/>:
                <i className="iconfontIndexCss" style={{fontSize:45,color:'#FFF'}}>&#xe60b;</i>}
                <span style={{color:'#FFF',fontSize:20,fontWeight:'bold',marginLeft:arithUtil.pxToDp(10)}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}</span>
            </div>
            <div style={styles.rightInfo}>
                {!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).shopRole||
                ((localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).shopRole&&
                (localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).editWarehouse)?<select style={{width:200,height:30,fontSize:14,borderWidth:0}} onChange={(event)=>{
                    stringUtil.httpRequest(urlUtil.OAUTH_WORKER_WAREHOUSE_URL+'/'+(!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).shopRole?'0':(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).workerId),{warehouseId:JSON.parse(event.target.value).id},['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            if(this.state.workerWarehouseDO==null){this.state.workerWarehouseDO={
                                workerId:0,warehouseId:JSON.parse(event.target.value).id,warehouseName:JSON.parse(event.target.value).name
                            }}else{
                                this.state.workerWarehouseDO.warehouseId=JSON.parse(event.target.value).id
                                this.state.workerWarehouseDO.warehouseName=JSON.parse(event.target.value).name;
                            }
                            window.location.reload();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }}><option style={{display:'none'}}>{stringUtil.language(975)}</option>
                    {this.state.warehousesAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} selected={this.state.workerWarehouseDO&&this.state.workerWarehouseDO.warehouseId==item.id?'selected':''} key={key}>{stringUtil.subStr(item.name,15)}</option>)
                    })}
                </select>:<span style={{color:'#FFF',fontSize:17}}>{this.state.workerWarehouseDO?this.state.workerWarehouseDO.warehouseName:stringUtil.language(1536)}</span>}
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(20),cursor:'pointer'}} onClick={()=>{
                    this.setState({exitShowPop:true});
                }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#FFF'}}>&#xe652;</i>
                    <span style={{color:'#FFF',marginLeft:arithUtil.pxToDp(5)}}>{stringUtil.language(523)}</span>
                </div>
            </div>
            <MaskModal title={stringUtil.language(523)} visible={this.state.exitShowPop} context={this}
                content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(155)}
                </div>}
                okBnEvents={(event) => {
                    //localStorage.clear();//清除stringUtil.language(1097)缓存
                    localStorage.removeItem('authorizationUser');
                    localStorage.removeItem('authorizationStall');
                    this.setState({exitShowPop: false},()=>{
                        this.props.context.props.history.push({pathname:"/"});
                    });
                }} closeBnEvents={() => {this.setState({exitShowPop: false})}}/>
        </div>);
    }
}

TabTop.propsTypes={
    viewHeight:PropTypes.number,//高度
};
TabTop.defaultProps = {
    viewHeight: arithUtil.pxToDp(80),
};

const styles = {
    container: {
        width:'100%',
        backgroundColor:'#52a056',
        display:'flex',
        flexDirection:'row',
        flexWrap:'nowrap',
        justifyContent:'space-between',
        alignItems:'flex-end',
    },
    leftInfo:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'nowrap',
        justifyContent:'flex-start',
        alignItems:'center',
        marginBottom:arithUtil.pxToDp(5),
        paddingLeft:arithUtil.pxToDp(20),
    },
    rightInfo:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'nowrap',
        justifyContent:'flex-end',
        alignItems:'center',
        marginBottom:arithUtil.pxToDp(10),
        paddingRight:arithUtil.pxToDp(20),
    },
};