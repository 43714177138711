import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as printUtil from '../utils/printUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';
import WholesaleInfoView from './WholesaleInfoView.js';
import OrdersInitChoiceView from './OrdersInitChoiceView';

 export default class OrdersListActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        pageTag:0,
        searchs:{
            workerId:-1,
            warehouseId:-1,
            buyerId:-1,
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrBefourDateTime(30,''),0)+''),
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        workersAll:[],
        warehousesAll:[],
        buyersAll:[],
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        saves:[],
        confirms:[],
        currXY:{x:-1,y:-1},
        currItemIndex:-1,
        ownersAll:[],
      };
    }
    componentDidMount(){
        this.initSaves();
        this.getDataList();
        stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workersAll = successResponse.data;
                this.state.workersAll.unshift({id:-1,noteName:stringUtil.language(346)});
                this.setState({workersAll:this.state.workersAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.buyersAll = successResponse.data;
                this.state.buyersAll.unshift({id:-1,name:stringUtil.language(281)});
                this.setState({buyersAll:this.state.buyersAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll = successResponse.data;
                this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
                this.setState({warehousesAll:this.state.warehousesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAllTemp = successResponse.data;
                this.state.ownersAll = [];
                // this.state.ownersAll.unshift({targetType:-1,targetId:-1,noteName:stringUtil.language(937)});
                for(let i=0;i<ownersAllTemp.length;i++){
                    this.state.ownersAll.push({targetType:0,targetId:ownersAllTemp[i].id,noteName:ownersAllTemp[i].noteName});
                }
                this.state.ownersAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
                this.setState({ownersAll:this.state.ownersAll});
                // stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
                //     if (successResponse.status==200) {
                //         let workersAll = successResponse.data;
                //         for(let i=0;i<workersAll.length;i++){
                //             this.state.ownersAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                //         }
                //         this.setState({ownersAll:this.state.ownersAll});
                //     }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                // },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initSaves(){
        stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_SAVE_URL,null,['key','get',1],(successResponse)=>{
            if(successResponse.status==200){
                this.state.saves=successResponse.data;
                if(!this.state.orderInfo&&this.state.saves.length){
                    this.state.currItemIndex=0;
                    this.initOrderInfo(this.state.saves[0]);
                }else{this.setState({saves:this.state.saves});}
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_URL,{
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime),
            workerId:this.state.searchs.workerId,
            buyerId:this.state.searchs.buyerId,
            warehouseId:this.state.searchs.warehouseId,
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let searchDtoObj = successResponse.data;
                this.state.totalNum = searchDtoObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.setState({confirms:JSON.parse(JSON.stringify(searchDtoObj.items))});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
      var _this= this;
      _this.setState({goValue : e.target.value})
      var value = e.target.value;
      if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
      else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
      else _this.pageClick(value);
    }
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }
    initOrderInfo(orderInfo){
        stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_URL+'/'+orderInfo.id,null,['key','get',1],(successResponse)=>{
            if(successResponse.status==200){
                this.state.orderInfo=successResponse.data;
                for(let i=0;i<this.state.orderInfo.customers.length;i++){let cusItem=this.state.orderInfo.customers[i];
                    let tempItems=[];
                    for(let j=0;j<this.state.orderInfo.commodities.length;j++){let comItem=this.state.orderInfo.commodities[j];
                        let isHava=false;
                        for(let k=0;k<cusItem.items.length;k++){
                            if(comItem.commodityId==cusItem.items[k].commodityId){
                                tempItems.push(cusItem.items[k]);
                                isHava=true;break;
                            }
                        }
                        if(!isHava){
                            tempItems.push({
                                commodityId:comItem.commodityId,
                                standard:comItem.standard,
                                unitPrice:comItem.unitPrice,
                                mode:comItem.mode,
                                number:0,
                                weight:0,
                            })
                        }
                    }
                    cusItem.items=tempItems;
                }
                this.setState({orderInfo:this.state.orderInfo});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        let oneScalWidth='5.5%';
        let scalWidth=((100-5.5)/((this.state.orderInfo&&this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).length+1))+'%';
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{width:'100%',height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{width:100,height:30,borderColor:!this.state.pageTag?'#2A9838':'#808080',backgroundColor:!this.state.pageTag?'#2A9838':'transparent',borderStyle:'solid',borderWidth:1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currItemIndex=-1;
                            this.setState({pageTag:0});
                        }}><span style={{color:!this.state.pageTag?'#FFF':'#666',fontSize:16}}>订货中</span></div>
                        <div style={{width:100,height:30,borderColor:this.state.pageTag==1?'#2A9838':'#808080',backgroundColor:this.state.pageTag==1?'#2A9838':'transparent',borderStyle:'solid',borderWidth:1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                           this.state.currItemIndex=-1;
                           this.setState({pageTag:1});
                        }}><span style={{color:this.state.pageTag==1?'#FFF':'#666',fontSize:16}}>已完成</span></div>
                    </div>
                    {this.state.pageTag?<div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{width:'12%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20}}>
                            {/* <span>{stringUtil.language(1122)}:</span> */}
                            <select style={{height:30,display:'flex',flexGrow:1,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.workerId = JSON.parse(event.target.value).id;
                                this.state.searchs.workerName = JSON.parse(event.target.value).noteName;
                                this.getDataList();
                            }}>
                            {this.state.workersAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </select>
                        </div>
                        <div style={{width:'12%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20}}>
                            {/* <span>{stringUtil.language(891)}:</span> */}
                            <select style={{height:30,display:'flex',flexGrow:1,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.buyerId = JSON.parse(event.target.value).id;
                                this.getDataList();
                            }}>
                            {this.state.buyersAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name} ({stringUtil.language(1156)}:{item.code})</option>)
                            })}
                            </select>
                        </div>
                        <div style={{width:'20%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20}}>
                            {/* <span>{stringUtil.language(892)}</span> */}
                            <select style={{height:30,display:'flex',flexGrow:1,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                                this.state.searchs.warehouseName = JSON.parse(event.target.value).name;
                                this.getDataList();
                            }}>
                            {this.state.warehousesAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                            </select>
                        </div>
                        <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20}}>
                            <span>{stringUtil.language(1039)}:</span><div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(new Date(date.valueOf()),'Y-M-D'),0);
                                this.getDataList();
                            }} /></div><span style={{marginLeft:7,marginRight:7}}>{stringUtil.language(1174)}</span><div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(new Date(date.valueOf()),'Y-M-D'));
                                this.getDataList();
                            }} /></div>
                        </div>
                    </div>:<div style={{width:0,flexGrow:1}}/>}
                    {otherUtil.authorityFindCode("020802")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.setState({ordersInitChoiceShowPop:true})
                        // this.state.keySearch='';
                        // this.state.ownersItemDatas=JSON.parse(JSON.stringify(this.state.ownersAll));
                        // this.state.choiceOwners=[];
                        // this.setState({ownersShowPop:true});
                    }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                        <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>新增订货接单</span>
                    </div>:<div/>}
                    {!this.state.pageTag&&this.state.orderInfo&&otherUtil.authorityFindCode("020803")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersSaveActivity',mainStacks:[
                            {title:stringUtil.language(1128),navigatePage:''},
                            {title:stringUtil.language(1202),navigatePage:'OrdersListActivity',paramsPage:'参数内容'},
                            {title:stringUtil.language(985),navigatePage:'OrdersSaveActivity',paramsPage:{itemInfo:JSON.stringify(this.state.orderInfo)}}
                        ]}});
                    }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe63a;</i>
                        <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>编辑订货单</span>
                    </div>:''}
                    {/* {!this.state.pageTag?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                        console.log('数据----',this.state.orderInfo)
                        let result={commodities:[],customers:[],state:1};
                        for(let i=0;i<this.state.orderInfo.commodities.length;i++){
                            result.commodities.push({
                                commodityId:this.state.orderInfo.commodities[i].commodityId,
                                unitPrice:this.state.orderInfo.commodities[i].unitPrice,
                                mode:this.state.orderInfo.commodities[i].mode,
                            })
                        }
                        for(let i=0;i<this.state.orderInfo.customers.length;i++){
                            let cusItem={items:[],
                                customerId:this.state.orderInfo.customers[i].customerId,
                            };
                            for(let j=0;j<this.state.orderInfo.customers[i].items.length;j++){
                                if(this.state.orderInfo.customers[i].items[j].number||this.state.orderInfo.customers[i].items[j].weight){
                                    if(!this.state.orderInfo.customers[i].items[j].unitPrice){
                                        ToastBox.error('('+this.state.orderInfo.customers[i].customerName+')的('+(this.state.orderInfo.commodities[j].code?this.state.orderInfo.commodities[j].code:this.state.orderInfo.commodities[j].commodityName)+')未报价');return;
                                    }
                                    cusItem.items.push({
                                        commodityId:this.state.orderInfo.customers[i].items[j].commodityId,
                                        unitPrice:this.state.orderInfo.customers[i].items[j].unitPrice,
                                        mode:this.state.orderInfo.customers[i].items[j].mode,
                                        number:this.state.orderInfo.customers[i].items[j].number,
                                        weight:this.state.orderInfo.customers[i].items[j].weight,
                                    })
                                }
                            }
                            if(cusItem.items.length)result.customers.push(cusItem)
                        }
                        if(!result.customers.length){ToastBox.error('没有客户不能生成销售单');return;}
                        this.state.resultDataInfo=result;
                        this.setState({submitShowPop:true});
                    }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                        <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>确订下推出货单</span>
                    </div>:''} */}
                    {this.state.orderInfo?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                        let resArr=new Array();
                        resArr.push(`<tr style="height: 50px"><td align="center" colspan="${(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).length+2}"><b><font size="3">${stringUtil.language(1202)}（${this.state.orderInfo.warehouseName}，${arithUtil.formatToTimeStr(new Date(this.state.orderInfo.time),'Y-M-D')}${(this.state.orderInfo.targetName?'，'+this.state.orderInfo.targetName:this.state.orderInfo.cargoMode==1?'，'+stringUtil.language(1051):'')+(this.state.orderInfo.cargoNo?stringUtil.language(1237,this.state.orderInfo.cargoNo):'')}，业务:${this.state.orderInfo.buyerId?this.state.orderInfo.buyerName:'档口老板'}，销售:${this.state.orderInfo.workerId?this.state.orderInfo.workerName:'档口老板'}）</font></b></td></tr>`);
                        resArr.push(`<tr><td align="center"><b><font size="3">品名代码</font></b></td>`);
                        for(let i=0;i<(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).length;i++){let item=this.state.orderInfo.commodities[i];
                            resArr.push(`<td align="center"><b><font size="3">${item.code?item.code:item.commodityName}</font></b></td>`);
                        }
                        resArr.push(`<td align="center"><b><font size="3">小计</font></b></td></tr>`);
                        resArr.push(`<tr><td align="center"><b><font size="3">库存结余</font></b></td>`);
                        let totalCommoditiesPrint={stockNumber:0,stockWeight:0};
                        let totalInfoPrint=[];
                        for(let i=0;i<(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).length;i++){let item=this.state.orderInfo.commodities[i];
                            totalInfoPrint.push({number:0,weight:0});
                            totalCommoditiesPrint.stockNumber=(totalCommoditiesPrint.stockNumber).add(item.stockNumber);
                            totalCommoditiesPrint.stockWeight=(totalCommoditiesPrint.stockWeight).add(item.stockWeight);
                            resArr.push(`<td align="center"><b><font size="3">${item.stockNumber?item.stockNumber:''}</font></b></td>`);
                        }
                        resArr.push(`<td align="center"><b><font size="3">${totalCommoditiesPrint.stockNumber}</font></b></td></tr>`);
                        resArr.push(`<tr><td align="center"><b><font size="3">客户报价</font></b></td>`);
                        for(let i=0;i<(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).length;i++){let item=this.state.orderInfo.commodities[i];
                            resArr.push(`<td align="center"><b><font size="3">${item.unitPrice?(item.mode?arithUtil.fenToYuan(item.unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)):''}</font></b></td>`);
                        }
                        resArr.push(`<td align="center"><b><font size="3">-</font></b></td></tr>`);
                        for(let i=0;i<(this.state.orderInfo.customers?this.state.orderInfo.customers:[]).length;i++){let item=this.state.orderInfo.customers[i];
                            let totalInfoCus={number:0,weight:0};
                            resArr.push(`<tr><td align="left"><font size="3">${item.customerName}</font></td>`);
                            for(let j=0;j<item.items.length;j++){
                                totalInfoPrint[j].number=(totalInfoPrint[j].number).add(item.items[j].number);
                                totalInfoPrint[j].weight=(totalInfoPrint[j].weight).add(item.items[j].weight);
                                totalInfoCus.number=(totalInfoCus.number).add(item.items[j].number);
                                totalInfoCus.weight=(totalInfoCus.weight).add(item.items[j].weight);
                                let isChangeUnitPrice=false;
                                if(this.state.orderInfo.commodities[j].unitPrice!=item.items[j].unitPrice)isChangeUnitPrice=true;
                                resArr.push(`<td align="center"><font size="3">${item.items[j].number?item.items[j].number:item.items[j].weight?arithUtil.keToWeight(item.items[j].weight,this.state.weightMode):''}${isChangeUnitPrice?(item.items[j].unitPrice?'('+(item.items[j].mode?arithUtil.fenToYuan(item.items[j].unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.items[j].unitPrice,2),0,this.state.weightMode))+')':''):''}</font></td>`);
                            }
                            resArr.push(`<td align="center"><font size="3">${totalInfoCus.number}</font></td></tr>`);
                        }
                        resArr.push(`<tr><td align="center"><b><font size="3">订货合计</font></b></td>`);
                        let totalInfoTotalPrint={number:0,balanceNumber:0};
                        for(let i=0;i<(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).length;i++){
                            totalInfoTotalPrint.number=(totalInfoTotalPrint.number).add(totalInfoPrint[i].number);
                            resArr.push(`<td align="center"><b><font size="3">${totalInfoPrint[i].number?totalInfoPrint[i].number:''}</font></b></td>`);
                        }
                        resArr.push(`<td align="center"><b><font size="3">${totalInfoTotalPrint.number}</font></b></td></tr>`);
                        resArr.push(`<tr><td align="center"><b><font size="3">剩余可售</font></b></td>`);
                        for(let i=0;i<(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).length;i++){
                            let balanceVal=(this.state.orderInfo.commodities[i].stockNumber).sub(totalInfoPrint[i].number,0);
                            totalInfoTotalPrint.balanceNumber=(totalInfoTotalPrint.balanceNumber).add(balanceVal);
                            resArr.push(`<td align="center"><b><font size="3">${balanceVal?balanceVal:''}</font></b></td>`);
                        }
                        resArr.push(`<td align="center"><b><font size="3">${totalInfoTotalPrint.balanceNumber}</font></b></td></tr>`);
                        otherUtil.createExcel(resArr.join(''),stringUtil.language(1202)+'('+this.state.orderInfo.warehouseName+','+arithUtil.formatToTimeStr(new Date(this.state.orderInfo.time),'Y-M-D')+')');
                    }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                        <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                    </div>:''}
                    {this.state.pageTag==1&&this.state.orderInfo?<div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_WHOLESALE_URL+'/'+this.state.orderInfo.id,null,['key','get',1],(successResponse)=>{
                            if(successResponse.status==200){
                                this.state.linkWholesales=successResponse.data;
                                if(this.state.linkWholesales)this.setState({wholesalesShowPop:true});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe61e;</i>
                        <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>此订货关联下推销售单</span>
                    </div>:''}
                    {this.state.pageTag==1&&this.state.orderInfo?<div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                        this.setState({orderNoFinishShowPop:true});
                    }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe60c;</i>
                        <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>反完成</span>
                    </div>:''}
                </div>
                <div style={{width:'100%',height:'92%',display:'flex',flexDirection:'row'}}>
                    <div style={{width:340,height:'100%',display:'flex',flexDirection:'column',borderStyle:'solid',borderColor:'#FFD700',borderWidth:2}}>
                        {!this.state.pageTag?<div style={{width:'100%',height:0,flexGrow:1,overflowY:'auto'}}>
                            {this.state.saves.map((item,key)=>{
                                return(<div style={{height:70,backgroundColor:this.state.currItemIndex==key?'#FFEFD5':'transparent',borderBottomWidth:1,borderBottomColor:'#DCDCDC',borderBottomStyle:'solid',display:'flex',flexDirection:'column',justifyContent:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                    this.state.currItemIndex=key;
                                    this.initOrderInfo(item);
                                }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <span style={{width:0,flexGrow:1,color:'#F00',fontSize:18,marginLeft:7}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                        <span style={{color:'#666',fontSize:12,marginRight:7}}>{item.buyerId?item.buyerName:''}</span>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <span style={{width:0,flexGrow:1,color:'#808080',fontSize:10,marginLeft:7}}>{item.warehouseName}</span>
                                        <span style={{color:'#808080',fontSize:10,marginRight:7}}>销售员:{item.workerId?item.workerName:'档口老板'}</span>
                                    </div>
                                </div>)
                            })}
                        </div>:this.state.pageTag==1?<div style={{width:'100%',height:0,flexGrow:1,overflowY:'auto'}}>
                            {this.state.confirms.map((item,key)=>{
                                return(<div style={{height:70,backgroundColor:this.state.currItemIndex==key?'#FFEFD5':'transparent',borderBottomWidth:1,borderBottomColor:'#DCDCDC',borderBottomStyle:'solid',display:'flex',flexDirection:'column',justifyContent:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                    this.state.currItemIndex=key;
                                    this.initOrderInfo(item);
                                }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <span style={{width:0,flexGrow:1,color:'#F00',fontSize:18,marginLeft:7}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                        <span style={{color:'#666',fontSize:12,marginRight:7}}>{item.buyerId?item.buyerName:''}</span>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <span style={{width:0,flexGrow:1,color:'#808080',fontSize:10,marginLeft:7}}>{item.warehouseName}</span>
                                        <span style={{color:'#808080',fontSize:10,marginRight:7}}>销售员:{item.workerId?item.workerName:'档口老板'}</span>
                                    </div>
                                </div>)
                            })}
                        </div>:''}
                        {this.state.pageTag==1&&this.state.totalPage>1?<div style={{height:60}}>
                            <Pagination total={this.state.totalNum}
                                current={this.state.currentPage}
                                totalPage={this.state.totalPage}
                                goValue={this.state.goValue}
                                pageClick={this.pageClick.bind(this)}
                                goPrev={this.goPrevClick.bind(this)}
                                goNext={this.goNext.bind(this)}
                                switchChange={this.goSwitchChange.bind(this)}/>
                        </div>:''}
                    </div>
                    {this.state.orderInfo?<div style={{width:0,flexGrow:1,overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>
                        <div><div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{color:'#666',marginLeft:20}}>批次：{this.state.orderInfo.targetName?this.state.orderInfo.targetName:this.state.orderInfo.cargoMode==1?stringUtil.language(1051):''} {this.state.orderInfo.cargoId?'第'+this.state.orderInfo.cargoNo+'批':'全部批次'}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{width:100,backgroundColor:'#DCDCDC'}}>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15}}>品名代码</span>
                                </div>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15}}>库存结余</span>
                                </div>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15}}>客户报价</span>
                                </div>
                            </div>
                            {(()=>{this.state.totalCommodities={stockNumber:0,stockWeight:0};
                                this.state.totalInfo=[];
                                this.state.totalInfoTotal={number:0,balanceNumber:0,money:0};
                            })()}
                            {(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).map((item,key)=>{
                                this.state.totalInfo.push({
                                    number:0,weight:0,money:0
                                })
                                this.state.totalCommodities.stockNumber=(this.state.totalCommodities.stockNumber).add(item.stockNumber);
                                this.state.totalCommodities.stockWeight=(this.state.totalCommodities.stockWeight).add(item.stockWeight);
                                return(<div style={{width:70,backgroundColor:'#DCDCDC'}} key={key}>
                                    <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{width:'100%',color:'#000',fontSize:17,fontWeight:'bold',textAlign:'center',textOverflow:'clip',whiteSpace:'nowrap',overflow:'hidden'}}>{item.code?item.code:item.commodityName}</span>
                                    </div>
                                    <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{color:'#000',fontSize:15}}>{item.stockNumber?item.stockNumber:''}</span>
                                    </div>
                                    <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{color:'#F00',fontSize:15}}>{item.unitPrice?(item.mode?arithUtil.fenToYuan(item.unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)):''}</span>
                                    </div>
                                </div>);
                            })}
                            <div style={{width:70,backgroundColor:'#DCDCDC'}}>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15}}>小计</span>
                                </div>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15}}>{this.state.totalCommodities.stockNumber}</span>
                                </div>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#F00',fontSize:17,fontWeight:'bold'}}>-</span>
                                </div>
                            </div>
                            <div style={{width:70,backgroundColor:'#DCDCDC'}}>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15}}>总价</span>
                                </div>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15}}>-</span>
                                </div>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#F00',fontSize:17,fontWeight:'bold'}}>-</span>
                                </div>
                            </div>
                        </div>
                        {(this.state.orderInfo.customers?this.state.orderInfo.customers:[]).map((item,key)=>{
                            let totalInfoCus={number:0,weight:0,money:0};
                            return(<div style={{backgroundColor:this.state.currXY.y==key?'#FFDEAD':'transparent',display:'flex',flexDirection:'row',alignItems:'center'}} key={key} onClick={()=>{
                                this.state.currXY.y=key;
                                this.setState({currXY:this.state.currXY});
                            }}><div style={{width:100,height:35,backgroundColor:this.state.currXY.y==key?'#FFDEAD':'#F5F5F5',borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:'100%',color:'#000',fontSize:15,fontWeight:'bold',marginLeft:5,textOverflow:'clip',whiteSpace:'nowrap',overflow:'hidden'}}>{item.customerName}</span>
                                </div>
                                {item.items.map((itemb,keyb)=>{
                                    let moneyTemp=0;
                                    if(itemb.mode){moneyTemp=(itemb.number).mul(itemb.unitPrice,5);
                                    }else{moneyTemp=(arithUtil.keToWeight(itemb.weight,0)).mul(itemb.unitPrice,5);}
                                    this.state.totalInfo[keyb].number=(this.state.totalInfo[keyb].number).add(itemb.number);
                                    this.state.totalInfo[keyb].weight=(this.state.totalInfo[keyb].weight).add(itemb.weight);
                                    this.state.totalInfo[keyb].money=(this.state.totalInfo[keyb].money).add(moneyTemp);
                                    totalInfoCus.number=(totalInfoCus.number).add(itemb.number);
                                    totalInfoCus.weight=(totalInfoCus.weight).add(itemb.weight);
                                    if(itemb.mode){totalInfoCus.money=(totalInfoCus.money).add((itemb.number).mul(itemb.unitPrice,5));
                                    }else{totalInfoCus.money=(totalInfoCus.money).add((arithUtil.keToWeight(itemb.weight,0)).mul(itemb.unitPrice,5));}
                                    let isChangeUnitPrice=false;
                                    if(this.state.orderInfo.commodities[keyb].unitPrice!=itemb.unitPrice)isChangeUnitPrice=true;
                                    return(<div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}} key={keyb}>
                                        <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{itemb.number?itemb.number:itemb.weight?arithUtil.keToWeight(itemb.weight,this.state.weightMode):''}</span>
                                        {isChangeUnitPrice?<span style={{color:'#F00',fontSize:15,position:'absolute',top:0,right:0}}>{itemb.unitPrice?(itemb.mode?arithUtil.fenToYuan(itemb.unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)):0}</span>:''}
                                    </div>);
                                })}
                                <div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:18,fontWeight:'bold'}}>{totalInfoCus.number}</span>
                                </div>
                                <div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:18,fontWeight:'bold'}}>{arithUtil.fenToYuan(totalInfoCus.money)}</span>
                                </div>
                            </div>)
                        })}
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F5F5F5'}}>
                            <div style={{width:100,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
                                <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>订货合计</span>
                            </div>
                            {(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).map((item,key)=>{
                                this.state.totalInfoTotal.number=(this.state.totalInfoTotal.number).add(this.state.totalInfo[key].number);
                                return(<div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}} key={key}>
                                    <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{this.state.totalInfo[key].number?this.state.totalInfo[key].number:''}</span>
                                </div>);
                            })}
                            <div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
                                <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{this.state.totalInfoTotal.number}</span>
                            </div>
                            <div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
                                <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>-</span>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F5F5F5'}}>
                            <div style={{width:100,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>价格合计</span>
                            </div>
                            {(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).map((item,key)=>{
                                this.state.totalInfoTotal.money=(this.state.totalInfoTotal.money).add(this.state.totalInfo[key].money);
                                return(<div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} key={key}>
                                    <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{this.state.totalInfo[key].money?arithUtil.fenToYuan(this.state.totalInfo[key].money):''}</span>
                                </div>);
                            })}
                            <div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>-</span>
                            </div>
                            <div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.totalInfoTotal.money)}</span>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F5F5F5'}}>
                            <div style={{width:100,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>剩余可售</span>
                            </div>
                            {(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).map((item,key)=>{
                                let balanceVal=(item.stockNumber).sub(this.state.totalInfo[key].number,0);
                                this.state.totalInfoTotal.balanceNumber=(this.state.totalInfoTotal.balanceNumber).add(balanceVal);
                                return(<div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}} key={key}>
                                    <span style={{color:'#F00',fontSize:15,fontWeight:'bold'}}>{balanceVal?balanceVal:''}</span>
                                </div>);
                            })}
                            <div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                <span style={{color:'#F00',fontSize:15,fontWeight:'bold'}}>{this.state.totalInfoTotal.balanceNumber}</span>
                            </div>
                            <div style={{width:70,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                <span style={{color:'#F00',fontSize:15,fontWeight:'bold'}}>-</span>
                            </div>
                        </div></div>
                    </div>:<div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <span style={{color:'#1B912A',fontSize:20}}>请选择左边单据！</span>
                    </div>}
                </div>
                {this.state.orderInfo&&this.state.isShowTabTitle?<div style={{width:'100%'}} id='printArea'>
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(1202)}</span>
                        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:17}}>{arithUtil.formatToTimeStr(new Date(this.state.orderInfo.time),'Y-M-D')}</span>
                            <span style={{fontSize:17,marginLeft:20}}>{this.state.orderInfo.warehouseName}</span>
                            <span style={{fontSize:17,marginLeft:20}}>{this.state.orderInfo.buyerId?this.state.orderInfo.buyerName:'档口老板'}</span>
                            <span style={{fontSize:17,marginLeft:20}}>{this.state.orderInfo.workerId?this.state.orderInfo.workerName:'档口老板'}</span>
                        </div>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{color:'#666',marginLeft:20}}>批次：{this.state.orderInfo.targetName?this.state.orderInfo.targetName:this.state.orderInfo.cargoMode==1?stringUtil.language(1051):''} {this.state.orderInfo.cargoNo?'第'+this.state.orderInfo.cargoNo+'批':''}</span>
                    </div>
                    <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{width:oneScalWidth,backgroundColor:'#DCDCDC'}}>
                            <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:15}}>品名代码</span>
                            </div>
                            <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:15}}>库存结余</span>
                            </div>
                            <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:15}}>客户报价</span>
                            </div>
                        </div>
                        {(()=>{this.state.totalCommoditiesPrint={stockNumber:0,stockWeight:0};
                            this.state.totalInfoPrint=[];
                            this.state.totalInfoTotalPrint={number:0,balanceNumber:0};
                        })()}
                        {(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).map((item,key)=>{
                            this.state.totalInfoPrint.push({
                                number:0,weight:0
                            })
                            this.state.totalCommoditiesPrint.stockNumber=(this.state.totalCommoditiesPrint.stockNumber).add(item.stockNumber);
                            this.state.totalCommoditiesPrint.stockWeight=(this.state.totalCommoditiesPrint.stockWeight).add(item.stockWeight);
                            return(<div style={{width:scalWidth,backgroundColor:'#DCDCDC'}} key={key}>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{width:'100%',color:'#000',fontSize:17,fontWeight:'bold',textAlign:'center',textOverflow:'clip',whiteSpace:'nowrap',overflow:'hidden'}}>{item.code?item.code:item.commodityName}</span>
                                </div>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15}}>{item.stockNumber?item.stockNumber:''}</span>
                                </div>
                                <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#F00',fontSize:15}}>{item.unitPrice?(item.mode?arithUtil.fenToYuan(item.unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)):''}</span>
                                </div>
                            </div>);
                        })}
                        <div style={{width:scalWidth,backgroundColor:'#DCDCDC'}}>
                            <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:15}}>小计</span>
                            </div>
                            <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:15}}>{this.state.totalCommoditiesPrint.stockNumber}</span>
                            </div>
                            <div style={{width:'100%',height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#F00',fontSize:17,fontWeight:'bold'}}>-</span>
                            </div>
                        </div>
                    </div>
                    {(this.state.orderInfo.customers?this.state.orderInfo.customers:[]).map((item,key)=>{
                        let totalInfoCus={number:0,weight:0};
                        return(<div style={{backgroundColor:this.state.currXY.y==key?'#FFDEAD':'transparent',width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key} onClick={()=>{
                            this.state.currXY.y=key;
                            this.setState({currXY:this.state.currXY});
                        }}><div style={{width:oneScalWidth,height:35,backgroundColor:this.state.currXY.y==key?'#FFDEAD':'#F5F5F5',borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:'100%',color:'#000',fontSize:15,fontWeight:'bold',marginLeft:5,textOverflow:'clip',whiteSpace:'nowrap',overflow:'hidden'}}>{item.customerName}</span>
                            </div>
                            {item.items.map((itemb,keyb)=>{
                                this.state.totalInfoPrint[keyb].number=(this.state.totalInfoPrint[keyb].number).add(itemb.number);
                                this.state.totalInfoPrint[keyb].weight=(this.state.totalInfoPrint[keyb].weight).add(itemb.weight);
                                totalInfoCus.number=(totalInfoCus.number).add(itemb.number);
                                totalInfoCus.weight=(totalInfoCus.weight).add(itemb.weight);
                                let isChangeUnitPrice=false;
                                if(this.state.orderInfo.commodities[keyb].unitPrice!=itemb.unitPrice)isChangeUnitPrice=true;
                                return(<div style={{width:scalWidth,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}} key={keyb}>
                                    <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{itemb.number?itemb.number:itemb.weight?arithUtil.keToWeight(itemb.weight,this.state.weightMode):''}</span>
                                    {isChangeUnitPrice?<span style={{color:'#F00',fontSize:15,position:'absolute',top:0,right:0}}>{itemb.unitPrice?(itemb.mode?arithUtil.fenToYuan(itemb.unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)):0}</span>:''}
                                </div>);
                            })}
                            <div style={{width:scalWidth,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#000',fontSize:18,fontWeight:'bold'}}>{totalInfoCus.number}</span>
                            </div>
                        </div>)
                    })}
                    <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F5F5F5'}}>
                        <div style={{width:oneScalWidth,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
                            <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>订货合计</span>
                        </div>
                        {(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).map((item,key)=>{
                            this.state.totalInfoTotalPrint.number=(this.state.totalInfoTotalPrint.number).add(this.state.totalInfoPrint[key].number);
                            return(<div style={{width:scalWidth,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}} key={key}>
                                <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{this.state.totalInfoPrint[key].number?this.state.totalInfoPrint[key].number:''}</span>
                            </div>);
                        })}
                        <div style={{width:scalWidth,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
                            <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{this.state.totalInfoTotalPrint.number}</span>
                        </div>
                    </div>
                    <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F5F5F5'}}>
                        <div style={{width:oneScalWidth,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>剩余可售</span>
                        </div>
                        {(this.state.orderInfo.commodities?this.state.orderInfo.commodities:[]).map((item,key)=>{
                            let balanceVal=(item.stockNumber).sub(this.state.totalInfoPrint[key].number,0);
                            this.state.totalInfoTotalPrint.balanceNumber=(this.state.totalInfoTotalPrint.balanceNumber).add(balanceVal);
                            return(<div style={{width:scalWidth,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}} key={key}>
                                <span style={{color:'#F00',fontSize:15,fontWeight:'bold'}}>{balanceVal?balanceVal:''}</span>
                            </div>);
                        })}
                        <div style={{width:scalWidth,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <span style={{color:'#F00',fontSize:15,fontWeight:'bold'}}>{this.state.totalInfoTotalPrint.balanceNumber}</span>
                        </div>
                    </div>
                </div>:''}
            </div>
            <MaskModal title={'选择商品'} visible={this.state.ordersInitChoiceShowPop?true:false} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.75)}
                content={<div style={{width:'100%',height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.addShowPopIndex}>
                    <div style={{width:'100%',height:'100%',overflow:'auto'}}>
                        <OrdersInitChoiceView context={this} successEvent={(result1,result2)=>{
                            this.state.choiceCommoditys = result1;
                            this.state.choiceBuyer = result2;
                        }}/>
                    </div>
                </div>} okBnTitle='添加订货单'
                okBnEvents={(event) => {
                    if(!this.state.choiceCommoditys||!this.state.choiceCommoditys.length){ToastBox.error(stringUtil.language(190));return;}
                    if(!this.state.choiceBuyer){ToastBox.error('请选择业务员');return;}
                    this.setState({ordersInitChoiceShowPop:false},()=>{
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersAddActivity',mainStacks:[
                            {title:stringUtil.language(1128),navigatePage:''},
                            {title:stringUtil.language(1202),navigatePage:'OrdersListActivity',paramsPage:'参数内容'},
                            {title:stringUtil.language(1222),navigatePage:'OrdersAddActivity',paramsPage:{choiceCommoditys:JSON.stringify(this.state.choiceCommoditys),buyerItemInfo:JSON.stringify(this.state.choiceBuyer)}}
                        ]}});
                    })
                }} closeBnEvents={() => {this.setState({ordersInitChoiceShowPop:false})}}/>
            <MaskModal visible={this.state.orderNoFinishShowPop?true:false} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <span>确定反完成该订货单？</span>
                    </div>}
                okBnEvents={(event) => {
                    this.state.orderNoFinishShowPop=false;
                    stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_CANCEL_URL+'/'+this.state.orderInfo.id,null,['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initSaves();
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({orderNoFinishShowPop: false})}}/>
            <MaskModal visible={this.state.ownersShowPop?true:false} width={400} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{width:'100%',height:'100%',paddingLeft:10,paddingRight:10,display:'flex',flexDirection:'column'}} key={this.state.addShowPopIndex}>
                    <div style={{width:'100%',height:35,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <input style={{width:230,height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                            this.state.keySearch = event.target.value;
                            if(this.state.keySearch=='')this.setState({ownersItemDatas:this.state.ownersAll});
                        }} placeholder={stringUtil.language(32)}/>
                        <span style={{width:40,height:'25px',color:'#FFF',lineHeight:'25px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:5,cursor:'pointer'}} onClick={()=>{
                            this.state.ownersItemDatas=[];
                            for(let i=0;i<this.state.ownersAll.length;i++){
                                if((this.state.ownersAll[i].noteName).indexOf(this.state.keySearch)==-1)continue;
                                this.state.ownersItemDatas.push(JSON.parse(JSON.stringify(this.state.ownersAll[i])));
                            }
                            this.setState({ownersItemDatas:this.state.ownersItemDatas});
                        }}>{stringUtil.language(1034)}</span>
                        <div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            if(this.state.isAllChoice){this.state.isAllChoice=0;
                                this.state.choiceOwners=[];
                            }else{this.state.isAllChoice=1;
                                this.state.choiceOwners=JSON.parse(JSON.stringify(this.state.ownersItemDatas));
                            }
                            this.setState({choiceOwners:this.state.choiceOwners});
                        }}>{this.state.isAllChoice?<span style={{fontFamily:'iconfont',fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</span>:
                            <span style={{fontFamily:'iconfont',fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</span>}
                            <span style={{marginRight:7}}>{stringUtil.language(1036)}</span>
                        </div>
                    </div>
                    <div style={{width:'100%',height:0,flexGrow:1,overflow:'auto'}}>
                        {(this.state.ownersItemDatas?this.state.ownersItemDatas:[]).map((item,key)=>{
                            let isChoice=0;
                            for(let i=0;i<(this.state.choiceOwners?this.state.choiceOwners:[]).length;i++){let owner=this.state.choiceOwners[i];
                                if(owner.targetType==item.targetType&&owner.targetId==item.targetId&&owner.noteName==item.noteName){
                                    isChoice=1;break;
                                }
                            }
                            return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth:1,cursor:'pointer'}} onClick={()=>{
                                let isChoice=0;
                                for(let i=0;i<(this.state.choiceOwners?this.state.choiceOwners:[]).length;i++){let owner=this.state.choiceOwners[i];
                                    if(owner.targetType==item.targetType&&owner.targetId==item.targetId&&owner.noteName==item.noteName){
                                        isChoice=1;this.state.choiceOwners.splice(i,1);break;
                                    }
                                }
                                if(!isChoice)this.state.choiceOwners.push(JSON.parse(JSON.stringify(item)));
                                this.setState({choiceOwners:this.state.choiceOwners});
                            }} key={key}>{isChoice?<span style={{fontFamily:'iconfont',fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</span>:
                                <span style={{fontFamily:'iconfont',fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</span>}
                                <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.noteName}</span>
                            </div>)
                        })}
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if(!this.state.choiceOwners&&!this.state.choiceOwners.length){ToastBox.error(stringUtil.language(891));return;}
                    this.setState({ownersShowPop:false},()=>{
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersAddActivity',mainStacks:[
                            {title:stringUtil.language(1128),navigatePage:''},
                            {title:stringUtil.language(1202),navigatePage:'OrdersListActivity',paramsPage:'参数内容'},
                            {title:stringUtil.language(1222),navigatePage:'OrdersAddActivity',paramsPage:{owners:JSON.stringify(this.state.choiceOwners)}}
                        ]}});
                    });
                }} closeBnEvents={() => {this.setState({ownersShowPop:false})}}/>
            <MaskModal visible={this.state.wholesalesShowPop?true:false} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)} 
                content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    <div style={{width:'100%',height:'95%',overflowY:'auto'}}>
                        {(this.state.linkWholesales?this.state.linkWholesales:[]).map((item,key)=>{
                            return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC'}}>
                                <div style={{width:50,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} onClick={()=>{
                                    if(item.isChoiceChenpinglong){this.state.linkWholesales[key].isChoiceChenpinglong=0;
                                    }else{this.state.linkWholesales[key].isChoiceChenpinglong=1;}
                                    this.setState({linkWholesales:this.state.linkWholesales});
                                }}>{item.isChoiceChenpinglong?<span style={{fontFamily:'iconfont',fontSize:17,color:'#088718'}}>&#xe698;</span>:
                                    <span style={{fontFamily:'iconfont',fontSize:17,color:'#A9A9A9'}}>&#xe6a7;</span>}
                                </div>
                                <div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currWholesaleInfoItem=JSON.parse(JSON.stringify(item));
                                    this.setState({ wholesaleInfoShowPop:true});
                                }} key={key}>
                                    <div style={{width:'33%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <span style={{color:'#666',fontSize:15,fontWeight:'bold',marginLeft:5}}>{arithUtil.formatToTimeStr(new Date(item.createTime),'Y-M-D')}</span>
                                        <span style={{color:'#808080',fontSize:10,fontWeight:'bold',marginLeft:5}}>{arithUtil.formatToTimeStr(new Date(item.createTime),'h:m:s')}</span>
                                    </div>
                                    <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <span style={{width:'100%',color:'#666',fontSize:15,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.customerName+(item.customerAlias?item.customerAlias:'')}</span>
                                        <span style={{width:'100%',color:'#808080',fontSize:10,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>KD.{stringUtil.subStrSE(item.wholesaleNo)}</span>
                                    </div>
                                    <div style={{width:'33%',display:'flex',flexDirection:'column',justifyContent:'center',justifyContent:'flex-end'}}>
                                        <span style={{color:'#666',fontSize:15,fontWeight:'bold',marginRight:5}}>{arithUtil.fenToYuan(item.money)}{stringUtil.currency(1)}</span>
                                        <span style={{color:'#808080',fontSize:10,fontWeight:'bold',marginRight:5}}>{item.number}{stringUtil.language(1173)}{arithUtil.keToWeight(item.weight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                    </div>
                                </div>
                            </div>)
                        })}
                        <div style={{width:'100%',height:40,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                            <div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} onClick={()=>{
                                    if(this.state.isChoiceAll){this.state.isChoiceAll=0;
                                        for(let i=0;i<(this.state.linkWholesales?this.state.linkWholesales:[]).length;i++){
                                            this.state.linkWholesales[i].isChoiceChenpinglong=0;
                                        }
                                    }else{this.state.isChoiceAll=1;
                                        for(let i=0;i<(this.state.linkWholesales?this.state.linkWholesales:[]).length;i++){
                                            this.state.linkWholesales[i].isChoiceChenpinglong=1;
                                        }
                                    }
                                    this.setState({linkWholesales:this.state.linkWholesales});
                                }}>{this.state.isChoiceAll?<span style={{fontFamily:'iconfont',fontSize:17,color:'#088718'}}>&#xe698;</span>:
                                    <span style={{fontFamily:'iconfont',fontSize:17,color:'#A9A9A9'}}>&#xe6a7;</span>}
                                    <span style={{fontSize:16,marginLeft:5,whiteSpace:'nowrap'}}>全选</span>
                                </div>
                            </div>
                            <div style={{height:35,borderStyle:'solid',borderColor:'#20932E',borderWidth:1,borderRadius:3,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:7,paddingRight:7}} onClick={()=>{
                                let wholesaleIds=[];
                                for(let i=0;i<this.state.linkWholesales.length;i++){
                                    let item = this.state.linkWholesales[i];
                                    if(item.isChoiceChenpinglong==1){
                                        wholesaleIds.push(item.id);
                                    }
                                }
                                if(wholesaleIds.length>0){
                                    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_LIST_URL,wholesaleIds,['json','post',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            let wholesales=successResponse.data;
                                            for(let i=0;i<wholesales.length;i++){
                                                if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||wholesales[i].customerType!=1) wholesales[i].billMoney=0;
                                                printUtil.toComputerPrint(otherUtil.wholesaleNull(wholesales[i]));
                                            }
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{ToastBox.error(stringUtil.language(18));}
                            }}><span style={{color:'#20932E',fontSize:13}}>打印所选销售单</span></div>
                            {otherUtil.authorityFindCode("020404")!=null?<div style={{height:35,borderStyle:'solid',borderColor:'#F00',borderWidth:1,borderRadius:3,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:7,paddingRight:7,marginLeft:15}} onClick={()=>{
                                let wholesaleIds=[];
                                for(let i=0;i<this.state.linkWholesales.length;i++){
                                    let item = this.state.linkWholesales[i];
                                    if(item.isChoiceChenpinglong==1){
                                        wholesaleIds.push(item.id);
                                    }
                                }
                                if(wholesaleIds.length>0){this.state.wholesaleIds=wholesaleIds;
                                    this.setState({delsShowPop:true});
                                }else{ToastBox.error(stringUtil.language(18));}
                            }}><span style={{color:'#F00',fontSize:13}}>删除所选销售单</span></div>:<div/>}
                        </div>
                    </div>
                </div>} closeBnTopEvents={() => {this.setState({wholesalesShowPop:false})}}/>
            <MaskModal visible={this.state.delsShowPop?true:false} content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <span style={{color:'#CD5C5C'}}>是否确定删除所选单据？</span>
                    </div>}
              okBnEvents={(event) => {
                this.state.delsShowPop=false;
                stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_BATCH_REMOVE_URL,this.state.wholesaleIds,['json','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        this.state.wholesalesShowPop=false;
                        this.state.delsShowPop=false;
                        this.initOrderInfo(this.state.orderInfo);
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }} closeBnEvents={() => {this.setState({delsShowPop:false})}}/>
            <MaskModal title={stringUtil.language(293)} visible={this.state.wholesaleInfoShowPop?true:false} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{width:'100%',height:'100%',overflow:'auto'}} key={this.state.currWholesaleInfoItem!=null?this.state.currWholesaleInfoItem.id:0}>
                  {this.state.currWholesaleInfoItem!=null?<WholesaleInfoView wholesaleItem={this.state.currWholesaleInfoItem} context={this}/>:''}
                </div>} closeBnTopEvents={() => {this.setState({wholesaleInfoShowPop:false})}}/>
            <MaskModal visible={this.state.submitShowPop?true:false} content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <span style={{color:'#CD5C5C',fontSize:18}}>订货表确订下推后</span>
                        <span style={{color:'#CD5C5C',fontSize:18,fontWeight:'bold'}}>将变成完成状态</span>
                        <span style={{color:'#CD5C5C'}}>是否确订？</span>
                    </div>}
              okBnEvents={(event) => {
                this.state.submitShowPop=false;
                stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_URL+'/'+this.state.orderInfo.id,this.state.resultDataInfo,['json','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let choiceWholesales=successResponse.data;
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_LIST_URL,choiceWholesales,['json','post',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                let wholesales=successResponse.data;
                                for(let i=0;i<wholesales.length;i++){
                                    if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||wholesales[i].customerType!=1) wholesales[i].billMoney=0;
                                    printUtil.toComputerPrint(otherUtil.wholesaleNull(wholesales[i]));
                                }
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        ToastBox.success(stringUtil.language(388));
                        this.state.orderInfo=null;
                        this.initSaves();
                        this.getDataList();
                    }else{this.state.isVisiblity=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{this.state.isVisiblity=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }} closeBnEvents={() => {this.setState({submitShowPop: false})}}/>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};