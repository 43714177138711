import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as printUtil from '../utils/printUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import CustomerAddActivityView from './CustomerAddActivityView';
import CustomerCollectView from './CustomerCollectView';

//我要{stringUtil.language(1037)}
export default class WholesaleAddsActivity extends Component{
    constructor(props) {
        super(props);
        this.state={
          regIndexVal:-1,
          wholesaleInsertVoItem:{createTime:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),
            totalNumber:0,
            totalWeight:0,
            totalGoodsMoney:0,
            totalOtherMoneyTotal:0,
            advanceMoney:0,
            costs:[],
            commodities:[],
            describe:'',
            money:0
          },
          weightMode:0,//0{stringUtil.language(1023)}，1市{stringUtil.language(1172)}
          workerHabitInfo:{},
          saleWorkersAll:[],
          saleWorkersData:[],
          customerItemAll:[],
          warehouseCommodityItemAll:[],
          warehouseCommodityItemData:[],
          wholesaleCargosAll:[],
          wholesaleCargosData:[],
          buyersAll:[],
          currCustomerItem:{},
          costsShowPop:false,
          keySearch:'',//{stringUtil.language(1063)}{stringUtil.language(1034)}关键字
          currCargoItem:{id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)},
          currWholesaleCommodityVo:null,//当前正在{stringUtil.language(1092)}的待{stringUtil.language(1037)}{stringUtil.language(1063)}
          currWholesaleCommodityVoKey:-1,
        };
        if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workerHabitInfo = successResponse.data;
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.customerItemAll = successResponse.data;
                if(this.state.currCustomerItem.id==null){
                    this.state.currCustomerItem=JSON.parse(JSON.stringify(this.state.customerItemAll[0]));
                    document.getElementById('customerSearch').value = stringUtil.subStr(this.state.currCustomerItem.noteName,10);
                    this.state.isPrintTag=this.state.currCustomerItem.type==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                }
                this.searchCustomers();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:-1,currPage:0,pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.wholesaleCargosAll = successResponse.data.items;
                this.setState({wholesaleCargosData:this.state.wholesaleCargosAll});//{stringUtil.language(1084)}列表
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehouseCommodityItemAll = successResponse.data;
                this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({buyersAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_WHOLESALE_WORKER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.saleWorkersAll=successResponse.data;
                this.state.saleWorkersAll.unshift({id:0,noteName:stringUtil.language(431)});
                for(let i=0;i<this.state.saleWorkersAll.length;i++){
                    if(localStorage.getItem("currentStallItem")!=null&&JSON.parse(localStorage.getItem("currentStallItem")).workerId==this.state.saleWorkersAll[i].id){
                        let currWorkerItem = JSON.parse(JSON.stringify(this.state.saleWorkersAll[i]));
                        this.state.wholesaleInsertVoItem.workerId=currWorkerItem.id;
                        this.state.wholesaleInsertVoItem.workerName=currWorkerItem.noteName;
                        document.getElementById('workerSearch').value = stringUtil.subStr(this.state.wholesaleInsertVoItem.workerName,10);
                    }
                }
                this.setState({saleWorkersData:this.state.saleWorkersAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SALE_COST_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.saleCostItemAll = successResponse.data;
                this.state.saleCostItemAll.forEach((item, index) => {
                    this.state.wholesaleInsertVoItem.costs.push({
                        costClauseCalMode:item.costClauseCalMode,
                        costClauseId:item.costClauseId,
                        costClauseName:item.costClauseName,
                        costClausePayee:item.costClausePayee,
                        costClauseScope:item.costClauseScope,
                        moneyScal:item.money,
                        money:0
                    })
                })
                this.setState({wholesaleInsertVoItem: this.state.wholesaleInsertVoItem});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        document.onkeydown=(e)=>{
            console.log('keyCode：',e)
            if(e.keyCode===35){
                if(this.state.currWholesaleCommodityVo)this.submitCommodityItem();
                this.setState({currWholesaleCommodityVo:null,regIndexVal:-1},()=>{document.getElementById('advance').focus();});
            }
            if(e.keyCode===33){
                if(this.state.wholesaleInsertVoItem.commodities==null)this.state.wholesaleInsertVoItem.commodities=[];
                if(this.state.choiceCommodityEditIndex==null||this.state.choiceCommodityEditIndex>=this.state.wholesaleInsertVoItem.commodities.length-1)this.state.choiceCommodityEditIndex=-1;
                this.state.choiceCommodityEditIndex=this.state.choiceCommodityEditIndex+1;
                this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(this.state.wholesaleInsertVoItem.commodities[this.state.choiceCommodityEditIndex]));
                this.state.currWholesaleCommodityVoKey = this.state.choiceCommodityEditIndex;
                if(this.state.currWholesaleCommodityVo.mode!=1)this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(this.state.currWholesaleCommodityVo.unitPrice,0,this.state.weightMode);
                this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                    // document.getElementById('workerSerial').value='';
                    // document.getElementById('customerSerial').value='';
                    document.getElementById('ownerSerial').value='';
                    document.getElementById('commoditySerial').value='';
                    document.getElementById('numberEdit').focus();
                });
            }
            if(e.keyCode===17&&this.state.currWholesaleCommodityVo){//ctrl
                if(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1){
                    this.state.currWholesaleCommodityVo.mode = 0;//stringUtil.language(1022)
                    this.updateCommodityInfo();
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                        if(this.state.currWholesaleCommodityVo)document.getElementById('numberEdit').focus();
                    });
                }else if(this.state.currWholesaleCommodityVo.mode!=1){
                    this.state.currWholesaleCommodityVo.mode = 1;//stringUtil.language(1021)-stringUtil.language(1015)
                    localStorage.setItem("wholesalePriceType",0);//设置缓存
                    this.updateCommodityInfo();
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                        if(this.state.currWholesaleCommodityVo)document.getElementById('numberEdit').focus();
                    });
                }else if(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')!=1){
                    this.state.currWholesaleCommodityVo.mode = 1;//stringUtil.language(1021)-stringUtil.language(1016)
                    localStorage.setItem("wholesalePriceType",1);//设置缓存
                    this.updateCommodityInfo();
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                        if(this.state.currWholesaleCommodityVo)document.getElementById('numberEdit').focus();
                    });
                }
            }
            if(document.getElementById('workerSearch')==document.activeElement||document.getElementById('workerSerial')==document.activeElement){
                if(this.state.showViewsTag!=0)this.setState({showViewsTag:0});
            }else if(document.getElementById('customerSearch')==document.activeElement||document.getElementById('customerSerial')==document.activeElement){
                if(this.state.showViewsTag!=1)this.setState({showViewsTag:1});
            }else if(document.getElementById('ownerSearch')==document.activeElement||document.getElementById('ownerSerial')==document.activeElement){
                if(this.state.showViewsTag!=2)this.setState({showViewsTag:2});
            }else if(document.getElementById('commoditySearch')==document.activeElement||document.getElementById('commoditySerial')==document.activeElement){
                if(this.state.showViewsTag!=3)this.setState({showViewsTag:3});
            }else if(document.getElementById('buyerEdit')==document.activeElement){
                if(this.state.showViewsTag!=5)this.setState({showViewsTag:5});
            }else if(this.state.currWholesaleCommodityVo){
                if(this.state.showViewsTag!=4)this.setState({showViewsTag:4});
            }
            if(e.keyCode===13&&(document.getElementById('advance')==document.activeElement||document.getElementById('describe')==document.activeElement)&&e.code=='NumpadEnter'){
                if(this.state.currCommodityEditsView=='save'){document.getElementById('advance').focus();this.state.currCommodityEditsView='advance';}
                else if(this.state.currCommodityEditsView=='confirm')this.state.currCommodityEditsView='save';
                else if(document.getElementById('describe')==document.activeElement)this.state.currCommodityEditsView='confirm';
                this.setState({currCommodityEditsView:this.state.currCommodityEditsView});
            }
            if(e.keyCode===13&&document.getElementById('describe')==document.activeElement&&e.code=='Enter'){
                if(this.state.currCommodityEditsView=='confirm')this.submitWholesale(1);
                else if(this.state.currCommodityEditsView=='save')this.submitWholesale(0);
            }
        }
        document.getElementById('workerSearch').focus();
    }
    searchWorkers(){
        this.state.saleWorkersData = [];
        if (this.state.keySearchWorker!=null&&this.state.keySearchWorker!='') {
            for(let i=0;i<this.state.saleWorkersAll.length;i++){
                if ((this.state.saleWorkersAll[i].noteName).indexOf(this.state.keySearchWorker)!=-1) {
                    this.state.saleWorkersData.push(this.state.saleWorkersAll[i]);
                }
            }
        }else{this.state.saleWorkersData = this.state.saleWorkersAll;}
        this.setState({saleWorkersData:this.state.saleWorkersData});
    }
    searchCustomers(){
        this.state.customerItemDatas = [];
        if (this.state.keySearchCus!=null&&this.state.keySearchCus!='') {
            for(let i=0;i<this.state.customerItemAll.length;i++){
                if ((this.state.customerItemAll[i].noteName).indexOf(this.state.keySearchCus)!=-1) {
                    this.state.customerItemDatas.push(this.state.customerItemAll[i]);
                }
            }
        }else{this.state.customerItemDatas = this.state.customerItemAll;}
        this.setState({customerItemDatas:this.state.customerItemDatas});
    }
    searchOwners(){
        this.state.wholesaleCargosData = [];
        if (this.state.keySearchOwner!=null&&this.state.keySearchOwner!='') {
            for(let i=0;i<this.state.wholesaleCargosAll.length;i++){
                if(this.state.wholesaleCargosAll[i].cargoMode==1)this.state.wholesaleCargosAll[i].targetNameChenpinglong=stringUtil.language(426);
                if(this.state.wholesaleCargosAll[i].targetNameChenpinglong==null)this.state.wholesaleCargosAll[i].targetNameChenpinglong='';
                if(this.state.wholesaleCargosAll[i].targetName==null)this.state.wholesaleCargosAll[i].targetName='';
                if(this.state.wholesaleCargosAll[i].cargoNo==null)this.state.wholesaleCargosAll[i].cargoNo='';
                if ((this.state.wholesaleCargosAll[i].targetNameChenpinglong).indexOf(this.state.keySearchOwner)!=-1 || (this.state.wholesaleCargosAll[i].targetName).indexOf(this.state.keySearchOwner)!=-1 || (this.state.wholesaleCargosAll[i].cargoNo).indexOf(this.state.keySearchOwner)!=-1) {
                    this.state.wholesaleCargosData.push(this.state.wholesaleCargosAll[i]);
                }
            }
        }else{this.state.wholesaleCargosData = this.state.wholesaleCargosAll;}
        this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
    }
    warehouseCommodityItemSearch(){
        this.state.warehouseCommodityItemData = [];
        for(let i=0;i<this.state.warehouseCommodityItemAll.length;i++){
            let commodityItem = this.state.warehouseCommodityItemAll[i];
            if (this.state.currCargoItem.id!=-1 && commodityItem.cargoId!=this.state.currCargoItem.id) continue;
            if(this.state.keySearch&&this.state.keySearch.trim().length>0){
                var keywords = this.state.keySearch.trim().split(/\s+/);
                let is = true;
                for(let j=0;j<keywords.length;j++){
                     let isSub = false;
                     let pys=stringUtil.makePy((commodityItem.commodityName).trim());let isPinYinHava=0;
                    if(pys&&pys.length>0)for(let k=0;k<pys.length;k++){
                        if(pys[k].indexOf((this.state.keySearch?this.state.keySearch:'').trim().toUpperCase())!=-1){
                            isPinYinHava=1;break;
                        }
                    }
                     if(isSub||commodityItem.commodityName.indexOf(keywords[j])!=-1||isPinYinHava){
                        isSub = true;
                     }
                     if(commodityItem.commodityCode){
                        if(isSub||commodityItem.commodityCode.indexOf(keywords[j])!=-1){
                            isSub = true;
                        }
                     }
                     let ownerName = "";
                     if(commodityItem.targetType==0||commodityItem.targetType==2){
                        ownerName = commodityItem.targetName?commodityItem.targetName:'';
                     }else if(commodityItem.targetType==1){
                        ownerName = stringUtil.language(1051)+(commodityItem.targetName?commodityItem.targetName:'');
                     }
                     if(isSub||ownerName.indexOf(keywords[j])!=-1){
                        isSub = true;
                     }
                     if(!isSub){
                        is = false;
                        break;
                     }
                }
                if(!is){
                  continue;
                }
            }
            this.state.warehouseCommodityItemData.push(commodityItem);
        }
    }

    keySelectCommodity(item){
        let currEditWarehouseCommodityItem = JSON.parse(JSON.stringify(item));
        for(let i=0;i<currEditWarehouseCommodityItem.costs.length;i++){
            currEditWarehouseCommodityItem.costs[i].moneyScal = currEditWarehouseCommodityItem.costs[i].money;
            currEditWarehouseCommodityItem.costs[i].money = 0;
        }
        this.state.currWholesaleCommodityVo = {
            warehouseId:currEditWarehouseCommodityItem.warehouseId,
            cargoCommodityId:currEditWarehouseCommodityItem.cargoCommodityId,
            cargoId:currEditWarehouseCommodityItem.cargoId,
            systemCommodityId:currEditWarehouseCommodityItem.systemCommodityId,
            commodityId:currEditWarehouseCommodityItem.commodityId,
            commodityName:currEditWarehouseCommodityItem.commodityName,
            commodityCode:currEditWarehouseCommodityItem.commodityCode,
            commoditySpecs:currEditWarehouseCommodityItem.commoditySpecs,
            targetType:currEditWarehouseCommodityItem.targetType,
            targetId:currEditWarehouseCommodityItem.targetId,
            targetName:currEditWarehouseCommodityItem.targetName,
            cargoNo:currEditWarehouseCommodityItem.cargoNo,
            unitMaster:currEditWarehouseCommodityItem.unitMaster,
            mode:currEditWarehouseCommodityItem.wholesaleMode,//0stringUtil.language(1022)，1stringUtil.language(1021)
            number:0,
            totalWeight:0,
            skinWeight:0,
            weight:0,
            unitPrice:currEditWarehouseCommodityItem.wholesaleMode?currEditWarehouseCommodityItem.numMakePrice:arithUtil.unitPriceTo(currEditWarehouseCommodityItem.weightMakePrice,0,this.state.weightMode),
            totalUnitPrice:currEditWarehouseCommodityItem.numMakePrice,
            numMakePrice:currEditWarehouseCommodityItem.numMakePrice,
            weightMakePrice:arithUtil.unitPriceTo(currEditWarehouseCommodityItem.weightMakePrice,0,this.state.weightMode),
            standard:currEditWarehouseCommodityItem.standard,
            commodityReduce:currEditWarehouseCommodityItem.reduce,
            reduceWeight:0,
            goodsMoney:0,
            costs:currEditWarehouseCommodityItem.costs,
        };
        this.state.currWholesaleCommodityVoKey = -1;
        this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{document.getElementById('numberEdit').focus();});
    }

    //{stringUtil.language(1151)}{stringUtil.language(1025)}、（{stringUtil.language(1166)}）、{stringUtil.language(1168)}、{stringUtil.language(1068)}、总{stringUtil.language(1068)}
    updateCommodityInfo(tag=0){//tag:1{stringUtil.language(1092)}stringUtil.language(1021),自动带{stringUtil.language(1194)}{stringUtil.language(1166)}
        let numberVal = parseFloat(this.state.currWholesaleCommodityVo.number);
        // let totalWeightVal = parseFloat(this.state.currWholesaleCommodityVo.totalWeight);
        // let skinWeightVal = parseFloat(this.state.currWholesaleCommodityVo.skinWeight);
        // let reduceWeightVal = numberVal.mul(this.state.currWholesaleCommodityVo.commodityReduce);//克
        // let standardVal = this.state.currWholesaleCommodityVo.standard;//克/{stringUtil.language(1173)}
        // let weightVal = standardVal?numberVal.mul(standardVal):this.state.currWholesaleCommodityVo.weight,goodsMoneyVal = 0;
        // this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
        // if (this.state.currWholesaleCommodityVo.mode==0) {
        //     if (tag==1&&this.state.workerHabitInfo.defWeight==0) {//{stringUtil.language(1092)}stringUtil.language(1021)，自动带{stringUtil.language(1194)}{stringUtil.language(1166)}
        //         this.state.currWholesaleCommodityVo.totalWeight = weightVal.add(reduceWeightVal).add(skinWeightVal);
        //     }else{
        //         weightVal = totalWeightVal.sub(skinWeightVal).sub(reduceWeightVal);
        //     }
        //     goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(arithUtil.keToWeight(weightVal,this.state.weightMode));
        // }else{
        //     goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
        // }
        // this.state.currWholesaleCommodityVo.weight = weightVal?weightVal:0;
        let skinWeightVal = parseFloat(this.state.currWholesaleCommodityVo.skinWeight);
        let reduceWeightVal = numberVal.mul(this.state.currWholesaleCommodityVo.commodityReduce);//克
        let standardVal = this.state.currWholesaleCommodityVo.standard,goodsMoneyVal = 0;//克/{stringUtil.language(1173)}
        if(standardVal!=null&&standardVal!=0){//有stringUtil.language(1024)情况
            if (this.state.currWholesaleCommodityVo.mode==0) {
                this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
                if (tag==1&&this.state.workerHabitInfo.defWeight==0) {//0stringUtil.language(1024)，1{stringUtil.language(1168)}
                    let totalWeightVal = numberVal.mul(standardVal);
                    this.state.currWholesaleCommodityVo.totalWeight = totalWeightVal;
                    this.state.currWholesaleCommodityVo.weight = totalWeightVal.sub(skinWeightVal).sub(reduceWeightVal);
                }else{
                    let totalWeightVal = parseFloat(this.state.currWholesaleCommodityVo.totalWeight);
                    this.state.currWholesaleCommodityVo.weight = totalWeightVal.sub(skinWeightVal).sub(reduceWeightVal);
                }
                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,this.state.weightMode));
            }else{
                let totalWeightVal = numberVal.mul(standardVal);
                this.state.currWholesaleCommodityVo.totalWeight = totalWeightVal;
                this.state.currWholesaleCommodityVo.weight=totalWeightVal.sub(skinWeightVal).sub(reduceWeightVal);
                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
            }
        }else{//无stringUtil.language(1024)情况
            if (this.state.currWholesaleCommodityVo.mode==0) {
                this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
                this.state.currWholesaleCommodityVo.weight=(parseFloat(this.state.currWholesaleCommodityVo.totalWeight)).sub(skinWeightVal).sub(reduceWeightVal);
                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,this.state.weightMode));
            }else{
                this.state.currWholesaleCommodityVo.weight=(parseFloat(this.state.currWholesaleCommodityVo.totalWeight)).sub(skinWeightVal).sub(reduceWeightVal);
                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
            }
        }
        this.state.currWholesaleCommodityVo.goodsMoney = goodsMoneyVal.round();
        if(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
            this.state.currWholesaleCommodityVo.totalGoodMoney=(this.state.currWholesaleCommodityVo.totalUnitPrice).mul(numberVal);
        }else{}//stringUtil.language(1015)
    }
    updatePriceTypeCommodityInfo(){
        if(this.state.currWholesaleCommodityVo.mode==1){
            if(localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
                this.state.currWholesaleCommodityVo.totalGoodMoney=(this.state.currWholesaleCommodityVo.totalUnitPrice).mul(this.state.currWholesaleCommodityVo.number);
                let otherMoneyTotal = 0;
                if(this.state.currWholesaleCommodityVo.costs==null)this.state.currWholesaleCommodityVo.costs=[];
                for(let i=0;i<this.state.currWholesaleCommodityVo.costs.length;i++){
                    let costsItem = this.state.currWholesaleCommodityVo.costs[i];
                    if(costsItem.moneyScal==null)costsItem.moneyScal=0;
                    if (costsItem.calMode==0) {//stringUtil.language(868)
                        costsItem.money = (this.state.currWholesaleCommodityVo.number).mul(costsItem.moneyScal).round();
                    }else if (costsItem.calMode==1) {//stringUtil.language(869)
                        costsItem.money = (arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,0)).mul(costsItem.moneyScal).roundDouble();
                    }else if (costsItem.calMode==2) {//stringUtil.language(396)
                        let otherMoneyTotal=0,scalTemp=0,goodsMoneyTemp=0;
                        for(let i=0;i<this.state.currWholesaleCommodityVo.costs.length;i++){
                            let costsItemTemp = this.state.currWholesaleCommodityVo.costs[i];
                            if(costsItemTemp.moneyScal==null)costsItemTemp.moneyScal=0;
                            if (costsItemTemp.calMode==0) {//stringUtil.language(868)
                                otherMoneyTotal=otherMoneyTotal.add((this.state.currWholesaleCommodityVo.number).mul(costsItemTemp.moneyScal).round());
                            }else if (costsItemTemp.calMode==1) {//stringUtil.language(869)
                                otherMoneyTotal=otherMoneyTotal.add((arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,0)).mul(costsItemTemp.moneyScal).roundDouble());
                            }else if (costsItemTemp.calMode==3) {
                                otherMoneyTotal=otherMoneyTotal.add(parseFloat(costsItemTemp.moneyScal).round());
                            }else{
                                scalTemp=scalTemp+costsItem.moneyScal;
                            }
                        }
                        goodsMoneyTemp=((this.state.currWholesaleCommodityVo.totalGoodMoney).sub(otherMoneyTotal)).div(scalTemp.mul(0.01).add(1));
                        costsItem.money = goodsMoneyTemp.mul(costsItem.moneyScal).mul(0.01).round();
                    }else{
                        costsItem.money = parseFloat(costsItem.moneyScal).round();
                    }
                    otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
                }
                this.state.currWholesaleCommodityVo.goodsMoney=(this.state.currWholesaleCommodityVo.totalGoodMoney).sub(otherMoneyTotal);/*20220524吕总说{stringUtil.language(1069)}费只有{stringUtil.language(1115)}的，不管了，就直接用总款减{stringUtil.language(1069)}费 */
                this.state.currWholesaleCommodityVo.unitPrice=(this.state.currWholesaleCommodityVo.goodsMoney).div(this.state.currWholesaleCommodityVo.number);
            }else{}//stringUtil.language(1015)
        }
    }
    submitCommodityItem(){
        if(this.state.currWholesaleCommodityVo.mode==0){
            this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(this.state.currWholesaleCommodityVo.unitPrice,this.state.weightMode,0);
            this.state.currWholesaleCommodityVo.ownerUnitPrice = arithUtil.unitPriceTo(this.state.currWholesaleCommodityVo.ownerUnitPrice,this.state.weightMode,0);
            this.state.currWholesaleCommodityVo.totalUnitPrice = 0;
        }else{this.updatePriceTypeCommodityInfo();}
        if(this.state.currWholesaleCommodityVo.supplementaryCommodity){
            this.state.currWholesaleCommodityVo.unitPrice = 0;
            this.state.currWholesaleCommodityVo.ownerUnitPrice = 0;
            this.state.currWholesaleCommodityVo.totalUnitPrice = 0;
        }
        if (this.state.currWholesaleCommodityVoKey==-1) {//-1stringUtil.language(1129)，不是-1{stringUtil.language(1092)}{stringUtil.language(1158)}{stringUtil.language(1063)}
            this.state.wholesaleInsertVoItem.commodities.push(this.state.currWholesaleCommodityVo);
        }else{
            this.state.wholesaleInsertVoItem.commodities[this.state.currWholesaleCommodityVoKey] = this.state.currWholesaleCommodityVo;
        }
    }

    renderInfoEdit(){let views = [];
        views.push(<div style={{paddingLeft:10}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1122)}</span>
                <input id='workerSearch' style={{width:150,height:50,border:'1px solid #d0d0d0',borderRadius:2,paddingLeft:5}} onKeyDown={(e)=>{
                    if(document.getElementById('workerSearch')==document.activeElement&&e.keyCode===13){
                        document.getElementById('workerSerial').focus();
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=0)this.state.showViewsTag=0;
                    if(this.state.keySearchWorker==null||this.state.keySearchWorker=='')this.setState({saleWorkersData:this.state.saleWorkersAll});
                    else this.searchWorkers();
                }} onChange={(event)=>{
                    this.state.keySearchWorker = event.target.value;
                    if(this.state.keySearchWorker==null||this.state.keySearchWorker=='')this.setState({saleWorkersData:this.state.saleWorkersAll});
                    else this.searchWorkers();
                }} placeholder={stringUtil.language(53)}/>
                <input id='workerSerial' style={{width:60,height:50,fontSize:18,color:'#F00',fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center'}} onKeyDown={(e)=>{
                    if(document.getElementById('workerSerial')==document.activeElement&&e.keyCode===13){
                        if(this.state.saleWorkersData.length==1){
                            let currWorkerItem = JSON.parse(JSON.stringify(this.state.saleWorkersData[0]));
                            this.state.wholesaleInsertVoItem.workerId=currWorkerItem.id;
                            this.state.wholesaleInsertVoItem.workerName=currWorkerItem.noteName;
                            document.getElementById('workerSearch').value = stringUtil.subStr(this.state.wholesaleInsertVoItem.workerName,10);
                            this.setState({saleWorkersData:this.state.saleWorkersAll});
                        }else if(this.state.saleWorkersData.length>1){
                            if(this.state.regIndexVal!=-1){
                                if(this.state.regIndexVal<=this.state.saleWorkersData.length){
                                    let currWorkerItem = JSON.parse(JSON.stringify(this.state.saleWorkersData[this.state.regIndexVal-1]));
                                    this.state.wholesaleInsertVoItem.workerId=currWorkerItem.id;
                                    this.state.wholesaleInsertVoItem.workerName=currWorkerItem.noteName;
                                }else{ToastBox.error(stringUtil.language(30));}
                            }
                            if(this.state.wholesaleInsertVoItem.workerId!=null&&this.state.wholesaleInsertVoItem.workerName!=null){
                                document.getElementById('workerSearch').value = stringUtil.subStr(this.state.wholesaleInsertVoItem.workerName,10);
                                this.setState({saleWorkersData:this.state.saleWorkersAll});
                            }
                        }else{ToastBox.error(stringUtil.language(378));};
                        // document.getElementById('workerSerial').value='';
                        this.state.regIndexVal=-1;
                        document.getElementById('customerSearch').focus();
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=0)this.state.showViewsTag=0;
                    if(this.state.keySearchWorker==null||this.state.keySearchWorker=='')this.setState({saleWorkersData:this.state.saleWorkersAll});
                    else this.searchWorkers();
                }} type='number' onChange={(event)=>{
                    this.state.regIndexVal=event.target.value!=''?parseInt(event.target.value):-1;
                }} placeholder={stringUtil.language(1104)}/>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1048)}</span>
                <input id='customerSearch' style={{width:150,height:50,border:'1px solid #d0d0d0',borderRadius:2,paddingLeft:5}} onKeyDown={(e)=>{
                    if(document.getElementById('customerSearch')==document.activeElement&&e.keyCode===13){
                        document.getElementById('customerSerial').focus();
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=1)this.state.showViewsTag=1;
                    if(this.state.keySearchCus==null||this.state.keySearchCus=='')this.setState({customerItemDatas:this.state.customerItemAll});
                    else this.searchCustomers();
                }} onChange={(event)=>{
                    this.state.keySearchCus = event.target.value;
                    if(this.state.keySearchCus==null||this.state.keySearchCus=='')this.setState({customerItemDatas:this.state.customerItemAll});
                    else this.searchCustomers();
                }} placeholder={stringUtil.language(261)+'..'}/>
                <input id='customerSerial' style={{width:60,height:50,fontSize:18,color:'#F00',fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center'}} onKeyDown={(e)=>{
                    if(document.getElementById('customerSerial')==document.activeElement&&e.keyCode===13){
                        if(this.state.customerItemDatas.length==1){
                            if (this.state.customerItemDatas[0].warning!=2) {
                                this.state.currCustomerItem = JSON.parse(JSON.stringify(this.state.customerItemDatas[0]));
                                document.getElementById('customerSearch').value = stringUtil.subStr(this.state.currCustomerItem.noteName,10);//20220708吕stringUtil.language(1027)不清空
                                this.setState({customerItemDatas:this.state.customerItemAll});
                                this.state.isPrintTag=this.state.currCustomerItem.type==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                            }else{ToastBox.error(stringUtil.language(50));}
                        }else if(this.state.customerItemDatas.length>1){
                            if(this.state.regIndexVal!=-1){
                                if(this.state.regIndexVal<=this.state.customerItemDatas.length){
                                    if (this.state.customerItemDatas[this.state.regIndexVal-1].warning!=2) {
                                        this.state.currCustomerItem = JSON.parse(JSON.stringify(this.state.customerItemDatas[this.state.regIndexVal-1]));
                                        this.state.isPrintTag=this.state.currCustomerItem.type==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                                    }else{ToastBox.error(stringUtil.language(50));return;}
                                }else{ToastBox.error(stringUtil.language(30));}
                            }
                            if(this.state.currCustomerItem){
                                document.getElementById('customerSearch').value = stringUtil.subStr(this.state.currCustomerItem.noteName,10);
                                this.setState({customerItemDatas:this.state.customerItemAll});
                            }
                        }else{ToastBox.error(stringUtil.language(378));};
                        // document.getElementById('customerSerial').value='';
                        this.state.regIndexVal=-1;
                        document.getElementById('ownerSearch').focus();
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=1)this.state.showViewsTag=1;
                    if(this.state.keySearchCus==null||this.state.keySearchCus=='')this.setState({customerItemDatas:this.state.customerItemAll});
                    else this.searchCustomers();
                }} type='number' onChange={(event)=>{
                    this.state.regIndexVal=event.target.value!=''?parseInt(event.target.value):-1;
                }} placeholder={stringUtil.language(1104)}/>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1049)}</span>
                <input id='ownerSearch' style={{width:150,height:50,border:'1px solid #d0d0d0',borderRadius:2,paddingLeft:5}} onKeyDown={(e)=>{
                    if(document.getElementById('ownerSearch')==document.activeElement&&e.keyCode===13){
                        document.getElementById('ownerSerial').focus();
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=2)this.state.showViewsTag=2;
                    if(this.state.keySearchOwner==null||this.state.keySearchOwner==''){
                        this.state.currCargoItem = JSON.parse(JSON.stringify({id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)}));
                        this.warehouseCommodityItemSearch();
                        this.setState({wholesaleCargosData:this.state.wholesaleCargosAll});
                    }else this.searchOwners();
                }} onChange={(event)=>{
                    this.state.keySearchOwner = event.target.value;
                    if(this.state.keySearchOwner==null||this.state.keySearchOwner==''){
                        this.state.currCargoItem = JSON.parse(JSON.stringify({id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)}));
                        this.warehouseCommodityItemSearch();
                        this.setState({wholesaleCargosData:this.state.wholesaleCargosAll});
                    }else this.searchOwners();
                }} placeholder={stringUtil.language(76)}/>
                <input id='ownerSerial' style={{width:60,height:50,fontSize:18,color:'#F00',fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center'}} onKeyDown={(e)=>{
                    if(document.getElementById('ownerSerial')==document.activeElement&&e.keyCode===13){
                        if(this.state.wholesaleCargosData.length==1){
                            this.state.currCargoItem = JSON.parse(JSON.stringify(this.state.wholesaleCargosData[0]));
                            let ownerNameStr = this.state.currCargoItem.cargoMode==1?stringUtil.language(1051)+(this.state.currCargoItem.targetName?'_'+stringUtil.subStr(this.state.currCargoItem.targetName,6):''):stringUtil.subStr(this.state.currCargoItem.targetName,7);
                            this.warehouseCommodityItemSearch();
                            document.getElementById('ownerSearch').value = '';//this.state.currCargoItem.id!=-1?stringUtil.subStr(ownerNameStr,10)+'stringUtil.language(1175)'+this.state.currCargoItem.cargoNo+'stringUtil.language(1176)':'';
                            this.setState({wholesaleCargosData:this.state.wholesaleCargosAll});
                        }else if(this.state.wholesaleCargosData.length>1){
                            if(this.state.regIndexVal!=-1){
                                if(this.state.regIndexVal<=this.state.wholesaleCargosData.length){
                                    this.state.currCargoItem = JSON.parse(JSON.stringify(this.state.wholesaleCargosData[this.state.regIndexVal-1]));
                                }else{ToastBox.error(stringUtil.language(30));}
                            }
                            if(this.state.currCargoItem){
                                let ownerNameStr = this.state.currCargoItem.cargoMode==1?stringUtil.language(1051)+(this.state.currCargoItem.targetName?'_'+stringUtil.subStr(this.state.currCargoItem.targetName,6):''):stringUtil.subStr(this.state.currCargoItem.targetName,7);
                                this.warehouseCommodityItemSearch();
                                document.getElementById('ownerSearch').value = '';//this.state.currCargoItem.id!=-1?stringUtil.subStr(ownerNameStr,10)+'stringUtil.language(1175)'+this.state.currCargoItem.cargoNo+'stringUtil.language(1176)':'';
                                this.setState({wholesaleCargosData:this.state.wholesaleCargosAll});
                            }
                        }else{ToastBox.error(stringUtil.language(378));};
                        this.setState({wholesaleCargosData:this.state.wholesaleCargosData},()=>{
                            document.getElementById('ownerSerial').value='';
                            this.state.regIndexVal=-1;
                            document.getElementById('commoditySearch').focus();
                        });
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=2)this.state.showViewsTag=2;
                    if(this.state.keySearchOwner==null||this.state.keySearchOwner==''){
                        this.state.currCargoItem = JSON.parse(JSON.stringify({id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)}));
                        this.warehouseCommodityItemSearch();
                        this.setState({wholesaleCargosData:this.state.wholesaleCargosAll});
                    }else this.searchOwners();
                }} type='number' onChange={(event)=>{
                    this.state.regIndexVal=event.target.value!=''?parseInt(event.target.value):-1;
                }} placeholder={stringUtil.language(1104)}/>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1063)}</span>
                <input id='commoditySearch' style={{width:150,height:50,border:'1px solid #d0d0d0',borderRadius:2,paddingLeft:5}} onKeyDown={(e)=>{
                    if(document.getElementById('commoditySearch')==document.activeElement&&e.keyCode===13){
                        document.getElementById('commoditySerial').focus();
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=3)this.state.showViewsTag=3;
                    this.warehouseCommodityItemSearch();
                    this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemData});
                }} onChange={(event)=>{
                    this.state.keySearch = event.target.value;
                    this.warehouseCommodityItemSearch();
                    this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemData});
                }} placeholder={stringUtil.language(1010)+stringUtil.language(1063)+stringUtil.language(1034)+'..'}/>
                <input id='commoditySerial' style={{width:60,height:50,fontSize:18,color:'#F00',fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center'}} onKeyDown={(e)=>{
                    if(document.getElementById('commoditySerial')==document.activeElement&&e.keyCode===13){
                        if(this.state.warehouseCommodityItemData.length==1){
                            let warehouseCommodity = JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[0]));
                            this.keySelectCommodity(warehouseCommodity);
                            document.getElementById('commoditySearch').value = '';//stringUtil.subStr(warehouseCommodity.commodityName,10);
                            this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemAll});
                        }else if(this.state.warehouseCommodityItemData.length>1){
                            if(this.state.regIndexVal!=-1){
                                if(this.state.regIndexVal<=this.state.warehouseCommodityItemData.length){
                                    let warehouseCommodity = JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[this.state.regIndexVal-1]));
                                    this.keySelectCommodity(warehouseCommodity);
                                    document.getElementById('commoditySearch').value = '';//stringUtil.subStr(warehouseCommodity.commodityName,10);
                                    this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemAll});
                                }else{ToastBox.error(stringUtil.language(30));}
                            }
                        }else{ToastBox.error(stringUtil.language(378));};
                        document.getElementById('commoditySerial').value='';
                        this.state.regIndexVal=-1;
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=3)this.state.showViewsTag=3;
                    this.warehouseCommodityItemSearch();
                    this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemData});
                }} type='number' onChange={(event)=>{
                    this.state.regIndexVal=event.target.value!=''?parseInt(event.target.value):-1;
                }} placeholder={stringUtil.language(1104)}/>
            </div>
        </div>);
        if(this.state.currWholesaleCommodityVo)views.push(<div style={{paddingLeft:10}}>
            <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',marginTop:10}}>
                <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.state.currWholesaleCommodityVo.mode = 0;//stringUtil.language(1022)
                    this.updateCommodityInfo();
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                        if(this.state.currWholesaleCommodityVo)document.getElementById('numberEdit').focus();
                    });
                }}>
                    {this.state.currWholesaleCommodityVo.mode==0?<i className="iconfontIndexCss" style={{color:'#52A056',fontSize:30}}>&#xe71f;</i>:
                    <i className="iconfontIndexCss" style={{color:'#808080',fontSize:30}}>&#xe641;</i>}
                    <span style={{color:this.state.currWholesaleCommodityVo.mode==0?'#52A056':'#808080',fontSize:30,fontWeight:this.state.currWholesaleCommodityVo.mode==0?'bold':'normal'}}> {stringUtil.language(1022)}</span>
                </div>
                <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.state.currWholesaleCommodityVo.mode = 1;//stringUtil.language(1021)-stringUtil.language(1015)
                    localStorage.setItem("wholesalePriceType",0);//设置缓存
                    this.updateCommodityInfo();
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                        if(this.state.currWholesaleCommodityVo)document.getElementById('numberEdit').focus();
                    });
                }}>
                    {this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')!=1?<i className="iconfontIndexCss" style={{color:'#52A056',fontSize:30}}>&#xe71f;</i>:
                    <i className="iconfontIndexCss" style={{color:'#808080',fontSize:30}}>&#xe641;</i>}
                    <span style={{color:this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')!=1?'#52A056':'#808080',fontSize:30,fontWeight:this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')!=1?'bold':'normal'}}> {stringUtil.language(1173)}净价</span>
                </div>
                <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.state.currWholesaleCommodityVo.mode = 1;//stringUtil.language(1021)-stringUtil.language(1016)
                    localStorage.setItem("wholesalePriceType",1);//设置缓存
                    this.updateCommodityInfo();
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                        if(this.state.currWholesaleCommodityVo)document.getElementById('numberEdit').focus();
                    });
                }}>
                    {this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?<i className="iconfontIndexCss" style={{color:'#52A056',fontSize:30}}>&#xe71f;</i>:
                    <i className="iconfontIndexCss" style={{color:'#808080',fontSize:30}}>&#xe641;</i>}
                    <span style={{color:this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?'#52A056':'#808080',fontSize:30,fontWeight:this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?'bold':'normal'}}> {stringUtil.language(1173)}总价</span>
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1165)}</span>
                <input id='numberEdit' style={{width:100,height:50,fontSize:25,fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center',paddingLeft:5}} type='number' value={this.state.currWholesaleCommodityVo.number==0?'':this.state.currWholesaleCommodityVo.number} onKeyDown={(e)=>{
                    if(document.getElementById('numberEdit')==document.activeElement&&e.keyCode===13){
                        if(this.state.currWholesaleCommodityVo.mode==1)document.getElementById('unitPriceEdit').focus();
                        else document.getElementById('totalWeightEdit').focus();
                    }
                }} placeholder={stringUtil.language(1165)} onChange={(event)=>{
                    this.state.currWholesaleCommodityVo.number = event.target.value&&!isNaN(event.target.value)?parseFloat(event.target.value):0;
                    this.updatePriceTypeCommodityInfo();
                    this.updateCommodityInfo(1);
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                }}/>
                <span style={{fontSize:25,marginLeft:10}}>{this.state.currWholesaleCommodityVo.standard?arithUtil.keToWeight(this.state.currWholesaleCommodityVo.standard,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))+'/'+(this.state.currWholesaleCommodityVo.unitMaster?this.state.currWholesaleCommodityVo.unitMaster:stringUtil.language(1173)):'无'+stringUtil.language(1024)}</span>
            </div>
        </div>);
        if(this.state.currWholesaleCommodityVo&&this.state.currWholesaleCommodityVo.mode!=1)views.push(<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10,paddingLeft:10}}>
            <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1022)}</span>
            <input id='totalWeightEdit' style={{width:100,height:50,fontSize:25,fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center',paddingLeft:5}} type='number' value={this.state.currWholesaleCommodityVo.totalWeight==0?'':arithUtil.keToWeight(this.state.currWholesaleCommodityVo.totalWeight,this.state.weightMode)} onKeyDown={(e)=>{
                if(document.getElementById('totalWeightEdit')==document.activeElement&&e.keyCode===13){document.getElementById('skinWeightEdit').focus();}
            }} placeholder={stringUtil.language(1166)} onChange={(event)=>{
                let totalWeight = event.target.value&&!isNaN(event.target.value)?parseFloat(event.target.value):0;
                this.state.currWholesaleCommodityVo.totalWeight = arithUtil.weightToKe(totalWeight,this.state.weightMode)
                this.updateCommodityInfo();
                this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
            }}/>
            <input id='skinWeightEdit' style={{width:100,height:50,fontSize:25,fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center',paddingLeft:5,marginLeft:10}} type='number' value={this.state.currWholesaleCommodityVo.skinWeight==0?'':arithUtil.keToWeight(this.state.currWholesaleCommodityVo.skinWeight,this.state.weightMode)} onKeyDown={(e)=>{
                if(document.getElementById('skinWeightEdit')==document.activeElement&&e.keyCode===13){document.getElementById('unitPriceEdit').focus();}
            }} placeholder={stringUtil.language(1167)} onChange={(event)=>{
                let skinWeight = event.target.value&&!isNaN(event.target.value)?parseFloat(event.target.value):0;
                this.state.currWholesaleCommodityVo.skinWeight = arithUtil.weightToKe(skinWeight,this.state.weightMode);
                this.updateCommodityInfo();
                this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
            }}/>
        </div>);
        if(this.state.currWholesaleCommodityVo)views.push(<div style={{paddingLeft:10}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1050)}</span>
                <input id='unitPriceEdit' style={{width:100,height:50,fontSize:25,fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center',paddingLeft:5}} type='number' value={this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?(this.state.currWholesaleCommodityVo.totalUnitPrice==0?'':arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.totalUnitPrice)):(this.state.currWholesaleCommodityVo.unitPrice==0?'':arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice))} onKeyDown={(e)=>{
                    if(document.getElementById('unitPriceEdit')==document.activeElement&&e.keyCode===13){
                        if(localStorage.getItem("currentStallItem")&&!JSON.parse(localStorage.getItem("currentStallItem")).wholesaleConfig.showOwnerPrice){document.getElementById('ownerUnitPriceEdit').focus();
                        }else{document.getElementById('buyerEdit').focus();}
                    }
                }} placeholder={stringUtil.language(1050)} onChange={(event)=>{
                    let unitPrice = event.target.value&&!isNaN(event.target.value)?parseFloat(event.target.value):0;
                    this.state.currWholesaleCommodityVo.unitPrice = arithUtil.yuanToFen(unitPrice);
                    this.state.currWholesaleCommodityVo.totalUnitPrice = arithUtil.yuanToFen(unitPrice);
                    this.updatePriceTypeCommodityInfo();
                    this.updateCommodityInfo();
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                }}/>
                <span style={{fontSize:25,marginLeft:10}}>/{this.state.currWholesaleCommodityVo.mode?(this.state.currWholesaleCommodityVo.unitMaster?this.state.currWholesaleCommodityVo.unitMaster:stringUtil.language(1173)):(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>
            </div>
            {localStorage.getItem("currentStallItem")&&!JSON.parse(localStorage.getItem("currentStallItem")).wholesaleConfig.showOwnerPrice?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1255)}</span>
                <input id='ownerUnitPriceEdit' style={{width:100,height:50,fontSize:25,fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center',paddingLeft:5}} type='number' value={this.state.currWholesaleCommodityVo.ownerUnitPrice?arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.ownerUnitPrice):''} onKeyDown={(e)=>{
                    if(document.getElementById('ownerUnitPriceEdit')==document.activeElement&&e.keyCode===13){document.getElementById('buyerEdit').focus();}
                }} placeholder={stringUtil.language(1050)} onChange={(event)=>{
                    let ownerUnitPrice = event.target.value&&!isNaN(event.target.value)?parseFloat(event.target.value):0;
                    this.state.currWholesaleCommodityVo.ownerUnitPrice = arithUtil.yuanToFen(ownerUnitPrice);
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                }}/>
            </div>:''}
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{fontSize:30,marginRight:10}}>{stringUtil.language(1119)}</span>
                <input id='buyerEdit' style={{width:100,height:50,fontSize:25,fontWeight:'bold',border:'1px solid #d0d0d0',borderRadius:2,textAlign:'center',paddingLeft:5}} value={this.state.currWholesaleCommodityVo.buyerCode?this.state.currWholesaleCommodityVo.buyerCode:''} onKeyDown={(e)=>{//stringUtil.language(1027)stringUtil.language(1129)当前{stringUtil.language(1092)}的{stringUtil.language(1063)}，并跳到再选{stringUtil.language(1063)}
                    if(document.getElementById('buyerEdit')==document.activeElement&&e.keyCode===13){
                        this.submitCommodityItem();
                        this.setState({currWholesaleCommodityVo:null,regIndexVal:-1},()=>{document.getElementById('ownerSearch').focus();});
                    }
                }} onFocus={()=>{
                    if(this.state.showViewsTag!=5)this.setState({showViewsTag:5});
                }} placeholder={stringUtil.language(1156)} onChange={(event)=>{
                    this.state.currWholesaleCommodityVo.buyerCode=event.target.value;
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                }}/>
            </div>
        </div>);
        return views;
    }
    renderInfoShow(){
        if(this.state.showViewsTag==null||this.state.showViewsTag==0){//{stringUtil.language(1122)}
            return (<div style={{height:'100%',overflow:'auto'}}>
                {(this.state.saleWorkersData?this.state.saleWorkersData:this.state.saleWorkersAll).map((item,key)=>{
                    return(<div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:5,paddingRight:5,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                        let currWorkerItem = JSON.parse(JSON.stringify(item));
                        this.state.wholesaleInsertVoItem.workerId=currWorkerItem.id;
                        this.state.wholesaleInsertVoItem.workerName=currWorkerItem.noteName;
                        document.getElementById('workerSearch').value = stringUtil.subStr(this.state.wholesaleInsertVoItem.workerName,10);
                        this.setState({saleWorkersData:this.state.saleWorkersAll});
                    }}><span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{key+1}</span>
                        <span style={{color:'#000',fontSize:16,marginLeft:10}}>{item.noteName}</span>
                    </div>)
                })}
            </div>);
        }else if(this.state.showViewsTag==1){//{stringUtil.language(1048)}
            return (<div style={{height:'100%',overflow:'auto'}}>
                {(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).map((item,key)=>{
                    return(<div style={{height:60,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:5,paddingRight:5,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                        if (item.warning!=2) {
                            this.state.currCustomerItem = JSON.parse(JSON.stringify(item));
                            document.getElementById('customerSearch').value = stringUtil.subStr(this.state.currCustomerItem.noteName,10);
                            this.setState({customerItemDatas:this.state.customerItemAll});
                            this.state.isPrintTag=this.state.currCustomerItem.type==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                        }else{ToastBox.error(stringUtil.language(50));}
                    }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{key+1}</span>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                                <img style={{width:40,height:40,borderRadius:20}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                                <div style={{marginLeft:5,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                    <span style={{color:item.warning!=2?'#000':'#808080',fontSize:18,fontWeight:'normal'}}>{item.noteName}</span>
                                    <span style={{color:'#FF8C00',fontSize:'10px'}}>{item.warning==1||item.quota!=0?(item.warning==2?stringUtil.language(458)+'/':'')+stringUtil.language(1066)+arithUtil.fenToYuan(item.quota):(item.warning==2?stringUtil.language(458):'')}</span>
                                </div>
                            </div>
                        </div>
                        <span style={{color:'#F00',fontSize:20}}>{arithUtil.fenToYuan(item.arrears)}</span>
                    </div>)
                })}
            </div>);
        }else if(this.state.showViewsTag==2){//{stringUtil.language(1049)}
            return (<div style={{height:'100%',overflow:'auto'}}>
                {(this.state.wholesaleCargosData?this.state.wholesaleCargosData:this.state.wholesaleCargosAll).map((item,key)=>{
                    return(<div style={{height:60,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:5,paddingRight:5,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                        this.state.currCargoItem = JSON.parse(JSON.stringify(item));
                        this.warehouseCommodityItemSearch();
                        this.setState({currCargoItem:this.state.currCargoItem});
                    }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:45,color:'#F00',fontSize:25,fontWeight:'bold'}}>{key+1}</span>
                            <i className="iconfontIndexCss" style={{fontSize:35,color:'#21A056'}}>&#xe643;</i>
                        </div>
                        <span style={{width:'50%',color:'#000',fontSize:16,marginLeft:5}}>{item.cargoMode==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,9):''):stringUtil.subStr(item.targetName,10)}</span>
                        <span style={{color:'#FF8C00',fontSize:14}}>{stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}</span>
                    </div>)
                })}
            </div>);
        }else if(this.state.showViewsTag==3){//{stringUtil.language(1063)}
            return (<div style={{height:'100%',overflow:'auto'}}>
                {(this.state.warehouseCommodityItemData?this.state.warehouseCommodityItemData:this.state.warehouseCommodityItemAll).map((item,key)=>{
                    return(<div style={{height:60,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:5,paddingRight:5,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                        let currEditWarehouseCommodityItem = JSON.parse(JSON.stringify(item));
                        for(let i=0;i<currEditWarehouseCommodityItem.costs.length;i++){
                            currEditWarehouseCommodityItem.costs[i].moneyScal = currEditWarehouseCommodityItem.costs[i].money;
                            currEditWarehouseCommodityItem.costs[i].money = 0;
                        }
                        this.state.currWholesaleCommodityVo = {
                            warehouseId:currEditWarehouseCommodityItem.warehouseId,
                            cargoCommodityId:currEditWarehouseCommodityItem.cargoCommodityId,
                            cargoId:currEditWarehouseCommodityItem.cargoId,
                            systemCommodityId:currEditWarehouseCommodityItem.systemCommodityId,
                            commodityId:currEditWarehouseCommodityItem.commodityId,
                            commodityName:currEditWarehouseCommodityItem.commodityName,
                            commodityCode:currEditWarehouseCommodityItem.commodityCode,
                            commoditySpecs:currEditWarehouseCommodityItem.commoditySpecs,
                            targetType:currEditWarehouseCommodityItem.targetType,
                            targetId:currEditWarehouseCommodityItem.targetId,
                            targetName:currEditWarehouseCommodityItem.targetName,
                            cargoNo:currEditWarehouseCommodityItem.cargoNo,
                            unitMaster:currEditWarehouseCommodityItem.unitMaster,
                            mode:currEditWarehouseCommodityItem.wholesaleMode,//0stringUtil.language(1022)，1stringUtil.language(1021)
                            number:0,
                            totalWeight:0,
                            skinWeight:0,
                            weight:0,
                            unitPrice:currEditWarehouseCommodityItem.wholesaleMode?currEditWarehouseCommodityItem.numMakePrice:arithUtil.unitPriceTo(currEditWarehouseCommodityItem.weightMakePrice,0,this.state.weightMode),
                            totalUnitPrice:currEditWarehouseCommodityItem.numMakePrice,
                            numMakePrice:currEditWarehouseCommodityItem.numMakePrice,
                            weightMakePrice:arithUtil.unitPriceTo(currEditWarehouseCommodityItem.weightMakePrice,0,this.state.weightMode),
                            standard:currEditWarehouseCommodityItem.standard,
                            commodityReduce:currEditWarehouseCommodityItem.reduce,
                            reduceWeight:0,
                            goodsMoney:0,
                            costs:currEditWarehouseCommodityItem.costs,
                        };
                        this.state.currWholesaleCommodityVoKey = -1;
                        this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                            document.getElementById('commoditySerial').value='';
                            if(this.state.currWholesaleCommodityVo)document.getElementById('numberEdit').focus();
                        });
                    }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:45,color:'#F00',fontSize:25,fontWeight:'bold'}}>{key+1}</span>
                            <div style={{width:40,position:'relative',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <span style={{width:40,color:'#F00',fontWeight:'bold',textAlign:'center',textOverflow:'ellipsis',overflow:'hidden',backgroundColor:'rgba(255,255,255,0.2)',position:'absolute'}}>{stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}</span>
                                {localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?'':<img style={{width:40,height:40}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>}
                            </div>
                        </div>
                        <div style={{width:'29%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <span style={{width:'100%',color:'#52A056',fontSize:16,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:stringUtil.showCode()==1?(item.commodityCode?item.commodityCode:''):''}</span>
                            <span style={{width:'100%',color:'#808080',fontSize:11,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commoditySpecs}</span>
                        </div>
                        <div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap'}}>
                            <span style={{color:'#FF8C00',fontSize:10,fontWeight:'bold'}}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,7):''):stringUtil.subStr(item.targetName,8)}&nbsp;</span>
                            <span style={{color:'#FF8C00',fontSize:10,fontWeight:'bold'}}>{item.cargoNo+stringUtil.language(1176)}</span>
                        </div>
                        <span style={{color:'#52A056',fontSize:14}}>{item.number+((item.unitMaster!=null&&item.unitMaster!='')?item.unitMaster:stringUtil.language(1173))}</span>
                    </div>)
                })}
            </div>);
        }else if(this.state.showViewsTag==4){//{stringUtil.language(1063)}信息
            if(this.state.currWholesaleCommodityVo==null)return(<div/>);
            let otherMoneyTotal = 0;
            for(let i=0;i<this.state.currWholesaleCommodityVo.costs.length;i++){
                let costsItem = this.state.currWholesaleCommodityVo.costs[i];
                if (costsItem.calMode==0) {//stringUtil.language(868)
                    costsItem.money = (this.state.currWholesaleCommodityVo.number).mul(costsItem.moneyScal).round();
                }else if (costsItem.calMode==1) {//stringUtil.language(869)
                    costsItem.money = (arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,0)).mul(costsItem.moneyScal).roundDouble();
                }else if (costsItem.calMode==2) {//stringUtil.language(396)
                    costsItem.money = (this.state.currWholesaleCommodityVo.goodsMoney).mul(costsItem.moneyScal).mul(0.01).round();
                }else{
                    costsItem.money = parseFloat(costsItem.moneyScal).round();
                }
                otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
            }
            return (<div style={{height:'100%',paddingLeft:10,paddingRight:10,overflow:'auto'}}>
                <span style={{fontSize:35,fontWeight:'bold'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(this.state.currWholesaleCommodityVo.commodityCode?'('+this.state.currWholesaleCommodityVo.commodityCode+')':''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?this.state.currWholesaleCommodityVo.commodityName:''}</span><br/>
                <span>{this.state.currWholesaleCommodityVo.targetType==1?stringUtil.language(1051)+(this.state.currWholesaleCommodityVo.targetName?'_'+this.state.currWholesaleCommodityVo.targetName:''):this.state.currWholesaleCommodityVo.targetName} {stringUtil.language(1175)}{this.state.currWholesaleCommodityVo.cargoNo}{stringUtil.language(1176)}</span>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{color:'#F00',fontSize:30,fontWeight:'bold'}}>{this.state.currWholesaleCommodityVo.number}</span><span>{this.state.currWholesaleCommodityVo.unitMaster?this.state.currWholesaleCommodityVo.unitMaster:stringUtil.language(1173)}</span>
                    <span style={{color:'#F00',fontSize:30,fontWeight:'bold'}}>{arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,this.state.weightMode)}</span><span>{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:7}}>
                    <span style={{fontSize:30}}>{stringUtil.language(1068)}:</span><span style={{color:'#F00',fontSize:30,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.goodsMoney)}</span>
                </div>
                {this.state.currWholesaleCommodityVo&&this.state.currWholesaleCommodityVo.costs?this.state.currWholesaleCommodityVo.costs.map((item,key)=>{
                    return(<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:7}} key={key}>
                        <i className="iconfontIndexCss" style={{width:40,height:40,fontSize:20,color:'#F00',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currWholesaleCommodityVo.costs.splice(key, 1);
                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                        }}>&#xe612;</i>
                        <span style={{fontSize:30}}>{item.costClauseName}:</span><span style={{color:'#F00',fontSize:30,fontWeight:'bold'}}>{arithUtil.fenToYuan(item.money)}</span>
                    </div>)
                }):<div/>}
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                   <span style={{color:'#F00',fontSize:60,fontWeight:'bold'}}>{arithUtil.fenToYuan(otherMoneyTotal.add(this.state.currWholesaleCommodityVo.goodsMoney))}</span>
                </div>
            </div>);
        }else if(this.state.showViewsTag==5){//stringUtil.language(891)列表
            return (<div style={{height:'100%',overflow:'auto'}}>
                {(this.state.buyersAll?this.state.buyersAll:[]).map((item,key)=>{
                    return(<div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:5,paddingRight:5,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1}} key={key}>
                        <span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{item.code}</span>
                        <span style={{color:'#000',fontSize:16,marginLeft:10}}>{item.name}</span>
                    </div>)
                })}
            </div>);
        }
    }
    render(){
        this.state.wholesaleInsertVoItem.totalNumber = 0;
        this.state.wholesaleInsertVoItem.totalWeight = 0;
        this.state.wholesaleInsertVoItem.totalGoodsMoney = 0;
        this.state.wholesaleInsertVoItem.totalOtherMoneyTotal = 0;
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' viewWidth='100%' context={this} navigationStacks={this.props.navigationStacks} isBackPrevPage={1}/>
            <div style={{height:'94%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                <div style={{width:'37%',height:'100%',backgroundColor:'#FFF',position:'relative'}}>
                    <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:20,fontWeight:'bold'}}>{stringUtil.subStr(this.state.currCustomerItem.noteName,10)}</span>
                            {this.state.currCustomerItem.type==1?<input style={{height:25,padding:0}} onChange={(event)=>{
                                this.state.wholesaleInsertVoItem.customerAlias=event.target.value;
                            }} placeholder={stringUtil.language(132)}/>:
                            <span style={{color:'#F00',fontSize:12}}>{this.state.currCustomerItem.warning!=0?stringUtil.language(1066)+arithUtil.fenToYuan(this.state.currCustomerItem.quota)+','+stringUtil.language(1164)+arithUtil.fenToYuan(this.state.currCustomerItem.arrears):''}</span>}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <DatePicker locale='zhCN' selected={new Date(this.state.wholesaleInsertVoItem.createTime)} onChange={(date) => {//showTimeSelect timeFormat="HH:mm" timeIntervals={15} 
                                this.state.wholesaleInsertVoItem.createTime = date.valueOf();
                                this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                            }} />
                        </div>
                    </div>
                    <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'7.5%',color:'#A9A9A9',textAlign:'center'}}></span>
                        <div style={{width:'82.5%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:'37%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(518)}</span>
                            <span style={{width:'15%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1165)}</span>
                            <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                            <span style={{width:'14%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                            <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1068)}</span>
                        </div>
                        <div style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1069)}</div>
                    </div>
                    <div style={{width:'100%',height:'57%',overflow:'auto'}}>
                        {this.state.wholesaleInsertVoItem.commodities.map((item,key)=>{
                            let otherMoneyTotal = 0;
                            for(let i=0;i<item.costs.length;i++){
                                let costsItem = item.costs[i];
                                if (costsItem.calMode==0) {//stringUtil.language(868)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.number).mul(costsItem.moneyScal).round();
                                }else if (costsItem.calMode==1) {//stringUtil.language(869)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (arithUtil.keToWeight(item.weight,0)).mul(costsItem.moneyScal).roundDouble();
                                }else if (costsItem.calMode==2) {//stringUtil.language(396)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.goodsMoney).mul(costsItem.moneyScal).mul(0.01).round();
                                }else{
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = parseFloat(costsItem.moneyScal).round();
                                }
                                otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
                            }
                            this.state.wholesaleInsertVoItem.totalNumber = (this.state.wholesaleInsertVoItem.totalNumber).add(item.number);
                            this.state.wholesaleInsertVoItem.totalWeight = (this.state.wholesaleInsertVoItem.totalWeight).add(item.weight);
                            this.state.wholesaleInsertVoItem.totalGoodsMoney = (this.state.wholesaleInsertVoItem.totalGoodsMoney).add(item.goodsMoney).round();
                            this.state.wholesaleInsertVoItem.totalOtherMoneyTotal = (this.state.wholesaleInsertVoItem.totalOtherMoneyTotal).add(otherMoneyTotal).round();
                            return(<div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',paddingRight:5}} key={key}>
                                <i className="iconfontIndexCss" style={{width:'7.5%',height:'100%',fontSize:15,color:'#F00',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.wholesaleInsertVoItem.commodities.splice(key, 1);
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }}>&#xe612;</i>
                                <div style={{width:'82.5%',height:'100%',cursor:'pointer'}} onClick={()=>{
                                    this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(item));
                                    if(this.state.currWholesaleCommodityVo.mode!=1)this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(this.state.currWholesaleCommodityVo.unitPrice,0,this.state.weightMode);
                                    this.state.currWholesaleCommodityVoKey = key;
                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{
                                        // document.getElementById('workerSerial').value='';
                                        // document.getElementById('customerSerial').value='';
                                        document.getElementById('ownerSerial').value='';
                                        document.getElementById('commoditySerial').value='';
                                        document.getElementById('numberEdit').focus();
                                    });
                                }}>
                                    <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                                        <span style={{width:'37%',fontSize:16,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{key+1}.{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?'('+item.commodityCode+')':''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                                        <span style={{width:'15%',fontSize:16,fontWeight:'bold',textAlign:'right'}}>{item.number}</span>
                                        <span style={{width:'17%',fontSize:16,fontWeight:'bold',textAlign:'right'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                        {item.mode==0?<span style={{width:'14%',fontSize:14,textAlign:'right'}}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)}</span>:
                                            <span style={{width:'14%',fontSize:14,textAlign:'right'}}>{arithUtil.fenToYuan(item.unitPrice,2)}</span>}
                                        <span style={{width:'17%',fontSize:16,fontWeight:'bold',textAlign:'right'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                                    </div>
                                    <div style={{height:15,display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                                        <span style={{width:'52%',color:'#52A056',fontSize:10}}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,5):''):stringUtil.subStr(item.targetName,5)}/{item.cargoNo}</span>
                                        {item.mode==0?<span style={{width:'51%',color:'#52A056',fontSize:10}}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>:
                                        <span style={{width:'31%',color:'#52A056',fontSize:10}}>{item.number+'X'+arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>}
                                        <span style={{width:'17%',color:'#52A056',fontSize:10,textAlign:'right'}}>{item.buyerCode?item.buyerCode:''}</span>
                                    </div>
                                </div>
                                <div style={{width:'10%',height:'100%',fontSize:16,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(item));
                                    this.setState({costsShowPop:true});
                                }}>{arithUtil.fenToYuan(otherMoneyTotal)}</div>
                            </div>)
                        })}
                    </div>
                    <div style={{width:'100%',height:'30%'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0',paddingRight:5}}>
                            <span style={{width:'35.5%',color:'#D2855D',fontSize:12}}>{stringUtil.language(890)}</span>
                            <span style={{width:'13.5%',color:'#D2855D',fontSize:12,textAlign:'right'}}>{this.state.wholesaleInsertVoItem.totalNumber}</span>
                            <span style={{width:'15%',color:'#D2855D',fontSize:12,textAlign:'right'}}>{arithUtil.keToWeight(this.state.wholesaleInsertVoItem.totalWeight,this.state.weightMode)}</span>
                            <span style={{width:'11%',color:'#D2855D',fontSize:12,textAlign:'right'}}></span>
                            <span style={{width:'15%',color:'#D2855D',fontSize:12,textAlign:'right'}}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.totalGoodsMoney)}</span>
                            <span style={{width:'10%',color:'#D2855D',fontSize:12,textAlign:'right'}}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.totalOtherMoneyTotal)}</span>
                        </div>
                        <div style={{height:'28%',display:'flex',flexDirection:'row'}}>
                            <div style={{width:'70%',height:'100%'}}>
                                {this.state.wholesaleInsertVoItem.costs.length!=0?<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                                    {this.state.wholesaleInsertVoItem.costs.map((item,key)=>{
                                        if (item.costClauseCalMode==0) {//stringUtil.language(868)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (this.state.wholesaleInsertVoItem.totalNumber).mul(item.moneyScal).round();
                                        }else if (item.costClauseCalMode==1) {//stringUtil.language(869)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (arithUtil.keToWeight(this.state.wholesaleInsertVoItem.totalWeight,0)).mul(item.moneyScal).roundDouble();
                                        }else if (item.costClauseCalMode==2) {//stringUtil.language(396)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (this.state.wholesaleInsertVoItem.totalGoodsMoney).mul(item.moneyScal).mul(0.01).round();
                                        }else{
                                            this.state.wholesaleInsertVoItem.costs[key].money = parseFloat(item.moneyScal).round();
                                        }
                                        return(<div style={{width:'45%',height:30,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:2}} key={key}>
                                            <span style={{color:'#808080',fontSize:16}}>{stringUtil.subStr(item.costClauseName,5)}：</span>
                                            {item.costClauseCalMode==0?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(1021)*/}</span></div>:
                                            item.costClauseCalMode==1?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(1022)*/}</span></div>:
                                            item.costClauseCalMode==2?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(355)*/}</span></div>:
                                            <div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(1029)*/}</span></div>}
                                        </div>)
                                    })}
                                </div>
                                </div>:<div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}><span style={{color:'#DCDCDC'}}>{stringUtil.language(52)}</span></div>}
                            </div>
                            <div style={{width:'30%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                {(()=>{this.state.yakuangxinxi={number:0,money:0};
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                                        if(this.state.wholesaleInsertVoItem.commodities[i].systemCommodityId==-1){
                                            this.state.yakuangxinxi.number=this.state.yakuangxinxi.number+this.state.wholesaleInsertVoItem.commodities[i].number;
                                            this.state.yakuangxinxi.money=(this.state.yakuangxinxi.money).add(this.state.wholesaleInsertVoItem.commodities[i].goodsMoney);
                                        }
                                    }
                                })()}
                                {this.state.yakuangxinxi.number?<span style={{fontSize:14}}>{stringUtil.language(1157)}:{this.state.yakuangxinxi.number}个,{arithUtil.fenToYuan(this.state.yakuangxinxi.money)}{stringUtil.currency(1)}</span>:''}
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{stringUtil.language(1161)}:</span><input id='advance' style={arithUtil.stylesAdd(styles.textInput,{width:'50px',height:25})} type='number' onKeyDown={(e)=>{
                                        if(document.getElementById('advance')==document.activeElement&&e.keyCode===13){document.getElementById('describe').focus();}
                                    }} placeholder='0' onChange={(event)=>{
                                        this.state.wholesaleInsertVoItem.advanceMoney = event.target.value?arithUtil.yuanToFen(event.target.value):0;
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{stringUtil.language(858)}</span>
                                <input id='describe' style={arithUtil.stylesAdd(styles.textInput,{width:170,height:25,fontSize:16,padding:0,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',textAlign:'left'})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                                    this.state.wholesaleInsertVoItem.describe = event.target.value;
                                }}/>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(7)}}>
                                <span style={{fontSize:16}}>{stringUtil.language(880)}</span>
                                {(()=>{
                                    let totalGoodsMoneyVal = this.state.wholesaleInsertVoItem.totalGoodsMoney;
                                    let totalOtherMoneyTotalVal = this.state.wholesaleInsertVoItem.totalOtherMoneyTotal;
                                    let advanceMoneyVal = this.state.wholesaleInsertVoItem.advanceMoney;
                                    let costsMoneyVal = 0;
                                    if (this.state.wholesaleInsertVoItem.costs.length>0) {
                                        for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                                            let costsItemMoney = this.state.wholesaleInsertVoItem.costs[i].money;
                                            costsMoneyVal = costsMoneyVal.add(costsItemMoney);
                                        }
                                    }
                                    this.state.wholesaleInsertVoItem.money = totalGoodsMoneyVal.add(totalOtherMoneyTotalVal).add(advanceMoneyVal).add(costsMoneyVal).round();
                                    this.state.wholesaleInsertVoItem.billMoney = 0;
                                    this.state.wholesaleTotalMoneyTemp = this.state.wholesaleInsertVoItem.money;
                                    if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==0)&&this.state.currCustomerItem.type==1){//临时{stringUtil.language(1048)}取整
                                        let madeAllMoney = arithUtil.getRoundMoney(this.state.wholesaleInsertVoItem.money);
                                        if (madeAllMoney!=this.state.wholesaleInsertVoItem.money){//在取整规则范围内
                                            this.state.wholesaleInsertVoItem.billMoney = madeAllMoney.sub(this.state.wholesaleInsertVoItem.money,2);
                                            this.state.wholesaleTotalMoneyTemp = madeAllMoney;
                                        }
                                    }
                                })()}
                                <span style={{color:'#F00',fontSize:23,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesaleTotalMoneyTemp)}</span>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:5}}>
                            <div style={{width:60,height:35,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{this.setState({isPrintTag:this.state.isPrintTag?0:1});/*1不{stringUtil.language(1045)}，null0{stringUtil.language(1045)}*/}}>
                                <span style={{color:this.state.isPrintTag!=1?'#D3855C':'#A9A9A9',textDecorationStyle:'solid',textDecorationLine:'underline',textDecorationColor:this.state.isPrintTag!=1?'#D3855C':'#A9A9A9'}}>{this.state.isPrintTag!=1?'✔'+stringUtil.language(1045):stringUtil.language(1045)}</span>
                            </div>
                            <span style={{width:130,height:'30px',color:'#FFF',lineHeight:'30px',borderStyle:'solid',borderColor:'#F00',borderWidth:this.state.currCommodityEditsView=='confirm'?5:0,borderRadius:3,backgroundColor:'#2B8D53',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.submitWholesale(1);
                            }}>{stringUtil.language(859)}</span>
                            <span style={{width:130,height:'30px',color:'#FFF',lineHeight:'30px',borderStyle:'solid',borderColor:'#F00',borderWidth:this.state.currCommodityEditsView=='save'?5:0,borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.submitWholesale(0);
                            }}>暂 存</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'36%',height:'100%',backgroundColor:'#FFF'}}>{this.renderInfoShow()}</div>
                <div style={{width:'26%',height:'100%',backgroundColor:'#C3C3C3'}}>{this.renderInfoEdit()}</div>
            </div>
            {/* <MaskModal title='stringUtil.language(392)stringUtil.language(886)' visible={this.state.costsCommodityShowPop} width={450} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{paddingLeft:10,paddingRight:10}}>
                    <div style={{height:'100%',overflow:'auto'}}>
                        {this.state.saleCostItemAll?this.state.saleCostItemAll.map((item,key)=>{
                            return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                this.state.currWholesaleCommodityVo.costs.push({
                                    calMode:item.calMode,
                                    costClauseId:item.costClauseId,
                                    costClauseName:item.costClauseName,
                                    payee:item.payee,
                                    scope:item.scope,
                                    moneyScal:item.money,
                                    money:0
                                });
                                this.setState({costsCommodityShowPop:false});
                            }}>
                                <span style={{width:'28%',color:'#808080'}}>{key+1+"、"+item.costClauseName}</span>
                                <span style={{width:'21%',color:'#808080'}}>{(item.payee==0?'{stringUtil.language(1049)}':item.payee==1?'stringUtil.language(1030)':'stringUtil.language(1031)')+'stringUtil.language(1032)'}</span>
                                <span style={{width:'23%',color:'#808080'}}>{item.calMode==0?'stringUtil.language(868)/{stringUtil.language(1173)}':item.calMode==1?'stringUtil.language(869)/'+'{stringUtil.language(1023)}':item.calMode==2?'stringUtil.language(396)':'stringUtil.language(1029)'}</span>
                                <span style={{width:'18%',color:'#D2855D',textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                            </div>)
                        }):<div/>}
                    </div>
                </div>}closeBnTopEvents={() => {this.setState({costsCommodityShowPop:false});}}/> */}
            <MaskModal title={stringUtil.language(691)} visible={this.state.customerCollectShowPop} width={700} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{width:'100%',height:'100%',paddingLeft:10,paddingRight:10}}>
                    <CustomerCollectView context={this} paramsInfo={{wholesaleItem:JSON.stringify(this.state.currWholesaleItem)}} successEvent={(result1)=>{
                        if(result1==0||result1==1||result1==-1){//0stringUtil.language(1027)按钮，1stringUtil.language(1028)按钮，-1不选中
                            this.setState({backResultVal:result1})
                        }else if(result1=='submitResult0'||result1=='submitResult1'){//0stringUtil.language(1027)stringUtil.language(1038)，1stringUtil.language(1028)stringUtil.language(1038)
                            if(result1=='submitResult0')this.collectionEvent(0);
                            if(result1=='submitResult1')this.collectionEvent(1);
                        }else{this.state.customerCollectInsertItem = result1;}
                    }}/>
                </div>} okBnColor={this.state.backResultVal==0?'#F00':'#52A056'} closeBnColor={this.state.backResultVal==1?'#F00':'#F5F5F5'}
                okBnEvents={(event) => {
                    this.collectionEvent(0);
                }} closeBnEvents={() => {this.collectionEvent(1);}}/>
            <MaskModal title={stringUtil.language(280)} visible={this.state.costsShowPop} width={450} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{paddingLeft:10,paddingRight:10}}>
                    <div style={{height:'100%',overflow:'auto'}}>
                        {this.state.currWholesaleCommodityVo&&this.state.currWholesaleCommodityVo.costs?this.state.currWholesaleCommodityVo.costs.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                                <span>{item.costClauseName}</span>
                                <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}</span>
                            </div>)
                        }):<div/>}
                    </div>
                </div>}closeBnTopEvents={() => {this.setState({ costsShowPop: false, });}}/>
        </div>)
    }
    collectionEvent(tag){
        if(tag==0){
            if (this.state.customerCollectInsertItem==null||(this.state.customerCollectInsertItem.collectMoney==0&&this.state.customerCollectInsertItem.discountMoney==0)) {
                ToastBox.error(stringUtil.language(96));return;
            }
            if (this.state.customerCollectInsertItem.accountId==null) {
                ToastBox.error(stringUtil.language(95));return;
            }
            let customerCollectInsertVo = {collects:[]};
            customerCollectInsertVo.time = arithUtil.timeToNumber(this.state.customerCollectInsertItem.time);
            customerCollectInsertVo.customerId = this.state.currWholesaleItem.customerId;
            customerCollectInsertVo.accountId = this.state.customerCollectInsertItem.accountId;
            customerCollectInsertVo.discountMoney = this.state.customerCollectInsertItem.discountMoney;
            customerCollectInsertVo.collectMoney = this.state.customerCollectInsertItem.collectMoney;
            customerCollectInsertVo.describe = this.state.customerCollectInsertItem.describe!=null?this.state.customerCollectInsertItem.describe:'';
            for(let i=0;i<this.state.customerCollectInsertItem.collects.length;i++){
                let temp = this.state.customerCollectInsertItem.collects[i];
                if ((temp.collectMoney!=null&&temp.collectMoney!=0) || (temp.discountMoney!=null&&temp.discountMoney!=0)) {
                    let collectDetailedTemp = {
                        collectMode:1,//0.stringUtil.language(913) 1stringUtil.language(1185)stringUtil.language(865)
                        wholesaleId:temp.wholesaleId,
                        targetType:temp.targetType,
                        targetId:temp.targetId,
                        discountMoney:temp.discountMoney,
                        collectMoney:temp.collectMoney
                    };
                    customerCollectInsertVo.collects.push(collectDetailedTemp);
                }
            }
            if(this.state.isVisiblityCollect){return;}
			this.state.isVisiblityCollect = true;
            stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL,customerCollectInsertVo,['json','post',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.setState({customerCollectShowPop:false},()=>{
                        global.isCloseThis=2;
                        this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                    });
                }else{this.state.isVisiblityCollect=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{this.state.isVisiblityCollect=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else if(tag==1){
            this.setState({customerCollectShowPop:false},()=>{
                global.isCloseThis=2;
                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
            });
        }
    }
    submitWholesale(wholesaleType){//0stringUtil.language(1145)，1stringUtil.language(1144)
        if(wholesaleType==1){
            this.state.wholesaleInsertVoItem.customerId = this.state.currCustomerItem.id;
            if (this.state.wholesaleInsertVoItem.commodities.length==0) {
                ToastBox.error(stringUtil.language(190));return;
            }
            for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                let commodityItem = this.state.wholesaleInsertVoItem.commodities[i];
                if (commodityItem.unitPrice==0) {
                    ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1011)+'！');return;
                }
                if (commodityItem.mode==0&&commodityItem.weight<=0) {
                    ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1012)+'！');return;
                }
                if (commodityItem.mode==1&&commodityItem.number<=0) {
                    ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(560));return;
                }
            }
            let wholesaleInsertVo = {workerId:0,costs:[],commodities:[]};
            wholesaleInsertVo.workerId = this.state.wholesaleInsertVoItem.workerId==null?0:(this.state.wholesaleInsertVoItem.workerId==0?-1:this.state.wholesaleInsertVoItem.workerId);
            wholesaleInsertVo.createTime = this.state.wholesaleInsertVoItem.createTime;
            wholesaleInsertVo.customerId = this.state.wholesaleInsertVoItem.customerId;
            wholesaleInsertVo.customerAlias = this.state.wholesaleInsertVoItem.customerAlias;
            wholesaleInsertVo.advanceMoney = this.state.wholesaleInsertVoItem.advanceMoney;
            wholesaleInsertVo.money = this.state.wholesaleInsertVoItem.money;
            wholesaleInsertVo.billMoney = this.state.wholesaleInsertVoItem.billMoney;
            wholesaleInsertVo.state = 1;//0stringUtil.language(1145)，1stringUtil.language(1144)
            wholesaleInsertVo.describe = this.state.wholesaleInsertVoItem.describe;
            for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                let costsTemp = {money:0};
                costsTemp.costClauseId = this.state.wholesaleInsertVoItem.costs[i].costClauseId;
                costsTemp.money = this.state.wholesaleInsertVoItem.costs[i].money;
                wholesaleInsertVo.costs.push(costsTemp);
            }
            for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                let commodityTemp = {costs:[]};
                commodityTemp.cargoId = this.state.wholesaleInsertVoItem.commodities[i].cargoId;
                commodityTemp.buyerCode = this.state.wholesaleInsertVoItem.commodities[i].buyerCode;
                commodityTemp.targetType = this.state.wholesaleInsertVoItem.commodities[i].targetType;
                commodityTemp.targetId = this.state.wholesaleInsertVoItem.commodities[i].targetId;
                commodityTemp.commodityId = this.state.wholesaleInsertVoItem.commodities[i].commodityId;
                commodityTemp.cargoCommodityId = this.state.wholesaleInsertVoItem.commodities[i].cargoCommodityId;
                commodityTemp.standard = this.state.wholesaleInsertVoItem.commodities[i].standard;
                commodityTemp.number = this.state.wholesaleInsertVoItem.commodities[i].number;
                commodityTemp.totalWeight = this.state.wholesaleInsertVoItem.commodities[i].totalWeight;
                commodityTemp.skinWeight = this.state.wholesaleInsertVoItem.commodities[i].skinWeight;
                commodityTemp.reduceWeight = this.state.wholesaleInsertVoItem.commodities[i].reduceWeight;
                commodityTemp.weight = this.state.wholesaleInsertVoItem.commodities[i].weight;
                commodityTemp.unitPrice = this.state.wholesaleInsertVoItem.commodities[i].unitPrice;
                commodityTemp.ownerUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice?this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice:0;
                commodityTemp.totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].totalUnitPrice;
                commodityTemp.mode = this.state.wholesaleInsertVoItem.commodities[i].mode;
                commodityTemp.goodsMoney = this.state.wholesaleInsertVoItem.commodities[i].goodsMoney;
                if (this.state.wholesaleInsertVoItem.commodities[i].costs==null)this.state.wholesaleInsertVoItem.commodities[i].costs=[];
                for(let j=0;j<this.state.wholesaleInsertVoItem.commodities[i].costs.length;j++){
                    let costsSource = JSON.parse(JSON.stringify(this.state.wholesaleInsertVoItem.commodities[i].costs[j]));
                    commodityTemp.costs.push({
                        costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                        money:costsSource.money!=null?costsSource.money:0,
                    });
                }
                wholesaleInsertVo.commodities.push(commodityTemp);
            }
            if(wholesaleInsertVo.customerId==null||wholesaleInsertVo.customerId==0){
                ToastBox.error(stringUtil.language(457)+'！');return;
            }
            if(this.state.isVisiblity){return;}
			this.state.isVisiblity = true;
            stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL,wholesaleInsertVo,['json','post',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.state.currWholesaleItem={id:successResponse.data,customerId:wholesaleInsertVo.customerId};
                    if(this.state.isPrintTag!=1)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+successResponse.data,null,['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            let printWholesaleInfo = successResponse.data;
                            if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||this.state.currCustomerItem.type!=1) printWholesaleInfo.billMoney=0;
                            if(localStorage.getItem("useSetting")&&JSON.parse(localStorage.getItem("useSetting")).everyCopyNum!=null){
                                let printDataTemp = otherUtil.wholesaleNull(printWholesaleInfo);
                                for(let i=0;i<JSON.parse(localStorage.getItem("useSetting")).everyCopyNum;i++){
                                    printUtil.toComputerPrint(printDataTemp);
                                }
                            }else{printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));}
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    if(otherUtil.authorityFindCode("020401")!=null&&otherUtil.authorityFindCode("030102")!=null&&this.state.currCustomerItem.type==1){//{stringUtil.language(1037)}{stringUtil.language(1171)}
                        this.setState({customerCollectShowPop:true});
                    }else{
                        global.isCloseThis=2;
                        this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                    }
                }else{this.state.isVisiblity=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{this.state.isVisiblity=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else if(wholesaleType==0){
            this.state.wholesaleInsertVoItem.customerId = this.state.currCustomerItem.id;
            let wholesaleInsertVo = {workerId:0,costs:[],commodities:[]};
            wholesaleInsertVo.workerId = this.state.wholesaleInsertVoItem.workerId==null?0:(this.state.wholesaleInsertVoItem.workerId==0?-1:this.state.wholesaleInsertVoItem.workerId);
            wholesaleInsertVo.customerAlias = this.state.wholesaleInsertVoItem.customerAlias;
            wholesaleInsertVo.customerId = this.state.wholesaleInsertVoItem.customerId;
            wholesaleInsertVo.advanceMoney = this.state.wholesaleInsertVoItem.advanceMoney;
            wholesaleInsertVo.money = this.state.wholesaleInsertVoItem.money;
            wholesaleInsertVo.billMoney = this.state.wholesaleInsertVoItem.billMoney;
            wholesaleInsertVo.state = 0;//0stringUtil.language(1145)，1stringUtil.language(1144)
            wholesaleInsertVo.describe = this.state.wholesaleInsertVoItem.describe;
            for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                let costsTemp = {money:0};
                costsTemp.costClauseId = this.state.wholesaleInsertVoItem.costs[i].costClauseId;
                costsTemp.money = this.state.wholesaleInsertVoItem.costs[i].money;
                wholesaleInsertVo.costs.push(costsTemp);
            }
            for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                let commodityTemp = {costs:[]};
                commodityTemp.cargoId = this.state.wholesaleInsertVoItem.commodities[i].cargoId;
                commodityTemp.buyerCode = this.state.wholesaleInsertVoItem.commodities[i].buyerCode;
                commodityTemp.targetType = this.state.wholesaleInsertVoItem.commodities[i].targetType;
                commodityTemp.targetId = this.state.wholesaleInsertVoItem.commodities[i].targetId;
                commodityTemp.commodityId = this.state.wholesaleInsertVoItem.commodities[i].commodityId;
                commodityTemp.cargoCommodityId = this.state.wholesaleInsertVoItem.commodities[i].cargoCommodityId;
                commodityTemp.standard = this.state.wholesaleInsertVoItem.commodities[i].standard;
                commodityTemp.number = this.state.wholesaleInsertVoItem.commodities[i].number;
                commodityTemp.totalWeight = this.state.wholesaleInsertVoItem.commodities[i].totalWeight;
                commodityTemp.skinWeight = this.state.wholesaleInsertVoItem.commodities[i].skinWeight;
                commodityTemp.reduceWeight = this.state.wholesaleInsertVoItem.commodities[i].reduceWeight;
                commodityTemp.weight = this.state.wholesaleInsertVoItem.commodities[i].weight;
                commodityTemp.unitPrice = this.state.wholesaleInsertVoItem.commodities[i].unitPrice;
                commodityTemp.ownerUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice?this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice:0;
                commodityTemp.totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].totalUnitPrice;
                commodityTemp.mode = this.state.wholesaleInsertVoItem.commodities[i].mode;
                commodityTemp.goodsMoney = this.state.wholesaleInsertVoItem.commodities[i].goodsMoney;
                if (this.state.wholesaleInsertVoItem.commodities[i].costs==null)this.state.wholesaleInsertVoItem.commodities[i].costs=[];
                for(let j=0;j<this.state.wholesaleInsertVoItem.commodities[i].costs.length;j++){
                    let costsSource = JSON.parse(JSON.stringify(this.state.wholesaleInsertVoItem.commodities[i].costs[j]));
                    commodityTemp.costs.push({
                        costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                        money:costsSource.money!=null?costsSource.money:0,
                    });
                }
                wholesaleInsertVo.commodities.push(commodityTemp);
            }
            if(wholesaleInsertVo.customerId==null||wholesaleInsertVo.customerId==0){
                ToastBox.error(stringUtil.language(457)+'！');return;
            }
            if(this.state.isVisiblity){return;}
			this.state.isVisiblity = true;
            stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL,wholesaleInsertVo,['json','post',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(562));
                    global.isCloseThis=2;
                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                }else{this.state.isVisiblity=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{this.state.isVisiblity=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }
    }
}

const styles = {
    textInput:{
        height:35,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:5,
        textAlign:'center',
        textAlignVertical:'center'
    },
}