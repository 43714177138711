import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import * as printUtil from '../utils/printUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
 import MaskModal from '../components/MaskModal';

  export default class WholesaleUpdatessView extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        wholesaleInsertVoItem:this.props.wholesaleInfoItemInfo,
        editKey:-1,
        workerHabitInfo:{},
        keySearch:'',
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workerHabitInfo = successResponse.data;
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initDatas();
        document.onkeydown=(e)=>{
            console.log('WholesaleUpdatessView按键信息：',e)
            if(e.keyCode===13){//Ent
                if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                if(this.state.editKey!=-1){
                    if(document.getElementById('numberEdit'+this.state.editKey)==document.activeElement){
                        document.getElementById('weightEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('weightEdit'+this.state.editKey)==document.activeElement){
                        document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('unitPriceEdit'+this.state.editKey)==document.activeElement){
                        this.state.editKey=this.state.editKey+1;
                        if(this.state.editKey<this.state.wholesaleInsertVoItem.commodities.length){
                            document.getElementById('numberEdit'+this.state.editKey).focus();
                        }else{this.state.editKey=-1;document.getElementById('advance').focus();}
                    }else if(document.getElementById('advance')==document.activeElement){
                        this.state.editKey=0;document.getElementById('numberEdit0').focus();
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                this.editRowKey();
            }else if(e.keyCode===38){//上
                if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                if(this.state.editKey!=-1){let editKeyTemp=this.state.editKey;
                    this.state.editKey=this.state.editKey-1;
                    if(this.state.editKey<0){this.state.editKey=this.state.wholesaleInsertVoItem.commodities.length-1;}
                    if(document.getElementById('numberEdit'+editKeyTemp)==document.activeElement){
                        document.getElementById('numberEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('weightEdit'+editKeyTemp)==document.activeElement){
                        document.getElementById('weightEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('unitPriceEdit'+editKeyTemp)==document.activeElement){
                        document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                this.editRowKey();
            }else if(e.keyCode===40){//下
                if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                if(this.state.editKey!=-1){let editKeyTemp=this.state.editKey;
                    this.state.editKey=this.state.editKey+1;
                    if(this.state.editKey==this.state.wholesaleInsertVoItem.commodities.length){this.state.editKey=0;}
                    if(document.getElementById('numberEdit'+editKeyTemp)==document.activeElement){
                        document.getElementById('numberEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('weightEdit'+editKeyTemp)==document.activeElement){
                        document.getElementById('weightEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('unitPriceEdit'+editKeyTemp)==document.activeElement){
                        document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                        if(this.state.editKey==this.state.wholesaleInsertVoItem.commodities.length)document.getElementById('advance').focus();
                    }else if(document.getElementById('advance')==document.activeElement){
                        this.state.editKey=0;document.getElementById('unitPriceEdit0').focus();
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                this.editRowKey();
            }else if(e.keyCode===37){//左
                if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                if(this.state.editKey!=-1){
                    if(document.getElementById('numberEdit'+this.state.editKey)==document.activeElement){
                        document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('weightEdit'+this.state.editKey)==document.activeElement){
                        document.getElementById('numberEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('unitPriceEdit'+this.state.editKey)==document.activeElement){
                        document.getElementById('weightEdit'+this.state.editKey).focus();
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                this.editRowKey();
            }else if(e.keyCode===39){//右
                if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                if(this.state.editKey!=-1){
                    if(document.getElementById('numberEdit'+this.state.editKey)==document.activeElement){
                        document.getElementById('weightEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('weightEdit'+this.state.editKey)==document.activeElement){
                        document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                    }else if(document.getElementById('unitPriceEdit'+this.state.editKey)==document.activeElement){
                        document.getElementById('numberEdit'+this.state.editKey).focus();
                        if(this.state.editKey==this.state.wholesaleInsertVoItem.commodities.length-1)document.getElementById('advance').focus();
                    }else if(document.getElementById('advance')==document.activeElement){
                        this.state.editKey=this.state.wholesaleInsertVoItem.commodities.length-1;document.getElementById('numberEdit'+this.state.editKey).focus();
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                this.editRowKey();
            }else if(e.keyCode===34){//PgDn
                this.props.successEvent(2);
            }else if(e.keyCode===33){//PgUp
                this.props.successEvent(3);
            }else if(e.keyCode===35){//End
                this.submitWholesale();
            }else if(e.keyCode===27){//Esc
                this.props.successEvent(0);
            }
        }
    }
    initDatas(){
        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+this.state.wholesaleInsertVoItem.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {this.state.wholesaleInsertVoItem=successResponse.data;
                for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                    let commoditiesData = this.state.wholesaleInsertVoItem.commodities[i];
                    for(let j=0;j<commoditiesData.costs.length;j++){
                        for(let k=0;k<commoditiesData.commodityCosts.length;k++){
                            if (commoditiesData.costs[j].costClauseId==commoditiesData.commodityCosts[k].costClauseId) {
                                this.state.wholesaleInsertVoItem.commodities[i].costs[j].moneyScal = commoditiesData.commodityCosts[k].money;
                            }
                        }
                    }
                }
                for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                        let costsItem = this.state.wholesaleInsertVoItem.costs[i];
                        this.state.wholesaleInsertVoItem.costs[i] = {
                            costClauseCalMode:costsItem.calMode,
                            costClauseId:costsItem.costClauseId,
                            costClauseName:costsItem.costClauseName,
                            costClausePayee:costsItem.payee,
                            moneyScal:0,
                            money:costsItem.money
                        };
                }
                this.state.isPrintTag=this.state.wholesaleInsertVoItem.customerType==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                stringUtil.httpRequest(urlUtil.BASIC_SALE_COST_URL,null,['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let resultVal = JSON.parse(JSON.stringify(successResponse.data));
                        resultVal.forEach((item, index) => {
                            for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                                if (this.state.wholesaleInsertVoItem.costs[i].costClauseId==item.costClauseId) {
                                    this.state.wholesaleInsertVoItem.costs[i].moneyScal = item.money;
                                }
                            }
                        })
                        this.setState({wholesaleInsertVoItem: this.state.wholesaleInsertVoItem},()=>{
                            if(document.getElementById('numberEdit0')){this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                        });
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    componentWillReceiveProps(props) {
        this.state.wholesaleInsertVoItem=props.wholesaleInfoItemInfo;
        this.initDatas();
    }
    submitWholesale(){//0stringUtil.language(1145)，1stringUtil.language(1144)
        if (this.state.wholesaleInsertVoItem.commodities.length==0) {
            ToastBox.error(stringUtil.language(190));return;
        }
        for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
            let commodityItem = this.state.wholesaleInsertVoItem.commodities[i];
            if (commodityItem.unitPrice==0) {
                ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1011)+'！');return;
            }
            if (commodityItem.mode==0&&commodityItem.weight<=0) {
                ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1012)+'！');return;
            }
            if (commodityItem.mode==1&&commodityItem.number<=0) {
                ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(560));return;
            }
        }
        let wholesaleInsertVo = {costs:[],commodities:[]};
        wholesaleInsertVo.customerId = this.state.wholesaleInsertVoItem.customerId;
        wholesaleInsertVo.advanceMoney = this.state.wholesaleInsertVoItem.advanceMoney;
        wholesaleInsertVo.money = this.state.wholesaleInsertVoItem.money;
        wholesaleInsertVo.describe = this.state.wholesaleInsertVoItem.describe;
        for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
            let costsTemp = {money:0};
            costsTemp.costClauseId = this.state.wholesaleInsertVoItem.costs[i].costClauseId;
            costsTemp.money = this.state.wholesaleInsertVoItem.costs[i].money;
            wholesaleInsertVo.costs.push(costsTemp);
        }
        for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
            let commodityTemp = {costs:[]};
            commodityTemp.supplementaryCommodity = this.state.wholesaleInsertVoItem.commodities[i].supplementaryCommodity?1:0;
            commodityTemp.cargoId = this.state.wholesaleInsertVoItem.commodities[i].cargoId;
            commodityTemp.buyerCode = this.state.wholesaleInsertVoItem.commodities[i].buyerCode;
            commodityTemp.targetType = this.state.wholesaleInsertVoItem.commodities[i].targetType;
            commodityTemp.targetId = this.state.wholesaleInsertVoItem.commodities[i].targetId;
            commodityTemp.commodityId = this.state.wholesaleInsertVoItem.commodities[i].commodityId;
            commodityTemp.cargoCommodityId = this.state.wholesaleInsertVoItem.commodities[i].cargoCommodityId;
            commodityTemp.standard = this.state.wholesaleInsertVoItem.commodities[i].standard;
            commodityTemp.number = this.state.wholesaleInsertVoItem.commodities[i].number;
            commodityTemp.totalWeight = this.state.wholesaleInsertVoItem.commodities[i].totalWeight;
            commodityTemp.skinWeight = this.state.wholesaleInsertVoItem.commodities[i].skinWeight;
            commodityTemp.reduceWeight = this.state.wholesaleInsertVoItem.commodities[i].reduceWeight;
            commodityTemp.weight = this.state.wholesaleInsertVoItem.commodities[i].weight;
            commodityTemp.unitPrice = this.state.wholesaleInsertVoItem.commodities[i].unitPrice;
            commodityTemp.ownerUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice?this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice:0;
            commodityTemp.totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].totalUnitPrice;
            commodityTemp.mode = this.state.wholesaleInsertVoItem.commodities[i].mode;
            commodityTemp.goodsMoney = this.state.wholesaleInsertVoItem.commodities[i].goodsMoney;
            if (this.state.wholesaleInsertVoItem.commodities[i].costs==null)this.state.wholesaleInsertVoItem.commodities[i].costs=[];
            for(let j=0;j<this.state.wholesaleInsertVoItem.commodities[i].costs.length;j++){
                let costsSource = JSON.parse(JSON.stringify(this.state.wholesaleInsertVoItem.commodities[i].costs[j]));
                commodityTemp.costs.push({
                    costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                    money:costsSource.money!=null?costsSource.money:0,
                });
            }
            wholesaleInsertVo.commodities.push(commodityTemp);
        }
        if(this.state.isVisiblity){return;}
        this.state.isVisiblity = true;
        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+this.state.wholesaleInsertVoItem.id,wholesaleInsertVo,['json','put',1],(successResponse)=>{
            if (successResponse.status==200) {
                ToastBox.success(stringUtil.language(388));
                if(this.state.isPrintTag!=1)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+this.state.wholesaleInsertVoItem.id,null,['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let printWholesaleInfo = successResponse.data;
                        if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||this.state.wholesaleInsertVoItem.customerType!=1) printWholesaleInfo.billMoney=0;
                        if(localStorage.getItem("useSetting")&&JSON.parse(localStorage.getItem("useSetting")).everyCopyNum!=null){
                            let printDataTemp = otherUtil.wholesaleNull(printWholesaleInfo);
                            for(let i=0;i<JSON.parse(localStorage.getItem("useSetting")).everyCopyNum;i++){
                                printUtil.toComputerPrint(printDataTemp);
                            }
                        }else{printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));}
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                document.removeEventListener('keydown',()=>{console.log('事件移除')});
                this.props.successEvent(1);
            }else{this.state.isVisiblity=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{this.state.isVisiblity=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    updateCommodityInfo(){
        let item=this.state.wholesaleInsertVoItem.commodities[this.state.editKey];
        console.log('当前商品：',item)
        let reduceWeightVal = (parseFloat(item.number)).mul(item.commodityReduce);//克
        let standardVal = item.standard,goodsMoneyVal = 0;//克/stringUtil.language(1173)
        if(standardVal!=null&&standardVal!=0){//有stringUtil.language(1024)情况
            item.reduceWeight = reduceWeightVal;
            if (document.getElementById('numberEdit'+this.state.editKey)==document.activeElement&&this.state.workerHabitInfo.defWeight==0) {//0stringUtil.language(1024)，1stringUtil.language(1168)
                item.totalWeight = (parseFloat(item.number)).mul(standardVal);
                item.weight=(item.totalWeight).sub(parseFloat(item.skinWeight)).sub(reduceWeightVal);
            }else if (document.getElementById('weightEdit'+this.state.editKey)==document.activeElement){
                item.totalWeight = (parseFloat(item.weight)).add(parseFloat(item.skinWeight)).add(reduceWeightVal);
            }
            if (item.mode) {goodsMoneyVal = (parseFloat(item.unitPrice)).mul((parseFloat(item.number)));
            }else{goodsMoneyVal = (parseFloat(item.unitPrice)).mul(arithUtil.keToWeight(item.weight,0));}
        }else{//无stringUtil.language(1024)情况
            item.reduceWeight = reduceWeightVal;
            if (document.getElementById('weightEdit'+this.state.editKey)==document.activeElement){
                item.totalWeight = (parseFloat(item.weight)).add(parseFloat(item.skinWeight)).add(reduceWeightVal);
            }
            if (item.mode) {goodsMoneyVal = (parseFloat(item.unitPrice)).mul((parseFloat(item.number)));
            }else{goodsMoneyVal = (parseFloat(item.unitPrice)).mul(arithUtil.keToWeight(item.weight,0));}
        }
        item.goodsMoney = goodsMoneyVal.round();
        if(item.mode==1&&localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
            item.totalGoodMoney=(parseFloat(item.totalUnitPrice)).mul((parseFloat(item.number)));
        }else{}//{stringUtil.language(1015)}
    }
    editRowKey(){
        for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
            if(document.getElementById('numberEdit'+i)==document.activeElement||
            document.getElementById('weightEdit'+i)==document.activeElement||
            document.getElementById('unitPriceEdit'+i)==document.activeElement){
                document.getElementById('editRow'+i).style.backgroundColor='#009A61';
                document.getElementById('numberEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('weightEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('unitPriceEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('pinming'+i).style.color='#FFF';
                document.getElementById('huozhu'+i).style.color='#FFF';
                document.getElementById('qita'+i).style.color='#FFF';
                document.getElementById('jiadanwei'+i).style.color='#FFF';
                document.getElementById('xiaoji'+i).style.color='#FFF';
                // document.getElementById('shanchu'+i).style.color='#FFF';
            }else{document.getElementById('editRow'+i).style.backgroundColor='#FFF';
                document.getElementById('numberEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('weightEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('unitPriceEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('pinming'+i).style.color='#000';
                document.getElementById('huozhu'+i).style.color='#696969';
                document.getElementById('qita'+i).style.color='#696969';
                document.getElementById('jiadanwei'+i).style.color='#000';
                document.getElementById('xiaoji'+i).style.color='#000';
                // document.getElementById('shanchu'+i).style.color='#F00';
            }
        }
    }

    render(){
        return(<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',backgroundColor:'#FFF'}}>
            <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#000',borderTopLeftRadius:5}}>
                <span style={{width:0,flexGrow:1,color:'#FFF'}}>No.{this.state.wholesaleInsertVoItem.wholesaleNo}</span>
                <div style={{width:80,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.props.successEvent(0);
                }}><span style={{color:'#FFF'}}>{stringUtil.language(1268)}(Esc)</span></div>
            </div>
            <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:0,flexGrow:1,color:'#000',fontSize:25,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{this.state.wholesaleInsertVoItem.customerName}</span>
                <span style={{color:'#888',fontSize:14}}>{arithUtil.formatToTimeStr(new Date(this.state.wholesaleInsertVoItem.finishTime),'Y-M-D h:m')}</span>
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#DAE7EF'}}>
                <span style={{width:0,flexGrow:1,fontSize:14,textAlign:'center'}}>{stringUtil.language(1063)}</span>
                <span style={{width:'13%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                <span style={{width:'17%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1168)}</span>
                <span style={{width:'20%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                <span style={{width:'17%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                {/* <span style={{width:50,fontSize:14,textAlign:'center'}}>{stringUtil.language(1026)}</span> */}
            </div>
            {(()=>{this.state.totalInfo={number:0,totalWeight:0,skinWeight:0,weight:0,goodsMoney:0,otherMoney:0,money:0};})()}
            <div style={{width:'100%',height:0,flexGrow:1,overflow:'auto'}}>
                {(this.state.wholesaleInsertVoItem.commodities?this.state.wholesaleInsertVoItem.commodities:[]).map((item,key)=>{
                    let otherMoneyTotal = 0;
                    for(let i=0;i<item.costs.length;i++){
                        let costsItem = item.costs[i];
                        if (costsItem.calMode==0) {//stringUtil.language(868)
                            this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.number).mul(costsItem.moneyScal).round();
                        }else if (costsItem.calMode==1) {//stringUtil.language(869)
                            this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (arithUtil.keToWeight(item.weight,0)).mul(costsItem.moneyScal).roundDouble();
                        }else if (costsItem.calMode==2) {//stringUtil.language(396)
                            this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.goodsMoney).mul(costsItem.moneyScal).mul(0.01).round();
                        }else{
                            this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = parseFloat(costsItem.moneyScal).round();
                        }
                        otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
                    }
                    this.state.totalInfo.number=(this.state.totalInfo.number).add(item.number);
                    this.state.totalInfo.totalWeight=(this.state.totalInfo.totalWeight).add(item.totalWeight);
                    this.state.totalInfo.skinWeight=(this.state.totalInfo.skinWeight).add(item.skinWeight);
                    this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                    this.state.totalInfo.goodsMoney=(this.state.totalInfo.goodsMoney).add(item.goodsMoney).round();
                    this.state.totalInfo.otherMoney=(this.state.totalInfo.otherMoney).add(otherMoneyTotal).round();
                    this.state.totalInfo.money=(this.state.totalInfo.money).add((item.goodsMoney).add(otherMoneyTotal)).round();
                    return(<div id={'editRow'+key} style={{height:60,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <span id={'pinming'+key} style={{width:'100%',fontSize:18,fontWeight:'bold',marginLeft:5,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityName}</span>
                            <span id={'huozhu'+key} style={{width:'100%',fontSize:12,color:'#696969',marginLeft:5,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commoditySpecs?'['+item.commoditySpecs+'] ':''}{item.targetName?stringUtil.subStr(item.targetName,6):item.targetType==1?stringUtil.language(1051):''} {stringUtil.language(1237,item.cargoNo)}</span>
                        </div>
                        <div style={{width:'13%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <input id={'numberEdit'+key} ref={'numberEdit'+key} defaultValue={item.number?item.number:''} style={{width:'95%',height:35,fontSize:18,backgroundColor:'#DAE7EF',textAlign:'center',padding:0}} type='number' onKeyDown={(event)=>{
                                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                    event.preventDefault();
                                }
                            }} onFocus={()=>{
                                this.state.editKey=key;
                                this.editRowKey();
                            }} onChange={(event)=>{
                                if(event.target.value==null || event.target.value==''){
                                    this.state.wholesaleInsertVoItem.commodities[key].number = 0;
                                    this.updateCommodityInfo();
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }else if(stringUtil.numberCheck(event.target.value)){
                                    this.state.wholesaleInsertVoItem.commodities[key].number = parseFloat(event.target.value);
                                    this.updateCommodityInfo();
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }
                            }} placeholder={stringUtil.language(1165)}/>
                        </div>
                        <div style={{width:'17%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <input id={'weightEdit'+key} ref={'weightEdit'+key} value={item.weight?arithUtil.keToWeight(item.weight,this.state.weightMode):''} style={{width:'95%',height:35,fontSize:18,backgroundColor:'#DAE7EF',textAlign:'center',padding:0}} type='number' onKeyDown={(event)=>{
                                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                    event.preventDefault();
                                }
                            }} onFocus={()=>{
                                this.state.editKey=key;
                                this.editRowKey();
                            }} onChange={(event)=>{
                                if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                    this.state.wholesaleInsertVoItem.commodities[key].weight = 0;
                                    this.updateCommodityInfo();
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }else if(stringUtil.numberCheck(event.target.value)){
                                    this.state.wholesaleInsertVoItem.commodities[key].weight = arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode);
                                    this.updateCommodityInfo();
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }
                            }} placeholder={stringUtil.language(1168)}/>
                        </div>
                        <div style={{width:'20%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <input id={'unitPriceEdit'+key} ref={'unitPriceRef'+key} defaultValue={item.mode==1&&localStorage.getItem('wholesalePriceType')==1?(item.totalUnitPrice?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.totalUnitPrice),0,this.state.weightMode):''):(item.unitPrice?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice),0,this.state.weightMode):'')} style={{width:0,flexGrow:1,height:35,fontSize:18,backgroundColor:'#DAE7EF',textAlign:'center',padding:0}} type='number' onKeyDown={(event)=>{
                                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                    event.preventDefault();
                                }
                            }} onFocus={()=>{
                                this.state.editKey=key;
                                this.editRowKey();
                            }} onChange={(event)=>{
                                if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                    this.state.wholesaleInsertVoItem.commodities[key].unitPrice = 0;
                                    this.state.wholesaleInsertVoItem.commodities[key].totalUnitPrice = 0;
                                    this.updateCommodityInfo();
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }else if(stringUtil.numberCheck(event.target.value)){
                                    if (!item.mode) {
                                        this.state.wholesaleInsertVoItem.commodities[key].unitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(parseFloat(event.target.value)),this.state.weightMode,0);
                                        this.state.wholesaleInsertVoItem.commodities[key].totalUnitPrice = 0;
                                    }else{
                                        this.state.wholesaleInsertVoItem.commodities[key].unitPrice = arithUtil.yuanToFen(parseFloat(event.target.value));
                                        this.state.wholesaleInsertVoItem.commodities[key].totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[key].unitPrice;
                                    }
                                    this.updateCommodityInfo();
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }
                            }} placeholder={stringUtil.language(1050)}/>
                            <span id={'jiadanwei'+key} style={{width:40,fontSize:12,marginTop:4,whiteSpace:'nowrap'}}>/{item.mode?item.commodityUnitMaster:(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                        </div>
                        <div style={{width:'17%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end'}}>
                            <span id={'xiaoji'+key} style={{fontSize:18,marginRight:15}}>{arithUtil.fenToYuan(otherMoneyTotal.add(item.goodsMoney))}</span>
                            <span id={'qita'+key} style={{fontSize:10,marginRight:15}}>{arithUtil.fenToYuan(otherMoneyTotal)}</span>
                        </div>
                        {/* <span id={'shanchu'+key} style={{width:50,color:'#F00',fontSize:11,textAlign:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.wholesaleInsertVoItem.commodities.splice(key,1);
                            if(this.state.wholesaleInsertVoItem.commodities.length)this.setState({editKey:0},()=>{document.getElementById('numberEdit0').focus();this.editRowKey();});
                            else this.setState({editKey:-1});
                        }}>{stringUtil.language(1113)}(Del)</span> */}
                    </div>)
                })}
            </div>
            <div style={{height:40,backgroundColor:'#EEE0D1',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:0,flexGrow:1,color:'#BC7127',fontSize:14,textAlign:'center'}}>{stringUtil.language(1072)}</span>
                <span style={{width:'13%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{this.state.totalInfo.number}</span>
                <span style={{width:'17%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)}</span>
                <span style={{width:'20%',color:'#BC7127',fontSize:14,textAlign:'center'}}></span>
                <span style={{width:'17%',color:'#BC7127',fontSize:14,textAlign:'right'}}>{arithUtil.fenToYuan(this.state.totalInfo.money)}&nbsp;&nbsp;</span>
                {/* <span style={{width:50,color:'#BC7127',fontSize:14,textAlign:'center'}}></span> */}
            </div>
            <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    {this.state.wholesaleInsertVoItem.costs.map((item,key)=>{
                        if (item.costClauseCalMode==0) {//stringUtil.language(868)
                            this.state.wholesaleInsertVoItem.costs[key].money = (this.state.totalInfo.number).mul(item.moneyScal).round();
                        }else if (item.costClauseCalMode==1) {//stringUtil.language(869)
                            this.state.wholesaleInsertVoItem.costs[key].money = (arithUtil.keToWeight(this.state.totalInfo.weight,0)).mul(item.moneyScal).roundDouble();
                        }else if (item.costClauseCalMode==2) {//stringUtil.language(396)
                            this.state.wholesaleInsertVoItem.costs[key].money = (this.state.totalInfo.goodsMoney).mul(item.moneyScal).mul(0.01).round();
                        }else{
                            this.state.wholesaleInsertVoItem.costs[key].money = parseFloat(item.moneyScal).round();
                        }
                        return(<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:10}} key={key}>
                            <span style={{color:'#696969',fontSize:16}}>{stringUtil.subStr(item.costClauseName,5)}:</span>
                            {item.costClauseCalMode==0?<span style={{fontSize:16}}>{arithUtil.fenToYuan(item.money)}</span>:
                            item.costClauseCalMode==1?<span style={{fontSize:16}}>{arithUtil.fenToYuan(item.money)}</span>:
                            item.costClauseCalMode==2?<span style={{fontSize:16}}>{arithUtil.fenToYuan(item.money)}</span>:
                            <span style={{fontSize:16}}>{arithUtil.fenToYuan(item.money)}</span>}
                        </div>)
                    })}
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:10}}>
                    {(()=>{let costsMoneyVal = 0;
                        if (this.state.wholesaleInsertVoItem.costs.length>0) {
                            for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                                let costsItemMoney = this.state.wholesaleInsertVoItem.costs[i].money;
                                costsMoneyVal = costsMoneyVal.add(costsItemMoney);
                            }
                        }
                        this.state.wholesaleInsertVoItem.money = (this.state.totalInfo.goodsMoney).add(this.state.totalInfo.otherMoney).add(this.state.wholesaleInsertVoItem.advanceMoney).add(costsMoneyVal).round();
                        this.state.wholesaleInsertVoItem.billMoney = 0;
                        this.state.wholesaleTotalMoneyTemp = this.state.wholesaleInsertVoItem.money;
                        if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==0)&&this.state.wholesaleInsertVoItem.customerType==1){//临时stringUtil.language(1048)取整
                            let madeAllMoney = arithUtil.getRoundMoney(this.state.wholesaleInsertVoItem.money);
                            if (madeAllMoney!=this.state.wholesaleInsertVoItem.money){//在取整规则范围内
                                this.state.wholesaleInsertVoItem.billMoney = madeAllMoney.sub(this.state.wholesaleInsertVoItem.money,2);
                                this.state.wholesaleTotalMoneyTemp = madeAllMoney;
                            }
                        }
                    })()}
                    <span style={{fontSize:18}}>{stringUtil.language(880)}</span>
                    <span style={{color:'#F00',fontSize:30,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesaleTotalMoneyTemp)}</span>
                </div>
            </div>
            <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{fontSize:16,whiteSpace:'nowrap'}}>{stringUtil.language(1161)}:</span><input id='advance' style={{width:100,height:35,backgroundColor:'#DAE7EF',padding:0,textAlign:'center'}} type='number' onChange={(event)=>{
                        this.state.wholesaleInsertVoItem.advanceMoney = event.target.value?arithUtil.yuanToFen(event.target.value):0;
                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                    }} placeholder='0'/>
                </div>
                <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                    <div style={{width:130,height:35,borderRight:3,backgroundColor:'#148C39',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.submitWholesale();
                    }}><span style={{color:'#FFF',fontSize:18}}>{stringUtil.language(1027)}(End)</span></div>
                    <div style={{width:130,height:35,borderRight:3,backgroundColor:'#AC6217',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:5,cursor:'pointer'}} onClick={()=>{
                        this.props.successEvent(2);
                    }}><span style={{color:'#FFF',fontSize:18}}>{stringUtil.language(1298)}(PgDn)</span></div>
                </div>
            </div>
        </div>)
    }
}

const styles = {}