import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //退菜{stringUtil.language(1037)}
 export default class BackAddWholesaleActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        wholesaleInsertVoItem:paramsPage.wholesaleInfoItemInfo?paramsPage.wholesaleInfoItemInfo:{
            workerId:0,
            totalNumber:0,
            totalWeight:0,
            totalGoodsMoney:0,
            totalOtherMoneyTotal:0,
            advanceMoney:0,
            costs:[],
            commodities:[],
            describe:'',
            money:0
        },
        weightMode:0,//0{stringUtil.language(1023)}，1市{stringUtil.language(1172)}
        currWholesaleCommodityVo:{},//当前正在{stringUtil.language(1092)}的待{stringUtil.language(1037)}{stringUtil.language(1063)}
        saleWorkers:[],
        wholesaleCostsAll:[],
        costsShowPop:false,
        cargoShowPop:false,
        cargoNoShowPop:false,
        editVegetableShowPop:false,
        selectDiglogTagEntCost:false,
        currWholesaleBackVO:{costs:[],commodities:[]},
        cargoBackVOAll:[],
        currCargoBackVOAll:{},
        currBatchCommodityItem:{},
        warehousesAll:[],
        keyTagVal:-1,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_WHOLESALE_WORKER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.saleWorkers=successResponse.data;
                this.state.saleWorkers.unshift({id:0,noteName:stringUtil.language(431)});
                this.setState({saleWorkers:this.state.saleWorkers});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll = successResponse.data;
                this.setState({warehousesAll:this.state.warehousesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        this.state.wholesaleInsertVoItem.totalNumber = 0;
        this.state.wholesaleInsertVoItem.totalWeight = 0;
        this.state.wholesaleInsertVoItem.totalGoodsMoney = 0;
        this.state.wholesaleInsertVoItem.totalOtherMoneyTotal = 0;
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <div style={{height:'6%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',backgroundColor:'#ECECEC'}}>
                <TabTopActivity viewHeight='100%' viewWidth='50%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
                <select style={{fontSize:12,borderWidth:0}} onChange={(event)=>{
                    }}>{this.state.warehousesAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} selected={localStorage.getItem("currentWarehouseItem")!=null&&(JSON.parse(localStorage.getItem("currentWarehouseItem"))).id==item.id?'selected':''} key={key}>{stringUtil.subStr(item.name,10)}</option>)
                    })}
                </select>
                <select style={{fontSize:12,borderWidth:0}} onChange={(event)=>{
                    }}>{this.state.saleWorkers.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} selected={localStorage.getItem("currentStallItem")!=null&&JSON.parse(localStorage.getItem("currentStallItem")).workerId==item.id?'selected':''} key={key}>{stringUtil.subStr(item.noteName,5)}</option>)
                    })}
                </select>
            </div>
            <div style={{width:'100%',height:'94%',display:'flex',flexDirection:'row',backgroundColor:'#FFF'}}>
                <div style={{width:'40%',backgroundColor:'#FFF',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                    <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:20,fontWeight:'bold'}}>{this.state.wholesaleInsertVoItem.customerName}</span>
                            <span style={{color:'#F00',fontSize:12}}>  {this.state.wholesaleInsertVoItem.customerWarning!=0?stringUtil.language(1066)+arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.customerQuota)+'，'+stringUtil.language(1164)+arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.customerArrears):''}</span>
                        </div>
                    </div>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{width:'100%',height:'5%'})}>
                        <div style={{width:'90%',height:'100%',display:'flex',flexDirection:'row'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'32%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(518)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1165)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'19%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1022)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1050)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1068)}</span>
                        </div>
                        <div style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',color:'#A9A9A9'})}>{stringUtil.language(1069)}</div>
                    </div>
                    <div style={{width:'100%',height:'75%',overflow:'auto'}}>
                        {this.state.wholesaleInsertVoItem.commodities!=null?this.state.wholesaleInsertVoItem.commodities.map((item,key)=>{
                            let otherMoneyTotal = 0;
                            for(let i=0;i<item.costs.length;i++){
                                otherMoneyTotal = otherMoneyTotal.add(item.costs[i].money);
                            }
                            this.state.wholesaleInsertVoItem.totalNumber = (this.state.wholesaleInsertVoItem.totalNumber).add(item.number);
                            this.state.wholesaleInsertVoItem.totalWeight = (this.state.wholesaleInsertVoItem.totalWeight).add(item.weight);
                            this.state.wholesaleInsertVoItem.totalGoodsMoney = (this.state.wholesaleInsertVoItem.totalGoodsMoney).add(item.goodsMoney);
                            this.state.wholesaleInsertVoItem.totalOtherMoneyTotal = (this.state.wholesaleInsertVoItem.totalOtherMoneyTotal).add(otherMoneyTotal);
                            return(<div style={{width:'100%',height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',cursor:'pointer'}} key={key}>
                                <div style={{width:'90%',height:'100%'}} onClick={()=>{
                                    this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(item));
                                    stringUtil.httpRequest(urlUtil.SALE_CARGO_BACK_URL,{
                                        cargoMode:this.state.currWholesaleCommodityVo.targetType,
                                        targetId:this.state.currWholesaleCommodityVo.targetId,
                                        commodityId:this.state.currWholesaleCommodityVo.commodityId
                                    },['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.cargoBackVOAll = successResponse.data;
                                            if(this.state.cargoBackVOAll.length>0){//此stringUtil.language(1049)有stringUtil.language(434)
                                                this.setState({cargoShowPop:!this.state.cargoShowPop});
                                            }else{
                                                this.setState({cargoNoShowPop:!this.state.cargoNoShowPop});
                                            }
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }}>
                                    <div style={{width:'100%',height:arithUtil.pxToDp(25),display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'32%',fontSize:14,fontWeight:'bold',justifyContent:'flex-start',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'})}>{key+1}.{item.commodityCode?item.commodityCode:''}{item.commodityName}</span>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',fontSize:14,fontWeight:'bold'})}>{item.number}</span>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'19%',fontSize:14,fontWeight:'bold'})}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                        {item.mode==0?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%'})}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)}</span>:
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%'})}>{arithUtil.fenToYuan(item.unitPrice,2)}</span>}
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',fontSize:14,fontWeight:'bold'})}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                                    </div>
                                    <div style={{width:'100%',height:arithUtil.pxToDp(15),display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'47%',color:'#52A056',fontSize:10,justifyContent:'flex-start'})}>{item.targetType==1?stringUtil.language(426)+(item.targetId!=0?item.targetId:''):stringUtil.subStr(item.targetName,5)}/{item.cargoNo}</span>
                                        {item.mode==0?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'57%',color:'#52A056',fontSize:10,justifyContent:'flex-start'})}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>:
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'53%',color:'#52A056',fontSize:10,justifyContent:'flex-start'})}>{item.number+'X'+arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>}
                                    </div>
                                </div>
                                <div style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',cursor:'pointer'})} onClick={()=>{
                                    this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(item));
                                    this.setState({costsShowPop:true});
                                }}>{arithUtil.fenToYuan(otherMoneyTotal)}</div>
                            </div>)
                        }):''}
                    </div>
                    <div style={{width:'100%',height:'12%'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'30%',color:'#D2855D',fontWeight:'bold',textAlign:'left'})}>{stringUtil.language(890)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13%',color:'#D2855D',fontWeight:'bold'})}>{this.state.wholesaleInsertVoItem.totalNumber}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#D2855D',fontWeight:'bold'})}>{arithUtil.keToWeight(this.state.wholesaleInsertVoItem.totalWeight,this.state.weightMode)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#D2855D',fontWeight:'bold'})}></span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',color:'#D2855D',fontWeight:'bold'})}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.totalGoodsMoney)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#D2855D',fontWeight:'bold'})}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.totalOtherMoneyTotal)}</span>
                        </div>
                        <span style={{fontSize:10}}>{stringUtil.language(858)}{this.state.wholesaleInsertVoItem.describe}</span>
                    </div>
                </div>
                {/* 右边 */}
                <div style={{width:'60%',backgroundColor:'#F5F3F3',paddingLeft:10,paddingRight:10,position:'relative'}}>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'})}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'30%',color:'#F00',textAlign:'center'})}>{stringUtil.language(1163)}{stringUtil.language(1020)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#F00',textAlign:'center'})}>{stringUtil.language(1163)}{stringUtil.language(1021)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#F00',textAlign:'center'})}>{stringUtil.language(1163)}{stringUtil.language(1022)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#F00',textAlign:'center'})}>{stringUtil.language(1163)}{stringUtil.language(1041)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'8%',color:'#F00',textAlign:'center'})}>{stringUtil.language(891)}</span>
                    </div>
                    {(()=>{this.state.backTotalInfo = {number:0,weight:0,goodsMoney:0};})()}
                    <div style={{width:'100%',position:'absolute',top:'5%',bottom:120,overflow:'auto'}}>
                        {this.state.currWholesaleBackVO.commodities.map((item,key)=>{
                            this.state.backTotalInfo = {
                                number:(this.state.backTotalInfo.number).add(item.number),
                                weight:(this.state.backTotalInfo.weight).add(item.weight),
                                goodsMoney:(this.state.backTotalInfo.goodsMoney).add(item.goodsMoney)
                            };
                            return(<div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                                <div style={arithUtil.stylesAdd(styles.tableTd,{width:'30%',flexDirection:'column',alignItems:'flex-start'})}>
                                    <span style={{fontSize:14,fontWeight:'bold',textAlign:'left'}}>{key+1}.{item.commodityCode?item.commodityCode:''}{item.commodityName}</span>
                                    <span style={{color:'#52A056',fontSize:10,textAlign:'left'}}>{item.targetType==1?stringUtil.language(426)+(item.targetId!=0?item.targetId:''):stringUtil.subStr(item.targetName,7)}/{item.cargoNo}</span>
                                </div>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:'15%'})} type='number' defaultValue={item.number!=0?item.number:''} placeholder='0' onChange={(event)=>{
                                    this.state.currWholesaleBackVO.commodities[key].number = parseFloat(event.target.value);
                                    this.state.currWholesaleBackVO.commodities[key].weight = (item.avgWeightNumber).mul(event.target.value);
                                    if(this.state.currWholesaleBackVO.commodities[key].mode==0){
                                        this.state.currWholesaleBackVO.commodities[key].goodsMoney = (item.unitPrice).mul(arithUtil.keToWeight((item.avgWeightNumber).mul(event.target.value),0)).round();
                                    }else{
                                        this.state.currWholesaleBackVO.commodities[key].goodsMoney = (item.unitPrice).mul(event.target.value).round();
                                    }
                                    this.setState({currWholesaleBackVO:this.state.currWholesaleBackVO});
                                }}/>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:'18%'})} type='number' defaultValue={item.weight!=0?arithUtil.keToWeight(item.weight,this.state.weightMode):''} placeholder='0' onChange={(event)=>{
                                    this.state.currWholesaleBackVO.commodities[key].weight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                    if(this.state.currWholesaleBackVO.commodities[key].mode==0){
                                        this.state.currWholesaleBackVO.commodities[key].goodsMoney = (item.unitPrice).mul(this.state.weightMode==0?(event.target.value):(parseFloat(event.target.value)).div(2)).round();
                                    }
                                    this.setState({currWholesaleBackVO:this.state.currWholesaleBackVO});
                                }}/>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:'18%'})} type='number' defaultValue={item.goodsMoney!=0?arithUtil.fenToYuan(item.goodsMoney):''} placeholder='0' onChange={(event)=>{
                                    this.state.currWholesaleBackVO.commodities[key].goodsMoney = arithUtil.yuanToFen(event.target.value).round();
                                    this.setState({currWholesaleBackVO:this.state.currWholesaleBackVO});
                                }}/>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:'8%'})} placeholder={stringUtil.language(1156)} onChange={(event)=>{
                                    this.state.currWholesaleBackVO.commodities[key].buyerCode=event.target.value;
                                }}/>
                                <i className="iconfontIndexCss" style={{width:'9%',height:'100%',fontSize:15,color:'#F00',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currWholesaleBackVO.commodities.splice(key, 1);
                                    this.setState({currWholesaleBackVO:this.state.currWholesaleBackVO});
                                }}>&#xe612;</i>
                            </div>)
                        })}
                    </div>
                    <div style={{width:'100%',height:120,position:'absolute',bottom:0}}>
                        <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <span style={{color:'#D2855D',fontWeight:'bold'}}>{stringUtil.language(1072)}{stringUtil.language(1163)}：{this.state.backTotalInfo.number} {stringUtil.language(1173)}，{arithUtil.keToWeight(this.state.backTotalInfo.weight,this.state.weightMode)} {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}，{arithUtil.fenToYuan(this.state.backTotalInfo.goodsMoney)} {stringUtil.currency(1)}</span>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                                if(this.state.isBackCommodity==1)this.setState({isBackCommodity:0});
                                else this.setState({isBackCommodity:1});
                            }}>{this.state.isBackCommodity==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; 退款不{stringUtil.language(1163)}</i>:
                            <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; 退款{stringUtil.language(1163)}</i>}</div>
                        </div>
                        <div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:12}}>{stringUtil.language(858)}</span>
                            <input style={arithUtil.stylesAdd(styles.textInput,{width:'85%',height:arithUtil.pxToDp(25),fontSize:10,padding:0,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',textAlign:'left'})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                                this.state.currWholesaleBackVO.describe = event.target.value;
                            }}/>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:arithUtil.pxToDp(10)}}>
                            <span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#2B8D53',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    if (this.state.currWholesaleBackVO.commodities.length==0) {
                                        ToastBox.error(stringUtil.language(190));return;
                                    }
                                    let wholesaleBackVO = {workerId:0,costs:[],commodities:[]};
                                    if(this.state.wholesaleInsertVoItem.cargoId)wholesaleBackVO.cargoId = this.state.wholesaleInsertVoItem.cargoId;
                                    wholesaleBackVO.workerId = this.state.wholesaleInsertVoItem.workerId==null?0:(this.state.wholesaleInsertVoItem.workerId==0?-1:this.state.wholesaleInsertVoItem.workerId);
                                    let totalGoodsMoney = 0;
                                    for(let i=0;i<this.state.currWholesaleBackVO.commodities.length;i++){
                                        let itemBackCom = this.state.currWholesaleBackVO.commodities[i];
                                        totalGoodsMoney = totalGoodsMoney.add(itemBackCom.goodsMoney);
                                        let commodityTemp = {costs:[]};
                                        commodityTemp.cargoId = itemBackCom.cargoId;
                                        commodityTemp.buyerCode = itemBackCom.buyerCode;
                                        commodityTemp.targetType = itemBackCom.targetType;
                                        commodityTemp.targetId = itemBackCom.targetId;
                                        commodityTemp.commodityId = itemBackCom.commodityId;
                                        if(itemBackCom.cargoCommodityId!=null) commodityTemp.cargoCommodityId = itemBackCom.cargoCommodityId;
                                        commodityTemp.standard = itemBackCom.standard;
                                        commodityTemp.number = (itemBackCom.number).mul(-1);
                                        commodityTemp.totalWeight = (itemBackCom.weight).mul(-1);
                                        commodityTemp.skinWeight = 0;
                                        commodityTemp.reduceWeight = 0;
                                        commodityTemp.weight = (itemBackCom.weight).mul(-1);
                                        commodityTemp.unitPrice = itemBackCom.unitPrice;
                                        commodityTemp.mode = itemBackCom.mode;
                                        commodityTemp.goodsMoney = (itemBackCom.goodsMoney).mul(-1);
                                        // if (itemBackCom.costs==null)itemBackCom.costs=[];
                                        // for(let j=0;j<itemBackCom.costs.length;j++){
                                        //     let costsSource = JSON.parse(JSON.stringify(itemBackCom.costs[j]));
                                        //     if (costsSource.calMode==0) {//stringUtil.language(868)
                                        //         costsSource.money = (commodityTemp.number).mul(costsSource.money).round();
                                        //     }else if (costsSource.calMode==1) {//stringUtil.language(869)
                                        //         costsSource.money = (arithUtil.keToWeight(commodityTemp.weight,0)).mul(costsSource.money).round();
                                        //     }else if (costsSource.calMode==2) {//stringUtil.language(396)
                                        //         costsSource.money = (commodityTemp.goodsMoney).mul(costsSource.money).mul(0.01).round();
                                        //     }else{
                                        //         costsSource.money = parseFloat(costsSource.money).round();
                                        //     }
                                        //     commodityTemp.costs.push({
                                        //         costClauseId:costsSource.costClauseId,
                                        //         money:costsSource.money!=null?costsSource.money:0,
                                        //     });
                                        // }
                                        commodityTemp.costs = [];//只退{stringUtil.language(1068)}
                                        if(commodityTemp.cargoCommodityId==null){
                                            commodityTemp.cargoBackCommodityVO = {
                                                commodityId:itemBackCom.cargoBackCommodityVO.commodityId,
                                                standard:itemBackCom.cargoBackCommodityVO.standard,
                                                reduce:itemBackCom.cargoBackCommodityVO.reduce,
                                                costs:[]
                                            }
                                            if(itemBackCom.cargoBackCommodityVO.costs!=null){for(let j=0;j<itemBackCom.cargoBackCommodityVO.costs.length;j++){
                                                commodityTemp.cargoBackCommodityVO.costs.push({
                                                    costClauseId:itemBackCom.cargoBackCommodityVO.costs[j].costClauseId,
                                                    money:itemBackCom.cargoBackCommodityVO.costs[j].money
                                                });
                                            }}
                                        }
                                        wholesaleBackVO.commodities.push(commodityTemp);
                                    }
                                    wholesaleBackVO.customerId = this.state.wholesaleInsertVoItem.customerId;
                                    wholesaleBackVO.isBackCommodity = this.state.isBackCommodity!=null?this.state.isBackCommodity:0;
                                    wholesaleBackVO.advanceMoney = 0;
                                    wholesaleBackVO.money = totalGoodsMoney.mul(-1);
                                    wholesaleBackVO.describe = this.state.currWholesaleBackVO.describe?this.state.currWholesaleBackVO.describe:'';
                                    wholesaleBackVO.costs = [];
                                    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_BACK_URL,wholesaleBackVO,['json','post',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            ToastBox.success(stringUtil.language(388));
                                            this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }}>退 货</span>
                            <span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#808080',lineHeight:'30px',borderRadius:3,backgroundColor:'#DCDCDC',marginLeft:arithUtil.pxToDp(10),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                }}>{stringUtil.language(860)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <MaskModal visible={this.state.cargoNoShowPop} title={stringUtil.language(1129)+'（'+(this.state.currWholesaleCommodityVo.targetType==1?stringUtil.language(426)+(this.state.currWholesaleCommodityVo.targetId!=0?this.state.currWholesaleCommodityVo.targetId:''):stringUtil.subStr(this.state.currWholesaleCommodityVo.targetName,6))+'）的'+stringUtil.language(1084)} viewWidth={arithUtil.pxToDp(400)} height={arithUtil.ProportionTo(document.body.clientHeight,0.4)}
              content={<div style={{paddingLeft:arithUtil.pxToDp(10)}}>
                    <span style={{height:arithUtil.pxToDp(40),color:'#F00',textAlignVertical:'center'}}>此{stringUtil.language(1049)}暂时没有{stringUtil.language(434)}！请{stringUtil.language(1129)}：</span>
                    <div style={{height:arithUtil.pxToDp(50),display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(944)}：<input style={{width:150,height:'100%',padding:0,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',textAlign:'left'}} type='number' placeholder={stringUtil.language(87)} onChange={(event)=>{
                            this.state.addCargoItem.cargoNo = event.target.value;
                        }}/>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.addCargoItem.cargoNo==null || this.state.addCargoItem.cargoNo=='') {
                        ToastBox.error(stringUtil.language(136));return;
                    }
                    this.setState({ cargoNoShowPop: false, });
                    let cargoInsertVo = {commodities:[]};
                    cargoInsertVo.cargoMode = this.state.currWholesaleCommodityVo.targetType;
                    cargoInsertVo.targetId = this.state.currWholesaleCommodityVo.targetId;
                    cargoInsertVo.cargoNo = this.state.addCargoItem.cargoNo;
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_URL,cargoInsertVo,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            stringUtil.httpRequest(urlUtil.SALE_CARGO_BACK_URL,{
                                cargoMode:this.state.currWholesaleCommodityVo.targetType,
                                targetId:this.state.currWholesaleCommodityVo.targetId,
                                commodityId:this.state.currWholesaleCommodityVo.commodityId
                            },['key','get',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    this.state.cargoBackVOAll = successResponse.data;
                                    if(this.state.cargoBackVOAll.length>0){//此stringUtil.language(1049)有stringUtil.language(434)
                                        this.setState({cargoShowPop:!this.state.cargoShowPop});
                                    }
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({ cargoNoShowPop: false, });}}/>
            <MaskModal visible={this.state.cargoShowPop} title={'选择（'+(this.state.currWholesaleCommodityVo.targetType==1?stringUtil.language(426)+(this.state.currWholesaleCommodityVo.targetId!=0?this.state.currWholesaleCommodityVo.targetId:''):stringUtil.subStr(this.state.currWholesaleCommodityVo.targetName,6))+'）的'+stringUtil.language(1084)} width={arithUtil.pxToDp(400)} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
              content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),overflow:'auto'}}>
                    {this.state.cargoBackVOAll.map((item,key)=>{
                        let cargoBackVOCommodityAll = item.commodities;
                        if(cargoBackVOCommodityAll.length>0){
                            return(<div key={key}>
                                {cargoBackVOCommodityAll.map((items,keys)=>{
                                return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={keys} onClick={()=>{
                                    this.setState({ cargoShowPop: false });
                                    let currWholesaleCommodityItem = this.state.currWholesaleCommodityVo;
                                    let otherMoneyTotal = 0;
                                    for(let i=0;i<currWholesaleCommodityItem.costs.length;i++){
                                        otherMoneyTotal = otherMoneyTotal.add(currWholesaleCommodityItem.costs[i].money);
                                    }
                                    let avgNumberPrice=0,avgWeightPrice=0,avgWeightNumber=0;
                                    if(currWholesaleCommodityItem.number!=0){
                                        avgNumberPrice = ((currWholesaleCommodityItem.goodsMoney).add(otherMoneyTotal)).div(currWholesaleCommodityItem.number);
                                        avgWeightNumber = (currWholesaleCommodityItem.weight).div(currWholesaleCommodityItem.number);
                                    }
                                    if(currWholesaleCommodityItem.weight!=0) avgWeightPrice = ((currWholesaleCommodityItem.goodsMoney).add(otherMoneyTotal)).div(arithUtil.keToWeight(currWholesaleCommodityItem.weight,0));
                                    this.state.currWholesaleBackVO.commodities.push({
                                        cargoId:item.id,
                                        targetType:currWholesaleCommodityItem.targetType,//0stringUtil.language(1049)，1stringUtil.language(1030)，2stringUtil.language(1031)
                                        targetId:currWholesaleCommodityItem.targetId,//!=0线路
                                        commodityId:currWholesaleCommodityItem.commodityId,
                                        cargoCommodityId:items.cargoCommodityId,//stringUtil.language(1084){stringUtil.language(1063)}{stringUtil.language(1155)} 没有为空
                                        standard:currWholesaleCommodityItem.standard,
                                        number:0,
                                        totalWeight:0,
                                        skinWeight:0,
                                        reduceWeight:0,
                                        weight:0,
                                        unitPrice:currWholesaleCommodityItem.mode==0?avgWeightPrice:avgNumberPrice,
                                        mode:currWholesaleCommodityItem.mode,//0{stringUtil.language(1114)}，1{stringUtil.language(1115)}
                                        goodsMoney:0,
                                        costs:currWholesaleCommodityItem.commodityCosts,
                                        commodityName:currWholesaleCommodityItem.commodityName,
                                        commodityCode:currWholesaleCommodityItem.commodityCode,
                                        commoditySpecs:currWholesaleCommodityItem.commoditySpecs,
                                        targetName:currWholesaleCommodityItem.targetName,
                                        cargoNo:item.cargoNo,
                                        avgWeightNumber:avgWeightNumber
                                    });
                                    this.setState({currWholesaleBackVO:this.state.currWholesaleBackVO});
                                }}>
                                    <span style={{width:'40%',color:'#1C8F3E'}}>{item.cargoNo}</span>
                                    <span>{this.state.currWholesaleCommodityVo.commodityCode?this.state.currWholesaleCommodityVo.commodityCode:''}{this.state.currWholesaleCommodityVo.commodityName}（{items.number+(items.unitMaster?items.unitMaster:stringUtil.language(1173))}）</span>
                                </div>)
                            })}
                            </div>);
                        }else{
                            return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                this.setState({ cargoShowPop: false });
                                let currWholesaleCommodityItem = this.state.currWholesaleCommodityVo;
                                let isHaveCommodity = false;
                                if(this.state.shopCommoditysAll==null){
                                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL,null,['key','get',1],(successResponse)=>{//sssssssssssssssssssssssssssssssssssssssss
                                        if (successResponse.status==200) {
                                            this.state.shopCommoditysAll = successResponse.data;
                                            for(let i=0;i<this.state.shopCommoditysAll.length;i++){
                                                let commodityItem = this.state.shopCommoditysAll[i];
                                                if((commodityItem.targetId==currWholesaleCommodityItem.targetId) && (commodityItem.id==currWholesaleCommodityItem.commodityId)) {
                                                    this.state.currBatchCommodityItem = commodityItem;
                                                    this.state.currCargoBackVOAll = item;
                                                    isHaveCommodity = true;
                                                    this.setState({editVegetableShowPop:!this.state.editVegetableShowPop});
                                                }
                                            }
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{
                                    for(let i=0;i<this.state.shopCommoditysAll.length;i++){
                                        let commodityItem = this.state.shopCommoditysAll[i];
                                        if((commodityItem.targetId==currWholesaleCommodityItem.targetId) && (commodityItem.id==currWholesaleCommodityItem.commodityId)) {
                                            this.state.currBatchCommodityItem = commodityItem;
                                            this.state.currCargoBackVOAll = item;
                                            isHaveCommodity = true;
                                            this.setState({editVegetableShowPop:!this.state.editVegetableShowPop});
                                        }
                                    }
                                }
                                if(!isHaveCommodity){ToastBox.error(stringUtil.language(1063)+'库无此'+stringUtil.language(1049)+stringUtil.language(1063)+'！');}
                            }}>
                                <span style={{width:'40%',color:'#1C8F3E'}}>{item.cargoNo}</span>
                                <span style={{color:'#F00'}}>{stringUtil.language(1224)}{this.state.currWholesaleCommodityVo.commodityCode?this.state.currWholesaleCommodityVo.commodityCode:''}{this.state.currWholesaleCommodityVo.commodityName}</span>
                            </div>);
                        }
                    })}
                </div>}closeBnTopEvents={() => {this.setState({ cargoShowPop: false, });}}/>
            <MaskModal title={stringUtil.language(191)+this.state.currBatchCommodityItem.name} visible={this.state.editVegetableShowPop} width={arithUtil.pxToDp(500)} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                        <div style={{height:'18%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                {stringUtil.language(863)}<input style={arithUtil.stylesAdd(styles.textInput,{width:100})} type='number' placeholder='0' onChange={(event)=>{
                                    this.state.currBatchCommodityItem.standard = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                    this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem})
                                }}/>&nbsp;{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                {stringUtil.language(864)}<input style={arithUtil.stylesAdd(styles.textInput,{width:100})} type='number' placeholder='0' onChange={(event)=>{
                                    this.state.currBatchCommodityItem.reduce = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                    this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem})
                                }}/>&nbsp;{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}
                            </div>
                        </div>
                        <div style={{height:'14%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                            <span>{stringUtil.language(89)}</span>
                            <i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                                if (this.state.wholesaleCostsAll.length==0) {
                                    stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:1},['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.wholesaleCostsAll = successResponse.data;
                                            this.setState({selectDiglogTagEntCost:!this.state.selectDiglogTagEntCost});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{this.setState({selectDiglogTagEntCost:!this.state.selectDiglogTagEntCost});}
                            }}>&#xe601;</i>
                        </div>
                        <div style={{height:'50%',overflow:'auto'}}>
                            {this.state.currBatchCommodityItem.costs!=null?this.state.currBatchCommodityItem.costs.map((item,key)=>{
                                return(<div key={key} style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                                    <span>{key+1+'、'+item.costClauseName}</span>
                                    <span>{item.costClauseCalMode==0?stringUtil.language(868):item.costClauseCalMode==1?stringUtil.language(869):item.costClauseCalMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                    <span>{(item.costClausePayee==0?stringUtil.language(1049):item.costClausePayee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                    {item.costClauseCalMode==0?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:60})} type='number' placeholder='0' onChange={(event)=>{
                                            this.state.currBatchCommodityItem.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                            this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem});
                                        }}/>&nbsp;
                                        <span style={{color:'#D2855D'}}>{stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                    item.costClauseCalMode==1?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:60})} type='number' placeholder='0' onChange={(event)=>{
                                            this.state.currBatchCommodityItem.costs[key].money = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                            this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem});
                                        }}/>&nbsp;
                                        <span style={{color:'#D2855D'}}>{stringUtil.currency(1)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span></div>:
                                    item.costClauseCalMode==2?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:60})} type='number' placeholder='0' onChange={(event)=>{
                                            this.state.currBatchCommodityItem.costs[key].money = parseFloat(event.target.value);
                                            this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem});
                                        }}/>&nbsp;
                                        <span style={{color:'#D2855D'}}>%</span></div>:
                                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:60})} type='number' placeholder='0' onChange={(event)=>{
                                            this.state.currBatchCommodityItem.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                            this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem});
                                        }}/></div>}
                                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',cursor:'pointer'}} onClick={()=>{
                                        this.state.currBatchCommodityItem.costs.splice(key,1);
                                        this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem})
                                    }}>&#xe612;</i>
                                </div>)
                            }):''}
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    // this.state.currBatchItem.commodities[this.state.currBatchCommodityIndex] = JSON.parse(JSON.stringify(this.state.currBatchCommodityItem));
                    let currWholesaleCommodityItem = this.state.currWholesaleCommodityVo;
                    let otherMoneyTotal = 0;
                    for(let i=0;i<currWholesaleCommodityItem.costs.length;i++){
                        otherMoneyTotal = otherMoneyTotal.add(currWholesaleCommodityItem.costs[i].money);
                    }
                    let avgNumberPrice=0,avgWeightPrice=0,avgWeightNumber=0;
                    if(currWholesaleCommodityItem.number!=0){
                        avgNumberPrice = ((currWholesaleCommodityItem.goodsMoney).add(otherMoneyTotal)).div(currWholesaleCommodityItem.number);
                        avgWeightNumber = (currWholesaleCommodityItem.weight).div(currWholesaleCommodityItem.number);
                    }
                    if(currWholesaleCommodityItem.weight!=0) avgWeightPrice = ((currWholesaleCommodityItem.goodsMoney).add(otherMoneyTotal)).div(arithUtil.keToWeight(currWholesaleCommodityItem.weight,0));
                    this.state.currWholesaleBackVO.commodities.push({
                        cargoId:this.state.currCargoBackVOAll.id,
                        targetType:currWholesaleCommodityItem.targetType,//0stringUtil.language(1049)，1stringUtil.language(1030)，2stringUtil.language(1031)
                        targetId:currWholesaleCommodityItem.targetId,//!=0线路
                        commodityId:currWholesaleCommodityItem.commodityId,
                        cargoCommodityId:null,//stringUtil.language(1084){stringUtil.language(1063)}{stringUtil.language(1155)} 没有为空
                        standard:currWholesaleCommodityItem.standard,
                        number:0,
                        totalWeight:0,
                        skinWeight:0,
                        reduceWeight:0,
                        weight:0,
                        unitPrice:currWholesaleCommodityItem.mode==0?avgWeightPrice:avgNumberPrice,
                        mode:currWholesaleCommodityItem.mode,//0{stringUtil.language(1114)}，1{stringUtil.language(1115)}
                        goodsMoney:0,
                        costs:currWholesaleCommodityItem.commodityCosts,
                        commodityName:currWholesaleCommodityItem.commodityName,
                        commodityCode:currWholesaleCommodityItem.commodityCode,
                        commoditySpecs:currWholesaleCommodityItem.commoditySpecs,
                        targetName:currWholesaleCommodityItem.targetName,
                        cargoNo:this.state.currCargoBackVOAll.cargoNo,
                        avgWeightNumber:avgWeightNumber,
                        cargoBackCommodityVO:{
                            commodityId:currWholesaleCommodityItem.commodityId,
                            standard:this.state.currBatchCommodityItem.standard,
                            reduce:this.state.currBatchCommodityItem.reduce,
                            costs:this.state.currBatchCommodityItem.costs,
                        }
                    });
                    this.setState({editVegetableShowPop: false});
                }} closeBnEvents={() => {this.setState({ editVegetableShowPop: false, })}}/>
            <MaskModal title={stringUtil.language(33)} visible={this.state.selectDiglogTagEntCost} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                        {(this.state.wholesaleCostsAll?this.state.wholesaleCostsAll:[]).map((item,key)=>{
                        if (this.state.currShopCommodityItem.targetType==0 || (this.state.currShopCommodityItem.targetType!=0&&item.payee!=0)) {
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} key={key} onClick={()=>{
                                this.state.currBatchCommodityItem.costs.push({
                                    costClauseId:item.id,
                                    costClauseName:item.name,
                                    costClausePayee:item.payee,
                                    costClauseCalMode:item.calMode,
                                    money:0
                                });
                                this.setState({selectDiglogTagEntCost:false});
                            }}>
                                <span>{item.name}</span>
                                <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                                <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                            </div>);
                        }
                    })}
                </div>}closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCost:false});}}/>
            <MaskModal title={stringUtil.language(280)} visible={this.state.costsShowPop} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
              content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                    {this.state.currWholesaleCommodityVo.costs!=null?this.state.currWholesaleCommodityVo.costs.map((item,key)=>{
                        return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                            <span>{item.costClauseName}</span>
                            <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                            <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                            <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}</span>
                        </div>)
                    }):''}
                </div>}closeBnTopEvents={() => {this.setState({ costsShowPop: false, });}}/>
        </div>)
    }
}

const styles = {
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'15%',
        fontSize:12,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'right'
    },
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:30,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
};