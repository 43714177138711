import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as printUtil from '../utils/printUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

//暂时未用到
export default class WholesaleUpdatessActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        regIndex:-1,//-1不操作任何区域，0客户，1选菜，2已选菜，3三轮区域
        wholesaleInsertVoItem:paramsPage.wholesaleInfoItemInfo,
        editKey:-1,
        workerHabitInfo:{},
        customerItemAll:[],
        warehouseCommodityItemAll:[],
        warehouseCommodityItemData:[],
        keySearch:'',
        keyTagVal:-1,
      };
      this.initDatas();
    }
    initDatas(){
        for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
            let commoditiesData = this.state.wholesaleInsertVoItem.commodities[i];
            for(let j=0;j<commoditiesData.costs.length;j++){
                for(let k=0;k<commoditiesData.commodityCosts.length;k++){
                    if (commoditiesData.costs[j].costClauseId==commoditiesData.commodityCosts[k].costClauseId) {
                        this.state.wholesaleInsertVoItem.commodities[i].costs[j].moneyScal = commoditiesData.commodityCosts[k].money;
                    }
                }
            }
        }
        for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                let costsItem = this.state.wholesaleInsertVoItem.costs[i];
                this.state.wholesaleInsertVoItem.costs[i] = {
                    costClauseCalMode:costsItem.calMode,
                    costClauseId:costsItem.costClauseId,
                    costClauseName:costsItem.costClauseName,
                    costClausePayee:costsItem.payee,
                    moneyScal:0,
                    money:costsItem.money
                };
        }
        this.state.isPrintTag=this.state.wholesaleInsertVoItem.customerType==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workerHabitInfo = successResponse.data;
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initCustomers();
        stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehouseCommodityItemAll = successResponse.data;
                this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SALE_COST_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let resultVal = JSON.parse(JSON.stringify(successResponse.data));
                resultVal.forEach((item, index) => {
                    for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                        if (this.state.wholesaleInsertVoItem.costs[i].costClauseId==item.costClauseId) {
                            this.state.wholesaleInsertVoItem.costs[i].moneyScal = item.money;
                        }
                    }
                })
                this.setState({wholesaleInsertVoItem: this.state.wholesaleInsertVoItem});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        document.onkeydown=(e)=>{
            console.log('按键信息：',e,this.state.regIndex)
            if(e.keyCode===17){//ctrl
                this.state.regIndex=0;
                this.setState({customerItemDatas:this.state.customerItemAll},()=>{
                    document.getElementById('customerSearch').value='';
                    document.getElementById('customerSearch').focus();
                });
            }else if(e.keyCode===13){//Ent
                if(!this.state.regIndex){//客户
                    let currCustomerItem = JSON.parse(JSON.stringify((this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll)[this.state.upDnKey?this.state.upDnKey:0]));
                    this.state.wholesaleInsertVoItem.customerId = currCustomerItem.id;
                    this.state.wholesaleInsertVoItem.customerName = currCustomerItem.noteName;
                    this.state.wholesaleInsertVoItem.customerType = currCustomerItem.type;
                    this.state.wholesaleInsertVoItem.customerWarning = currCustomerItem.warning;
                    this.state.wholesaleInsertVoItem.customerQuota = currCustomerItem.quota;
                    this.state.wholesaleInsertVoItem.customerArrears = currCustomerItem.arrears;
                    this.state.isPrintTag=this.state.wholesaleInsertVoItem.customerType==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                    this.setState({upDnKey:0,regIndex:-1});
                }else if(this.state.regIndex==1){//选菜
                    this.state.upDnKey=0;
                    if(!this.state.warehouseCommodityItemData.length){
                        ToastBox.error(stringUtil.language(1269));return;
                    }else if(this.state.warehouseCommodityItemData.length==1){
                        this.state.currChoicedCommodity = JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[this.state.upDnKey]));
                        for(let i=0;i<this.state.currChoicedCommodity.costs.length;i++){
                            this.state.currChoicedCommodity.costs[i].moneyScal = this.state.currChoicedCommodity.costs[i].money;
                            this.state.currChoicedCommodity.costs[i].money = 0;
                        }
                    }
                    if(this.state.currChoicedCommodity){this.state.choiceCommoditysShow=false;this.keySelectCommodity();
                    }else if(this.state.warehouseCommodityItemData.length!=1&&!this.state.currChoicedCommodity){
                        this.setState({choiceCommoditysShow:true},()=>{
                            this.state.currChoicedCommodity = JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[this.state.upDnKey]));
                            for(let i=0;i<this.state.currChoicedCommodity.costs.length;i++){
                                this.state.currChoicedCommodity.costs[i].moneyScal = this.state.currChoicedCommodity.costs[i].money;
                                this.state.currChoicedCommodity.costs[i].money = 0;
                            }
                        });
                    }
                }else{if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                    this.state.regIndex=2;
                    if(this.state.editKey!=-1){
                        if(document.getElementById('numberEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('totalWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('totalWeightEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('skinWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('skinWeightEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('unitPriceEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('ownerUnitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('ownerUnitPriceEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('describeEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('describeEdit'+this.state.editKey)==document.activeElement){
                            this.state.editKey=this.state.editKey+1;
                            if(this.state.editKey<this.state.wholesaleInsertVoItem.commodities.length){
                                document.getElementById('numberEdit'+this.state.editKey).focus();
                            }else{
                                this.state.regIndex=1;
                                document.getElementById('commoditySearch').value='';
                                this.warehouseCommodityItemSearch();
                                this.setState({upDnKey:-1},()=>{document.getElementById('commoditySearch').focus();});
                            }
                        }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    this.editRowKey();
                }
            }else if(e.keyCode===107){//+
                this.state.regIndex=1;
                document.getElementById('commoditySearch').value='';
                this.warehouseCommodityItemSearch();
                this.setState({upDnKey:-1},()=>{document.getElementById('commoditySearch').focus();});
            }else if(e.keyCode===38){//上
                if(!this.state.regIndex){//客户区域
                    if(!(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).length)return;
                    if(!this.state.upDnKey){this.state.upDnKey=(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).length-1;
                    }else{this.state.upDnKey=this.state.upDnKey-1;}
                    this.setState({upDnKey:this.state.upDnKey});
                }else if(this.state.regIndex==1){//选菜
                    if(!this.state.warehouseCommodityItemData.length)return;
                    if(!this.state.upDnKey){this.state.upDnKey=this.state.warehouseCommodityItemData.length-1;
                    }else{this.state.upDnKey=this.state.upDnKey-1;}
                    this.state.currChoicedCommodity = JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[this.state.upDnKey]));
                    for(let i=0;i<this.state.currChoicedCommodity.costs.length;i++){
                        this.state.currChoicedCommodity.costs[i].moneyScal = this.state.currChoicedCommodity.costs[i].money;
                        this.state.currChoicedCommodity.costs[i].money = 0;
                    }
                    this.setState({upDnKey:this.state.upDnKey});
                }else{if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                    this.state.regIndex=2;
                    if(this.state.editKey!=-1){let editKeyTemp=this.state.editKey;
                        this.state.editKey=this.state.editKey-1;
                        if(this.state.editKey<0){this.state.editKey=this.state.wholesaleInsertVoItem.commodities.length-1;}
                        if(document.getElementById('numberEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('numberEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('totalWeightEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('totalWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('skinWeightEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('skinWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('unitPriceEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('ownerUnitPriceEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('ownerUnitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('describeEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('describeEdit'+this.state.editKey).focus();
                        }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    this.editRowKey();
                }
            }else if(e.keyCode===40){//下
                if(!this.state.regIndex){//客户区域
                    if(!(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).length)return;
                    if(this.state.upDnKey==null||this.state.upDnKey==(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).length-1){
                        this.state.upDnKey=0;
                    }else{this.state.upDnKey=this.state.upDnKey+1;}
                    this.setState({upDnKey:this.state.upDnKey});
                }else if(this.state.regIndex==1){//选菜
                    if(!this.state.warehouseCommodityItemData.length)return;
                    if(this.state.upDnKey==null||this.state.upDnKey==this.state.warehouseCommodityItemData.length-1){
                        this.state.upDnKey=0;
                    }else{this.state.upDnKey=this.state.upDnKey+1;}
                    this.state.currChoicedCommodity = JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[this.state.upDnKey]));
                    for(let i=0;i<this.state.currChoicedCommodity.costs.length;i++){
                        this.state.currChoicedCommodity.costs[i].moneyScal = this.state.currChoicedCommodity.costs[i].money;
                        this.state.currChoicedCommodity.costs[i].money = 0;
                    }
                    this.setState({upDnKey:this.state.upDnKey});
                }else{if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                    this.state.regIndex=2;
                    if(this.state.editKey!=-1){let editKeyTemp=this.state.editKey;
                        this.state.editKey=this.state.editKey+1;
                        if(this.state.editKey==this.state.wholesaleInsertVoItem.commodities.length){this.state.editKey=0;}
                        if(document.getElementById('numberEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('numberEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('totalWeightEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('totalWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('skinWeightEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('skinWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('unitPriceEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('ownerUnitPriceEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('ownerUnitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('describeEdit'+editKeyTemp)==document.activeElement){
                            document.getElementById('describeEdit'+this.state.editKey).focus();
                        }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    this.editRowKey();
                }
            }else if(e.keyCode===37){//左
                if(!this.state.regIndex){//客户区域
                }else if(this.state.regIndex==1){//选菜
                }else{if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                    this.state.regIndex=2;
                    if(this.state.editKey!=-1){
                        if(document.getElementById('numberEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('describeEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('totalWeightEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('numberEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('skinWeightEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('totalWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('unitPriceEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('skinWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('ownerUnitPriceEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('describeEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('ownerUnitPriceEdit'+this.state.editKey).focus();
                        }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    this.editRowKey();
                }
            }else if(e.keyCode===39){//右
                if(!this.state.regIndex){//客户区域
                }else if(this.state.regIndex==1){//选菜
                }else{if(!this.state.wholesaleInsertVoItem.commodities.length)return;
                    this.state.regIndex=2;
                    if(this.state.editKey!=-1){
                        if(document.getElementById('numberEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('totalWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('totalWeightEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('skinWeightEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('skinWeightEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('unitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('unitPriceEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('ownerUnitPriceEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('ownerUnitPriceEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('describeEdit'+this.state.editKey).focus();
                        }else if(document.getElementById('describeEdit'+this.state.editKey)==document.activeElement){
                            document.getElementById('numberEdit'+this.state.editKey).focus();
                        }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    }else{this.state.editKey=0;document.getElementById('numberEdit0').focus();}
                    this.editRowKey();
                }
            }else if(e.keyCode===46){//del
                this.state.currChoicedCommodity=null;
                this.state.upDnKey=0;
                this.state.choiceCommoditysShow=false;
                this.state.regIndex=-1;
                if(!this.state.regIndex){//客户区域
                }else if(this.state.regIndex==1){//选菜
                }else{
                    if(this.state.wholesaleInsertVoItem.commodities[this.state.editKey]){
                        this.state.wholesaleInsertVoItem.commodities.splice(this.state.editKey,1);
                        if(this.state.wholesaleInsertVoItem.commodities.length){
                            this.state.editKey=0;
                            document.getElementById('numberEdit0').focus();
                            this.editRowKey();
                        }else{this.state.editKey=-1;}
                    }
                }
                this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
            }else if(e.keyCode===33){//PgUp
                if(this.state.regIndex==2){//客户区域
                    if(this.state.wholesaleInsertVoItem.commodities[this.state.editKey].mode){
                        this.state.wholesaleInsertVoItem.commodities[this.state.editKey].mode = 0;
                        this.state.wholesaleInsertVoItem.commodities[this.state.editKey].unitPrice=this.state.wholesaleInsertVoItem.commodities[this.state.editKey].weightMakePrice;
                        this.refs['unitPriceRef'+this.state.editKey].value=this.state.wholesaleInsertVoItem.commodities[this.state.editKey].unitPrice;
                    }else{
                        this.state.wholesaleInsertVoItem.commodities[this.state.editKey].mode = 1;
                        this.state.wholesaleInsertVoItem.commodities[this.state.editKey].unitPrice=this.state.wholesaleInsertVoItem.commodities[this.state.editKey].numMakePrice;
                        this.refs['unitPriceRef'+this.state.editKey].value=this.state.wholesaleInsertVoItem.commodities[this.state.editKey].unitPrice;
                    }
                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                }
            }else if(e.keyCode===34){//PgDn
                this.state.regIndex=3;
                document.getElementById('advance').focus();
            }else if(e.keyCode===35){//End
                this.submitWholesale();
            }else if(e.keyCode===27){//Esc
                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
            }
        }
    }
    initCustomers(){
        stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.customerItemAll = successResponse.data;
                this.searchCustomers();
                this.setState({customerItemDatas:this.state.customerItemDatas});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchCustomers(){
        this.state.customerItemDatas = [];
        if (this.state.keySearchCus!=null&&this.state.keySearchCus!='') {
            for(let i=0;i<this.state.customerItemAll.length;i++){
                let pys=stringUtil.makePy((this.state.customerItemAll[i].noteName).trim());let isPinYinHava=0;
                if(pys&&pys.length>0)for(let k=0;k<pys.length;k++){
                    if(pys[k].indexOf((this.state.keySearchCus?this.state.keySearchCus:'').trim().toUpperCase())!=-1){
                        isPinYinHava=1;break;
                    }
                }
                if ((this.state.customerItemAll[i].noteName).indexOf(this.state.keySearchCus)!=-1||isPinYinHava) {
                    this.state.customerItemDatas.push(this.state.customerItemAll[i]);
                }
            }
        }else{this.state.customerItemDatas = this.state.customerItemAll;}
    }
    warehouseCommodityItemSearch(){
        this.state.warehouseCommodityItemData = [];
        for(let i=0;i<this.state.warehouseCommodityItemAll.length;i++){
            let commodityItem = this.state.warehouseCommodityItemAll[i];
            if(this.state.keySearch&&this.state.keySearch.trim().length>0){
                var keywords = this.state.keySearch.trim().split(/\s+/);
                let is = true;
                for(let j=0;j<keywords.length;j++){
                    console.log(keywords[j]);
                    let isSub = false;
                    let pys=stringUtil.makePy((commodityItem.commodityName).trim());let isPinYinHava=0;
                    if(pys&&pys.length>0)for(let k=0;k<pys.length;k++){
                        if(pys[k].indexOf((this.state.keySearch?this.state.keySearch:'').trim().toUpperCase())!=-1){
                            isPinYinHava=1;break;
                        }
                    }
                     if(isSub||commodityItem.commodityName.indexOf(keywords[j])!=-1||isPinYinHava){
                        isSub = true;
                     }
                     if(commodityItem.commodityCode){
                        if(isSub||commodityItem.commodityCode.indexOf(keywords[j])!=-1){
                            isSub = true;
                        }
                     }
                     let ownerName = "";
                     if(commodityItem.targetType==0||commodityItem.targetType==2){
                        ownerName = commodityItem.targetName?commodityItem.targetName:'';
                     }else if(commodityItem.targetType==1){
                        ownerName = stringUtil.language(1051)+(commodityItem.targetName?commodityItem.targetName:'');
                     }
                     if(isSub||ownerName.indexOf(keywords[j])!=-1){
                        isSub = true;
                     }
                     if(!isSub){
                        is = false;
                        break;
                     }
                }
                if(!is){
                  continue;
                }
            }
            //if (commodityItem.commodityName.indexOf(this.state.keySearch)==-1&&(commodityItem.commodityCode?commodityItem.commodityCode:'').indexOf(this.state.keySearch)==-1) continue;
            this.state.warehouseCommodityItemData.push(commodityItem);
        }
    }
    keySelectCommodity(){
        let currEditWarehouseCommodityItem = JSON.parse(JSON.stringify(this.state.currChoicedCommodity));
        for(let i=0;i<currEditWarehouseCommodityItem.costs.length;i++){
            currEditWarehouseCommodityItem.costs[i].moneyScal = currEditWarehouseCommodityItem.costs[i].money;
            currEditWarehouseCommodityItem.costs[i].money = 0;
        }
        let commodityTemp = {
            buyerCode:currEditWarehouseCommodityItem.buyerCode,
            warehouseId:currEditWarehouseCommodityItem.warehouseId,
            cargoCommodityId:currEditWarehouseCommodityItem.cargoCommodityId,
            cargoId:currEditWarehouseCommodityItem.cargoId,
            systemCommodityId:currEditWarehouseCommodityItem.systemCommodityId,
            commodityId:currEditWarehouseCommodityItem.commodityId,
            commodityName:currEditWarehouseCommodityItem.commodityName,
            commodityCode:currEditWarehouseCommodityItem.commodityCode,
            commoditySpecs:currEditWarehouseCommodityItem.commoditySpecs,
            targetType:currEditWarehouseCommodityItem.targetType,
            targetId:currEditWarehouseCommodityItem.targetId,
            targetName:currEditWarehouseCommodityItem.targetName,
            cargoNo:currEditWarehouseCommodityItem.cargoNo,
            unitMaster:currEditWarehouseCommodityItem.unitMaster,
            mode:currEditWarehouseCommodityItem.wholesaleMode,//0stringUtil.language(1022)，1stringUtil.language(1021)
            number:0,
            totalWeight:0,
            skinWeight:0,
            weight:0,
            unitPrice:currEditWarehouseCommodityItem.wholesaleMode?arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice):arithUtil.unitPriceTo(arithUtil.fenToYuan(currEditWarehouseCommodityItem.weightMakePrice),0,this.state.weightMode),
            totalUnitPrice:arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice),
            numMakePrice:arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice),
            weightMakePrice:arithUtil.unitPriceTo(arithUtil.fenToYuan(currEditWarehouseCommodityItem.weightMakePrice),0,this.state.weightMode),
            standard:currEditWarehouseCommodityItem.standard,
            commodityReduce:currEditWarehouseCommodityItem.reduce,
            reduceWeight:0,
            goodsMoney:0,
            costs:currEditWarehouseCommodityItem.costs
        };
        this.state.wholesaleInsertVoItem.commodities.push(commodityTemp);
        this.state.regIndex=-1;
        this.state.editKey=this.state.wholesaleInsertVoItem.commodities.length-1;
        this.setState({currChoicedCommodity:null},()=>{document.getElementById('numberEdit'+this.state.editKey).focus();this.editRowKey();});
    }
    submitWholesale(){//0stringUtil.language(1145)，1stringUtil.language(1144)
        if (this.state.wholesaleInsertVoItem.commodities.length==0) {
            ToastBox.error(stringUtil.language(190));return;
        }
        for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
            let commodityItem = this.state.wholesaleInsertVoItem.commodities[i];
            if (commodityItem.unitPrice==0) {
                ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1011)+'！');return;
            }
            if (commodityItem.mode==0&&commodityItem.weight<=0) {
                ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1012)+'！');return;
            }
            if (commodityItem.mode==1&&commodityItem.number<=0) {
                ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(560));return;
            }
        }
        let wholesaleInsertVo = {costs:[],commodities:[]};
        wholesaleInsertVo.customerId = this.state.wholesaleInsertVoItem.customerId;
        wholesaleInsertVo.advanceMoney = this.state.wholesaleInsertVoItem.advanceMoney;
        wholesaleInsertVo.money = this.state.wholesaleInsertVoItem.money;
        wholesaleInsertVo.describe = this.state.wholesaleInsertVoItem.describe;
        for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
            let costsTemp = {money:0};
            costsTemp.costClauseId = this.state.wholesaleInsertVoItem.costs[i].costClauseId;
            costsTemp.money = this.state.wholesaleInsertVoItem.costs[i].money;
            wholesaleInsertVo.costs.push(costsTemp);
        }
        for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
            let commodityTemp = {costs:[]};
            commodityTemp.supplementaryCommodity = this.state.wholesaleInsertVoItem.commodities[i].supplementaryCommodity?1:0;
            commodityTemp.cargoId = this.state.wholesaleInsertVoItem.commodities[i].cargoId;
            commodityTemp.buyerCode = this.state.wholesaleInsertVoItem.commodities[i].buyerCode;
            commodityTemp.targetType = this.state.wholesaleInsertVoItem.commodities[i].targetType;
            commodityTemp.targetId = this.state.wholesaleInsertVoItem.commodities[i].targetId;
            commodityTemp.commodityId = this.state.wholesaleInsertVoItem.commodities[i].commodityId;
            commodityTemp.cargoCommodityId = this.state.wholesaleInsertVoItem.commodities[i].cargoCommodityId;
            commodityTemp.standard = this.state.wholesaleInsertVoItem.commodities[i].standard;
            commodityTemp.number = this.state.wholesaleInsertVoItem.commodities[i].number;
            commodityTemp.totalWeight = this.state.wholesaleInsertVoItem.commodities[i].totalWeight;
            commodityTemp.skinWeight = this.state.wholesaleInsertVoItem.commodities[i].skinWeight;
            commodityTemp.reduceWeight = this.state.wholesaleInsertVoItem.commodities[i].reduceWeight;
            commodityTemp.weight = this.state.wholesaleInsertVoItem.commodities[i].weight;
            commodityTemp.unitPrice = this.state.wholesaleInsertVoItem.commodities[i].unitPrice;
            commodityTemp.ownerUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice?this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice:0;
            commodityTemp.totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].totalUnitPrice;
            commodityTemp.mode = this.state.wholesaleInsertVoItem.commodities[i].mode;
            commodityTemp.goodsMoney = this.state.wholesaleInsertVoItem.commodities[i].goodsMoney;
            if (this.state.wholesaleInsertVoItem.commodities[i].costs==null)this.state.wholesaleInsertVoItem.commodities[i].costs=[];
            for(let j=0;j<this.state.wholesaleInsertVoItem.commodities[i].costs.length;j++){
                let costsSource = JSON.parse(JSON.stringify(this.state.wholesaleInsertVoItem.commodities[i].costs[j]));
                commodityTemp.costs.push({
                    costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                    money:costsSource.money!=null?costsSource.money:0,
                });
            }
            wholesaleInsertVo.commodities.push(commodityTemp);
        }
        if(this.state.isVisiblity){return;}
        this.state.isVisiblity = true;
        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+this.state.wholesaleInsertVoItem.id,wholesaleInsertVo,['json','put',1],(successResponse)=>{
            if (successResponse.status==200) {
                ToastBox.success(stringUtil.language(388));
                if(this.state.isPrintTag!=1)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+this.state.wholesaleInsertVoItem.id,null,['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let printWholesaleInfo = successResponse.data;
                        if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||this.state.wholesaleInsertVoItem.customerType!=1) printWholesaleInfo.billMoney=0;
                        if(localStorage.getItem("useSetting")&&JSON.parse(localStorage.getItem("useSetting")).everyCopyNum!=null){
                            let printDataTemp = otherUtil.wholesaleNull(printWholesaleInfo);
                            for(let i=0;i<JSON.parse(localStorage.getItem("useSetting")).everyCopyNum;i++){
                                printUtil.toComputerPrint(printDataTemp);
                            }
                        }else{printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));}
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                global.isCloseThis=3;
                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
            }else{this.state.isVisiblity=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{this.state.isVisiblity=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    updateCommodityInfo(){
        let item=this.state.wholesaleInsertVoItem.commodities[this.state.editKey];
        console.log('当前商品：',item)
        let reduceWeightVal = (parseFloat(item.number)).mul(item.commodityReduce);//克
        let standardVal = item.standard,goodsMoneyVal = 0;//克/stringUtil.language(1173)
        if(standardVal!=null&&standardVal!=0){//有stringUtil.language(1024)情况
            if (item.mode==0) {
                item.reduceWeight = reduceWeightVal;
                if (document.getElementById('numberEdit'+this.state.editKey)==document.activeElement&&this.state.workerHabitInfo.defWeight==0) {//0stringUtil.language(1024)，1stringUtil.language(1168)
                    item.totalWeight = (parseFloat(item.number)).mul(standardVal);
                    item.weight=(item.totalWeight).sub(parseFloat(item.skinWeight)).sub(reduceWeightVal);
                }else{item.weight = (parseFloat(item.totalWeight)).sub(parseFloat(item.skinWeight)).sub(reduceWeightVal);}
                goodsMoneyVal = (parseFloat(item.unitPrice)).mul(arithUtil.keToWeight(item.weight,0));
            }else{
                let totalWeightVal = (parseFloat(item.number)).mul(standardVal);
                item.totalWeight = totalWeightVal;
                item.weight=totalWeightVal.sub(parseFloat(item.skinWeight)).sub(reduceWeightVal);
                goodsMoneyVal = (parseFloat(item.unitPrice)).mul((parseFloat(item.number)));
            }
        }else{//无stringUtil.language(1024)情况
            if (item.mode==0) {
                item.reduceWeight = reduceWeightVal;
                item.weight=(parseFloat(item.totalWeight)).sub(parseFloat(item.skinWeight)).sub(reduceWeightVal);
                goodsMoneyVal = (parseFloat(item.unitPrice)).mul(arithUtil.keToWeight(item.weight,0));
            }else{
                item.weight=(parseFloat(item.totalWeight)).sub(parseFloat(item.skinWeight)).sub(reduceWeightVal);
                goodsMoneyVal = (parseFloat(item.unitPrice)).mul((parseFloat(item.number)));
            }
        }
        item.goodsMoney = goodsMoneyVal.round();
        if(item.mode==1&&localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
            item.totalGoodMoney=(parseFloat(item.totalUnitPrice)).mul((parseFloat(item.number)));
        }else{}//{stringUtil.language(1015)}
    }
    editRowKey(){
        for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
            if(document.getElementById('numberEdit'+i)==document.activeElement||
            document.getElementById('totalWeightEdit'+i)==document.activeElement||
            document.getElementById('skinWeightEdit'+i)==document.activeElement||
            document.getElementById('unitPriceEdit'+i)==document.activeElement||
            document.getElementById('ownerUnitPriceEdit'+i)==document.activeElement||
            document.getElementById('describeEdit'+i)==document.activeElement){
                document.getElementById('editRow'+i).style.backgroundColor='#009A61';
                document.getElementById('numberEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('totalWeightEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('skinWeightEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('unitPriceEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('ownerUnitPriceEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('describeEdit'+i).style.backgroundColor='#FFF';
                document.getElementById('xuhao'+i).style.color='#FFF';
                document.getElementById('pinming'+i).style.color='#FFF';
                document.getElementById('huozhu'+i).style.color='#FFF';
                document.getElementById('moshi'+i).style.color='#FFF';
                document.getElementById('jingzhong'+i).style.color='#FFF';
                document.getElementById('huokuan'+i).style.color='#FFF';
                document.getElementById('qita'+i).style.color='#FFF';
                document.getElementById('xiaoji'+i).style.color='#FFF';
                document.getElementById('shanchu'+i).style.color='#FFF';
            }else{document.getElementById('editRow'+i).style.backgroundColor='#FFF';
                document.getElementById('numberEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('totalWeightEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('skinWeightEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('unitPriceEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('ownerUnitPriceEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('describeEdit'+i).style.backgroundColor='#DAE7EF';
                document.getElementById('xuhao'+i).style.color='#000';
                document.getElementById('pinming'+i).style.color='#000';
                document.getElementById('huozhu'+i).style.color='#696969';
                document.getElementById('moshi'+i).style.color='#249444';
                document.getElementById('jingzhong'+i).style.color='#000';
                document.getElementById('huokuan'+i).style.color='#000';
                document.getElementById('qita'+i).style.color='#000';
                document.getElementById('xiaoji'+i).style.color='#000';
                document.getElementById('shanchu'+i).style.color='#F00';
            }
        }
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'column',padding:'0.5%'}}>
            <div style={{width:'100%',height:50,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#ECECEC'}}>
                <div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <TabTopActivity viewHeight='100%' viewWidth='100%' context={this} navigationStacks={this.props.navigationStacks} isBackPrevPage={1}/>
                </div>
                <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <input style={{width:300,height:'85%',borderRadius:3,backgroundColor:'#FFF',display:'flex',textAlign:'center',padding:0}} id='commoditySearch' onFocus={()=>{
                        this.state.regIndex=1;
                        document.getElementById('commoditySearch').value='';
                        this.warehouseCommodityItemSearch();
                        this.setState({upDnKey:-1});
                    }} onChange={(event)=>{
                        if(event.target.value=='+')document.getElementById('commoditySearch').value='';
                        this.state.regIndex=1;
                        this.state.keySearch = event.target.value;
                        this.warehouseCommodityItemSearch();
                        this.setState({upDnKey:0});
                    }} placeholder={stringUtil.language(32)} autoComplete='off'/>
                </div>
            </div>
            {!this.state.regIndex?<div style={{width:600,height:arithUtil.ProportionTo(document.body.clientHeight,0.8),borderStyle:'solid',borderColor:'#696969',borderWidth:1,backgroundColor:'#FFF',position:'absolute',left:150,top:arithUtil.ProportionTo(document.body.clientHeight,0.1)+55,paddingLeft:5,paddingRight:5,display:'flex',flexDirection:'column'}}>
                <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',borderBottomStyle:'solid',borderBottomColor:'#A9A9A9',borderBottomWidth:1}}>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.setState({upDnKey:0,regIndex:-1});
                    }}><span style={{color:'#239544',fontSize:15}}>[{stringUtil.language(1268)}(Del)]</span></div>
                </div>
                <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                    <input id='customerSearch' style={{display:'flex',flexGrow:1,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                        this.state.regIndex=0;
                        this.state.upDnKey=0;
                        this.state.keySearchCus = event.target.value;
                        if(this.state.keySearchCus==null||this.state.keySearchCus=='')this.setState({customerItemDatas:this.state.customerItemAll});
                        else{this.searchCustomers();this.setState({customerItemDatas:this.state.customerItemDatas});}
                    }} placeholder={stringUtil.language(32)} autoComplete='off'/>
                </div>
                <div style={{width:'100%',height:0,flexGrow:1,overflow:'auto'}}><div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap',alignContent:'flex-start'}}>
                    {(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).map((item,key)=>{
                        return(<div style={{width:'49%',height:60,borderStyle:'solid',borderWidth:(!this.state.upDnKey&&!key)||this.state.upDnKey==key?0:1,backgroundColor:(!this.state.upDnKey&&!key)||this.state.upDnKey==key?'#148C39':'#FFF',borderColor:'#A9A9A9',borderRadius:5,padding:3,marginLeft:5,marginBottom:5,position:'relative'}} key={key}>
                            <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                if (item.warning!=2) {
                                    let currCustomerItem = JSON.parse(JSON.stringify(item));
                                    this.state.wholesaleInsertVoItem.customerId = currCustomerItem.id;
                                    this.state.wholesaleInsertVoItem.customerName = currCustomerItem.noteName;
                                    this.state.wholesaleInsertVoItem.customerType = currCustomerItem.type;
                                    this.state.wholesaleInsertVoItem.customerWarning = currCustomerItem.warning;
                                    this.state.wholesaleInsertVoItem.customerQuota = currCustomerItem.quota;
                                    this.state.wholesaleInsertVoItem.customerArrears = currCustomerItem.arrears;
                                    this.state.isPrintTag=this.state.wholesaleInsertVoItem.customerType==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                                    this.setState({upDnKey:0,regIndex:-1});
                                }else{ToastBox.error(stringUtil.language(50));}
                            }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <img style={{width:40,height:40,borderRadius:20}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                                    <div style={{marginLeft:5,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <span style={{color:(!this.state.upDnKey&&!key)||this.state.upDnKey==key?'#FFF':'#696969',fontWeight:item.warning!=2?'bold':'normal',fontSize:17}}>{(key+1)+'.'+item.noteName}</span>
                                        <span style={{color:'#FF8C00',fontSize:14}}>{item.warning==1||item.quota!=0?(item.warning==2?stringUtil.language(458)+'/':'')+stringUtil.language(1066)+arithUtil.fenToYuan(item.quota):(item.warning==2?stringUtil.language(458):'')}</span>
                                    </div>
                                </div>
                                <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                            </div>
                        </div>);
                    })}
                </div></div>
            </div>:''}
            {this.state.choiceCommoditysShow?<div style={{width:800,height:arithUtil.ProportionTo(document.body.clientHeight,0.8),borderStyle:'solid',borderColor:'#696969',borderWidth:1,backgroundColor:'#FFF',position:'absolute',right:0,top:arithUtil.ProportionTo(document.body.clientHeight,0.1)+55,paddingLeft:5,paddingRight:5,display:'flex',flexDirection:'column'}}>
                <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',borderBottomStyle:'solid',borderBottomColor:'#A9A9A9',borderBottomWidth:1}}>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.currChoicedCommodity=null;
                        this.state.regIndex=-1;
                        this.setState({upDnKey:0,choiceCommoditysShow:false});
                    }}><span style={{color:'#239544',fontSize:15}}>[{stringUtil.language(1268)}(Del)]</span></div>
                </div>
                <div style={{width:'100%',height:0,flexGrow:1,overflow:'auto'}}><div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap',alignContent:'flex-start'}}>
                    {this.state.warehouseCommodityItemData.map((item,key)=>{
                        return(<div style={{width:'32%',height:85,borderStyle:'solid',borderWidth:(!this.state.upDnKey&&!key)||this.state.upDnKey==key?0:1,backgroundColor:(!this.state.upDnKey&&!key)||this.state.upDnKey==key?'#148C39':'#FFF',borderColor:'#A9A9A9',borderRadius:5,padding:3,marginLeft:5,marginBottom:5,position:'relative'}} key={key}>
                            <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.state.currChoicedCommodity = JSON.parse(JSON.stringify(item));
                                for(let i=0;i<this.state.currChoicedCommodity.costs.length;i++){
                                    this.state.currChoicedCommodity.costs[i].moneyScal = this.state.currChoicedCommodity.costs[i].money;
                                    this.state.currChoicedCommodity.costs[i].money = 0;
                                }
                                this.state.upDnKey=0;
                                this.state.choiceCommoditysShow=false;
                                this.keySelectCommodity();
                            }}><div style={{display:'flex',flexDirection:'column',}}>
                                    <div style={{width:50,position:'relative',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{width:50,height:50,color:'#F00',fontWeight:'bold',textAlign:'center',textOverflow:'ellipsis',overflow:'hidden',backgroundColor:'rgba(255,255,255,0.2)',position:'absolute'}}>{stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}</span>
                                        {localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?'':<img style={{width:50,height:50}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>}
                                    </div>
                                    <span style={{color:'#F00',fontSize:10}}>{item.number+((item.unitMaster!=null&&item.unitMaster!='')?item.unitMaster:stringUtil.language(1173))}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,marginLeft:3,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                    <span style={{color:(!this.state.upDnKey&&!key)||this.state.upDnKey==key?'#FFF':'#52A056',fontSize:16,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:stringUtil.showCode()==1?(item.commodityCode?item.commodityCode:''):''}</span>
                                    <span style={{color:(!this.state.upDnKey&&!key)||this.state.upDnKey==key?'#FFF':'#808080',fontSize:11,marginTop:2,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commoditySpecs}</span>
                                    <span style={{color:(!this.state.upDnKey&&!key)||this.state.upDnKey==key?'#FFF':'#A9A9A9',fontSize:11,marginTop:2,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetName?stringUtil.subStr(item.targetName,6):item.targetType==1?stringUtil.language(426):''} {stringUtil.language(1237,item.cargoNo)}</span>
                                </div>
                            </div>
                        </div>);
                    })}
                </div></div>
            </div>:''}
            <div style={{height:0,flexGrow:1,display:'flex',flexDirection:'column',backgroundColor:'#FFF'}}>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#DAE7EF'}}>
                    <span style={{width:45,fontSize:14,textAlign:'center'}}>{stringUtil.language(1104)}</span>
                    <span style={{width:0,flexGrow:1,fontSize:14,textAlign:'center'}}>{stringUtil.language(1063)}</span>
                    <span style={{width:45,fontSize:14,textAlign:'center'}}>{stringUtil.language(1152)}</span>
                    <span style={{width:'7%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                    <span style={{width:'9%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1166)}</span>
                    <span style={{width:'6%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1167)}</span>
                    <span style={{width:'9%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1168)}</span>
                    <span style={{width:'7%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                    <span style={{width:'7%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1255)}</span>
                    <span style={{width:'9%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1068)}</span>
                    <span style={{width:'6%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1069)}</span>
                    <span style={{width:'9%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1061)}</span>
                    <span style={{width:'10%',fontSize:14,textAlign:'center'}}>{stringUtil.language(1033)}</span>
                    <span style={{width:50,fontSize:14,textAlign:'center'}}>{stringUtil.language(1026)}</span>
                </div>
                {(()=>{this.state.totalInfo={number:0,totalWeight:0,skinWeight:0,weight:0,goodsMoney:0,otherMoney:0,money:0};})()}
                <div style={{width:'100%',height:0,flexGrow:1,overflow:'auto'}}>
                    {this.state.wholesaleInsertVoItem.commodities.map((item,key)=>{
                        let otherMoneyTotal = 0;
                        for(let i=0;i<item.costs.length;i++){
                            let costsItem = item.costs[i];
                            if (costsItem.calMode==0) {//stringUtil.language(868)
                                this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.number).mul(costsItem.moneyScal).round();
                            }else if (costsItem.calMode==1) {//stringUtil.language(869)
                                this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (arithUtil.keToWeight(item.weight,0)).mul(costsItem.moneyScal).roundDouble();
                            }else if (costsItem.calMode==2) {//stringUtil.language(396)
                                this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.goodsMoney).mul(costsItem.moneyScal).mul(0.01).round();
                            }else{
                                this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = parseFloat(costsItem.moneyScal).round();
                            }
                            otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
                        }
                        this.state.totalInfo.number=(this.state.totalInfo.number).add(item.number);
                        this.state.totalInfo.totalWeight=(this.state.totalInfo.totalWeight).add(item.totalWeight);
                        this.state.totalInfo.skinWeight=(this.state.totalInfo.skinWeight).add(item.skinWeight);
                        this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                        this.state.totalInfo.goodsMoney=(this.state.totalInfo.goodsMoney).add(item.goodsMoney).round();
                        this.state.totalInfo.otherMoney=(this.state.totalInfo.otherMoney).add(otherMoneyTotal).round();
                        this.state.totalInfo.money=(this.state.totalInfo.money).add((item.goodsMoney).add(otherMoneyTotal)).round();
                        return(<div id={'editRow'+key} style={{height:60,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span id={'xuhao'+key} style={{width:45,textAlign:'center'}}>{key+1}</span>
                            <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <span id={'pinming'+key} style={{width:'100%',fontSize:18,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityName}</span>
                                <span id={'huozhu'+key} style={{width:'100%',fontSize:12,color:'#696969',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commoditySpecs?'['+item.commoditySpecs+'] ':''}{item.targetName?stringUtil.subStr(item.targetName,6):item.targetType==1?stringUtil.language(1051):''} {stringUtil.language(1237,item.cargoNo)}</span>
                            </div>
                            <div style={{width:45,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                if(item.mode){
                                    this.state.wholesaleInsertVoItem.commodities[key].mode = 0;
                                    this.state.wholesaleInsertVoItem.commodities[key].unitPrice=this.state.wholesaleInsertVoItem.commodities[key].weightMakePrice;
                                    this.refs['unitPriceRef'+key].value=this.state.wholesaleInsertVoItem.commodities[key].unitPrice;
                                }else{
                                    this.state.wholesaleInsertVoItem.commodities[key].mode = 1;
                                    this.state.wholesaleInsertVoItem.commodities[key].unitPrice=this.state.wholesaleInsertVoItem.commodities[key].numMakePrice;
                                    this.refs[['unitPriceRef'+key]].value=this.state.wholesaleInsertVoItem.commodities[key].unitPrice;
                                }
                                this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                            }}><span id={'moshi'+key} style={{color:'#249444'}}>{item.mode?stringUtil.language(1115):stringUtil.language(1114)}</span>
                            </div>
                            <div style={{width:'7%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input id={'numberEdit'+key} ref={'numberEdit'+key} defaultValue={item.number?item.number:''} style={{width:'95%',height:35,fontSize:18,backgroundColor:'#DAE7EF',textAlign:'center',padding:0}} type='number' onKeyDown={(event)=>{
                                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                        event.preventDefault();
                                    }
                                }} onFocus={()=>{
                                    this.state.regIndex=2;
                                    this.state.editKey=key;
                                    this.editRowKey();
                                }} onChange={(event)=>{
                                    if(event.target.value==null || event.target.value==''){
                                        this.state.wholesaleInsertVoItem.commodities[key].number = 0;
                                        this.updateCommodityInfo();
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }else if(stringUtil.numberCheck(event.target.value)){
                                        this.state.wholesaleInsertVoItem.commodities[key].number = parseFloat(event.target.value);
                                        this.updateCommodityInfo();
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }
                                }} placeholder={stringUtil.language(1165)}/>
                            </div>
                            <div style={{width:'9%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input id={'totalWeightEdit'+key} ref={'totalWeightEdit'+key} defaultValue={item.totalWeight?arithUtil.keToWeight(item.totalWeight,this.state.weightMode):''} style={{width:'95%',height:35,fontSize:18,backgroundColor:'#DAE7EF',textAlign:'center',padding:0}} type='number' onKeyDown={(event)=>{
                                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                        event.preventDefault();
                                    }
                                }} onFocus={()=>{
                                    this.state.regIndex=2;
                                    this.state.editKey=key;
                                    this.editRowKey();
                                }} onChange={(event)=>{
                                    if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                        this.state.wholesaleInsertVoItem.commodities[key].totalWeight = 0;
                                        this.updateCommodityInfo();
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }else if(stringUtil.numberCheck(event.target.value)){
                                        this.state.wholesaleInsertVoItem.commodities[key].totalWeight = arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode);
                                        this.updateCommodityInfo();
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }
                                }} placeholder={stringUtil.language(1166)}/>
                            </div>
                            <div style={{width:'6%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input id={'skinWeightEdit'+key} ref={'skinWeightEdit'+key} defaultValue={item.skinWeight?arithUtil.keToWeight(item.skinWeight,this.state.weightMode):''} style={{width:'95%',height:35,fontSize:18,backgroundColor:'#DAE7EF',textAlign:'center',padding:0}} type='number' onKeyDown={(event)=>{
                                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                        event.preventDefault();
                                    }
                                }} onFocus={()=>{
                                    this.state.regIndex=2;
                                    this.state.editKey=key;
                                    this.editRowKey();
                                }} onChange={(event)=>{
                                    if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                        this.state.wholesaleInsertVoItem.commodities[key].skinWeight = 0;
                                        this.updateCommodityInfo();
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }else if(stringUtil.numberCheck(event.target.value)){
                                        this.state.wholesaleInsertVoItem.commodities[key].skinWeight = arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode);
                                        this.updateCommodityInfo();
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }
                                }} placeholder={stringUtil.language(1167)}/>
                            </div>
                            <span id={'jingzhong'+key} style={{width:'9%',fontSize:18,textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            <div style={{width:'7%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input id={'unitPriceEdit'+key} ref={'unitPriceRef'+key} defaultValue={item.mode==1&&localStorage.getItem('wholesalePriceType')==1?(item.totalUnitPrice?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.totalUnitPrice),0,this.state.weightMode):''):(item.unitPrice?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice),0,this.state.weightMode):'')} style={{width:'95%',height:35,fontSize:18,backgroundColor:'#DAE7EF',textAlign:'center',padding:0}} type='number' onKeyDown={(event)=>{
                                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                        event.preventDefault();
                                    }
                                }} onFocus={()=>{
                                    this.state.regIndex=2;
                                    this.state.editKey=key;
                                    this.editRowKey();
                                }} onChange={(event)=>{
                                    if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                        this.state.wholesaleInsertVoItem.commodities[key].unitPrice = 0;
                                        this.state.wholesaleInsertVoItem.commodities[key].totalUnitPrice = 0;
                                        this.updateCommodityInfo();
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }else if(stringUtil.numberCheck(event.target.value)){
                                        if (!item.mode) {
                                            this.state.wholesaleInsertVoItem.commodities[key].unitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(parseFloat(event.target.value)),this.state.weightMode,0);
                                            this.state.wholesaleInsertVoItem.commodities[key].totalUnitPrice = 0;
                                        }else{
                                            this.state.wholesaleInsertVoItem.commodities[key].unitPrice = arithUtil.yuanToFen(parseFloat(event.target.value));
                                            this.state.wholesaleInsertVoItem.commodities[key].totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[key].unitPrice;
                                        }
                                        this.updateCommodityInfo();
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }
                                }} placeholder={stringUtil.language(1050)}/>
                            </div>
                            <div style={{width:'7%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input id={'ownerUnitPriceEdit'+key} ref={'ownerUnitPriceEdit'+key} defaultValue={item.ownerUnitPrice?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.ownerUnitPrice),0,this.state.weightMode):''} style={{width:'95%',height:35,fontSize:18,backgroundColor:'#DAE7EF',textAlign:'center',padding:0}} type='number' onKeyDown={(event)=>{
                                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                        event.preventDefault();
                                    }
                                }} onFocus={()=>{
                                    this.state.regIndex=2;
                                    this.state.editKey=key;
                                    this.editRowKey();
                                }} onChange={(event)=>{
                                    if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                        this.state.wholesaleInsertVoItem.commodities[key].ownerUnitPrice = 0;
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }else if(stringUtil.numberCheck(event.target.value)){
                                        if (!item.mode) {this.state.wholesaleInsertVoItem.commodities[key].ownerUnitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(parseFloat(event.target.value)),this.state.weightMode,0);
                                        }else{this.state.wholesaleInsertVoItem.commodities[key].ownerUnitPrice = arithUtil.yuanToFen(parseFloat(event.target.value));}
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }
                                }} placeholder={stringUtil.language(1255)}/>
                            </div>
                            <span id={'huokuan'+key} style={{width:'9%',fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                            <span id={'qita'+key} style={{width:'6%',fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan(otherMoneyTotal)}</span>
                            <span id={'xiaoji'+key} style={{width:'9%',fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan(otherMoneyTotal.add(item.goodsMoney))}</span>
                            <div style={{width:'10%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input id={'describeEdit'+key} style={{width:'95%',height:35,fontSize:13,backgroundColor:'#DAE7EF',padding:0,textAlign:'center'}} onKeyDown={(event)=>{
                                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {// 阻止上下键的默认行为
                                        event.preventDefault();
                                    }
                                }} onFocus={()=>{
                                    this.state.regIndex=2;
                                    this.state.editKey=key;
                                    this.editRowKey();
                                }} onChange={(event)=>{
                                    this.state.wholesaleInsertVoItem.commodities[key].describe = event.target.value;
                                }} placeholder={stringUtil.language(12)} autoComplete='off'/>
                            </div>
                            <span id={'shanchu'+key} style={{width:50,color:'#F00',fontSize:11,textAlign:'center',cursor:'pointer'}} onClick={()=>{
                                this.state.wholesaleInsertVoItem.commodities.splice(key,1);
                                if(this.state.wholesaleInsertVoItem.commodities.length)this.setState({editKey:0},()=>{document.getElementById('numberEdit0').focus();this.editRowKey();});
                                else this.setState({editKey:-1});
                            }}>{stringUtil.language(1113)}(Del)</span>
                        </div>)
                    })}
                </div>
                <div style={{height:40,backgroundColor:'#EEE0D1',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:45,fontSize:14,textAlign:'center'}}></span>
                    <span style={{width:0,flexGrow:1,color:'#BC7127',fontSize:14,textAlign:'center'}}>{stringUtil.language(1072)}</span>
                    <span style={{width:45,color:'#BC7127',fontSize:14,textAlign:'center'}}></span>
                    <span style={{width:'7%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{this.state.totalInfo.number}</span>
                    <span style={{width:'9%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.totalWeight,this.state.weightMode)}</span>
                    <span style={{width:'6%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.skinWeight,this.state.weightMode)}</span>
                    <span style={{width:'9%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)}</span>
                    <span style={{width:'7%',color:'#BC7127',fontSize:14,textAlign:'center'}}></span>
                    <span style={{width:'7%',color:'#BC7127',fontSize:14,textAlign:'center'}}></span>
                    <span style={{width:'9%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.goodsMoney)}</span>
                    <span style={{width:'6%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.otherMoney)}</span>
                    <span style={{width:'9%',color:'#BC7127',fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.money)}</span>
                    <span style={{width:'10%',color:'#BC7127',fontSize:14,textAlign:'center'}}></span>
                    <span style={{width:50,color:'#BC7127',fontSize:14,textAlign:'center'}}></span>
                </div>
                <div style={{height:100,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{width:300,display:'flex',flexDirection:'column',cursor:'pointer'}} onClick={()=>{
                        this.state.regIndex=0;
                        this.setState({customerItemDatas:this.state.customerItemAll});
                    }}><div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{this.state.wholesaleInsertVoItem.customerName}</span>
                            <span style={{color:'#249444'}}>{stringUtil.language(1253)}(Ctrl)&gt;</span>
                        </div>
                        <span style={{width:'100%',color:'#A9A9A9',fontSize:10,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{this.state.wholesaleInsertVoItem.customerWarning?stringUtil.language(1066)+arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.customerQuota)+','+stringUtil.language(1164)+arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.customerArrears):''}</span>
                    </div>
                    <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:10}}>
                            {this.state.wholesaleInsertVoItem.costs.map((item,key)=>{
                                if (item.costClauseCalMode==0) {//stringUtil.language(868)
                                    this.state.wholesaleInsertVoItem.costs[key].money = (this.state.totalInfo.number).mul(item.moneyScal).round();
                                }else if (item.costClauseCalMode==1) {//stringUtil.language(869)
                                    this.state.wholesaleInsertVoItem.costs[key].money = (arithUtil.keToWeight(this.state.totalInfo.weight,0)).mul(item.moneyScal).roundDouble();
                                }else if (item.costClauseCalMode==2) {//stringUtil.language(396)
                                    this.state.wholesaleInsertVoItem.costs[key].money = (this.state.totalInfo.goodsMoney).mul(item.moneyScal).mul(0.01).round();
                                }else{
                                    this.state.wholesaleInsertVoItem.costs[key].money = parseFloat(item.moneyScal).round();
                                }
                                return(<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:10}} key={key}>
                                    <span style={{color:'#696969',fontSize:16}}>{stringUtil.subStr(item.costClauseName,5)}:</span>
                                    {item.costClauseCalMode==0?<span style={{fontSize:16}}>{arithUtil.fenToYuan(item.money)}</span>:
                                    item.costClauseCalMode==1?<span style={{fontSize:16}}>{arithUtil.fenToYuan(item.money)}</span>:
                                    item.costClauseCalMode==2?<span style={{fontSize:16}}>{arithUtil.fenToYuan(item.money)}</span>:
                                    <span style={{fontSize:16}}>{arithUtil.fenToYuan(item.money)}</span>}
                                </div>)
                            })}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:10}}>
                            {(()=>{let costsMoneyVal = 0;
                                if (this.state.wholesaleInsertVoItem.costs.length>0) {
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                                        let costsItemMoney = this.state.wholesaleInsertVoItem.costs[i].money;
                                        costsMoneyVal = costsMoneyVal.add(costsItemMoney);
                                    }
                                }
                                this.state.wholesaleInsertVoItem.money = (this.state.totalInfo.goodsMoney).add(this.state.totalInfo.otherMoney).add(this.state.wholesaleInsertVoItem.advanceMoney).add(costsMoneyVal).round();
                                this.state.wholesaleInsertVoItem.billMoney = 0;
                                this.state.wholesaleTotalMoneyTemp = this.state.wholesaleInsertVoItem.money;
                                if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==0)&&this.state.wholesaleInsertVoItem.customerType==1){//临时stringUtil.language(1048)取整
                                    let madeAllMoney = arithUtil.getRoundMoney(this.state.wholesaleInsertVoItem.money);
                                    if (madeAllMoney!=this.state.wholesaleInsertVoItem.money){//在取整规则范围内
                                        this.state.wholesaleInsertVoItem.billMoney = madeAllMoney.sub(this.state.wholesaleInsertVoItem.money,2);
                                        this.state.wholesaleTotalMoneyTemp = madeAllMoney;
                                    }
                                }
                            })()}
                            <span style={{fontSize:18}}>{stringUtil.language(880)}</span>
                            <span style={{color:'#F00',fontSize:30,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesaleTotalMoneyTemp)}</span>
                        </div>
                    </div>
                    <div style={{height:'100%',display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:16}}>{stringUtil.language(1161)}:</span><input id='advance' style={{width:100,height:35,backgroundColor:'#DAE7EF',padding:0,textAlign:'center'}} type='number' onChange={(event)=>{
                                this.state.wholesaleInsertVoItem.advanceMoney = event.target.value?arithUtil.yuanToFen(event.target.value):0;
                                this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                            }} placeholder='0'/>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{width:130,height:35,borderRight:3,backgroundColor:'#148C39',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.submitWholesale();
                            }}><span style={{color:'#FFF',fontSize:18}}>{stringUtil.language(1027)}(End)</span></div>
                            <div style={{width:130,height:35,borderRight:3,backgroundColor:'#AC6217',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:5,cursor:'pointer'}} onClick={()=>{
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                            }}><span style={{color:'#FFF',fontSize:18}}>{stringUtil.language(1028)}(Esc)</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {};