import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 //{stringUtil.language(230)}
 export default class DebtCustomerActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        searchs:{
            targetType:-1,
            targetId:-1,
            keySearch:'',
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
            sort:0,//0stringUtil.language(491)，1stringUtil.language(1089)字母排序
        },
        customerArrearsDOAll:[],
        customerArrearsDOs:[],
        personsAll:[],
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                this.state.personsAll = [];
                let allOwnerItem = {targetType:-1,targetId:-1,noteName:stringUtil.language(425)};
                this.state.personsAll.unshift(allOwnerItem);
                for(let i=0;i<ownersAll.length;i++){
                    this.state.personsAll.push({targetType:0,targetId:ownersAll[i].id,noteName:ownersAll[i].noteName});
                }
                this.state.personsAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                        }
                        this.setState({personsAll:this.state.personsAll});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initData();
    }
    initData(){
        // stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_ARREARS_SUMM_URL,{
        //     targetType:-1,
        //     targetId:this.state.currPersonItem.targetId,
        // },['key','get',1],(successResponse)=>{
        //     if (successResponse.status==200) {
        //         this.state.customerArrearsDOAll = successResponse.data;
        //         this.setState({customerArrearsDOAll: this.state.customerArrearsDOAll});
        //     }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        // },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_ACCRUED_ARREARS_URL,{
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
            date:arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D'),
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.customerArrearsDOAll = successResponse.data;
                this.searchDatas();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchDatas(){
        this.state.customerArrearsDOs = [];
        for(let i=0;i<this.state.customerArrearsDOAll.length;i++){
            let customerItem = this.state.customerArrearsDOAll[i];
            if (customerItem.customerName.indexOf(this.state.searchs.keySearch)!=-1) {
                this.state.customerArrearsDOs.push(customerItem);
            }
        }
        if(this.state.searchs.sort==0){//stringUtil.language(491)
            this.state.customerArrearsDOs = this.state.customerArrearsDOs.sort((param1, param2)=>{
                return ((param2.arrears).sub(param1.arrears));
            });
        }else if(this.state.searchs.sort==1){//stringUtil.language(1089)字母排序
            this.state.customerArrearsDOs = this.state.customerArrearsDOs.sort(function compareFunction(param1, param2) {
                return (param1.customerName).localeCompare(param2.customerName,"zh");
            });
        }
        this.setState({customerArrearsDOs:this.state.customerArrearsDOs});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(918)}</span>
                            <Select showSearch defaultValue={stringUtil.language(425)} style={{width:arithUtil.pxToDp(150)}} onChange={(value)=>{
                                this.state.searchs.targetType = JSON.parse(value).targetType;
                                this.state.searchs.targetId = JSON.parse(value).targetId;
                                this.state.searchs.targetName = JSON.parse(value).noteName;
                                this.initData();
                            }}>
                            {this.state.personsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </Select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:75}}>{stringUtil.language(294)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endTime = date.valueOf();
                                this.initData();
                            }} /></div>
                        </div>
                        <div style={{width:100,height:32,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#52A056',borderWidth:1,borderRadius:20,marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            this.state.searchs.sort = this.state.searchs.sort==0?1:0;
                            this.searchDatas();
                        }}><span style={{color:'#52A056',fontSize:14}}>{this.state.searchs.sort==0?stringUtil.language(491):stringUtil.language(492)}</span>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:10,cursor:'pointer'}} onClick={()=>{
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="3"><b><font size="3">欠[${this.state.searchs.targetName?this.state.searchs.targetName:stringUtil.language(425)}]${stringUtil.language(493)}（${stringUtil.language(919)}${arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D')}）</font></b></td></tr>
                            <tr><td align="center"><b><font size="3">${stringUtil.language(1155)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1048)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1090)}</font></b></td></tr>`);
                            let totalInfo = {arrears:0};
                            for(let key=0;key<this.state.customerArrearsDOs.length;key++){
                                totalInfo.arrears=(totalInfo.arrears).add(this.state.customerArrearsDOs[key].arrears);
                                resArr.push(`<tr><td align="left"><font size="3">${this.state.customerArrearsDOs[key].customerCode}</font></td>
                                <td align="left"><font size="3">${this.state.customerArrearsDOs[key].customerName}</font></td>
                                <td align="left"><font size="3">${arithUtil.fenToYuan(this.state.customerArrearsDOs[key].arrears)}</font></td></tr>`);
                            }
                            resArr.push(`<tr><td align="left" colspan="4"><b><font size="3">${stringUtil.language(97,this.state.customerArrearsDOs.length,arithUtil.fenToYuan(totalInfo.debtMoneyTotal))}${stringUtil.currency(1)}</font></b></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),stringUtil.language(493)+'（'+arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D')+'）');
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                {(()=>{this.state.totalInfo = {debtMoneyTotal:0}})()}
                <div style={{width:'100%',height:'92%',overflowY:'auto',paddingTop:5}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>欠[{this.state.searchs.targetName?this.state.searchs.targetName:stringUtil.language(425)}]{stringUtil.language(493)}</span>
                        <span style={{fontSize:17}}>{stringUtil.language(919)}{arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D')}</span>
                    </div>:''}
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        {this.state.customerArrearsDOs.map((item,key)=>{
                            this.state.totalInfo.debtMoneyTotal = (this.state.totalInfo.debtMoneyTotal).add(item.arrears);
                            return(<div style={styles.itemStyle} key={key}>
                                <div style={{width:'17%',height:'100%',borderRightStyle:'solid',borderRightColor:'#D3D3D3',borderRightWidth: 1,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:'100%',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',marginLeft:5}}>{item.customerCode}</span>
                                </div>
                                <div style={{width:'47%',borderRightStyle:'solid',borderRightColor:'#D3D3D3',borderRightWidth: 1,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:'100%',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',marginLeft:5}}>{item.customerName}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                                    <span style={{color:'#F00',marginRight:5}}>{arithUtil.fenToYuan(item.arrears)}</span>
                                </div>
                            </div>)
                        })}
                    </div>
                    <br/><span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(97,this.state.customerArrearsDOs.length,arithUtil.fenToYuan(this.state.totalInfo.debtMoneyTotal))} {stringUtil.currency(1)}</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(30),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTitle:{
        width:arithUtil.pxToDp(90),
        height:arithUtil.pxToDp(30),
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    tableTd:{
        width:arithUtil.pxToDp(90),
        height:arithUtil.pxToDp(30),
        fontSize:13,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
    itemStyle:{
        width:'24.5%',
        height:40,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#D3D3D3',
        borderWidth: 1
    },
};