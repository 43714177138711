import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

//{stringUtil.language(735)}
 export default class ProcureOrdersActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        pageIndex:1,//1stringUtil.language(426)，2stringUtil.language(411)
        currCargoItem:null,
        searchConditions:{},
        wholesaleCargosData:[],
        commoditysAll:[],
        commoditysData:[],
        suppliersAll:[],
        warehousesAll:[],
        searchShowPop:false,searchShowPopIndex:0,
        addShowPop:false,addShowPopIndex:0,//初始化组{stringUtil.language(1173)}，清空值
        infoShowPop:false,
        editShowPop:false,
        addVegetableShowPop:false,
        editVegetableShowPop:false,editShowPopIndex:0,//初始化组{stringUtil.language(1173)}，清空值
        delPurchaseOrderPop:false,
        currEditVegetableItem:{},
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        purchaseOrdersData: [],             //列表数据
        currPurchaseOrderInfo:{},
        currPurchaseOrderItem:{createTime:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),costs:[],commodities:[],money:0},
        currCommodityKey:-1,
        currCommodities:[],
        keySearch:'',
        currCommodityTypeItem:{},
        commodityTypesAll:[],
        costsOtherOrderAll:[],
        costsAll:[],
        costsOtherAll:[],
        selectDiglogTagEntCostAdd:false,
        selectDiglogTagEntCostE:false,
        selectDiglogTagEntCostOth:false,
        selectDiglogTagEntCostUpd:false,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
        if (typeof(paramsPage.pageIndex)!='undefined' && paramsPage.pageIndex!=null) {
            this.state.pageIndex = JSON.parse(JSON.stringify(paramsPage.pageIndex));
        }
        if (typeof(paramsPage.cargoItemInfo)!='undefined' && paramsPage.cargoItemInfo!=null) {
            this.state.currCargoItem = JSON.parse(JSON.stringify(paramsPage.cargoItemInfo));
            this.state.currPurchaseOrderItem.cargoId = this.state.currCargoItem.id;
            this.state.searchConditions = {
                cargoId:this.state.currCargoItem.id,
                cargoNo:this.state.currCargoItem.cargoNo,
                cargoMode:this.state.currCargoItem.cargoMode,
                targetName:this.state.currCargoItem.targetName!=null?this.state.currCargoItem.targetName:'',
                warehouseId:this.state.currCargoItem.warehouseId,
                warehouseName:this.state.currCargoItem.warehouseName
            };
        }
    }
    componentDidMount(){
        this.getDataList();
        stringUtil.httpRequest(urlUtil.OAUTH_SUPPLIER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({suppliersAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({warehousesAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.commodityTypesAll= successResponse.data;
                this.state.commodityTypesAll.unshift({id:0,name:stringUtil.language(854)});
                this.state.commodityTypesAll.unshift({id:-1,name:stringUtil.language(389)});
                this.setState({commodityTypesAll: this.state.commodityTypesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:-1,
            currPage:0,
            pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownerCargosResultObj= successResponse.data;
                this.setState({wholesaleCargosData:ownerCargosResultObj.items});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.SALE_PURCHASE_SHOP_COMMODITY_URL+'/'+(this.state.searchConditions.cargoId!=null?this.state.searchConditions.cargoId:''),null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let commoditysAllTemp = successResponse.data;
                this.state.commoditysAll = [];
                for(let i=0;i<commoditysAllTemp.length;i++){
                    let searchNeeds;
                    if (this.state.pageIndex==1) {//档口自营
                        searchNeeds = commoditysAllTemp[i].targetType==1;// && commoditysAllTemp[i].systemCommodityId!=0
                    }else if (this.state.pageIndex==2) {//员工炒货
                        searchNeeds = commoditysAllTemp[i].targetType==2 && JSON.parse(localStorage.getItem("currentStallItem")).workerId==commoditysAllTemp[i].targetId;
                    }
                    if (searchNeeds) this.state.commoditysAll.push(commoditysAllTemp[i]);
                }
                this.setState({commoditysData: this.state.commoditysAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.PURCHASE_SEARCH_URL,{
            cargoId:this.state.searchConditions.cargoId!=null?this.state.searchConditions.cargoId:'',
            warehouseId:this.state.searchConditions.warehouseId!=null?this.state.searchConditions.warehouseId:-1,
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let purchaseResultObj = successResponse.data;
                this.state.totalNum = purchaseResultObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.setState({purchaseOrdersData:purchaseResultObj.items});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
		var _this= this;
		_this.setState({goValue : e.target.value})
		var value = e.target.value;
		if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
		else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
		else _this.pageClick(value);
	}
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }
    searchCommoditys(){
        this.state.commoditysData = [];
        for(let i=0;i<this.state.commoditysAll.length;i++){
            let commodityItem = this.state.commoditysAll[i];
            if (commodityItem.name.indexOf(this.state.keySearch)!=-1||(commodityItem.code?commodityItem.code:'').indexOf(this.state.keySearch)!=-1) {
                if (this.state.currCommodityTypeItem.id==null || this.state.currCommodityTypeItem.id==-1 || commodityItem.shopCommodityTypeId==this.state.currCommodityTypeItem.id) {
                    this.state.commoditysData.push(commodityItem);
                }
            }
        }
        this.setState({commoditysData:this.state.commoditysData});
    }
    purchaseOrderTotal(){
        let orderCostsTotalTemp = 0;
        let totalNumbers=0,totalWeights=0,totalGoodMoneys=0;
        if (this.state.currPurchaseOrderItem.commodities!=null && this.state.currPurchaseOrderItem.commodities.length>0) {
            for(let i=0;i<this.state.currPurchaseOrderItem.commodities.length;i++){
                let commodityItemTemp = this.state.currPurchaseOrderItem.commodities[i];
                totalNumbers = totalNumbers.add(commodityItemTemp.number);
                totalWeights = totalWeights.add(commodityItemTemp.weight);
                let goodsMoneyTemp = (commodityItemTemp.goodsMoney!=null)?commodityItemTemp.goodsMoney:0;
                totalGoodMoneys = totalGoodMoneys.add(goodsMoneyTemp);
                if (commodityItemTemp.costs!=null && commodityItemTemp.costs.length>0) {
                    for(let j=0;j<commodityItemTemp.costs.length;j++){
                        let costsMoneyTemp = commodityItemTemp.costs[j];
                        // if (costsMoneyTemp.calMode==0) {//stringUtil.language(868)
                        //     orderCostsTotalTemp = orderCostsTotalTemp.add((commodityItemTemp.number).mul(costsMoneyTemp.money));
                        // }else if (costsMoneyTemp.calMode==1) {//stringUtil.language(869)
                        //     orderCostsTotalTemp = orderCostsTotalTemp.add((arithUtil.keToWeight(commodityItemTemp.weight,0)).mul(costsMoneyTemp.money));
                        // }else if (costsMoneyTemp.calMode==2) {//stringUtil.language(396)
                        //     orderCostsTotalTemp = orderCostsTotalTemp.add((commodityItemTemp.goodsMoney).mul(costsMoneyTemp.money).mul(0.01));
                        // }else if (costsMoneyTemp.calMode==3) {//stringUtil.language(1029)
                            orderCostsTotalTemp = orderCostsTotalTemp.add(costsMoneyTemp.money);
                        // }
                    }
                }
                orderCostsTotalTemp = orderCostsTotalTemp.add(parseFloat(goodsMoneyTemp));
            }
        }
        if (this.state.currPurchaseOrderItem.costs!=null && this.state.currPurchaseOrderItem.costs.length>0) {
            for(let x=0;x<this.state.currPurchaseOrderItem.costs.length;x++){
                let poCostsItem = this.state.currPurchaseOrderItem.costs[x];
                orderCostsTotalTemp = orderCostsTotalTemp.add(poCostsItem.money);
            }
        }
        this.state.currPurchaseOrderItem.money = orderCostsTotalTemp.round();
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:arithUtil.pxToDp(8),paddingRight:arithUtil.pxToDp(8),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span style={{color:'#D2855D'}}>{this.state.searchConditions.cargoMode==0?this.state.searchConditions.targetName:this.state.searchConditions.cargoMode==1?stringUtil.language(426):this.state.searchConditions.cargoMode==2?stringUtil.language(438):stringUtil.language(517)} | {this.state.searchConditions.cargoNo!=null?this.state.searchConditions.cargoNo:stringUtil.language(571)} | {this.state.searchConditions.warehouseName!=null?this.state.searchConditions.warehouseName:stringUtil.language(453)}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.searchConditions = {};
                            this.setState({searchShowPopIndex:this.state.searchShowPopIndex+1,searchShowPop:!this.state.searchShowPop});
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#D2855D'}}>&#xe6a3;</i>
                            <span style={{color:'#D2855D'}}> {stringUtil.language(218)}</span>
                        </div>
                        {otherUtil.authorityFindCode("020202")!=null&&this.state.currCargoItem!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(20),cursor:'pointer'}} onClick={()=>{
                            this.state.currPurchaseOrderItem = {createTime:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),costs:[],commodities:[],money:0};
                            this.setState({addShowPopIndex:this.state.addShowPopIndex+1,addShowPop: !this.state.addShowPop});
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                            <span style={{color:'#52A056'}}> {stringUtil.language(572)}</span>
                        </div>:''}
                    </div>
                </div>
                <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'14%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1040)}</span>
                    <span style={{width:'20%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1039)}</span>
                    <span style={{width:'16%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(921)}</span>
                    <span style={{width:'25%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1084)}</span>
                    <span style={{width:'13%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                    <span style={{width:'12%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1026)}</span>
                </div>
                <div style={{height:this.state.totalPage>1?'77%':'87%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.purchaseOrdersData.map((item,key)=>{
                        return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'14%',fontSize:13}}>SG.{item.purchaseNo}</span>
                            <span style={{width:'20%',fontSize:13,textAlign:'center'}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                            <span style={{width:'16%',fontSize:13,textAlign:'center'}}>{item.supplierName}</span>
                            <span style={{width:'25%',fontSize:13,textAlign:'center'}}>{item.cargoNo}</span>
                            <span style={{width:'13%',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                            <div style={{width:'12%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                                    this.state.currPurchaseOrderInfo = JSON.parse(JSON.stringify(item));
                                    stringUtil.httpRequest(urlUtil.SALE_PURCHASE_URL+'/'+this.state.currPurchaseOrderInfo.id,null,['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.currPurchaseOrderInfo = JSON.parse(JSON.stringify(successResponse.data));
                                            this.setState({infoShowPop: !this.state.infoShowPop});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                                }}>&#xe64c;</i>
                            </div>
                        </div>);
                    })}
                </div>
                {this.state.totalPage>1?<div style={{height:'10%'}}>
                    <Pagination total={this.state.totalNum}
                        current={this.state.currentPage}
                        totalPage={this.state.totalPage}
                        goValue={this.state.goValue}
                        pageClick={this.pageClick.bind(this)}
                        goPrev={this.goPrevClick.bind(this)}
                        goNext={this.goNext.bind(this)}
                        switchChange={this.goSwitchChange.bind(this)}/>
                </div>:''}
            </div>
            <MaskModal visible={this.state.searchShowPop} title={stringUtil.language(218)} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.5)}
                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.searchShowPopIndex}>
                    {this.state.currCargoItem==null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(15)}}>
                        <span>{stringUtil.language(856)}</span>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} onChange={(event)=>{
                                this.state.searchConditions.cargoId = JSON.parse(event.target.value).id;//-1stringUtil.language(1097)
                                this.state.searchConditions.cargoNo = JSON.parse(event.target.value).cargoNo;//-1stringUtil.language(1097)
                                this.state.searchConditions.cargoMode = JSON.parse(event.target.value).cargoMode;
                                this.state.searchConditions.targetName = JSON.parse(event.target.value).targetName!=null?JSON.parse(event.target.value).targetName:'';
                            }}>
                                <option style={{display:'none'}}></option>
                            {this.state.wholesaleCargosData.map((item,key)=>{
                                if (item.cargoMode!=0) {
                                    return(<option value={JSON.stringify(item)} key={key}>{item.cargoMode==1?stringUtil.language(426):item.cargoMode==-1?stringUtil.language(517):stringUtil.language(438)} / {item.cargoMode==-1?stringUtil.language(571):item.cargoNo}</option>)
                                }
                            })}
                        </select>
                    </div>:''}
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(15)}}>
                        <span>{stringUtil.language(892)}</span>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} onChange={(event)=>{
                                this.state.searchConditions.warehouseId = JSON.parse(event.target.value).id;//-1stringUtil.language(1097)
                                this.state.searchConditions.warehouseName = JSON.parse(event.target.value).name;
                            }}>
                                <option style={{display:'none'}}></option>
                            {this.state.warehousesAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                        </select>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if(this.state.currCargoItem){
                        this.state.searchConditions.cargoId=this.state.currCargoItem.id;
                        this.state.searchConditions.cargoNo=this.state.currCargoItem.cargoNo;
                        this.state.searchConditions.cargoMode=this.state.currCargoItem.cargoMode;
                        this.state.searchConditions.targetName=this.state.currCargoItem.targetName!=null?this.state.currCargoItem.targetName:'';
                    }
                    if (this.state.searchConditions.cargoId==null) {
                        this.state.searchConditions.cargoId='';
                    }
                    if (this.state.searchConditions.warehouseId==null) {
                        this.state.searchConditions.warehouseId=-1;
                    }
                    this.state.searchShowPop = false;
                    this.getDataList();
                }} closeBnEvents={() => {
                    this.state.searchConditions = {};
                    if(this.state.currCargoItem){
                        this.state.searchConditions.cargoId=this.state.currCargoItem.id;
                        this.state.searchConditions.cargoNo=this.state.currCargoItem.cargoNo;
                        this.state.searchConditions.cargoMode=this.state.currCargoItem.cargoMode;
                        this.state.searchConditions.targetName=this.state.currCargoItem.targetName!=null?this.state.currCargoItem.targetName:'';
                    }
                    this.state.searchShowPop = false;
                    this.getDataList();
                }}/>
            <MaskModal visible={this.state.addShowPop} title={stringUtil.language(573)} width={700} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.addShowPopIndex}>
                    <div style={{height:'11%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(422)}</span>
                                <select style={arithUtil.stylesAdd(styles.inputText,{width:150})} onChange={(event)=>{
                                    this.state.currPurchaseOrderItem.supplierId = JSON.parse(event.target.value).id;
                                    this.state.currPurchaseOrderItem.supplierName = JSON.parse(event.target.value).noteName;
                                }}>
                                    <option style={{display:'none'}}></option>
                                    {this.state.suppliersAll.map((item,key)=>{
                                        return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                                    })}
                                </select>
                            </div>
                            <span style={{fontSize:10}}>{stringUtil.language(856)}{this.state.currCargoItem?(this.state.currCargoItem.cargoMode==1?stringUtil.language(426):stringUtil.language(438)):''} / {this.state.currCargoItem?this.state.currCargoItem.cargoNo:''}</span>
                        </div>
                        <div><DatePicker locale='zhCN' selected={new Date(this.state.currPurchaseOrderItem.createTime)} onChange={(date) => {
                            this.state.currPurchaseOrderItem.createTime = date.valueOf();
                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                        }} /></div>
                    </div>
                    <div style={{height:'7%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'18%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                        <span style={{width:'20%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                        <span style={{width:'18%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                        <span style={{width:'18%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                        <span style={{width:'14%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1069)}({stringUtil.currency(1)})</span>
                        <span style={{width:'12%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1026)}</span>
                    </div>
                    <div style={{height:'55%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                        {(()=>{this.state.purchaseAddTotalInfo={number:0,weight:0,goodsMoney:0,otherMoneyTotal:0};})()}
                        {this.state.currPurchaseOrderItem.commodities.map((item,key)=>{
                            let otherMoneyTotal = 0;
                            if (item.costs!=null && item.costs.length>0) {
                                for(let i=0;i<item.costs.length;i++){
                                    let currTemp = item.costs[i];
                                    // if (currTemp.calMode==0) {//stringUtil.language(868)
                                    //     otherMoneyTotal = otherMoneyTotal.add((item.number).mul(currTemp.money));
                                    // }else if (currTemp.calMode==1) {//stringUtil.language(869)
                                    //     otherMoneyTotal = otherMoneyTotal.add((arithUtil.keToWeight(item.weight,0)).mul(currTemp.money));
                                    // }else if (currTemp.calMode==2) {//stringUtil.language(396)
                                    //     otherMoneyTotal = otherMoneyTotal.add((item.goodsMoney).mul(currTemp.money).mul(0.01));
                                    // }else if (currTemp.calMode==3) {//stringUtil.language(1029)
                                        otherMoneyTotal = otherMoneyTotal.add(currTemp.money);
                                    // }
                                }
                            }
                            this.state.purchaseAddTotalInfo.number=this.state.purchaseAddTotalInfo.number+item.number;
                            this.state.purchaseAddTotalInfo.weight=(this.state.purchaseAddTotalInfo.weight).add(item.weight);
                            this.state.purchaseAddTotalInfo.goodsMoney=(this.state.purchaseAddTotalInfo.goodsMoney).add(item.goodsMoney);
                            this.state.purchaseAddTotalInfo.otherMoneyTotal=(this.state.purchaseAddTotalInfo.otherMoneyTotal).add(otherMoneyTotal);
                            return(<div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:'18%',fontSize:13,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                                <span style={{width:'20%',fontSize:13,textAlign:'center'}}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                <span style={{width:'18%',fontSize:13,textAlign:'center'}}>{item.mode==0?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode):arithUtil.fenToYuan(item.unitPrice,2)}{item.mode==1?('/'+stringUtil.language(1173)):(this.state.weightMode==0?'/kg':'/'+stringUtil.language(1172))}</span>
                                <span style={{width:'18%',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                                <span style={{width:'14%',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(otherMoneyTotal)}</span>
                                <div style={{width:'12%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#808080',cursor:'pointer'}} onClick={()=>{
                                        this.state.currCommodityKey = key;
                                        this.state.currEditVegetableItem = JSON.parse(JSON.stringify(item));
                                        this.setState({editShowPopIndex:this.state.editShowPopIndex+1,editVegetableShowPop:true});
                                    }}>&#xe63a;</i>
                                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',cursor:'pointer'}} onClick={()=>{
                                        this.state.currPurchaseOrderItem.commodities.splice(key,1);
                                        this.purchaseOrderTotal();
                                        this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                    }}>&#xe612;</i>
                                </div>
                            </div>)
                        })}
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'18%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{stringUtil.language(890)}</span>
                            <span style={{width:'20%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{this.state.purchaseAddTotalInfo.number}{stringUtil.language(1173)} {arithUtil.keToWeight(this.state.purchaseAddTotalInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>
                            <span style={{width:'18%'}}></span>
                            <span style={{width:'18%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.purchaseAddTotalInfo.goodsMoney)}</span>
                            <span style={{width:'14%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.purchaseAddTotalInfo.otherMoneyTotal)}</span>
                            <span style={{width:'12%'}}></span>
                        </div>
                    </div>
                    <div style={{height:'27%'}}>
                        <div style={{height:'60%',display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomColor:'#F2F2F2',borderBottomWidth:1}}>
                            <div style={{width:'28%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',borderRightColor:'#F2F2F2',borderRightWidth: 1}}>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currCommodities = [];
                                    this.setState({addVegetableShowPop:true});
                                }}>
                                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                                    <span style={{color:'#52A056'}}> {stringUtil.language(391)}</span>
                                </div>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    if (this.state.costsOtherOrderAll.length==0) {
                                        stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:2},['key','get',1],(successResponse)=>{
                                            if (successResponse.status==200) {
                                                this.state.costsOtherOrderAll = successResponse.data;
                                                this.setState({selectDiglogTagEntCostAdd:!this.state.selectDiglogTagEntCostAdd});
                                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                    }else{this.setState({selectDiglogTagEntCostAdd:!this.state.selectDiglogTagEntCostAdd});}
                                }}>
                                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#D2855D'}}>&#xe601;</i>
                                    <span style={{color:'#D2855D'}}> {stringUtil.language(574)}</span>
                                </div>
                                <MaskModal title={stringUtil.language(158)} visible={this.state.selectDiglogTagEntCostAdd} width={arithUtil.pxToDp(400)} height={arithUtil.ProportionTo(document.body.clientHeight,0.4)}
                                    content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                                        {this.state.costsOtherOrderAll.map((item,key)=>{
                                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} key={key} onClick={()=>{
                                                if (this.state.currPurchaseOrderItem.costs==null) {
                                                    this.state.currPurchaseOrderItem.costs = [];
                                                }
                                                this.state.currPurchaseOrderItem.costs.push({
                                                    costClauseId:JSON.parse(JSON.stringify(item)).id,
                                                    costClauseName:JSON.parse(JSON.stringify(item)).name,
                                                    payee:JSON.parse(JSON.stringify(item)).payee,
                                                    calMode:JSON.parse(JSON.stringify(item)).calMode,
                                                    money:0
                                                });
                                                this.setState({selectDiglogTagEntCostAdd:false});
                                            }}>
                                                <span>{item.name}</span>
                                                <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                                                <span>{item.calMode==0?stringUtil.language(868)+'/'+stringUtil.language(1173):item.calMode==1?stringUtil.language(869)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                            </div>);
                                        })}
                                    </div>}
                                closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCostAdd:false});}}/>
                            </div>
                            <div style={{width:'72%',height:'100%',overflow:'auto'}}>
                                {this.state.currPurchaseOrderItem.costs!=null?this.state.currPurchaseOrderItem.costs.map((item,key)=>{
                                    return(<div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}} key={key}>
                                        <div style={{width:'87%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <span>{item.costClauseName}({item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)})</span>
                                            {item.calMode==0?<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <input style={arithUtil.stylesAdd(styles.textInput,{width:70})} type='number' placeholder='' defaultValue={item.money?arithUtil.fenToYuan(item.money):''} onChange={(event)=>{
                                                    this.state.currPurchaseOrderItem.costs[key].money = (this.state.purchaseAddTotalInfo.number).mul(arithUtil.yuanToFen(event.target.value)).round();
                                                    this.purchaseOrderTotal();
                                                    this.refs.costsShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.costs[key].money)+' '+stringUtil.currency(1);
                                                    this.refs.moneyShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.money)+' '+stringUtil.currency(1);
                                                }}/><span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                            item.calMode==1?<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <input style={arithUtil.stylesAdd(styles.textInput,{width:70})} type='number' placeholder='' defaultValue={item.money?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money),0,this.state.weightMode):''} onChange={(event)=>{
                                                    this.state.currPurchaseOrderItem.costs[key].money = (arithUtil.yuanToFen(event.target.value)).mul(arithUtil.keToWeight(this.state.purchaseAddTotalInfo.weight,this.state.weightMode)).round();
                                                    this.purchaseOrderTotal();
                                                    this.refs.costsShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.costs[key].money)+' '+stringUtil.currency(1);
                                                    this.refs.moneyShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.money)+' '+stringUtil.currency(1);
                                                }}/><span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span></div>:
                                            item.calMode==2?<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <input style={arithUtil.stylesAdd(styles.textInput,{width:70})} type='number' placeholder='' defaultValue={item.money?item.money:''} onChange={(event)=>{
                                                    this.state.currPurchaseOrderItem.costs[key].money = (this.state.purchaseAddTotalInfo.goodsMoney).mul(parseFloat(event.target.value)).mul(0.01).round();
                                                    this.purchaseOrderTotal();
                                                    this.refs.costsShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.costs[key].money)+' '+stringUtil.currency(1);
                                                    this.refs.moneyShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.money)+' '+stringUtil.currency(1);
                                                }}/><span style={{color:'#D2855D'}}> %</span></div>:
                                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <input style={arithUtil.stylesAdd(styles.textInput,{width:70})} type='number' placeholder='' defaultValue={item.money?arithUtil.fenToYuan(item.money):''} onChange={(event)=>{
                                                    this.state.currPurchaseOrderItem.costs[key].money = arithUtil.yuanToFen(event.target.value).round();
                                                    this.purchaseOrderTotal();
                                                    this.refs.costsShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.costs[key].money)+' '+stringUtil.currency(1);
                                                    this.refs.moneyShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.money)+' '+stringUtil.currency(1);
                                                }}/><span style={{color:'#D2855D'}}> {stringUtil.currency(1)}</span></div>}<span>，{stringUtil.language(1182)}</span>
                                            <span style={{color:'#F00'}} ref='costsShishiRef'>0 {stringUtil.currency(1)}</span>
                                        </div>
                                        <i className="iconfontIndexCss" style={{width:'13%',fontSize:25,color:'#F00',textAlign:'center',textAlignVertical:'center',cursor:'pointer'}} onClick={()=>{
                                            this.state.currPurchaseOrderItem.costs.splice(key,1);
                                            this.purchaseOrderTotal();
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}>&#xe612;</i>
                                    </div>)
                                }):<div/>}
                            </div>
                        </div>
                        <div style={{height:'40%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(858)}</span>
                                <input style={arithUtil.stylesAdd(styles.inputText,{width:350})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                                    this.state.currPurchaseOrderItem.describe = event.target.value;
                                }}/>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(890)}</span><span style={{color:'#D2855D',fontSize:20,fontWeight:'bold'}} ref='moneyShishiRef'>{arithUtil.fenToYuan(this.state.currPurchaseOrderItem.money)} {stringUtil.currency(1)}</span>
                            </div>
                        </div>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currPurchaseOrderItem.supplierId==null) {
                        ToastBox.error(stringUtil.language(159));return;
                    }
                    if (this.state.currCargoItem==null && this.state.currPurchaseOrderItem.cargoId==null) {
                        ToastBox.error(stringUtil.language(215));return;
                    }
                    if (this.state.currPurchaseOrderItem.commodities==null || this.state.currPurchaseOrderItem.commodities.length==0) {
                        ToastBox.error(stringUtil.language(190));return;
                    }
                    let purchaseInsertVo = {createTime:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),costs:[],commodities:[]};
                    purchaseInsertVo.createTime = this.state.currPurchaseOrderItem.createTime;
                    purchaseInsertVo.supplierId = this.state.currPurchaseOrderItem.supplierId!=null?this.state.currPurchaseOrderItem.supplierId:0;
                    purchaseInsertVo.money = this.state.currPurchaseOrderItem.money!=null?this.state.currPurchaseOrderItem.money:0;
                    purchaseInsertVo.cargoId = this.state.currPurchaseOrderItem.cargoId!=null?this.state.currPurchaseOrderItem.cargoId:this.state.currCargoItem.id?this.state.currCargoItem.id:'';
                    purchaseInsertVo.describe = this.state.currPurchaseOrderItem.describe!=null?this.state.currPurchaseOrderItem.describe:'';
                    for(let i=0;i<this.state.currPurchaseOrderItem.costs.length;i++){
                        let costsTemp = {};
                        costsTemp.costClauseId = this.state.currPurchaseOrderItem.costs[i].costClauseId!=null?this.state.currPurchaseOrderItem.costs[i].costClauseId:0;
                        costsTemp.money = this.state.currPurchaseOrderItem.costs[i].money!=null?this.state.currPurchaseOrderItem.costs[i].money:0;
                        purchaseInsertVo.costs.push(costsTemp);
                    }
                    for(let i=0;i<this.state.currPurchaseOrderItem.commodities.length;i++){
                        let commodityTemp = {costs:[],commodityCosts:[]};
                        commodityTemp.commodityId = this.state.currPurchaseOrderItem.commodities[i].commodityId!=null?this.state.currPurchaseOrderItem.commodities[i].commodityId:0;
                        commodityTemp.standard = this.state.currPurchaseOrderItem.commodities[i].standard!=null?this.state.currPurchaseOrderItem.commodities[i].standard:0;
                        commodityTemp.reduce = this.state.currPurchaseOrderItem.commodities[i].reduce!=null?this.state.currPurchaseOrderItem.commodities[i].reduce:0;
                        commodityTemp.number = this.state.currPurchaseOrderItem.commodities[i].number!=null?this.state.currPurchaseOrderItem.commodities[i].number:0;
                        commodityTemp.weight = this.state.currPurchaseOrderItem.commodities[i].weight!=null?this.state.currPurchaseOrderItem.commodities[i].weight:0;
                        commodityTemp.unitPrice = this.state.currPurchaseOrderItem.commodities[i].unitPrice!=null?this.state.currPurchaseOrderItem.commodities[i].unitPrice:0;
                        commodityTemp.mode = this.state.currPurchaseOrderItem.commodities[i].mode!=null?this.state.currPurchaseOrderItem.commodities[i].mode:0;
                        commodityTemp.goodsMoney = this.state.currPurchaseOrderItem.commodities[i].goodsMoney!=null?this.state.currPurchaseOrderItem.commodities[i].goodsMoney:0;
                        if (this.state.currPurchaseOrderItem.commodities[i].costs==null)this.state.currPurchaseOrderItem.commodities[i].costs=[];
                        for(let j=0;j<this.state.currPurchaseOrderItem.commodities[i].costs.length;j++){
                            let costsSource = JSON.parse(JSON.stringify(this.state.currPurchaseOrderItem.commodities[i].costs[j]));
                            commodityTemp.costs.push({
                                costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                                money:costsSource.money!=null?costsSource.money:0,
                            });
                        }
                        if (this.state.currPurchaseOrderItem.commodities[i].commodityCosts==null)this.state.currPurchaseOrderItem.commodities[i].commodityCosts=[];
                        for(let j=0;j<this.state.currPurchaseOrderItem.commodities[i].commodityCosts.length;j++){
                            let costsSource = JSON.parse(JSON.stringify(this.state.currPurchaseOrderItem.commodities[i].commodityCosts[j]));
                            commodityTemp.commodityCosts.push({
                                costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                                money:costsSource.money!=null?costsSource.money:0,
                            });
                        }
                        purchaseInsertVo.commodities.push(commodityTemp);
                    }
                    this.setState({ addShowPop: false });
                    stringUtil.httpRequest(urlUtil.SALE_PURCHASE_URL,purchaseInsertVo,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({ addShowPop: false, });}}/>
            <MaskModal title={stringUtil.language(421)+'（'+this.state.currPurchaseOrderInfo.purchaseNo+'）'} visible={this.state.infoShowPop} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10,overflow:'auto'}}>
                    <div id='printArea'>
                        <div style={{height:60,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                <span>{stringUtil.language(422)}{this.state.currPurchaseOrderInfo.supplierName}</span>
                                <span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.currPurchaseOrderInfo.time),'Y-M-D h:m:s')}</span>
                            </div>
                            <span>{stringUtil.language(955)}：{this.state.currPurchaseOrderInfo.workerId==0?stringUtil.language(431):this.state.currPurchaseOrderInfo.workerName}</span>
                        </div>
                        <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center',borderTopStyle:'solid',borderTopWidth:1,borderTopColor:'#808080',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#808080'}}>
                            <span style={{width:'21%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                            <span style={{width:'28%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                            <span style={{width:'19%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                            <span style={{width:'19%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                            <span style={{width:'13%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1069)}({stringUtil.currency(1)})</span>
                        </div>
                        {(()=>{this.state.totalInfo={number:0,weight:0,goodsMoney:0,other:0}})()}
                        {this.state.currPurchaseOrderInfo.commodities!=null?this.state.currPurchaseOrderInfo.commodities.map((item,key)=>{
                            let otherMoneyTotal = 0;
                            for(let i=0;i<item.costs.length;i++){
                                otherMoneyTotal = otherMoneyTotal.add(item.costs[i].money);
                            }
                            this.state.totalInfo={
                                number:this.state.totalInfo.number+item.number,
                                weight:(this.state.totalInfo.weight).add(item.weight),
                                goodsMoney:(this.state.totalInfo.goodsMoney).add(item.goodsMoney),
                                other:(this.state.totalInfo.other).add(otherMoneyTotal)}
                            return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:'21%',fontSize:13,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                                <span style={{width:'28%',fontSize:13,textAlign:'center'}}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                <span style={{width:'19%',fontSize:13,textAlign:'center'}}>{item.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173)))}</span>
                                <span style={{width:'19%',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                                <span style={{width:'13%',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(otherMoneyTotal)}</span>
                            </div>)
                        }):<div/>}
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#808080'}}>
                            <span style={{width:'21%',color:'#D2855D',fontSize:12}}>{stringUtil.language(890)}</span>
                            <span style={{width:'28%',color:'#D2855D',fontSize:12,textAlign:'center'}}>{this.state.totalInfo.number}{stringUtil.language(1173)} {arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>
                            <span style={{width:'19%',color:'#D2855D',fontSize:12,textAlign:'center'}}></span>
                            <span style={{width:'19%',color:'#D2855D',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.goodsMoney)}</span>
                            <span style={{width:'13%',color:'#D2855D',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.other)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                            {this.state.currPurchaseOrderInfo.costs!=null?this.state.currPurchaseOrderInfo.costs.map((item,key)=>{
                                return(<span style={{marginRight:10}} key={key}>{item.costClauseName}：{arithUtil.fenToYuan(item.money)}</span>)
                            }):<div/>}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                            <span>{stringUtil.language(858)}{this.state.currPurchaseOrderInfo.describe}</span>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(1112)}：</span><span style={{color:'#F00',fontSize:30,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currPurchaseOrderInfo.money)}</span><span>{stringUtil.currency(1)}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{height:45,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                        {otherUtil.authorityFindCode("020203")!=null?<div style={{width:80,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#52A056',cursor:'pointer'}} onClick={()=>{
                            this.state.currPurchaseOrderItem = JSON.parse(JSON.stringify(this.state.currPurchaseOrderInfo));
                            this.setState({infoShowPop:false,editShowPop:!this.state.editShowPop});
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#FFF'}}>&#xe63a;</i>
                            <span style={{color:'#FFF',marginLeft:5}}>{stringUtil.language(1092)}</span>
                        </div>:''}
                        {otherUtil.authorityFindCode("020204")!=null?<div style={{width:80,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#F00',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                            this.setState({infoShowPop:false,delPurchaseOrderPop:!this.state.delPurchaseOrderPop});
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#FFF'}}>&#xe612;</i>
                            <span style={{color:'#FFF',marginLeft:5}}>{stringUtil.language(1113)}</span>
                        </div>:''}
                        <div style={{width:80,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#31BDE2',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                            const printArea = document.getElementById('printArea');
                            const iframe = document.createElement('IFRAME');
                            let doc = null;
                            iframe.setAttribute('style','position:absolute;width:0px;height:0px;left:500px;top:500px;');
                            document.body.appendChild(iframe);
                            doc = iframe.contentWindow.document;
                            doc.write(printArea.innerHTML);
                            doc.close();
                            iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                            iframe.contentWindow.print();
                            if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                            this.setState({isShowTabTitle:false});
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#FFF'}}>&#xe867;</i>
                            <span style={{color:'#FFF',marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                        {otherUtil.authorityFindCode("020402")!=null?<div style={{width:80,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#52A056',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                            let currPurchaseOrderItem = JSON.parse(JSON.stringify(this.state.currPurchaseOrderInfo));
                            this.setState({infoShowPop:false},()=>{
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'PurchaseToWholesaleAddActivity',mainStacks:[
                                    {title:stringUtil.language(1128),navigatePage:''},
                                    {title:stringUtil.language(735),navigatePage:'ProcureOrdersActivity',paramsPage:{pageIndex:this.state.pageIndex,cargoItemInfo:this.state.currCargoItem}},
                                    {title:stringUtil.language(833),navigatePage:'PurchaseToWholesaleAddActivity',paramsPage:{purchaseInfo:currPurchaseOrderItem}},
                                ]}});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#FFF'}}>&#xe63a;</i>
                            <span style={{color:'#FFF',marginLeft:5}}>{stringUtil.language(1037)}</span>
                        </div>:''}
                    </div>
                </div>} closeBnTopEvents={() => {this.setState({ infoShowPop: false, });}}/>
            <MaskModal visible={this.state.editShowPop} title={stringUtil.language(575)} width={700} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10,display:'flex',flexDirection:'column'}} key={this.state.currPurchaseOrderItem.id}>
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                        <span>{stringUtil.language(422)}{this.state.currPurchaseOrderItem.supplierName}</span>
                        <span>{stringUtil.language(856)}{this.state.currCargoItem?(this.state.currCargoItem.cargoMode==1?stringUtil.language(426):stringUtil.language(438)):''} / {this.state.currCargoItem?this.state.currCargoItem.cargoNo:''}</span>
                        <span>{stringUtil.language(892)}{this.state.currPurchaseOrderItem.warehouseName}</span>
                    </div>
                    <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'18%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                        <span style={{width:'20%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                        <span style={{width:'18%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                        <span style={{width:'18%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                        <span style={{width:'14%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1069)}({stringUtil.currency(1)})</span>
                        <span style={{width:'12%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1026)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'column',flexGrow:1,borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                        {(()=>{this.state.purchaseEditTotalInfo={number:0,weight:0,goodsMoney:0,otherMoneyTotal:0};})()}
                        {this.state.currPurchaseOrderItem.commodities.map((item,key)=>{
                            let otherMoneyTotal = 0;
                            if (item.costs!=null && item.costs.length>0) {
                                for(let i=0;i<item.costs.length;i++){
                                    let currTemp = item.costs[i];
                                    otherMoneyTotal = otherMoneyTotal.add(currTemp.money);
                                }
                            }
                            this.state.purchaseEditTotalInfo.number=this.state.purchaseEditTotalInfo.number+item.number;
                            this.state.purchaseEditTotalInfo.weight=(this.state.purchaseEditTotalInfo.weight).add(item.weight);
                            this.state.purchaseEditTotalInfo.goodsMoney=(this.state.purchaseEditTotalInfo.goodsMoney).add(item.goodsMoney);
                            this.state.purchaseEditTotalInfo.otherMoneyTotal=(this.state.purchaseEditTotalInfo.otherMoneyTotal).add(otherMoneyTotal);
                            return(<div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:'18%',fontSize:13,textAlign:'center',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                                <span style={{width:'20%',fontSize:13,textAlign:'center'}}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                <span style={{width:'18%',fontSize:13,textAlign:'center'}}>{item.mode==0?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode):arithUtil.fenToYuan(item.unitPrice,2)}{item.mode==1?('/'+(item.unitMaster?item.unitMaster:stringUtil.language(1173))):(this.state.weightMode==0?'/kg':'/'+stringUtil.language(1172))}</span>
                                <span style={{width:'18%',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                                <span style={{width:'14%',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(otherMoneyTotal)}</span>
                                <div style={{width:'12%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#808080',cursor:'pointer'}} onClick={()=>{
                                        this.state.currCommodityKey = key;
                                        this.state.currEditVegetableItem = JSON.parse(JSON.stringify(item));
                                        this.setState({editShowPopIndex:this.state.editShowPopIndex+1,editVegetableShowPop:true});
                                    }}>&#xe63a;</i>
                                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',cursor:'pointer'}} onClick={()=>{
                                        this.state.currPurchaseOrderItem.commodities.splice(key,1);
                                        this.purchaseOrderTotal();
                                        this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                    }}>&#xe612;</i>
                                </div>
                            </div>)
                        })}
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'18%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{stringUtil.language(890)}</span>
                            <span style={{width:'20%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{this.state.purchaseEditTotalInfo.number}{stringUtil.language(1173)} {arithUtil.keToWeight(this.state.purchaseEditTotalInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>
                            <span style={{width:'18%'}}></span>
                            <span style={{width:'18%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.purchaseEditTotalInfo.goodsMoney)}</span>
                            <span style={{width:'14%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.purchaseEditTotalInfo.otherMoneyTotal)}</span>
                            <span style={{width:'12%'}}></span>
                        </div>
                    </div>
                    <div style={{height:200}}>
                        <div style={{height:(200-40),display:'flex',flexDirection:'row'}}>
                            <div style={{width:'28%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currCommodities = [];
                                    this.setState({addVegetableShowPop:true});
                                }}>
                                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                                    <span style={{color:'#52A056'}}> {stringUtil.language(391)}</span>
                                </div>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    if (this.state.costsOtherOrderAll.length==0) {
                                        stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:2},['key','get',1],(successResponse)=>{
                                            if (successResponse.status==200) {
                                                this.state.costsOtherOrderAll = successResponse.data;
                                                this.setState({selectDiglogTagEntCostUpd:!this.state.selectDiglogTagEntCostUpd});
                                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                    }else{this.setState({selectDiglogTagEntCostUpd:!this.state.selectDiglogTagEntCostUpd});}
                                }}>
                                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#D2855D'}}>&#xe601;</i>
                                    <span style={{color:'#D2855D'}}> {stringUtil.language(574)}</span>
                                </div>
                                <MaskModal title={stringUtil.language(158)} visible={this.state.selectDiglogTagEntCostUpd} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                                    content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                                    {this.state.costsOtherOrderAll.map((item,key)=>{
                                        return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} key={key} onClick={()=>{
                                            if (this.state.currPurchaseOrderItem.costs==null) {
                                                this.state.currPurchaseOrderItem.costs = [];
                                            }
                                            this.state.currPurchaseOrderItem.costs.push({
                                                costClauseId:item.id,
                                                costClauseName:item.name,
                                                payee:item.payee,
                                                calMode:item.calMode,
                                                money:0
                                            });
                                            this.setState({selectDiglogTagEntCostUpd:false});
                                        }}>
                                            <span>{item.name}</span>
                                            <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                                            <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                        </div>);
                                    })}
                                </div>} closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCostUpd:false});}}/>
                            </div>
                            <div style={{width:'72%',height:'100%',overflow:'auto'}}>
                                {this.state.currPurchaseOrderItem.costs!=null?this.state.currPurchaseOrderItem.costs.map((item,key)=>{
                                    return(<div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}} key={key}>
                                        <div style={{width:'87%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <span>{item.costClauseName}</span>
                                            <input style={arithUtil.stylesAdd(styles.textInput,{width:70})} type='number' placeholder='' defaultValue={item.money?arithUtil.fenToYuan(item.money):''} onChange={(event)=>{
                                                this.state.currPurchaseOrderItem.costs[key].money = arithUtil.yuanToFen(event.target.value).round();
                                                this.purchaseOrderTotal();
                                                this.refs.moneyEditShishiRef.innerHTML=arithUtil.fenToYuan(this.state.currPurchaseOrderItem.money)+' '+stringUtil.currency(1);
                                            }}/><span style={{color:'#D2855D'}}> {stringUtil.currency(1)}</span>
                                        </div>
                                        <i className="iconfontIndexCss" style={{width:'13%',fontSize:25,color:'#F00',textAlign:'center',textAlignVertical:'center',cursor:'pointer'}} onClick={()=>{
                                            this.state.currPurchaseOrderItem.costs.splice(key,1);
                                            this.purchaseOrderTotal();
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}>&#xe612;</i>
                                    </div>)
                                }):<div/>}
                            </div>
                        </div>
                        <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(858)}</span>
                                <input style={arithUtil.stylesAdd(styles.inputText,{width:300})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} defaultValue={this.state.currPurchaseOrderItem.describe?this.state.currPurchaseOrderItem.describe:''} onChange={(event)=>{
                                    this.state.currPurchaseOrderItem.describe = event.target.value;
                                }}/>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(890)}</span><span style={{color:'#D2855D',fontSize:20,fontWeight:'bold'}} ref='moneyEditShishiRef'>{arithUtil.fenToYuan(this.state.currPurchaseOrderItem.money)} {stringUtil.currency(1)}</span>
                            </div>
                        </div>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currPurchaseOrderItem.commodities==null || this.state.currPurchaseOrderItem.commodities.length==0) {
                        ToastBox.error(stringUtil.language(190));return;
                    }
                    let purchaseInsertVo = {costs:[],commodities:[]};
                    purchaseInsertVo.money = this.state.currPurchaseOrderItem.money!=null?this.state.currPurchaseOrderItem.money:0;
                    purchaseInsertVo.describe = this.state.currPurchaseOrderItem.describe!=null?this.state.currPurchaseOrderItem.describe:'';
                    for(let i=0;i<this.state.currPurchaseOrderItem.costs.length;i++){
                        let costsTemp = {};
                        costsTemp.costClauseId = this.state.currPurchaseOrderItem.costs[i].costClauseId!=null?this.state.currPurchaseOrderItem.costs[i].costClauseId:0;
                        costsTemp.money = this.state.currPurchaseOrderItem.costs[i].money!=null?this.state.currPurchaseOrderItem.costs[i].money:0;
                        purchaseInsertVo.costs.push(costsTemp);
                    }
                    for(let i=0;i<this.state.currPurchaseOrderItem.commodities.length;i++){
                        let commodityTemp = {costs:[],commodityCosts:[]};
                        commodityTemp.commodityId = this.state.currPurchaseOrderItem.commodities[i].commodityId!=null?this.state.currPurchaseOrderItem.commodities[i].commodityId:0;
                        commodityTemp.standard = this.state.currPurchaseOrderItem.commodities[i].standard!=null?this.state.currPurchaseOrderItem.commodities[i].standard:0;
                        commodityTemp.reduce = this.state.currPurchaseOrderItem.commodities[i].reduce!=null?this.state.currPurchaseOrderItem.commodities[i].reduce:0;
                        commodityTemp.number = this.state.currPurchaseOrderItem.commodities[i].number!=null?this.state.currPurchaseOrderItem.commodities[i].number:0;
                        commodityTemp.weight = this.state.currPurchaseOrderItem.commodities[i].weight!=null?this.state.currPurchaseOrderItem.commodities[i].weight:0;
                        commodityTemp.unitPrice = this.state.currPurchaseOrderItem.commodities[i].unitPrice!=null?this.state.currPurchaseOrderItem.commodities[i].unitPrice:0;
                        commodityTemp.mode = this.state.currPurchaseOrderItem.commodities[i].mode!=null?this.state.currPurchaseOrderItem.commodities[i].mode:0;
                        commodityTemp.goodsMoney = this.state.currPurchaseOrderItem.commodities[i].goodsMoney!=null?this.state.currPurchaseOrderItem.commodities[i].goodsMoney:0;
                        if (this.state.currPurchaseOrderItem.commodities[i].costs==null)this.state.currPurchaseOrderItem.commodities[i].costs=[];
                        for(let j=0;j<this.state.currPurchaseOrderItem.commodities[i].costs.length;j++){
                            let costsSource = JSON.parse(JSON.stringify(this.state.currPurchaseOrderItem.commodities[i].costs[j]));
                            commodityTemp.costs.push({
                                costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                                money:costsSource.money!=null?costsSource.money:0,
                            });
                        }
                        if (this.state.currPurchaseOrderItem.commodities[i].commodityCosts==null)this.state.currPurchaseOrderItem.commodities[i].commodityCosts=[];
                        for(let j=0;j<this.state.currPurchaseOrderItem.commodities[i].commodityCosts.length;j++){
                            let costsSource = JSON.parse(JSON.stringify(this.state.currPurchaseOrderItem.commodities[i].commodityCosts[j]));
                            commodityTemp.commodityCosts.push({
                                costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                                money:costsSource.money!=null?costsSource.money:0,
                            });
                        }
                        purchaseInsertVo.commodities.push(commodityTemp);
                    }
      
                    this.setState({ editShowPop: false });
                    stringUtil.httpRequest(urlUtil.SALE_PURCHASE_URL+'/'+this.state.currPurchaseOrderItem.id,purchaseInsertVo,['json','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({ editShowPop: false, });}}/>
            <MaskModal title={stringUtil.language(392)} visible={this.state.addVegetableShowPop} width={760} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'11%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                                this.state.keySearch = event.target.value;
                                if(this.state.keySearch=='')this.searchCommoditys();
                            }} placeholder={stringUtil.language(32)}/>&nbsp;&nbsp;
                            <span style={{width:arithUtil.pxToDp(50),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.searchCommoditys();
                            }}>{stringUtil.language(1034)}</span>
                        </div>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(100)})} onChange={(event)=>{
                                this.state.currCommodityTypeItem = JSON.parse(event.target.value);
                                this.searchCommoditys();
                            }}>
                            {this.state.commodityTypesAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                        </select>
                    </div>
                    <div style={{height:'89%',overflow:'auto'}}>
                        {this.state.commoditysData.map((item,key)=>{
                            return(<div key={key} style={arithUtil.stylesAdd(styles.modalAddVegetableItem,{cursor:'pointer'})}>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} onClick={()=>{
                                    let isHave = false;
                                    for(let i=0;i<this.state.currCommodities.length;i++){
                                        let comItem = this.state.currCommodities[i];
                                        if (comItem.commodityId==item.id) {
                                            isHave = true;
                                            this.state.currCommodities.splice(i,1);
                                            break;
                                        }
                                    }
                                    if (!isHave) {
                                        this.state.currCommodities.push({
                                            mode:0,
                                            commodityId:item.id,
                                            commodityName:item.name,
                                            commodityCode:item.code,
                                            standard:item.standard,
                                            reduce:item.reduce,
                                            sendNumber:0,
                                            sendWeight:0,
                                            costs:item.costs,//stringUtil.language(1063)对应stringUtil.language(1037)stringUtil.language(886)目
                                        });
                                    }
                                    this.setState({currCommodities:this.state.currCommodities});
                                }}><div style={{width:50,position:'relative',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{width:50,color:'#F00',fontWeight:'bold',textAlign:'center',textOverflow:'ellipsis',overflow:'hidden',backgroundColor:'rgba(255,255,255,0.2)',position:'absolute'}}>{stringUtil.showCode()==2?(item.code?item.code:''):''}</span>
                                        {localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?'':<img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>}
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',marginLeft:8}}>
                                        <span style={{fontSize:17}}>{!stringUtil.showCode()||stringUtil.showCode()==2?item.name:stringUtil.showCode()==1?(item.code?item.code:''):''}</span>
                                        <span style={{color:'#808080',fontSize:12}}>{item.commoditySpecs}</span>
                                    </div>
                                </div>
                                {this.state.currCommodities.map((itemCurr,key)=>{
                                    if (itemCurr.commodityId == item.id) {
                                        return(<div style={{display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                            <input style={arithUtil.stylesAdd(styles.textInput,{width:80,color:this.state.currCommodities[key].sendNumber!=0?'#696969':'#A9A9A9'})} type='number' placeholder={stringUtil.language(1021)} onChange={(event)=>{
                                                this.state.currCommodities[key].sendNumber = parseInt(event.target.value);
                                                if(this.state.currCommodities[key].standard!=null){
                                                    this.state.currCommodities[key].sendWeight=(this.state.currCommodities[key].standard).mul(parseInt(event.target.value));
                                                    (this.refs)['comChoiceWeightShishiRef'+key].value = this.state.currCommodities[key].sendWeight!=null?arithUtil.keToWeight(this.state.currCommodities[key].sendWeight,this.state.weightMode):0;
                                                }
                                                let goodsMoneyTemp = 0;
                                                if(this.state.currCommodities[key].unitPrice==null)this.state.currCommodities[key].unitPrice=0;
                                                if (this.state.currCommodities[key].mode==null||this.state.currCommodities[key].mode==0) {
                                                    if(this.state.currCommodities[key].sendWeight==null)this.state.currCommodities[key].sendWeight=0;
                                                    goodsMoneyTemp = (this.state.currCommodities[key].unitPrice).mul(arithUtil.keToWeight(this.state.currCommodities[key].sendWeight,0));
                                                }else{
                                                    if(this.state.currCommodities[key].sendNumber==null)this.state.currCommodities[key].sendNumber=0;
                                                    goodsMoneyTemp = (this.state.currCommodities[key].unitPrice).mul(this.state.currCommodities[key].sendNumber);
                                                }
                                                this.state.currCommodities[key].goodsMoney = goodsMoneyTemp.round();
                                            }}/>
                                            <input style={arithUtil.stylesAdd(styles.textInput,{width:80,color:this.state.currCommodities[key].sendWeight!=0?'#696969':'#A9A9A9',marginLeft:15})} ref={'comChoiceWeightShishiRef'+key} type='number' placeholder={stringUtil.language(1022)} onChange={(event)=>{
                                                this.state.currCommodities[key].sendWeight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                                let goodsMoneyTemp = 0;
                                                if(this.state.currCommodities[key].unitPrice==null)this.state.currCommodities[key].unitPrice=0;
                                                if (this.state.currCommodities[key].mode==null||this.state.currCommodities[key].mode==0) {
                                                    if(this.state.currCommodities[key].sendWeight==null)this.state.currCommodities[key].sendWeight=0;
                                                    goodsMoneyTemp = (this.state.currCommodities[key].unitPrice).mul(arithUtil.keToWeight(this.state.currCommodities[key].sendWeight,0));
                                                }else{
                                                    if(this.state.currCommodities[key].sendNumber==null)this.state.currCommodities[key].sendNumber=0;
                                                    goodsMoneyTemp = (this.state.currCommodities[key].unitPrice).mul(this.state.currCommodities[key].sendNumber);
                                                }
                                                this.state.currCommodities[key].goodsMoney = goodsMoneyTemp.round();
                                            }}/>
                                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                                                if(this.state.currCommodities[key].mode==null||this.state.currCommodities[key].mode==0){
                                                    this.state.currCommodities[key].mode=1;
                                                    this.state.currCommodities[key].unitPrice = arithUtil.unitPriceTo(this.state.currCommodities[key].unitPrice,0,this.state.weightMode);
                                                }else{
                                                    this.state.currCommodities[key].mode=0;
                                                    this.state.currCommodities[key].unitPrice = arithUtil.unitPriceTo(this.state.currCommodities[key].unitPrice,this.state.weightMode,0);
                                                }
                                                if(this.state.currCommodities[key].standard!=null){
                                                    this.state.currCommodities[key].sendWeight=(this.state.currCommodities[key].standard).mul(this.state.currCommodities[key].sendNumber);
                                                    (this.refs)['comChoiceWeightShishiRef'+key].value = this.state.currCommodities[key].sendWeight!=null?arithUtil.keToWeight(this.state.currCommodities[key].sendWeight,this.state.weightMode):0;
                                                }
                                                let goodsMoneyTemp = 0;
                                                if(this.state.currCommodities[key].unitPrice==null)this.state.currCommodities[key].unitPrice=0;
                                                if (this.state.currCommodities[key].mode==null||this.state.currCommodities[key].mode==0) {
                                                    if(this.state.currCommodities[key].sendWeight==null)this.state.currCommodities[key].sendWeight=0;
                                                    goodsMoneyTemp = (this.state.currCommodities[key].unitPrice).mul(arithUtil.keToWeight(this.state.currCommodities[key].sendWeight,0));
                                                }else{
                                                    if(this.state.currCommodities[key].sendNumber==null)this.state.currCommodities[key].sendNumber=0;
                                                    goodsMoneyTemp = (this.state.currCommodities[key].unitPrice).mul(this.state.currCommodities[key].sendNumber);
                                                }
                                                this.state.currCommodities[key].goodsMoney = goodsMoneyTemp.round();
                                                this.setState({currCommodities:this.state.currCommodities});
                                            }}>
                                                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe6a3;</i>
                                                {this.state.currCommodities[key].mode==null||this.state.currCommodities[key].mode==0?<span style={{color:'#52A056'}}>{stringUtil.language(1114)}</span>:<span style={{color:'#52A056'}}>{stringUtil.language(1115)}</span>}
                                            </div>
                                            <input style={arithUtil.stylesAdd(styles.textInput,{width:80,color:this.state.currCommodities[key].unitPrice!=0?'#696969':'#A9A9A9',marginLeft:15})} type='number' placeholder={stringUtil.language(1050)} onChange={(event)=>{
                                                let goodsMoneyTemp = 0;
                                                if (this.state.currCommodities[key].mode==null||this.state.currCommodities[key].mode==0) {
                                                    this.state.currCommodities[key].unitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                                    if(this.state.currCommodities[key].sendWeight==null)this.state.currCommodities[key].sendWeight=0;
                                                    goodsMoneyTemp = (arithUtil.yuanToFen(event.target.value)).mul(arithUtil.keToWeight(this.state.currCommodities[key].sendWeight,this.state.weightMode));
                                                }else{
                                                    this.state.currCommodities[key].unitPrice = arithUtil.yuanToFen(event.target.value);
                                                    if(this.state.currCommodities[key].sendNumber==null)this.state.currCommodities[key].sendNumber=0;
                                                    goodsMoneyTemp = (arithUtil.yuanToFen(event.target.value)).mul(this.state.currCommodities[key].sendNumber);
                                                }
                                                this.state.currCommodities[key].goodsMoney = goodsMoneyTemp.round();
                                            }}/>
                                        </div>);
                                    }
                                })}
                            </div>)
                        })}
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currPurchaseOrderItem.commodities==null) {
                        this.state.currPurchaseOrderItem.commodities = [];
                    }
                    let currSelectCommodities = JSON.parse(JSON.stringify(this.state.currCommodities));
                    if (currSelectCommodities.length>0) {
                        for(let i=0;i<currSelectCommodities.length;i++){
                            this.state.currPurchaseOrderItem.commodities.push({
                                commodityId:currSelectCommodities[i].commodityId,
                                commodityName:currSelectCommodities[i].commodityName,
                                commodityCode:currSelectCommodities[i].commodityCode,
                                commoditySpecs:currSelectCommodities[i].commoditySpecs,
                                number:currSelectCommodities[i].sendNumber,
                                weight:currSelectCommodities[i].sendWeight,
                                standard:currSelectCommodities[i].standard,
                                reduce:currSelectCommodities[i].reduce,
                                commodityCosts:currSelectCommodities[i].costs,
                                unitPrice:currSelectCommodities[i].unitPrice,
                                mode:currSelectCommodities[i].mode,
                                goodsMoney:currSelectCommodities[i].goodsMoney,
                            });
                        }
                    }
                    this.purchaseOrderTotal();
                    this.setState({addVegetableShowPop: false});
                }} closeBnEvents={() => {this.setState({ addVegetableShowPop: false, })}}/>
            <MaskModal visible={this.state.editVegetableShowPop} title={stringUtil.language(576)} width={arithUtil.pxToDp(600)} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.editShowPopIndex}>
                    <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(323)}{this.state.currCommodityKey!=-1?(stringUtil.showCode()==1||stringUtil.showCode()==2?(this.state.currEditVegetableItem.commodityCode?this.state.currEditVegetableItem.commodityCode:''):'')+(!stringUtil.showCode()||stringUtil.showCode()==2?this.state.currEditVegetableItem.commodityName:''):''}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(324)}</span>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currEditVegetableItem.mode = 0;
                                    if(this.state.currEditVegetableItem.unitPrice==null)this.state.currEditVegetableItem.unitPrice=0;
                                    this.state.currEditVegetableItem.unitPrice = arithUtil.unitPriceTo(this.state.currEditVegetableItem.unitPrice,this.state.weightMode,0);
                                    this.state.currEditVegetableItem.goodsMoney = (this.state.currEditVegetableItem.unitPrice).mul(arithUtil.keToWeight(this.state.currEditVegetableItem.weight,0)).round();
                                    this.setState({currEditVegetableItem:this.state.currEditVegetableItem});
                                }}><i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currCommodityKey!=-1&&this.state.currEditVegetableItem.mode==0?'#D2855D':'#D3D3D3'}}>&#xe614;</i>
                                    <span> {stringUtil.language(577)}</span>
                                </div>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currEditVegetableItem.mode = 1;
                                    if(this.state.currEditVegetableItem.unitPrice==null)this.state.currEditVegetableItem.unitPrice=0;
                                    this.state.currEditVegetableItem.unitPrice = arithUtil.unitPriceTo(this.state.currEditVegetableItem.unitPrice,0,this.state.weightMode);
                                    this.state.currEditVegetableItem.goodsMoney = (this.state.currEditVegetableItem.unitPrice).mul(this.state.currEditVegetableItem.number).round();
                                    this.setState({currEditVegetableItem:this.state.currEditVegetableItem});
                                }}>
                                    <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currCommodityKey!=-1&&this.state.currEditVegetableItem.mode==1?'#D2855D':'#D3D3D3'}}>&#xe614;</i>
                                    <span> {stringUtil.language(578)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1021)}/{stringUtil.language(862)}</span>
                            <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' defaultValue={this.state.currCommodityKey!=-1?this.state.currEditVegetableItem.number:0} onChange={(event)=>{
                                this.state.currEditVegetableItem.number = parseInt(event.target.value);
                                if(this.state.currEditVegetableItem.standard!=null){
                                    this.state.currEditVegetableItem.weight=(this.state.currEditVegetableItem.standard).mul(parseInt(event.target.value));
                                    this.refs.comEditWeightShishiRef.value = this.state.currEditVegetableItem.weight!=null?arithUtil.keToWeight(this.state.currEditVegetableItem.weight,this.state.weightMode):0;
                                }
                                if(this.state.currEditVegetableItem.mode==0) {
                                    if(this.state.currEditVegetableItem.weight!=null)this.state.currEditVegetableItem.goodsMoney = (this.state.currEditVegetableItem.unitPrice).mul(arithUtil.keToWeight(this.state.currEditVegetableItem.weight,0)).round();
                                }else{
                                    this.state.currEditVegetableItem.goodsMoney = (this.state.currEditVegetableItem.unitPrice).mul(parseInt(event.target.value)).round();
                                }
                                this.refs.comMoneyShishiRef.innerHTML = (this.state.currCommodityKey!=-1&&!isNaN(this.state.currEditVegetableItem.goodsMoney))?arithUtil.fenToYuan(this.state.currEditVegetableItem.goodsMoney):0;
                            }}/>
                            <span> {stringUtil.language(1173)} </span>
                            <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} ref='comEditWeightShishiRef' type='number' placeholder='' defaultValue={this.state.currCommodityKey!=-1?arithUtil.keToWeight(this.state.currEditVegetableItem.weight,this.state.weightMode):0} onChange={(event)=>{
                                this.state.currEditVegetableItem.weight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                if (this.state.currEditVegetableItem.mode==0) {
                                    this.state.currEditVegetableItem.goodsMoney = (this.state.currEditVegetableItem.unitPrice).mul(this.state.weightMode==0?parseFloat(event.target.value):parseFloat(event.target.value)/2).round();
                                    this.refs.comMoneyShishiRef.innerHTML = (this.state.currCommodityKey!=-1&&!isNaN(this.state.currEditVegetableItem.goodsMoney))?arithUtil.fenToYuan(this.state.currEditVegetableItem.goodsMoney):0;
                                }
                            }}/>
                            <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(579)}：</span>
                            <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' defaultValue={this.state.currCommodityKey!=-1?(this.state.currEditVegetableItem.mode==1?arithUtil.fenToYuan(this.state.currEditVegetableItem.unitPrice,2):(arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.currEditVegetableItem.unitPrice,2),0,this.state.weightMode))):0} onChange={(event)=>{
                                let goodsMoneyTemp = 0;
                                if (this.state.currEditVegetableItem.mode==0) {
                                    this.state.currEditVegetableItem.unitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                    goodsMoneyTemp = (arithUtil.yuanToFen(event.target.value)).mul(arithUtil.keToWeight(this.state.currEditVegetableItem.weight,this.state.weightMode));
                                }else{
                                    this.state.currEditVegetableItem.unitPrice = arithUtil.yuanToFen(event.target.value);
                                    goodsMoneyTemp = (arithUtil.yuanToFen(event.target.value)).mul(this.state.currEditVegetableItem.number);
                                }
                                this.state.currEditVegetableItem.goodsMoney = goodsMoneyTemp.round();
                                this.refs.comMoneyShishiRef.innerHTML = (this.state.currCommodityKey!=-1&&!isNaN(this.state.currEditVegetableItem.goodsMoney))?arithUtil.fenToYuan(this.state.currEditVegetableItem.goodsMoney):0;
                            }}/>
                            <span> {this.state.currCommodityKey!=-1&&this.state.currEditVegetableItem.mode==0?stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):stringUtil.currency(1)+'/'+stringUtil.language(1173)}</span>
                        </div>
                    </div>
                    <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(580)}：</span>
                            <span style={{fontSize:25,fontWeight:'bold',color:'#F00'}} ref='comMoneyShishiRef'>{this.state.currCommodityKey!=-1&&!isNaN(this.state.currEditVegetableItem.goodsMoney)?arithUtil.fenToYuan(this.state.currEditVegetableItem.goodsMoney):0}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1024)}/{stringUtil.language(864)}</span>
                            <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' defaultValue={this.state.currCommodityKey!=-1?arithUtil.keToWeight(this.state.currEditVegetableItem.standard,this.state.weightMode):0} onChange={(event)=>{
                                this.state.currEditVegetableItem.standard = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                            }}/>
                            <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}，</span>
                            <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' defaultValue={this.state.currCommodityKey!=-1?arithUtil.keToWeight(this.state.currEditVegetableItem.reduce,this.state.weightMode):0} onChange={(event)=>{
                                this.state.currEditVegetableItem.reduce = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                            }}/>
                            <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}</span>
                        </div>
                    </div>
                    <div style={{position:'absolute',left:0,top:arithUtil.pxToDp(140),right:0,bottom:0}} >
                        {/* <div style={{height:'10%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                            <span>{stringUtil.language(89)}</span>
                            <i className="iconfontIndexCss" style={{fontSize:30,color:'#C0C0C0',cursor:'pointer'}} onClick={()=>{
                                if (this.state.costsAll.length==0) {
                                    stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:0},['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.costsAll = successResponse.data;
                                            this.setState({selectDiglogTagEntCostE: !this.state.selectDiglogTagEntCostE});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{this.setState({selectDiglogTagEntCostE: !this.state.selectDiglogTagEntCostE});}
                            }}>&#xe601;</i>
                            <MaskModal title='{stringUtil.language(33)}' visible={this.state.selectDiglogTagEntCostE} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                                        {this.state.costsAll.map((item,key)=>{
                                        if (this.state.currShopCommodityItem.targetType==0 || (this.state.currShopCommodityItem.targetType!=0&&item.payee!=0)) {
                                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'center',paddingLeft:arithUtil.pxToDp(10)}} key={key} onClick={()=>{
                                                if (this.state.currEditVegetableItem.commodityCosts==null) {
                                                    this.state.currEditVegetableItem.commodityCosts = [];
                                                }
                                                this.state.currEditVegetableItem.commodityCosts.push({
                                                    costClauseId:item.id,
                                                    costClauseName:item.name,
                                                    payee:item.payee,
                                                    calMode:item.calMode,
                                                    money:0,
                                                });
                                                this.setState({selectDiglogTagEntCostE:false});
                                            }}>
                                                <span>{item.name}</span>
                                                <span>{item.scope==0?'stringUtil.language(865)':item.scope==1?'stringUtil.language(393)':item.scope==2?'stringUtil.language(866)':item.scope==3?'stringUtil.language(394)':item.scope==4?'stringUtil.language(867)':item.scope==5?'stringUtil.language(395)':'stringUtil.language(278)'}</span>
                                                <span>{item.calMode==0?'stringUtil.language(868)':item.calMode==1?'stringUtil.language(869)':item.calMode==2?'stringUtil.language(396)':'stringUtil.language(1029)'}</span>
                                            </div>);
                                        }
                                        })}
                                    </div>}
                                closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCostE:false});}}/>
                        </div>
                        <div style={{height:'40%',overflow:'auto'}}>
                            {this.state.currCommodityKey!=-1&&this.state.currEditVegetableItem.commodityCosts!=null?this.state.currEditVegetableItem.commodityCosts.map((item,key)=>{
                                return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}} key={key}>
                                    <span style={{width:'36%',color:'#808080'}}>{key+1+'、'+item.costClauseName}</span>
                                    <span style={{width:'18%',color:'#808080'}}>{item.calMode==0?'stringUtil.language(868)':item.calMode==1?'stringUtil.language(869)':item.calMode==2?'stringUtil.language(396)':'stringUtil.language(1029)'}</span>
                                    {item.calMode==0?<div style={{width:'33%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' value={arithUtil.fenToYuan(item.money)} onChange={(event)=>{
                                            this.state.currEditVegetableItem.commodityCosts[key].money = arithUtil.yuanToFen(event.target.value);
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}/>
                                        <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                    item.calMode==1?<div style={{width:'33%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' value={arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money),0,this.state.weightMode)} onChange={(event)=>{
                                            this.state.currEditVegetableItem.commodityCosts[key].money = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}/>
                                        <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{this.state.weightMode==0?'stringUtil.language(1023)':'stringUtil.language(1172)'}</span></div>:
                                    item.calMode==2?<div style={{width:'33%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' value={item.money} onChange={(event)=>{
                                            this.state.currEditVegetableItem.commodityCosts[key].money = parseFloat(event.target.value);
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}/>
                                        <span style={{color:'#D2855D'}}> %</span></div>:
                                    <div style={{width:'33%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' value={arithUtil.fenToYuan(item.money)} onChange={(event)=>{
                                            this.state.currEditVegetableItem.commodityCosts[key].money = arithUtil.yuanToFen(event.target.value);
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}/></div>}
                                    <i className="iconfontIndexCss" style={{width:'13%',fontSize:25,color:'#F00',textAlign:'center',textAlignVertical:'center',cursor:'pointer'}} onClick={()=>{
                                        this.state.currEditVegetableItem.commodityCosts.splice(key,1);
                                        this.setState({currEditVegetableItem:this.state.currEditVegetableItem});
                                    }}>&#xe612;</i>
                                </div>)
                            }):<div/>}
                        </div> */}
                        <div style={{height:'10%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                            <span>{stringUtil.language(160)}</span>
                            <i className="iconfontIndexCss" style={{fontSize:30,color:'#C0C0C0',cursor:'pointer'}} onClick={()=>{
                                if (this.state.costsOtherAll.length==0) {
                                    stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:3},['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.costsOtherAll = successResponse.data;
                                            this.setState({selectDiglogTagEntCostOth: !this.state.selectDiglogTagEntCostOth});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{this.setState({selectDiglogTagEntCostOth: !this.state.selectDiglogTagEntCostOth});}
                            }}>&#xe601;</i>
                            <MaskModal title={stringUtil.language(158)} visible={this.state.selectDiglogTagEntCostOth} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                                {this.state.costsOtherAll.map((item,key)=>{
                                    return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} key={key} onClick={()=>{
                                        if (this.state.currEditVegetableItem.costs==null) {
                                            this.state.currEditVegetableItem.costs = [];
                                        }
                                        this.state.currEditVegetableItem.costs.push({
                                            costClauseId:item.id,
                                            costClauseName:item.name,
                                            payee:item.payee,
                                            calMode:item.calMode,
                                            money:0
                                        });
                                        this.setState({selectDiglogTagEntCostOth:false});
                                    }}>
                                        <span>{item.name}</span>
                                        <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                                        <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                    </div>);
                                })}
                            </div>} closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCostOth:false});}}/>
                        </div>
                        <div style={{height:'40%',overflow:'auto'}}>
                            {this.state.currCommodityKey!=-1&&this.state.currEditVegetableItem.costs!=null?this.state.currEditVegetableItem.costs.map((item,key)=>{
                                return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}} key={key}>
                                    <span style={{width:'42%',color:'#808080'}}>{key+1+'、'+item.costClauseName}</span>
                                    <div style={{width:'39%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(120)})} type='number' placeholder='' defaultValue={item.money?arithUtil.fenToYuan(item.money):''} onChange={(event)=>{
                                            this.state.currEditVegetableItem.costs[key].money = arithUtil.yuanToFen(event.target.value).round();
                                        }}/>
                                        <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}</span>
                                    </div>
                                    <i className="iconfontIndexCss" style={{width:'19%',fontSize:25,color:'#F00',textAlign:'center',textAlignVertical:'center',cursor:'pointer'}} onClick={()=>{
                                        this.state.currEditVegetableItem.costs.splice(key,1);
                                        this.setState({currEditVegetableItem:this.state.currEditVegetableItem});
                                    }}>&#xe612;</i>
                                    {/* {item.calMode==0?<div style={{width:'33%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' value={arithUtil.fenToYuan(item.money)} onChange={(event)=>{
                                            this.state.currEditVegetableItem.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}/>
                                        <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                    item.calMode==1?<div style={{width:'33%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' value={arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money),0,this.state.weightMode)} onChange={(event)=>{
                                            this.state.currEditVegetableItem.costs[key].money = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}/>
                                        <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{this.state.weightMode==0?'stringUtil.language(1023)':'stringUtil.language(1172)'}</span></div>:
                                    item.calMode==2?<div style={{width:'33%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' value={item.money} onChange={(event)=>{
                                            this.state.currEditVegetableItem.costs[key].money = parseFloat(event.target.value);
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}/>
                                        <span style={{color:'#D2855D'}}> %</span></div>:
                                    <div style={{width:'33%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(70)})} type='number' placeholder='' value={arithUtil.fenToYuan(item.money)} onChange={(event)=>{
                                            this.state.currEditVegetableItem.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                            this.setState({currPurchaseOrderItem:this.state.currPurchaseOrderItem});
                                        }}/></div>} */}
                                </div>)
                            }):<div/>}
                        </div>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    this.state.currPurchaseOrderItem.commodities[this.state.currCommodityKey] = JSON.parse(JSON.stringify(this.state.currEditVegetableItem));
                    this.purchaseOrderTotal();
                    this.setState({editVegetableShowPop: false});
                }} closeBnEvents={() => {this.setState({editVegetableShowPop: false})}}/>
            <MaskModal visible={this.state.delPurchaseOrderPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {stringUtil.language(65)}
                        </div>}
                okBnEvents={(event) => {
                    this.setState({delPurchaseOrderPop: false});
                    stringUtil.httpRequest(urlUtil.SALE_PURCHASE_URL+'/'+this.state.currPurchaseOrderInfo.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={()=>{this.setState({delPurchaseOrderPop: false});}}/>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(30),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    modalAddVegetableItem:{
        height:arithUtil.pxToDp(55),
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottomStyle:'solid',
        borderBottomWidth:1,
        borderBottomColor:'#DCDCDC',
        paddingLeft:arithUtil.pxToDp(10),
        paddingRight:arithUtil.pxToDp(10)
    },
    modalAddVegItemInput:{
        width:arithUtil.pxToDp(80),
        height:arithUtil.pxToDp(35),
        padding:0,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:3,
        textAlign:'center',
        textAlignVertical:'center',
    },
};