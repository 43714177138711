import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';

 //自营商品收发库存
 export default class InOutWarehousesActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            warehouseId:-1,
            warehouseName:stringUtil.language(453),
            commodityName:'',
            commoditySpecs:'',
            targetType:-1,
            targetId:-1,
            targetName:stringUtil.language(517),
            startDate:arithUtil.getCurrDateTime(1),
            endDate:arithUtil.getCurrDateTime(1),
        },
        owners:[{targetType:-1,targetId:-1,targetName:stringUtil.language(517)}],
        stockCommodityDO:[],stockCommodityDODatas:[],
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll = successResponse.data;
                this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
                this.initData();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_STOCK_COMMODITY_RANGE_URL,{
            startDate:this.state.searchs.startDate,
            endDate:this.state.searchs.endDate,
            warehouseId:this.state.searchs.warehouseId,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.stockCommodityDO=successResponse.data;
                if(this.state.stockCommodityDO.length>0&&this.state.owners.length<2){//整理stringUtil.language(1034)条stringUtil.language(1173)的数据
                    for(let i=0;i<this.state.stockCommodityDO.length;i++){
                        let dataTemp = this.state.stockCommodityDO[i];
                        let isHavaOwner=0;
                        for(let j=0;j<this.state.owners.length;j++){
                            let ownerTemp = this.state.owners[j];
                            if(dataTemp.targetType==ownerTemp.targetType&&dataTemp.targetId==ownerTemp.targetId){
                                isHavaOwner=1;break;
                            }
                        }
                        if(!isHavaOwner)this.state.owners.push({
                            targetType:dataTemp.targetType,
                            targetId:dataTemp.targetId,
                            targetName:dataTemp.targetName,
                        });
                    }
                }
                this.searchsData();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchsData(){
        this.state.stockCommodityDODatas=[];
        for(let i=0;i<this.state.stockCommodityDO.length;i++){//stringUtil.language(1034)
            let dataTemp = this.state.stockCommodityDO[i];
            if((this.state.searchs.targetType==-1&&this.state.searchs.targetId==-1)||(dataTemp.targetType==this.state.searchs.targetType&&dataTemp.targetId==this.state.searchs.targetId)){
                if((dataTemp.commodityName).indexOf(this.state.searchs.commodityName)!=-1){
                    if((dataTemp.commoditySpecs?dataTemp.commoditySpecs:'').indexOf(this.state.searchs.commoditySpecs)!=-1){
                        this.state.stockCommodityDODatas.push(dataTemp);
                    }
                }
            }
        }
        this.setState({stockCommodityDODatas:this.state.stockCommodityDODatas});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(888)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.startDate)} onChange={(date) => {
                                this.state.searchs.startDate = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div><span style={{paddingLeft:3,paddingRight:3}}>{stringUtil.language(1174)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.endDate)} onChange={(date) => {
                                this.state.searchs.endDate = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(892)}</span>
                            <select style={{width:200,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                                this.state.searchs.warehouseName = JSON.parse(event.target.value).name;
                                this.initData();
                            }}>
                            {(this.state.warehousesAll?this.state.warehousesAll:[]).map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                            </select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(856)}</span>
                            <select style={{width:150,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.targetType = JSON.parse(event.target.value).targetType;
                                this.state.searchs.targetId = JSON.parse(event.target.value).targetId;
                                this.state.searchs.targetName = JSON.parse(event.target.value).targetName;
                                this.searchsData();
                            }}>
                            {(this.state.owners?this.state.owners:[]).map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.targetName?item.targetName:item.targetType==1?stringUtil.language(1051):''}</option>)
                            })}
                            </select>
                        </div>
                        <div style={{width:200,height:30,display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <SearchInput onChangeEvents={(value)=>{
                                this.state.searchs.commodityName = value;
                                this.searchsData();
                            }} placeholder={stringUtil.language(35)}/>
                        </div>
                        <div style={{width:150,height:30,display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <SearchInput onChangeEvents={(value)=>{
                                this.state.searchs.commoditySpecs = value;
                                this.searchsData();
                            }} placeholder={stringUtil.language(1010)+stringUtil.language(1105)+'..'}/>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:15,cursor:'pointer'}} onClick={()=>{
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="31"><b><font size="3">${stringUtil.language(1275)}（${this.state.searchs.startDate+stringUtil.language(1174)+this.state.searchs.endDate}）</font></b></td></tr>
                            <tr><td align="center" rowspan="2"><b><font size="3">${stringUtil.language(1156)}</font></b></td>
                            <td align="center" rowspan="2"><b><font size="3">${stringUtil.language(518)}</font></b></td>
                            <td align="center" rowspan="2"><b><font size="3">${stringUtil.language(1105)}</font></b></td>
                            <td align="center" rowspan="2"><b><font size="3">${stringUtil.language(1049)}</font></b></td>
                            <td align="center" colspan="5"><b><font size="3">${stringUtil.language(520)}</font></b></td>
                            <td align="center" colspan="5"><b><font size="3">${stringUtil.language(521)}</font></b></td>
                            <td align="center" colspan="7"><b><font size="3">${stringUtil.language(1276)}</font></b></td>
                            <td align="center" colspan="5"><b><font size="3">${stringUtil.language(1277)}</font></b></td>
                            <td align="center" colspan="5"><b><font size="3">${stringUtil.language(483)}</font></b></td></tr>
                            <tr><td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}(${this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)})</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1296)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1297)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(947)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}(${this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)})</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1296)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1297)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(947)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}(${this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)})</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1296)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1297)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(889)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(947)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1120)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}(${this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)})</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1296)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1297)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(947)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}(${this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)})</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1296)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1297)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(947)}</font></b></td></tr>`);
                            let totalInfo={beforeNumber:0,beforeNumberBlance:0,beforeWeight:0,beforeMoney:0,inNumber:0,inNumberBlance:0,inWeight:0,inMoney:0,
                                outNumber:0,outNumberBlance:0,outWeight:0,outMoney:0,outCost:0,moneyPal:0,otherNumber:0,otherNumberBlance:0,otherWeight:0,otherMoney:0,number:0,numberBlance:0,weight:0,money:0};
                            for(let key=0;key<this.state.stockCommodityDODatas.length;key++){let item=this.state.stockCommodityDODatas[key];
                                let beforeNumber=parseInt((item.beforeNumber).div(item.unitScale,1)),beforeNumberBlance=parseInt((item.beforeNumber)%(item.unitScale));
                                let beforeNumberCost=(item.beforeNumber?(item.beforeMoney).div(item.beforeNumber,2):0).mul(item.unitScale),beforeWeightCost=item.beforeWeight?(item.beforeMoney).div(arithUtil.keToWeight(item.beforeWeight,this.state.weightMode),2):0;
                                totalInfo.beforeNumber=(totalInfo.beforeNumber).add(beforeNumber);
                                totalInfo.beforeNumberBlance=(totalInfo.beforeNumberBlance).add(beforeNumberBlance);
                                totalInfo.beforeWeight=(totalInfo.beforeWeight).add(item.beforeWeight);
                                totalInfo.beforeMoney=(totalInfo.beforeMoney).add(item.beforeMoney);
                                let inNumber=parseInt((item.inNumber).div(item.unitScale,1)),inNumberBlance=parseInt((item.inNumber)%(item.unitScale));
                                let inNumberCost=(item.inNumber?(item.inMoney).div(item.inNumber,2):0).mul(item.unitScale),inWeightCost=item.inWeight?(item.inMoney).div(arithUtil.keToWeight(item.inWeight,this.state.weightMode),2):0;
                                totalInfo.inNumber=(totalInfo.inNumber).add(inNumber);
                                totalInfo.inNumberBlance=(totalInfo.inNumberBlance).add(inNumberBlance);
                                totalInfo.inWeight=(totalInfo.inWeight).add(item.inWeight);
                                totalInfo.inMoney=(totalInfo.inMoney).add(item.inMoney);
                                let outNumber=parseInt((item.outNumber).div(item.unitScale,1)),outNumberBlance=parseInt((item.outNumber)%(item.unitScale));
                                let outNumberCost=item.outNumber?(item.outCost).div(item.outNumber,2):0,outWeightCost=item.outWeight?(item.outCost).div(arithUtil.keToWeight(item.outWeight,this.state.weightMode),2):0;
                                totalInfo.outNumber=(totalInfo.outNumber).add(outNumber);
                                totalInfo.outNumberBlance=(totalInfo.outNumberBlance).add(outNumberBlance);
                                totalInfo.outWeight=(totalInfo.outWeight).add(item.outWeight);
                                totalInfo.outMoney=(totalInfo.outMoney).add(item.outMoney);
                                let inSumMoney=(item.beforeMoney).add(item.inMoney).add(item.otherMoney);
                                let inSumWeight=(item.beforeWeight).add(item.inWeight).add(item.otherWeight);
                                let inSumNum=(item.otherNumber).add(item.beforeNumber).add(item.inNumber);
                                totalInfo.outCost=(totalInfo.outCost).add(item.outCost);
                                let moneyPal=(item.outMoney).sub(item.outCost);
                                totalInfo.moneyPal=(totalInfo.moneyPal).add(moneyPal);
                                let otherNumber=parseInt((item.otherNumber).div(item.unitScale,1)),otherNumberBlance=parseInt((item.otherNumber)%(item.unitScale));
                                let otherNumberCost=(item.otherNumber?(item.otherMoney).div(item.otherNumber,2):0).mul(item.unitScale),otherWeightCost=item.otherWeight?(item.otherMoney).div(arithUtil.keToWeight(item.otherWeight,this.state.weightMode),2):0;
                                totalInfo.otherNumber=(totalInfo.otherNumber).add(otherNumber);
                                totalInfo.otherNumberBlance=(totalInfo.otherNumberBlance).add(otherNumberBlance);
                                totalInfo.otherWeight=(totalInfo.otherWeight).add(item.otherWeight);
                                totalInfo.otherMoney=(totalInfo.otherMoney).add(item.otherMoney);
                                let number=parseInt((item.number).div(item.unitScale,1)),numberBlance=parseInt((item.number)%(item.unitScale));
                                let numberCost=(item.number?(item.money).div(item.number,2):0).mul(item.unitScale),weightCost=item.weight?(item.money).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2):0;
                                totalInfo.number=(totalInfo.number).add(number);
                                totalInfo.numberBlance=(totalInfo.numberBlance).add(numberBlance);
                                totalInfo.weight=(totalInfo.weight).add(item.weight);
                                totalInfo.money=(totalInfo.money).add(item.money);
                                resArr.push(`<tr><td align="left"><font size="3">${item.commodityCode?item.commodityCode:''}</font></td>
                                <td align="left"><font size="3">${item.commodityName}</font></td>
                                <td align="left"><font size="3">${item.commoditySpecs?item.commoditySpecs:''}</font></td>
                                <td align="left"><font size="3">${item.targetName?item.targetName:item.targetType==1?stringUtil.language(1051):''}</font></td>
                                <td align="center"><font size="3">${beforeNumber?beforeNumber+(item.unitSlave?item.unitSlave:stringUtil.language(1173)):''}${beforeNumberBlance?beforeNumberBlance+(item.unitMaster?item.unitMaster:stringUtil.language(1173)):''}</font></td>
                                <td align="center"><font size="3">${item.beforeWeight?arithUtil.keToWeight(item.beforeWeight,this.state.weightMode):''}</font></td>
                                <td align="center"><font size="3">${beforeNumberCost?arithUtil.fenToYuan(beforeNumberCost):''}</font></td>
                                <td align="center"><font size="3">${beforeWeightCost?arithUtil.fenToYuan(beforeWeightCost):''}</font></td>
                                <td align="center"><font size="3">${item.beforeMoney?arithUtil.fenToYuan(item.beforeMoney):''}</font></td>
                                <td align="center"><font size="3">${inNumber?inNumber+(item.unitSlave?item.unitSlave:stringUtil.language(1173)):''}${inNumberBlance?inNumberBlance+(item.unitMaster?item.unitMaster:stringUtil.language(1173)):''}</font></td>
                                <td align="center"><font size="3">${item.inWeight?arithUtil.keToWeight(item.inWeight,this.state.weightMode):''}</font></td>
                                <td align="center"><font size="3">${inNumberCost?arithUtil.fenToYuan(inNumberCost):''}</font></td>
                                <td align="center"><font size="3">${inWeightCost?arithUtil.fenToYuan(inWeightCost):''}</font></td>
                                <td align="center"><font size="3">${item.inMoney?arithUtil.fenToYuan(item.inMoney):''}</font></td>
                                <td align="center"><font size="3">${outNumber?outNumber+(item.unitSlave?item.unitSlave:stringUtil.language(1173)):''}${outNumberBlance?outNumberBlance+(item.unitMaster?item.unitMaster:stringUtil.language(1173)):''}</font></td>
                                <td align="center"><font size="3">${item.outWeight?arithUtil.keToWeight(item.outWeight,this.state.weightMode):''}</font></td>
                                <td align="center"><font size="3">${outNumberCost?arithUtil.fenToYuan(outNumberCost):''}</font></td>
                                <td align="center"><font size="3">${outWeightCost?arithUtil.fenToYuan(outWeightCost):''}</font></td>
                                <td align="center"><font size="3">${item.outMoney?arithUtil.fenToYuan(item.outMoney):''}</font></td>
                                <td align="center"><font size="3">${item.outCost?arithUtil.fenToYuan(item.outCost):''}</font></td>
                                <td align="center"><font size="3">${moneyPal?arithUtil.fenToYuan(moneyPal):''}</font></td>
                                <td align="center"><font size="3">${otherNumber?otherNumber+(item.unitSlave?item.unitSlave:stringUtil.language(1173)):''}${otherNumberBlance?otherNumberBlance+(item.unitMaster?item.unitMaster:stringUtil.language(1173)):''}</font></td>
                                <td align="center"><font size="3">${item.otherWeight?arithUtil.keToWeight(item.otherWeight,this.state.weightMode):''}</font></td>
                                <td align="center"><font size="3">${otherNumberCost?arithUtil.fenToYuan(otherNumberCost):''}</font></td>
                                <td align="center"><font size="3">${otherWeightCost?arithUtil.fenToYuan(otherWeightCost):''}</font></td>
                                <td align="center"><font size="3">${item.otherMoney?arithUtil.fenToYuan(item.otherMoney):''}</font></td>
                                <td align="center"><font size="3">${number?number+(item.unitSlave?item.unitSlave:stringUtil.language(1173)):''}${numberBlance?numberBlance+(item.unitMaster?item.unitMaster:stringUtil.language(1173)):''}</font></td>
                                <td align="center"><font size="3">${item.weight?arithUtil.keToWeight(item.weight,this.state.weightMode):''}</font></td>
                                <td align="center"><font size="3">${numberCost?arithUtil.fenToYuan(numberCost):''}</font></td>
                                <td align="center"><font size="3">${weightCost?arithUtil.fenToYuan(weightCost):''}</font></td>
                                <td align="center"><font size="3">${item.money?arithUtil.fenToYuan(item.money):''}</font></td></tr>`);
                            }
                            resArr.push(`<tr><td align="left" colspan="4"><b><font size="3">${stringUtil.language(890)}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.beforeNumber?totalInfo.beforeNumber:''}${totalInfo.beforeNumberBlance?'('+totalInfo.beforeNumberBlance+')':''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.beforeWeight?arithUtil.keToWeight(totalInfo.beforeWeight,this.state.weightMode):''}</font></b></td>
                            <td align="center"><b><font size="3"></font></b></td>
                            <td align="center"><b><font size="3"></font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.beforeMoney?arithUtil.fenToYuan(totalInfo.beforeMoney):''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.inNumber?totalInfo.inNumber:''}${totalInfo.inNumberBlance?'('+totalInfo.inNumberBlance+')':''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.inWeight?arithUtil.keToWeight(totalInfo.inWeight,this.state.weightMode):''}</font></b></td>
                            <td align="center"><b><font size="3"></font></b></td>
                            <td align="center"><b><font size="3"></font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.inMoney?arithUtil.fenToYuan(totalInfo.inMoney):''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.outNumber?totalInfo.outNumber:''}${totalInfo.outNumberBlance?'('+totalInfo.outNumberBlance+')':''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.outWeight?arithUtil.keToWeight(totalInfo.outWeight,this.state.weightMode):''}</font></b></td>
                            <td align="center"><b><font size="3"></font></b></td>
                            <td align="center"><b><font size="3"></font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.outMoney?arithUtil.fenToYuan(totalInfo.outMoney):''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.outCost?arithUtil.fenToYuan(totalInfo.outCost):''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.moneyPal?arithUtil.fenToYuan(totalInfo.moneyPal):''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.otherNumber?totalInfo.otherNumber:''}${totalInfo.otherNumberBlance?'('+totalInfo.otherNumberBlance+')':''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.otherWeight?arithUtil.keToWeight(totalInfo.otherWeight,this.state.weightMode):''}</font></b></td>
                            <td align="center"><b><font size="3"></font></b>
                            <td align="center"><b><font size="3"></font></b>
                            <td align="center"><b><font size="3">${totalInfo.otherMoney?arithUtil.fenToYuan(totalInfo.otherMoney):''}</font></b>
                            <td align="center"><b><font size="3">${totalInfo.number?totalInfo.number:''}${totalInfo.numberBlance?'('+totalInfo.numberBlance+')':''}</font></b></td>
                            <td align="center"><b><font size="3">${totalInfo.weight?arithUtil.keToWeight(totalInfo.weight,this.state.weightMode):''}</font></b></td>
                            <td align="center"><b><font size="3"></font></b>
                            <td align="center"><b><font size="3"></font></b>
                            <td align="center"><b><font size="3">${totalInfo.money?arithUtil.fenToYuan(totalInfo.money):''}</font></b></tr>`);
                            otherUtil.createExcel(resArr.join(''),stringUtil.language(1275)+'（'+this.state.searchs.startDate+stringUtil.language(1174)+this.state.searchs.endDate+'）');
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}} id='printArea'>
                    <div>{this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(1275)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.startDate+stringUtil.language(1174)+this.state.searchs.endDate}</span>
                    </div>:''}
                    <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:60,height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1156)}</span>
                        <span style={{width:150,height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stringUtil.language(518)}</span>
                        <span style={{width:90,height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1105)}</span>
                        <span style={{width:100,height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1049)}</span>
                        <div style={{width:450,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(520)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:0,flexGrow:1.5,fontSize:10,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1296)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1297)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(947)}</span>
                            </div>
                        </div>
                        <div style={{width:450,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(521)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:0,flexGrow:1.5,fontSize:10,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1296)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1297)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(947)}</span>
                            </div>
                        </div>
                        <div style={{width:500,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(1276)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:0,flexGrow:1.5,fontSize:10,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1296)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1297)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(889)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(947)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1120)}</span>
                            </div>
                        </div>
                        <div style={{width:450,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(1277)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:0,flexGrow:1.5,fontSize:10,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1296)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1297)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(947)}</span>
                            </div>
                        </div>
                        <div style={{width:450,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(483)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:0,flexGrow:1.5,fontSize:10,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1296)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1297)}</span>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(947)}</span>
                            </div>
                        </div>
                    </div>
                    {(()=>{this.state.totalInfo={beforeNumber:0,beforeNumberBlance:0,beforeWeight:0,beforeMoney:0,inNumber:0,inNumberBlance:0,inWeight:0,inMoney:0,
                        outNumber:0,outNumberBlance:0,outWeight:0,outMoney:0,outCost:0,moneyPal:0,otherNumber:0,otherNumberBlance:0,otherWeight:0,otherMoney:0,number:0,numberBlance:0,weight:0,money:0}})()}
                    {this.state.stockCommodityDODatas.map((item,key)=>{
                        let beforeNumber=parseInt((item.beforeNumber).div(item.unitScale,1)),beforeNumberBlance=parseInt((item.beforeNumber)%(item.unitScale));
                        let beforeNumberCost=(item.beforeNumber?(item.beforeMoney).div(item.beforeNumber,2):0).mul(item.unitScale),beforeWeightCost=item.beforeWeight?(item.beforeMoney).div(arithUtil.keToWeight(item.beforeWeight,this.state.weightMode),2):0;
                        this.state.totalInfo.beforeNumber=(this.state.totalInfo.beforeNumber).add(beforeNumber);
                        this.state.totalInfo.beforeNumberBlance=(this.state.totalInfo.beforeNumberBlance).add(beforeNumberBlance);
                        this.state.totalInfo.beforeWeight=(this.state.totalInfo.beforeWeight).add(item.beforeWeight);
                        this.state.totalInfo.beforeMoney=(this.state.totalInfo.beforeMoney).add(item.beforeMoney);
                        let inNumber=parseInt((item.inNumber).div(item.unitScale,1)),inNumberBlance=parseInt((item.inNumber)%(item.unitScale));
                        let inNumberCost=(item.inNumber?(item.inMoney).div(item.inNumber,2):0).mul(item.unitScale),inWeightCost=item.inWeight?(item.inMoney).div(arithUtil.keToWeight(item.inWeight,this.state.weightMode),2):0;
                        this.state.totalInfo.inNumber=(this.state.totalInfo.inNumber).add(inNumber);
                        this.state.totalInfo.inNumberBlance=(this.state.totalInfo.inNumberBlance).add(inNumberBlance);
                        this.state.totalInfo.inWeight=(this.state.totalInfo.inWeight).add(item.inWeight);
                        this.state.totalInfo.inMoney=(this.state.totalInfo.inMoney).add(item.inMoney);
                        let outNumber=parseInt((item.outNumber).div(item.unitScale,1)),outNumberBlance=parseInt((item.outNumber)%(item.unitScale));
                        let outNumberCost=item.outNumber?(item.outCost).div(item.outNumber,2):0,outWeightCost=item.outWeight?(item.outCost).div(arithUtil.keToWeight(item.outWeight,this.state.weightMode),2):0;
                        this.state.totalInfo.outNumber=(this.state.totalInfo.outNumber).add(outNumber);
                        this.state.totalInfo.outNumberBlance=(this.state.totalInfo.outNumberBlance).add(outNumberBlance);
                        this.state.totalInfo.outWeight=(this.state.totalInfo.outWeight).add(item.outWeight);
                        this.state.totalInfo.outMoney=(this.state.totalInfo.outMoney).add(item.outMoney);
                        let inSumMoney=(item.beforeMoney).add(item.inMoney).add(item.otherMoney);
                        let inSumWeight=(item.beforeWeight).add(item.inWeight).add(item.otherWeight);
                        let inSumNum=(item.otherNumber).add(item.beforeNumber).add(item.inNumber);
                        this.state.totalInfo.outCost=(this.state.totalInfo.outCost).add(item.outCost);
                        let moneyPal=(item.outMoney).sub(item.outCost);
                        this.state.totalInfo.moneyPal=(this.state.totalInfo.moneyPal).add(moneyPal);
                        let otherNumber=parseInt((item.otherNumber).div(item.unitScale,1)),otherNumberBlance=parseInt((item.otherNumber)%(item.unitScale));
                        let otherNumberCost=(item.otherNumber?(item.otherMoney).div(item.otherNumber,2):0).mul(item.unitScale),otherWeightCost=item.otherWeight?(item.otherMoney).div(arithUtil.keToWeight(item.otherWeight,this.state.weightMode),2):0;
                        this.state.totalInfo.otherNumber=(this.state.totalInfo.otherNumber).add(otherNumber);
                        this.state.totalInfo.otherNumberBlance=(this.state.totalInfo.otherNumberBlance).add(otherNumberBlance);
                        this.state.totalInfo.otherWeight=(this.state.totalInfo.otherWeight).add(item.otherWeight);
                        this.state.totalInfo.otherMoney=(this.state.totalInfo.otherMoney).add(item.otherMoney);
                        let number=parseInt((item.number).div(item.unitScale,1)),numberBlance=parseInt((item.number)%(item.unitScale));
                        let numberCost=(item.number?(item.money).div(item.number,2):0).mul(item.unitScale),weightCost=item.weight?(item.money).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2):0;
                        this.state.totalInfo.number=(this.state.totalInfo.number).add(number);
                        this.state.totalInfo.numberBlance=(this.state.totalInfo.numberBlance).add(numberBlance);
                        this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                        this.state.totalInfo.money=(this.state.totalInfo.money).add(item.money);
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:60,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityCode}</span>
                            <span style={{width:150,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityName}</span>
                            <span style={{width:90,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commoditySpecs}</span>
                            <span style={{width:100,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetName?item.targetName:item.targetType==1?stringUtil.language(1051):''}</span>
                            <div style={{width:450}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:0,flexGrow:1.5,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    {beforeNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{beforeNumber}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                    </div>:''}
                                    {beforeNumberBlance?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{beforeNumberBlance}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                    </div>:''}
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.beforeWeight?arithUtil.keToWeight(item.beforeWeight,this.state.weightMode):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{beforeNumberCost?arithUtil.fenToYuan(beforeNumberCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{beforeWeightCost?arithUtil.fenToYuan(beforeWeightCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.beforeMoney?arithUtil.fenToYuan(item.beforeMoney):''}</span>
                                </div>
                            </div></div>
                            <div style={{width:450}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:0,flexGrow:1.5,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    {inNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{inNumber}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                    </div>:''}
                                    {inNumberBlance?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{inNumberBlance}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                    </div>:''}
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.inWeight?arithUtil.keToWeight(item.inWeight,this.state.weightMode):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{inNumberCost?arithUtil.fenToYuan(inNumberCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{inWeightCost?arithUtil.fenToYuan(inWeightCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.inMoney?arithUtil.fenToYuan(item.inMoney):''}</span>
                                </div>
                            </div></div>
                            <div style={{width:500}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:0,flexGrow:1.5,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    {outNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{outNumber}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                    </div>:''}
                                    {outNumberBlance?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{outNumberBlance}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                    </div>:''}
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.outWeight?arithUtil.keToWeight(item.outWeight,this.state.weightMode):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{outNumberCost?arithUtil.fenToYuan(outNumberCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{outWeightCost?arithUtil.fenToYuan(outWeightCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.outMoney?arithUtil.fenToYuan(item.outMoney):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.outCost?arithUtil.fenToYuan(item.outCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{moneyPal?arithUtil.fenToYuan(moneyPal):''}</span>
                                </div>
                            </div></div>
                            <div style={{width:450}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:0,flexGrow:1.5,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    {otherNumber?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{otherNumber}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                    </div>:''}
                                    {otherNumberBlance?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{otherNumberBlance}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                    </div>:''}
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.otherWeight?arithUtil.keToWeight(item.otherWeight,this.state.weightMode):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{otherNumberCost?arithUtil.fenToYuan(otherNumberCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{otherWeightCost?arithUtil.fenToYuan(otherWeightCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.otherMoney?arithUtil.fenToYuan(item.otherMoney):''}</span>
                                </div>
                            </div></div>
                            <div style={{width:450}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:0,flexGrow:1.5,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    {number?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{number}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                    </div>:''}
                                    {numberBlance?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{fontSize:14}}>{numberBlance}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                    </div>:''}
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.weight?arithUtil.keToWeight(item.weight,this.state.weightMode):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{numberCost?arithUtil.fenToYuan(numberCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{weightCost?arithUtil.fenToYuan(weightCost):''}</span>
                                </div>
                                <div style={{width:0,flexGrow:1,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.money?arithUtil.fenToYuan(item.money):''}</span>
                                </div>
                            </div></div>
                        </div>)
                    })}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573'}}>{stringUtil.language(890)}</span>
                        <div style={{width:450,display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1.5,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.beforeNumber?this.state.totalInfo.beforeNumber:''}{this.state.totalInfo.beforeNumberBlance?'('+this.state.totalInfo.beforeNumberBlance+')':''}</span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.beforeWeight?arithUtil.keToWeight(this.state.totalInfo.beforeWeight,this.state.weightMode):''}</span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.beforeMoney?arithUtil.fenToYuan(this.state.totalInfo.beforeMoney):''}</span>
                        </div>
                        <div style={{width:450,display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1.5,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.inNumber?this.state.totalInfo.inNumber:''}{this.state.totalInfo.inNumberBlance?'('+this.state.totalInfo.inNumberBlance+')':''}</span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.inWeight?arithUtil.keToWeight(this.state.totalInfo.inWeight,this.state.weightMode):''}</span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.inMoney?arithUtil.fenToYuan(this.state.totalInfo.inMoney):''}</span>
                        </div>
                        <div style={{width:500,display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1.5,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.outNumber?this.state.totalInfo.outNumber:''}{this.state.totalInfo.outNumberBlance?'('+this.state.totalInfo.outNumberBlance+')':''}</span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.outWeight?arithUtil.keToWeight(this.state.totalInfo.outWeight,this.state.weightMode):''}</span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.outMoney?arithUtil.fenToYuan(this.state.totalInfo.outMoney):''}</span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.outCost?arithUtil.fenToYuan(this.state.totalInfo.outCost):''}</span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.moneyPal?arithUtil.fenToYuan(this.state.totalInfo.moneyPal):''}</span>
                        </div>
                        <div style={{width:450,display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1.5,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.otherNumber?this.state.totalInfo.otherNumber:''}{this.state.totalInfo.otherNumberBlance?'('+this.state.totalInfo.otherNumberBlance+')':''}</span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.otherWeight?arithUtil.keToWeight(this.state.totalInfo.otherWeight,this.state.weightMode):''}</span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.otherMoney?arithUtil.fenToYuan(this.state.totalInfo.otherMoney):''}</span>
                        </div>
                        <div style={{width:450,display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1.5,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.number?this.state.totalInfo.number:''}{this.state.totalInfo.numberBlance?'('+this.state.totalInfo.numberBlance+')':''}</span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.weight?arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode):''}</span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1}}></span>
                            <span style={{width:0,flexGrow:1,fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.money?arithUtil.fenToYuan(this.state.totalInfo.money):''}</span>
                        </div>
                    </div></div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};