import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';

 //营业账目日报表
 export default class BusinessDailyActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            dateTimeStr:arithUtil.getCurrDateTime(1),
        },
        busData:{}
      };
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_CAPITAL_MANAGE_URL,{date:this.state.searchs.dateTimeStr},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({busData:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(888)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.dateTimeStr)} onChange={(date) => {
                                this.state.searchs.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:15,cursor:'pointer'}} onClick={()=>{
                            let collectArr=[],expendArr=[];
                            collectArr.push({txt:stringUtil.language(1281),money:this.state.busData.saleMoney});
                            collectArr.push({txt:stringUtil.language(1282),money:this.state.busData.nowSaleArrears});
                            collectArr.push({txt:stringUtil.language(1283),money:this.state.busData.nowCollectMoney});
                            collectArr.push({txt:stringUtil.language(1284),money:this.state.busData.beforeCollectMoney});
                            collectArr.push({txt:stringUtil.language(1285),money:this.state.busData.discountMoney});
                            for(let i=0;i<(this.state.busData.shopCollectSettle?this.state.busData.shopCollectSettle:[]).length;i++){
                                collectArr.push({txt:this.state.busData.shopCollectSettle[i].clauseName,money:this.state.busData.shopCollectSettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.handMoneySettle?this.state.busData.handMoneySettle:[]).length;i++){
                                expendArr.push({txt:this.state.busData.handMoneySettle[i].accountOutName+stringUtil.language(1181)+this.state.busData.handMoneySettle[i].accountInName,
                                    money:this.state.busData.handMoneySettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.shopToShopMoneySettle?this.state.busData.shopToShopMoneySettle:[]).length;i++){
                                expendArr.push({txt:this.state.busData.shopToShopMoneySettle[i].accountOutName+stringUtil.language(1181)+this.state.busData.shopToShopMoneySettle[i].accountInName,
                                    money:this.state.busData.shopToShopMoneySettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.bossToShopMoneySettle?this.state.busData.bossToShopMoneySettle:[]).length;i++){
                                expendArr.push({txt:this.state.busData.bossToShopMoneySettle[i].accountOutName+stringUtil.language(1181)+this.state.busData.bossToShopMoneySettle[i].accountInName,
                                    money:this.state.busData.bossToShopMoneySettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.bossToBossMoneySettle?this.state.busData.bossToBossMoneySettle:[]).length;i++){
                                expendArr.push({txt:this.state.busData.bossToBossMoneySettle[i].accountOutName+stringUtil.language(1181)+this.state.busData.bossToBossMoneySettle[i].accountInName,
                                    money:this.state.busData.bossToBossMoneySettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.ownerExpendSettle?this.state.busData.ownerExpendSettle:[]).length;i++){
                                expendArr.push({txt:stringUtil.language(1193)+(this.state.busData.ownerExpendSettle[i].targetName?this.state.busData.ownerExpendSettle[i].targetName:this.state.busData.ownerExpendSettle[i].targetType==1?stringUtil.language(1051):'')+this.state.busData.ownerExpendSettle[i].clauseName,
                                    money:this.state.busData.ownerExpendSettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.shopExpendSettle?this.state.busData.shopExpendSettle:[]).length;i++){
                                expendArr.push({txt:this.state.busData.shopExpendSettle[i].clauseName,money:this.state.busData.shopExpendSettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.supplierPaySettle?this.state.busData.supplierPaySettle:[]).length;i++){
                                expendArr.push({txt:this.state.busData.supplierPaySettle[i].supplierName,money:this.state.busData.supplierPaySettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.workerPaySettle?this.state.busData.workerPaySettle:[]).length;i++){
                                expendArr.push({txt:this.state.busData.workerPaySettle[i].workerName,money:this.state.busData.workerPaySettle[i].money});
                            }
                            for(let i=0;i<(this.state.busData.ownerPaySettle?this.state.busData.ownerPaySettle:[]).length;i++){
                                expendArr.push({txt:this.state.busData.ownerPaySettle[i].ownerName,money:this.state.busData.ownerPaySettle[i].money});
                            }
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="6"><b><font size="3">${localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}${stringUtil.language(1279)}（${this.state.searchs.dateTimeStr}）</font></b></td></tr>
                            <tr><td align="center" colspan="2"><b><font size="3">${stringUtil.language(606)}</font></b></td>
                            <td align="center" colspan="4"><b><font size="3">${stringUtil.language(607)}</font></b></td></tr>
                            <tr><td align="center"><b><font size="3">${stringUtil.language(1280)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1041)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1280)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1041)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1280)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1041)}</font></b></td></tr>`);
                            while(collectArr.length||expendArr.length){
                                resArr.push(`<tr>`);
                                resArr.push(`<td align="left"><font size="3">${collectArr[0]?collectArr[0].txt:''}</font></td>
                                <td align="center"><font size="3">${collectArr[0]?arithUtil.fenToYuan(collectArr[0].money):''}</font></td>`);
                                resArr.push(`<td align="left"><font size="3">${expendArr[0]?expendArr[0].txt:''}</font></td>
                                <td align="center"><font size="3">${expendArr[0]?arithUtil.fenToYuan(expendArr[0].money):''}</font></td>`);
                                resArr.push(`<td align="left"><font size="3">${expendArr[1]?expendArr[1].txt:''}</font></td>
                                <td align="center"><font size="3">${expendArr[1]?arithUtil.fenToYuan(expendArr[1].money):''}</font></td>`);
                                resArr.push(`</tr>`);
                                if(collectArr[0]){collectArr.splice(0,1);}
                                if(expendArr[1]){expendArr.splice(1,1);}
                                if(expendArr[0]){expendArr.splice(0,1);}
                            }
                            resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1286)}</font></b></td>
                                <td align="center"><b><font size="3">${arithUtil.fenToYuan(this.state.busData.collectSumm)}</font></b></td>
                                <td align="left"><b><font size="3"></font></b></td><td align="center"><b><font size="3"></font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(1287)}</font></b></td>
                                <td align="center"><b><font size="3">${arithUtil.fenToYuan(this.state.busData.expendSumm)}</font></b></td></tr>
                                <tr><td align="left"><b><font size="3">${stringUtil.language(1288)}</font></b></td>
                                <td align="center"><b><font size="3">${arithUtil.fenToYuan(this.state.busData.recordArrears)}</font></b></td>
                                <td align="left"><b><font size="3"></font></b></td><td align="center"><b><font size="3"></font></b></td>
                                <td align="left"><b><font size="3"></font></b></td><td align="center"><b><font size="3"></font></b></td></tr>
                                <tr><td align="left"><b><font size="3">${stringUtil.language(1289)}</font></b></td>
                                <td align="center"><b><font size="3">${arithUtil.fenToYuan(this.state.busData.beforeRecordArrears)}</font></b></td>
                                <td align="left"><b><font size="3"></font></b></td><td align="center"><b><font size="3"></font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(1290)}</font></b></td>
                                <td align="center"><b><font size="3">${arithUtil.fenToYuan(this.state.busData.balance)}</font></b></td></tr>
                                <tr><td align="left" rowspan="2"><font size="3">${stringUtil.language(1291)}：</font></td>
                                <td align="left" colspan="5"><font size="3">${stringUtil.language(1292)}</font></td></tr>
                                <tr><td align="left" colspan="5"><font size="3">${stringUtil.language(1293)}</font></td></tr>
                                <tr><td align="left" colspan="2"><font size="3">${stringUtil.language(1247)}：</font></td>
                                <td align="left" colspan="2"><font size="3">${stringUtil.language(1294)}：</font></td>
                                <td align="left" colspan="2"><font size="3">${stringUtil.language(1295)}：</font></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),stringUtil.language(1279)+'（'+this.state.searchs.dateTimeStr+'）');
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflow:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}{stringUtil.language(1279)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.dateTimeStr}</span>
                    </div>:''}
                    <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <div style={{width:'33.333%',height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(606)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1280)}</span>
                                <span style={{width:'30%',fontSize:10,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                            </div>
                        </div>
                        <div style={{width:0,flexGrow:1,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(607)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1280)}</span>
                                    <span style={{width:'30%',fontSize:10,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                                </div>
                                <div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:0,flexGrow:1,fontSize:10,textAlign:'center'}}>{stringUtil.language(1280)}</span>
                                    <span style={{width:'30%',fontSize:10,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        {(()=>{let row1=(this.state.busData.shopCollectSettle?this.state.busData.shopCollectSettle:[]).length+5;
                            let row2=(this.state.busData.handMoneySettle?this.state.busData.handMoneySettle:[]).length+
                            (this.state.busData.shopToShopMoneySettle?this.state.busData.shopToShopMoneySettle:[]).length+
                            (this.state.busData.bossToShopMoneySettle?this.state.busData.bossToShopMoneySettle:[]).length+
                            (this.state.busData.bossToBossMoneySettle?this.state.busData.bossToBossMoneySettle:[]).length+
                            (this.state.busData.ownerExpendSettle?this.state.busData.ownerExpendSettle:[]).length+
                            (this.state.busData.shopExpendSettle?this.state.busData.shopExpendSettle:[]).length+
                            (this.state.busData.supplierPaySettle?this.state.busData.supplierPaySettle:[]).length+
                            (this.state.busData.workerPaySettle?this.state.busData.workerPaySettle:[]).length+
                            (this.state.busData.ownerPaySettle?this.state.busData.ownerPaySettle:[]).length;
                            this.state.row1=0;this.state.row2=0;
                            if(row1*2>=row2){this.state.row2=row1*2-row2;
                            }else{let row1Temp=parseInt(row2.div(2));
                                if(row2%2){
                                    row1Temp=row1Temp+1;
                                    this.state.row2=1;
                                }
                                this.state.row1=row1Temp-row1;
                            }
                            this.state.row1Arr=[];this.state.row2Arr=[];//需要输出的空格组件
                            for(let i=0;i<this.state.row1;i++){this.state.row1Arr.push({});}
                            for(let i=0;i<this.state.row2;i++){this.state.row2Arr.push({});}
                        })()}
                        <div style={{width:'33.333%',display:'flex',flexDirection:'column'}}>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1281)}</span>
                                <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.saleMoney)}</span>
                            </div>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1282)}</span>
                                <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.nowSaleArrears)}</span>
                            </div>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1283)}</span>
                                <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.nowCollectMoney)}</span>
                            </div>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1284)}</span>
                                <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.beforeCollectMoney)}</span>
                            </div>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1285)}</span>
                                <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.discountMoney)}</span>
                            </div>
                            {(this.state.busData.shopCollectSettle?this.state.busData.shopCollectSettle:[]).map((item,key)=>{
                                return(<div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.clauseName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {this.state.row1Arr.map((item,key)=>{
                                return(<div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                                </div>);
                            })}
                        </div>
                        <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',flexWrap:'wrap',alignContent:'flex-start'}}>
                            {(this.state.busData.handMoneySettle?this.state.busData.handMoneySettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.accountOutName}{stringUtil.language(1181)}{item.accountInName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {(this.state.busData.shopToShopMoneySettle?this.state.busData.shopToShopMoneySettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.accountOutName}{stringUtil.language(1181)}{item.accountInName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {(this.state.busData.bossToShopMoneySettle?this.state.busData.bossToShopMoneySettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.accountOutName}{stringUtil.language(1181)}{item.accountInName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {(this.state.busData.bossToBossMoneySettle?this.state.busData.bossToBossMoneySettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.accountOutName}{stringUtil.language(1181)}{item.accountInName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {(this.state.busData.ownerExpendSettle?this.state.busData.ownerExpendSettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1193)}{item.targetName?item.targetName:item.targetType==1?stringUtil.language(1051):''}{item.clauseName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {(this.state.busData.shopExpendSettle?this.state.busData.shopExpendSettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.clauseName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {(this.state.busData.supplierPaySettle?this.state.busData.supplierPaySettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1193)}{item.supplierName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {(this.state.busData.workerPaySettle?this.state.busData.workerPaySettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1193)}{item.workerName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {(this.state.busData.ownerPaySettle?this.state.busData.ownerPaySettle:[]).map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1193)}{item.ownerName}</span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                </div>);
                            })}
                            {this.state.row2Arr.map((item,key)=>{
                                return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                                    <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                                </div>);
                            })}
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{width:'33.333%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1286)}</span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.collectSumm)}</span>
                        </div>
                        <div style={{width:'33.333%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                        </div>
                        <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1287)}</span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.expendSumm)}</span>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{width:'33.333%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1288)}</span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.recordArrears)}</span>
                        </div>
                        <div style={{width:'33.333%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                        </div>
                        <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{width:'33.333%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1289)}</span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.beforeRecordArrears)}</span>
                        </div>
                        <div style={{width:'33.333%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                        </div>
                        <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row'}}>
                            <span style={{width:0,flexGrow:1,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1290)}</span>
                            <span style={{width:'30%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.busData.balance)}</span>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <span style={{height:'30px',lineHeight:'30px',whiteSpace:'nowrap'}}>{stringUtil.language(1291)}：</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <span style={{height:'30px',lineHeight:'30px',whiteSpace:'nowrap'}}>{stringUtil.language(1292)}</span>
                            <span style={{height:'30px',lineHeight:'30px',whiteSpace:'nowrap'}}>{stringUtil.language(1293)}</span>
                        </div>
                    </div>
                    {this.state.isShowTabTitle?<div style={{height:60,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'33.333%'}}>{stringUtil.language(1247)}：</span>
                        <span style={{width:'33.333%'}}>{stringUtil.language(1294)}：</span>
                        <span style={{width:'33.333%'}}>{stringUtil.language(1295)}：</span>
                    </div>:''}
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};