import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class CargosFinishActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        discounts:{},
        wholesaleCargosAll:[],wholesaleCargosData:[],
        cargoFinishItem:{},
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_CARGO_DISCOUNT_BATCH_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({discounts:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:0,//-1全部，0代销，1自营
            currPage:0,
            pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.wholesaleCargosAll=successResponse.data.items;
                this.setState({wholesaleCargosData:JSON.parse(JSON.stringify(this.state.wholesaleCargosAll))});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    updateEntrust(){
      if(this.state.cargoFinishItem.entrustModeChenpinglong==null){this.state.cargoFinishItem.entrustModeChenpinglong=0;}
      if(this.state.cargoFinishItem.entrustUnitPriceChenpinglong==null){this.state.cargoFinishItem.entrustUnitPriceChenpinglong=0;}
      if(this.state.cargoFinishItem.entrustModeChenpinglong==0)this.state.cargoFinishItem.entrustMoneyChenpinglong=(this.state.cargoFinishItem.entrustUnitPriceChenpinglong).round();
      else if(this.state.cargoFinishItem.entrustModeChenpinglong==1)this.state.cargoFinishItem.entrustMoneyChenpinglong=(this.state.cargoFinishItem.entrustUnitPriceChenpinglong).mul(this.state.cargoFinishItem.saleNumber).round();
      else if(this.state.cargoFinishItem.entrustModeChenpinglong==2)this.state.cargoFinishItem.entrustMoneyChenpinglong=(this.state.cargoFinishItem.entrustUnitPriceChenpinglong).mul(arithUtil.keToWeight(this.state.cargoFinishItem.saleWeight,this.state.weightMode)).round();
      else if(this.state.cargoFinishItem.entrustModeChenpinglong==3){
        let saleTotalMoney=this.state.cargoFinishItem.saleGoodsMoney;
        if(this.state.cargoFinishItem.saleCosts){
          for(let i=0;i<this.state.cargoFinishItem.saleCosts.length;i++){
            if(this.state.cargoFinishItem.cargoMode==0&&this.state.cargoFinishItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
              saleTotalMoney=saleTotalMoney.add(this.state.cargoFinishItem.saleCosts[i].money);
            }
          }
        }
        this.state.cargoFinishItem.entrustMoneyChenpinglong=saleTotalMoney.mul((this.state.cargoFinishItem.entrustUnitPriceChenpinglong).div(100)).round();
      }
    }
    updateDisburden(){
      if(this.state.cargoFinishItem.disburdenModeChenpinglong==null){this.state.cargoFinishItem.disburdenModeChenpinglong=0;}
      if(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong==null){this.state.cargoFinishItem.disburdenUnitPriceChenpinglong=0;}
      if(this.state.cargoFinishItem.disburdenModeChenpinglong==0)this.state.cargoFinishItem.disburdenMoneyChenpinglong=(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong).round();
      else if(this.state.cargoFinishItem.disburdenModeChenpinglong==1)this.state.cargoFinishItem.disburdenMoneyChenpinglong=(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong).mul(this.state.cargoFinishItem.saleNumber).round();
      else if(this.state.cargoFinishItem.disburdenModeChenpinglong==2)this.state.cargoFinishItem.disburdenMoneyChenpinglong=(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong).mul((this.state.cargoFinishItem.saleWeight).div(1000000)).round();
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:8,paddingRight:8,backgroundColor:'#FFF',display:'flex',flexDirection:'column'}}>
                <div style={{height:35,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if(this.state.isAllChoice){this.state.isAllChoice=0;
                            this.state.wholesaleCargosData=JSON.parse(JSON.stringify(this.state.wholesaleCargosAll));
                        }else{this.state.isAllChoice=1;
                            let tag={};
                            for(let i=0;i<this.state.wholesaleCargosData.length;i++){
                                this.state.wholesaleCargosData[i].isChoiceChenpinglong=1;
                                this.state.wholesaleCargosData[i].entrustMoneyChenpinglong=0;
                                this.state.wholesaleCargosData[i].disburdenMoneyChenpinglong=0;
                                this.state.wholesaleCargosData[i].discountMoneyChenpinglong=0;
                                this.state.wholesaleCargosData[i].entrustModeChenpinglong=this.state.wholesaleCargosData[i].entrustMode;
                                this.state.wholesaleCargosData[i].entrustUnitPriceChenpinglong=this.state.wholesaleCargosData[i].entrustUnitPrice;
                                this.state.wholesaleCargosData[i].disburdenModeChenpinglong=this.state.wholesaleCargosData[i].disburdenMode;
                                this.state.wholesaleCargosData[i].disburdenUnitPriceChenpinglong=this.state.wholesaleCargosData[i].disburdenUnitPrice;
                                if(!this.state.wholesaleCargosData[i].cargoMode&&!tag[this.state.wholesaleCargosData[i].cargoMode+'_'+this.state.wholesaleCargosData[i].targetId]){tag[this.state.wholesaleCargosData[i].cargoMode+'_'+this.state.wholesaleCargosData[i].targetId]=1;
                                    this.state.wholesaleCargosData[i].discountMoneyChenpinglong=this.state.discounts[this.state.wholesaleCargosData[i].cargoMode+'_'+this.state.wholesaleCargosData[i].targetId]?this.state.discounts[this.state.wholesaleCargosData[i].cargoMode+'_'+this.state.wholesaleCargosData[i].targetId]:0;
                                }
                                this.state.cargoFinishItem=this.state.wholesaleCargosData[i];
                                this.updateEntrust();
                                this.updateDisburden();
                            }
                        }this.setState({isAllChoice:this.state.isAllChoice});
                    }}>{this.state.isAllChoice?<i className="iconfontIndexCss" style={{fontSize:25,color:'#009A61'}}>&#xe698;</i>:<i className="iconfontIndexCss" style={{fontSize:25,color:'#696969'}}>&#xe6a7;</i>}</div>
                    <span style={{width:0,flexGrow:3,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(519)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(535)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(536)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1259)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1260)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1261)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(963)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(543)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1069)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1262)}</span>
                </div>
                {(()=>{this.state.totalInfo={saleNumber:0,saleWeight:0,saleMoney:0,ownerMoney:0,entrustMoney:0,disburdenMoney:0,discountMoney:0,othersMoney:0,payOwner:0,choiceCount:0};})()}
                <div style={{height:0,flexGrow:1,borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.wholesaleCargosData.map((item,key)=>{
                        if(item.isChoiceChenpinglong){this.state.totalInfo.choiceCount=this.state.totalInfo.choiceCount+1;}
                        this.state.totalInfo.saleNumber=(this.state.totalInfo.saleNumber).add(item.saleNumber);
                        this.state.totalInfo.saleWeight=(this.state.totalInfo.saleWeight).add(item.saleWeight);
                        this.state.totalInfo.saleMoney=(this.state.totalInfo.saleMoney).add(item.saleMoney);
                        let ownerMoney=item.balance;if(item.expends){for(let i=0;i<item.expends.length;i++){ownerMoney=ownerMoney+item.expends[i].money;}}
                        this.state.totalInfo.ownerMoney=(this.state.totalInfo.ownerMoney).add(ownerMoney);
                        this.state.totalInfo.entrustMoney=(this.state.totalInfo.entrustMoney).add(item.isChoiceChenpinglong&&item.entrustMoneyChenpinglong?item.entrustMoneyChenpinglong:0);
                        this.state.totalInfo.disburdenMoney=(this.state.totalInfo.disburdenMoney).add(item.isChoiceChenpinglong&&item.disburdenMoneyChenpinglong?item.disburdenMoneyChenpinglong:0);
                        this.state.totalInfo.discountMoney=(this.state.totalInfo.discountMoney).add(item.isChoiceChenpinglong&&item.discountMoneyChenpinglong?item.discountMoneyChenpinglong:0);
                        this.state.totalInfo.othersMoney=(this.state.totalInfo.othersMoney).add((item.saleMoney).sub(ownerMoney));
                        this.state.totalInfo.payOwner=(this.state.totalInfo.payOwner).add(ownerMoney.sub(item.isChoiceChenpinglong&&item.entrustMoneyChenpinglong?item.entrustMoneyChenpinglong:0)
                            .sub(item.isChoiceChenpinglong&&item.disburdenMoneyChenpinglong?item.disburdenMoneyChenpinglong:0)
                            .sub(item.isChoiceChenpinglong&&item.discountMoneyChenpinglong?item.discountMoneyChenpinglong:0)
                            .sub((item.saleMoney).sub(ownerMoney)));
                        return(<div style={{height:55,backgroundColor:key%2?'rgba(0,196,255,0.1)':'#FFF',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                if(item.isChoiceChenpinglong){item.isChoiceChenpinglong=0;
                                }else{item.isChoiceChenpinglong=1;
                                    let currOwnerCount=0;
                                    for(let i=0;i<this.state.wholesaleCargosData.length;i++){
                                        if(this.state.wholesaleCargosData[i].isChoiceChenpinglong&&this.state.wholesaleCargosData[i].cargoMode==item.cargoMode&&this.state.wholesaleCargosData[i].targetId==item.targetId){
                                            currOwnerCount=currOwnerCount+1;
                                            if(currOwnerCount>1)break;
                                        }
                                    }
                                    item.entrustMoneyChenpinglong=0;
                                    item.disburdenMoneyChenpinglong=0;
                                    item.discountMoneyChenpinglong=0;
                                    item.entrustModeChenpinglong=item.entrustMode;
                                    item.entrustUnitPriceChenpinglong=item.entrustUnitPrice;
                                    item.disburdenModeChenpinglong=item.disburdenMode;
                                    item.disburdenUnitPriceChenpinglong=item.disburdenUnitPrice;
                                    if(!item.cargoMode&&currOwnerCount<2){item.discountMoneyChenpinglong=this.state.discounts[item.cargoMode+'_'+item.targetId]?this.state.discounts[item.cargoMode+'_'+item.targetId]:0;}
                                    this.state.cargoFinishItem=item;
                                    this.updateEntrust();
                                    this.updateDisburden();
                                }this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
                            }}>{item.isChoiceChenpinglong?<i className="iconfontIndexCss" style={{fontSize:25,color:'#009A61'}}>&#xe698;</i>:<i className="iconfontIndexCss" style={{fontSize:25,color:'#696969'}}>&#xe6a7;</i>}</div>
                            <div style={{width:0,flexGrow:3,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <span style={{width:'100%',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',fontSize:18}}>{item.targetName?item.targetName:item.cargoMode==1?stringUtil.language(426):''}</span>
                                <span style={{width:'100%',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',color:'#A9A9A9',fontSize:10}}>{stringUtil.language(1237,item.cargoNo)}</span>
                            </div>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{item.saleNumber}</span>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleMoney)}</span>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan(ownerMoney)}</span>
                            <div style={{width:0,flexGrow:2,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                {!item.cargoMode&&item.isChoiceChenpinglong?<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={{width:0,flexGrow:1,height:'98%',fontSize:18,fontWeight:'bold',backgroundColor:'transparent',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} value={this.state.wholesaleCargosData[key].entrustMoneyChenpinglong?arithUtil.fenToYuan(this.state.wholesaleCargosData[key].entrustMoneyChenpinglong):''} type='number' onChange={(event)=>{
                                        this.state.wholesaleCargosData[key].entrustMoneyChenpinglong=event.target.value!=''?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                        this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
                                    }} placeholder='0'/>
                                    <i className="iconfontIndexCss" style={{fontSize:25,color:'#009A61',paddingRight:5,cursor:'pointer'}} onClick={()=>{
                                        this.state.cargoFinishItem=this.state.wholesaleCargosData[key];
                                        this.state.finishStallMoney=0;
                                        if(this.state.cargoFinishItem.entrustModeChenpinglong==1){
                                          this.state.finishStallMoney=(this.state.cargoFinishItem.entrustUnitPriceChenpinglong).mul(this.state.cargoFinishItem.saleNumber).round();
                                        }else if(this.state.cargoFinishItem.entrustModeChenpinglong==2){
                                          this.state.finishStallMoney=(this.state.cargoFinishItem.entrustUnitPriceChenpinglong).mul(arithUtil.keToWeight(this.state.cargoFinishItem.saleWeight,this.state.weightMode)).round();
                                        }else if(this.state.cargoFinishItem.entrustModeChenpinglong==3){
                                          let saleTotalMoney=this.state.cargoFinishItem.saleGoodsMoney;
                                          if(this.state.cargoFinishItem.saleCosts){
                                            for(let i=0;i<this.state.cargoFinishItem.saleCosts.length;i++){
                                              if(this.state.cargoFinishItem.cargoMode==0&&this.state.cargoFinishItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                                                saleTotalMoney=saleTotalMoney.add(this.state.cargoFinishItem.saleCosts[i].money);
                                              }
                                            }
                                          }
                                          this.state.finishStallMoney=saleTotalMoney.mul((this.state.cargoFinishItem.entrustUnitPriceChenpinglong).div(100)).round();
                                        }
                                        this.setState({entrustMoneyShowPop:true});
                                    }}>&#xe63a;</i>
                                </div>:''}
                            </div>
                            <div style={{width:0,flexGrow:2,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                {!item.cargoMode&&item.isChoiceChenpinglong?<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={{width:0,flexGrow:1,height:'98%',fontSize:18,fontWeight:'bold',backgroundColor:'transparent',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} value={this.state.wholesaleCargosData[key].disburdenMoneyChenpinglong?arithUtil.fenToYuan(this.state.wholesaleCargosData[key].disburdenMoneyChenpinglong):''} type='number' onChange={(event)=>{
                                        this.state.wholesaleCargosData[key].disburdenMoneyChenpinglong=event.target.value!=''?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                        this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
                                        // let item=this.state.wholesaleCargosData[key];
                                        // this.refs['payOwnerRef'+key].innerHTML=arithUtil.fenToYuan(ownerMoney.sub(item.isChoiceChenpinglong&&item.entrustMoneyChenpinglong?item.entrustMoneyChenpinglong:0)
                                        //     .sub(item.isChoiceChenpinglong&&item.disburdenMoneyChenpinglong?item.disburdenMoneyChenpinglong:0)
                                        //     .sub(item.isChoiceChenpinglong&&item.discountMoneyChenpinglong?item.discountMoneyChenpinglong:0)
                                        //     .sub((item.saleMoney).sub(ownerMoney)));
                                    }} placeholder='0'/>
                                    <i className="iconfontIndexCss" style={{fontSize:25,color:'#009A61',paddingRight:5,cursor:'pointer'}} onClick={()=>{
                                        this.state.cargoFinishItem=this.state.wholesaleCargosData[key];
                                        this.state.finishDisburdenMoney=0;
                                        if(this.state.cargoFinishItem.disburdenModeChenpinglong==1){
                                          this.state.finishDisburdenMoney=(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong).mul(this.state.cargoFinishItem.saleNumber).round();
                                        }else if(this.state.cargoFinishItem.disburdenModeChenpinglong==2){
                                          this.state.finishDisburdenMoney=(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong).mul((this.state.cargoFinishItem.saleWeight).div(1000000)).round();
                                        }
                                        this.setState({disburdenMoneyShowPop:true});
                                    }}>&#xe63a;</i>
                                </div>:''}
                            </div>
                            <div style={{width:0,flexGrow:2,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                {!item.cargoMode&&item.isChoiceChenpinglong?<input style={{width:'99%',height:'98%',fontSize:18,fontWeight:'bold',backgroundColor:'transparent',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} value={this.state.wholesaleCargosData[key].discountMoneyChenpinglong?arithUtil.fenToYuan(this.state.wholesaleCargosData[key].discountMoneyChenpinglong):''} type='number' onChange={(event)=>{
                                    this.state.wholesaleCargosData[key].discountMoneyChenpinglong=event.target.value!=''?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                    this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
                                }} placeholder='0'/>:''}
                            </div>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan((item.saleMoney).sub(ownerMoney))}</span>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}} ref={'payOwnerRef'+key}>{arithUtil.fenToYuan(ownerMoney.sub(item.isChoiceChenpinglong&&item.entrustMoneyChenpinglong?item.entrustMoneyChenpinglong:0).sub(item.isChoiceChenpinglong&&item.disburdenMoneyChenpinglong?item.disburdenMoneyChenpinglong:0).sub(item.isChoiceChenpinglong&&item.discountMoneyChenpinglong?item.discountMoneyChenpinglong:0).sub((item.saleMoney).sub(ownerMoney)))}</span>
                        </div>);
                    })}
                </div>
                <div style={{height:60,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:0,flexGrow:1,color:'#CD853F',textAlign:'center'}}>{stringUtil.language(890)}</span>
                    <span style={{width:0,flexGrow:3,color:'#CD853F',textAlign:'center'}}>{this.state.wholesaleCargosData.length} {stringUtil.language(1176)}</span>
                    <span style={{width:0,flexGrow:2,color:'#CD853F',textAlign:'center'}}>{this.state.totalInfo.saleNumber}</span>
                    <span style={{width:0,flexGrow:2,color:'#CD853F',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode)}</span>
                    <span style={{width:0,flexGrow:2,color:'#CD853F',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleMoney)}</span>
                    <span style={{width:0,flexGrow:2,color:'#CD853F',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.ownerMoney)}</span>
                    <div style={{width:0,flexGrow:10,height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:0,flexGrow:1,color:'#F00',fontSize:18,fontWeight:'bold',textAlign:'right',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',marginRight:10}}>{stringUtil.language(1263,this.state.totalInfo.choiceCount)}</span>
                        <div style={{width:150,height:'85%',borderRadius:4,backgroundColor:'#009A61',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{{
                            let result=[];
                            for(let i=0;i<this.state.wholesaleCargosData.length;i++){
                                if(this.state.wholesaleCargosData[i].isChoiceChenpinglong){
                                    result.push({cargoId:this.state.wholesaleCargosData[i].id,
                                        entrustMode:this.state.wholesaleCargosData[i].entrustModeChenpinglong?this.state.wholesaleCargosData[i].entrustModeChenpinglong:0,
                                        entrustUnitPrice:this.state.wholesaleCargosData[i].entrustUnitPriceChenpinglong?this.state.wholesaleCargosData[i].entrustUnitPriceChenpinglong:0,
                                        disburdenMode:this.state.wholesaleCargosData[i].disburdenModeChenpinglong?this.state.wholesaleCargosData[i].disburdenModeChenpinglong:0,
                                        disburdenUnitPrice:this.state.wholesaleCargosData[i].disburdenUnitPriceChenpinglong?this.state.wholesaleCargosData[i].disburdenUnitPriceChenpinglong:0,
                                        entrustMoney:this.state.wholesaleCargosData[i].entrustMoneyChenpinglong?this.state.wholesaleCargosData[i].entrustMoneyChenpinglong:0,
                                        disburdenMoney:this.state.wholesaleCargosData[i].disburdenMoneyChenpinglong?this.state.wholesaleCargosData[i].disburdenMoneyChenpinglong:0,
                                        discountMoney:this.state.wholesaleCargosData[i].discountMoneyChenpinglong?this.state.wholesaleCargosData[i].discountMoneyChenpinglong:0,
                                    });
                                }
                            }
                            if(!result.length){ToastBox.error(stringUtil.language(131));return;}
                            this.setState({result:result,submitPop:true});
                        }}}><span style={{color:'#FFF',fontSize:25,fontWeight:'bold'}}>{stringUtil.language(1264)}</span></div>
                    </div>
                </div>
            </div>
            <MaskModal visible={this.state.submitPop?true:false} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {stringUtil.language(1027)+stringUtil.language(1264)}？
                        </div>}
                okBnEvents={(event) => {
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_FINISH_BATCH_URL,this.state.result,['json','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.setState({submitPop:false},()=>{
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                            });
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={()=>{this.setState({submitPop: false});}}/>
            <MaskModal visible={this.state.entrustMoneyShowPop?true:false} title={stringUtil.language(354)} width={400} height={250} context={this}
              content={<div style={{height:'100%',paddingLeft:10}} key={this.state.cargoFinishItem.id}>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(1152)}：</span><div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.entrustModeChenpinglong=0;
                      this.state.cargoFinishItem.entrustUnitPriceChenpinglong=0;
                      this.refs.moneyDangkouRef.value=this.state.cargoFinishItem.entrustUnitPriceChenpinglong!=null?(this.state.cargoFinishItem.entrustModeChenpinglong!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPriceChenpinglong):this.state.cargoFinishItem.entrustUnitPriceChenpinglong):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{!this.state.cargoFinishItem.entrustModeChenpinglong?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1029)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1029)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.entrustModeChenpinglong=1;
                      this.state.cargoFinishItem.entrustUnitPriceChenpinglong=0;
                      this.refs.moneyDangkouRef.value=this.state.cargoFinishItem.entrustUnitPriceChenpinglong!=null?(this.state.cargoFinishItem.entrustModeChenpinglong!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPriceChenpinglong):this.state.cargoFinishItem.entrustUnitPriceChenpinglong):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.entrustModeChenpinglong==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1115)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1115)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.entrustModeChenpinglong=2;
                      this.state.cargoFinishItem.entrustUnitPriceChenpinglong=0;
                      this.refs.moneyDangkouRef.value=this.state.cargoFinishItem.entrustUnitPriceChenpinglong!=null?(this.state.cargoFinishItem.entrustModeChenpinglong!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPriceChenpinglong):this.state.cargoFinishItem.entrustUnitPriceChenpinglong):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.entrustModeChenpinglong==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1114)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1114)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.entrustModeChenpinglong=3;
                      this.state.cargoFinishItem.entrustUnitPriceChenpinglong=0;
                      this.refs.moneyDangkouRef.value=this.state.cargoFinishItem.entrustUnitPriceChenpinglong!=null?(this.state.cargoFinishItem.entrustModeChenpinglong!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPriceChenpinglong):this.state.cargoFinishItem.entrustUnitPriceChenpinglong):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.entrustModeChenpinglong==3?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(355)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(355)}</i>}</div>
                </div>
                {this.state.cargoFinishItem.entrustModeChenpinglong?<span style={{height:30,color:'#F00',textAlignVertical:'center'}}>{this.state.cargoFinishItem.entrustModeChenpinglong==1?stringUtil.language(861)+this.state.cargoFinishItem.saleNumber+stringUtil.language(1173):this.state.cargoFinishItem.entrustModeChenpinglong==2?stringUtil.language(862)+arithUtil.keToWeight(this.state.cargoFinishItem.saleWeight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):((()=>{
                  let saleTotalMoney=this.state.cargoFinishItem.saleGoodsMoney;
                  if(this.state.cargoFinishItem.saleCosts){
                    for(let i=0;i<this.state.cargoFinishItem.saleCosts.length;i++){
                      if(this.state.cargoFinishItem.cargoMode==0&&this.state.cargoFinishItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                        saleTotalMoney=saleTotalMoney.add(this.state.cargoFinishItem.saleCosts[i].money);
                      }
                    }
                  }return stringUtil.language(883)+arithUtil.fenToYuan(saleTotalMoney)+stringUtil.currency(1);
                })())}</span>:''}
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(1050)}：</span><input style={{height:30,color:'#F00',fontWeight:'bold',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',borderRadius:2,textAlign:'center',display:'flex',flexGrow:1}} type='number' placeholder='0' defaultValue={this.state.cargoFinishItem.entrustUnitPriceChenpinglong!=null?(this.state.cargoFinishItem.entrustModeChenpinglong!=3?arithUtil.fenToYuan(this.state.cargoFinishItem.entrustUnitPriceChenpinglong):this.state.cargoFinishItem.entrustUnitPriceChenpinglong):''} ref='moneyDangkouRef' onChange={(event)=>{
                    this.state.cargoFinishItem.entrustUnitPriceChenpinglong = this.state.cargoFinishItem.entrustModeChenpinglong!=3?arithUtil.yuanToFen(event.target.value):parseFloat(event.target.value);
                    let finishStallMoney=0;
                    if(this.state.cargoFinishItem.entrustModeChenpinglong==1){
                      finishStallMoney=(this.state.cargoFinishItem.entrustUnitPriceChenpinglong).mul(this.state.cargoFinishItem.saleNumber).round();
                    }else if(this.state.cargoFinishItem.entrustModeChenpinglong==2){
                      finishStallMoney=(this.state.cargoFinishItem.entrustUnitPriceChenpinglong).mul(arithUtil.keToWeight(this.state.cargoFinishItem.saleWeight,this.state.weightMode)).round();
                    }else if(this.state.cargoFinishItem.entrustModeChenpinglong==3){
                      let saleTotalMoney=this.state.cargoFinishItem.saleGoodsMoney;
                      if(this.state.cargoFinishItem.saleCosts){
                        for(let i=0;i<this.state.cargoFinishItem.saleCosts.length;i++){
                          if(this.state.cargoFinishItem.cargoMode==0&&this.state.cargoFinishItem.saleCosts[i].payee==0){//0{stringUtil.language(1049)}，1stringUtil.language(1030)，2stringUtil.language(1031)--------代销
                            saleTotalMoney=saleTotalMoney.add(this.state.cargoFinishItem.saleCosts[i].money);
                          }
                        }
                      }
                      finishStallMoney=saleTotalMoney.mul((this.state.cargoFinishItem.entrustUnitPriceChenpinglong).div(100)).round();
                    }
                    if(this.state.cargoFinishItem.entrustModeChenpinglong)this.refs.finishStallMoney.innerHTML=stringUtil.language(1153)+'：'+arithUtil.fenToYuan(finishStallMoney)+stringUtil.currency(1);
                  }}/><span> {!this.state.cargoFinishItem.entrustModeChenpinglong?stringUtil.currency(1):this.state.cargoFinishItem.entrustModeChenpinglong==1?stringUtil.currency(1)+'/'+stringUtil.language(1173):this.state.cargoFinishItem.entrustModeChenpinglong==2?stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):'%'}</span>
                </div>
                {this.state.cargoFinishItem.entrustModeChenpinglong?<span ref='finishStallMoney' style={{height:30,color:'#F00',fontWeight:'bold',textAlignVertical:'center'}}>{stringUtil.language(1153)+'：'+arithUtil.fenToYuan(this.state.finishStallMoney?this.state.finishStallMoney:0)+stringUtil.currency(1)}</span>:''}
              </div>}
              okBnEvents={(event) => {
                this.state.entrustMoneyShowPop=false;
                this.updateEntrust();
                this.setState({cargoFinishItem:this.state.cargoFinishItem});
              }} closeBnEvents={() => {this.setState({entrustMoneyShowPop: false});}}/>
          <MaskModal visible={this.state.disburdenMoneyShowPop?true:false} title={JSON.parse(localStorage.getItem("currentStallItem")).disburdenText+stringUtil.language(1151)} width={400} height={250} context={this}
              content={<div style={{height:'100%',paddingLeft:10}} key={this.state.cargoFinishItem.id}>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(1152)}:</span><div style={{justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.disburdenModeChenpinglong=0;
                      this.state.cargoFinishItem.disburdenUnitPriceChenpinglong=0;
                      this.refs.moneyXiehuoRef.value=this.state.cargoFinishItem.disburdenUnitPriceChenpinglong!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{!this.state.cargoFinishItem.disburdenModeChenpinglong?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1029)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1029)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.disburdenModeChenpinglong=1;
                      this.state.cargoFinishItem.disburdenUnitPriceChenpinglong=0;
                      this.refs.moneyXiehuoRef.value=this.state.cargoFinishItem.disburdenUnitPriceChenpinglong!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.disburdenModeChenpinglong==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1115)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1115)}</i>}</div>
                  <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                      this.state.cargoFinishItem.disburdenModeChenpinglong=2;
                      this.state.cargoFinishItem.disburdenUnitPriceChenpinglong=0;
                      this.refs.moneyXiehuoRef.value=this.state.cargoFinishItem.disburdenUnitPriceChenpinglong!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong):'';
                      this.setState({cargoFinishItem:this.state.cargoFinishItem});
                  }}>{this.state.cargoFinishItem.disburdenModeChenpinglong==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1114)}</i>:
                  <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1114)}</i>}</div>
                </div>
                {this.state.cargoFinishItem.disburdenModeChenpinglong?<span style={{height:30,color:'#F00',textAlignVertical:'center'}}>{this.state.cargoFinishItem.disburdenModeChenpinglong==1?stringUtil.language(861)+this.state.cargoFinishItem.saleNumber+stringUtil.language(1173):stringUtil.language(862)+(arithUtil.keToWeight(this.state.cargoFinishItem.saleWeight,0).div(1000,3))+stringUtil.language(1197)}</span>:''}
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span>{stringUtil.language(1050)}:</span><input style={{height:30,color:'#F00',fontWeight:'bold',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',borderRadius:2,textAlign:'center',display:'flex',flexGrow:1}} type='number' placeholder='0' defaultValue={this.state.cargoFinishItem.disburdenUnitPriceChenpinglong!=null?arithUtil.fenToYuan(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong):''} ref='moneyXiehuoRef' onChange={(event)=>{
                    this.state.cargoFinishItem.disburdenUnitPriceChenpinglong = arithUtil.yuanToFen(event.target.value);
                    let finishDisburdenMoney=0;
                    if(this.state.cargoFinishItem.disburdenModeChenpinglong==1){
                      finishDisburdenMoney=(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong).mul(this.state.cargoFinishItem.saleNumber).round();
                    }else if(this.state.cargoFinishItem.disburdenModeChenpinglong==2){
                      finishDisburdenMoney=(this.state.cargoFinishItem.disburdenUnitPriceChenpinglong).mul((this.state.cargoFinishItem.saleWeight).div(1000000)).round();
                    }
                    if(this.state.cargoFinishItem.disburdenModeChenpinglong)this.refs.finishDisburdenMoney.innerHTML=stringUtil.language(1153)+'：'+arithUtil.fenToYuan(finishDisburdenMoney)+stringUtil.currency(1);
                  }}/><span> {!this.state.cargoFinishItem.disburdenModeChenpinglong?stringUtil.currency(1):this.state.cargoFinishItem.disburdenModeChenpinglong==1?stringUtil.currency(1)+'/'+stringUtil.language(1173):stringUtil.currency(1)+'/'+stringUtil.language(1197)}</span>
                </div>
                {this.state.cargoFinishItem.disburdenModeChenpinglong?<span ref='finishDisburdenMoney' style={{height:30,color:'#F00',fontWeight:'bold',textAlignVertical:'center'}}>{stringUtil.language(1153)+'：'+arithUtil.fenToYuan(this.state.finishDisburdenMoney?this.state.finishDisburdenMoney:0)+stringUtil.currency(1)}</span>:''}
              </div>}
              okBnEvents={(event) => {
                this.state.disburdenMoneyShowPop=false;
                this.updateDisburden();
                this.setState({cargoFinishItem:this.state.cargoFinishItem});
              }} closeBnEvents={() => {this.setState({disburdenMoneyShowPop: false});}}/>
        </div>)
    }
}

const styles = {};