import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 export default class UpdateBackActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            warehouseId:-1,
            startDate:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
            endDate:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        warehousesAll:[],
        stockSumm:[],
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll = successResponse.data;
                this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
                this.setState({warehousesAll:this.state.warehousesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_WHOLESALE_OPERATE_URL,{
            warehouseId:this.state.searchs.warehouseId,
            startDate:arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D'),
            endDate:arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D'),
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({stockSumm:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(892)}</span>
                            <select style={{width:200,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                                this.initData();
                            }}>{(this.state.warehousesAll?this.state.warehousesAll:[]).map((item,key)=>{
                                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                                })}
                            </select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:25}}>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startDate = date.valueOf();
                                this.initData();
                            }} /></div><span style={{marginLeft:5,marginRight:5}}>{stringUtil.language(1174)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endDate = date.valueOf();
                                this.initData();
                            }} /></div>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(1306)}</span>
                        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:17}}>{arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D')}{stringUtil.language(1174)}{arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D')}</span>
                            <span style={{fontSize:17,marginLeft:20}}>{this.state.searchs.warehouseName?this.state.searchs.warehouseName:stringUtil.language(453)}</span>
                        </div>
                    </div>:''}
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'44%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderWidth:0.1,fontSize:15,textAlign:'center'}}>{stringUtil.language(1048)}</span>
                            <span style={{width:'28%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderWidth:0.1,fontSize:15,textAlign:'center'}}>{stringUtil.language(1300)}</span>
                            <span style={{width:'28%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderWidth:0.1,fontSize:15,textAlign:'center'}}>{stringUtil.language(1163)}</span>
                        </div>
                        <div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'44%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderWidth:0.1,fontSize:15,textAlign:'center'}}>{stringUtil.language(1048)}</span>
                            <span style={{width:'28%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderWidth:0.1,fontSize:15,textAlign:'center'}}>{stringUtil.language(1300)}</span>
                            <span style={{width:'28%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderWidth:0.1,fontSize:15,textAlign:'center'}}>{stringUtil.language(1163)}</span>
                        </div>
                    </div>
                    {(()=>{this.state.totalInfo={updateMoney:0,backMoney:0}})()}
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        {(this.state.stockSumm?this.state.stockSumm:[]).map((item,key)=>{
                            this.state.totalInfo.updateMoney=(this.state.totalInfo.updateMoney).add(item.updateMoney);
                            this.state.totalInfo.backMoney=(this.state.totalInfo.backMoney).add(item.backMoney);
                            return(<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:'44%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:16,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.customerName}</span>
                                <span style={{width:'28%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.updateMoney)}</span>
                                <span style={{width:'28%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.backMoney)}</span>
                            </div>)
                        })}
                        {(this.state.stockSumm?this.state.stockSumm:[]).length%2?<div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'44%',height:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                            <span style={{width:'28%',height:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                            <span style={{width:'28%',height:'30px',borderStyle:'solid',borderWidth:0.1}}></span>
                        </div>:''}
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:16}}>{stringUtil.language(1307,this.state.stockSumm.length,arithUtil.fenToYuan(this.state.totalInfo.updateMoney)+stringUtil.currency(1),arithUtil.fenToYuan(this.state.totalInfo.backMoney)+stringUtil.currency(1))}</span>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:16}}>{stringUtil.language(1308)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};