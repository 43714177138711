import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

export default class WarehouseOutEntAddActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        warehousesAll:[],
        currAllotItem:{},
        commodities:[],
        currItemAllotTotal:{variety:0,number:0,weight:0},
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll= successResponse.data;
                // let allItem = {id:0,name:'stringUtil.language(453)'};
                // this.state.warehousesAll.unshift(allItem);
                this.setState({warehousesAll:this.state.warehousesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let warehouseCommoditys = successResponse.data;
                this.state.commodities = [];
                this.state.currItemAllotTotal = {variety:0,number:0,weight:0};
                if (warehouseCommoditys.length>0) {
                    for(let i=0;i<warehouseCommoditys.length;i++){
                        let warehouseCommoditysTemp = warehouseCommoditys[i];
                        this.state.commodities.push({
                            cargoId:warehouseCommoditysTemp.cargoId,
                            cargoNo:warehouseCommoditysTemp.cargoNo,
                            cargoCommodityId:warehouseCommoditysTemp.cargoCommodityId,
                            commodityId:warehouseCommoditysTemp.commodityId,
                            commodityName:warehouseCommoditysTemp.commodityName,
                            commodityCode:warehouseCommoditysTemp.commodityCode,
                            commoditySpecs:warehouseCommoditysTemp.commoditySpecs,
                            targetType:warehouseCommoditysTemp.targetType,
                            targetId:warehouseCommoditysTemp.targetId,
                            targetName:warehouseCommoditysTemp.targetName,
                            number:0,
                            weight:0
                        });
                    }
                }
                this.setState({currAllotItem:this.state.currAllotItem});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    outEntTotal(){
        this.state.currItemAllotTotal = {variety:0,number:0,weight:0};
        if (this.state.commodities.length>0) {
            for(let i=0;i<this.state.commodities.length;i++){
                let commodityTemp = this.state.commodities[i];
                if (commodityTemp.number!=0 || commodityTemp.weight!=0) {
                    this.state.currItemAllotTotal.variety = this.state.currItemAllotTotal.variety+1;
                    this.state.currItemAllotTotal.number = (this.state.currItemAllotTotal.number).add(commodityTemp.number);
                    this.state.currItemAllotTotal.weight = (this.state.currItemAllotTotal.weight).add(commodityTemp.weight);
                }
            }
            this.setState({currItemAllotTotal:this.state.currItemAllotTotal});
        }
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:arithUtil.pxToDp(8),paddingRight:arithUtil.pxToDp(8),backgroundColor:'#FFF'}}>
                <div style={{height:'11%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{marginRight:20}}>{stringUtil.language(677)}：{localStorage.getItem("currentWarehouseItem")?(JSON.parse(localStorage.getItem("currentWarehouseItem"))).name:''}</span>
                        <span>{stringUtil.language(678)}：</span>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(250)})} onChange={(event)=>{
                            this.state.currAllotItem.warehouseInId = JSON.parse(event.target.value).id;
                            this.state.currAllotItem.warehouseInName = JSON.parse(event.target.value).name;
                          }}>
                              <option style={{display:'none'}}></option>
                          {this.state.warehousesAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                          })}
                        </select>
                    </div>
                </div>
                <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'40%',color:'#A9A9A9'})}>{stringUtil.language(1084)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9'})}>{stringUtil.language(1021)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9'})}>{stringUtil.language(1022)}</span>
                </div>
                <div style={{height:'75%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.commodities.map((item,key)=>{
                        return(<div style={{height:arithUtil.pxToDp(50),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{textAlign:'left'})}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'40%',textAlign:'left'})}>{item.targetType==0?stringUtil.subStr(item.targetName,9)+stringUtil.language(1063):item.targetType==1?stringUtil.language(449):stringUtil.language(450)} / {item.cargoNo}</span>
                            <div style={arithUtil.stylesAdd(styles.tableTd,{display:'flex',justifyContent:'center',alignItems:'center'})}>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(120)})} type='number' placeholder='0' onChange={(event)=>{
                                    this.state.commodities[key].number = parseFloat(event.target.value);
                                    this.outEntTotal();
                                }}/>
                            </div>
                            <div style={arithUtil.stylesAdd(styles.tableTd,{display:'flex',justifyContent:'center',alignItems:'center'})}>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(120)})} type='number' placeholder='0' onChange={(event)=>{
                                    this.state.commodities[key].weight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                    this.outEntTotal();
                                }}/>
                            </div>
                        </div>)
                    })}
                </div>
                <div style={{height:'9%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span style={{color:'#D2855D'}}>{stringUtil.language(890)}{this.state.currItemAllotTotal.variety}{stringUtil.language(1177)}，{this.state.currItemAllotTotal.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(this.state.currItemAllotTotal.weight,this.state.weightMode)}stringUtil.language(1023)</span>
                    <span style={{width:arithUtil.pxToDp(200),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',marginLeft:arithUtil.pxToDp(20),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if (this.state.currAllotItem.warehouseInId==null) {
                            ToastBox.error('stringUtil.language(186)！');return;
                        }
                        this.state.currAllotItem.commodities = [];
                        for(let i=0;i<this.state.commodities.length;i++){
                            let commodityTemp = this.state.commodities[i];
                            if (commodityTemp.number!=0 || commodityTemp.weight!=0) {
                                this.state.currAllotItem.commodities.push(JSON.parse(JSON.stringify(commodityTemp)));
                            }
                        }
                        if (this.state.currAllotItem.commodities.length==0) {
                            ToastBox.error('stringUtil.language(22)');return;
                        }
                        stringUtil.httpRequest(urlUtil.SALE_ALLOT_URL,this.state.currAllotItem,['json','post',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                ToastBox.success('stringUtil.language(388)');
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:this.props.navigationStacks[this.props.navigationStacks.length-1].navigatePage,mainStacks:this.props.navigationStacks}});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(859)}</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'20%',
        textAlign:'center',
        textAlignVertical:'center',
    },
};