import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import moment from 'moment';
// import { DatePicker } from 'antd';
// import 'antd/dist/antd.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

export default class TabMoneyCustomerCollectDebt extends Component{
  constructor(props){
    super(props);
    this.state={
        searchs:{
            isTodayWholesale:-1,//0今天没开单 1今天开单了
            targetType:-1,
            targetId:-1,
            keySearch:'',
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
            sort:0,//0stringUtil.language(491)，1stringUtil.language(1089)字母排序
        },
        customerAccruedArrearsDO:[],
        customerArrearsDOs:[],
        personsAll:[],
        keyTagVal:-1,
     };
  }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                this.state.personsAll.unshift({targetType:-1,targetId:-1,noteName:stringUtil.language(425)});
                for(let i=0;i<ownersAll.length;i++){
                    this.state.personsAll.push({targetType:0,targetId:ownersAll[i].id,noteName:ownersAll[i].noteName});
                }
                this.state.personsAll.push({targetType:1,targetId:0,noteName:stringUtil.language(426)});
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                        }
                        this.setState({personsAll:this.state.personsAll});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_ARREARS_SUMM_URL,{
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.customerAccruedArrearsDO = successResponse.data;
                this.searchDatas();
              }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        // stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_ACCRUED_ARREARS_URL,{
        //     targetType:this.state.searchs.targetType,
        //     targetId:this.state.searchs.targetId,
        //     date:arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D'),
        // },['key','get',1],(successResponse)=>{
        //     if (successResponse.status==200) {
        //         this.state.customerAccruedArrearsDO = successResponse.data;
        //         this.searchDatas();
        //     }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        // },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchDatas(){
        this.state.customerArrearsDOs = [];
        for(let i=0;i<this.state.customerAccruedArrearsDO.length;i++){
            let customerItem = this.state.customerAccruedArrearsDO[i];
            if (customerItem.customerName.indexOf(this.state.searchs.keySearch)!=-1) {
                if(this.state.searchs.isTodayWholesale==-1||this.state.searchs.isTodayWholesale==customerItem.isTodayWholesale){
                    this.state.customerArrearsDOs.push(customerItem);
                }
            }
        }
        if(this.state.searchs.sort==0){//stringUtil.language(491)
            this.state.customerArrearsDOs = this.state.customerArrearsDOs.sort((param1, param2)=>{
                return ((param2.arrears).sub(param1.arrears));
            });
        }else if(this.state.searchs.sort==1){//stringUtil.language(1089)字母排序
            this.state.customerArrearsDOs = this.state.customerArrearsDOs.sort(function compareFunction(param1, param2) {
                return (param1.customerName).localeCompare(param2.customerName,"zh");
            });
        }
        this.setState({customerArrearsDOs:this.state.customerArrearsDOs});
    }
    
    render(){ 
        return(<div style={{width:'100%',height:'100%'}}>
            <div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <Select showSearch defaultValue={stringUtil.language(425)}  style={{width:200,height:25,fontSize:12}} onChange={(value)=>{
                        this.state.searchs.targetType = JSON.parse(value).targetType;
                        this.state.searchs.targetId = JSON.parse(value).targetId;
                        this.initData();
                    }}>{this.state.personsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                        })}
                    </Select>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{color:this.state.searchs.isTodayWholesale==-1?'#FF8C00':'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.searchs.isTodayWholesale = -1;
                            this.searchDatas();
                        }}>{this.state.searchs.isTodayWholesale==-1?<i className="iconfontIndexCss" style={{fontSize:'27px',cursor:'pointer'}}>&#xe71f;</i>:
                            <i className="iconfontIndexCss" style={{fontSize:'27px'}}>&#xe641;</i>}
                            <span>{stringUtil.language(618)}</span>
                        </div>
                        <div style={{color:this.state.searchs.isTodayWholesale==1?'#FF8C00':'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.searchs.isTodayWholesale = 1;
                            this.searchDatas();
                        }}>{this.state.searchs.isTodayWholesale==1?<i className="iconfontIndexCss" style={{fontSize:'27px',cursor:'pointer'}}>&#xe71f;</i>:
                            <i className="iconfontIndexCss" style={{fontSize:'27px'}}>&#xe641;</i>}
                            <span>{stringUtil.language(1305)}</span>
                        </div>
                    </div>
                    {/* <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(294)}<div style={styles.time}>
                            <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endTime = date.valueOf();
                                this.initData();
                            }} />
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                        </div>
                        <DatePicker locale='zhCN' style={{height:25,backgroundColor:'transparent',paddingLeft:5,paddingRight:5}} format="YYYY-MM-DD" placeholder={arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D')} onChange={(date, dateString) => {// date 就是原始的stringUtil.language(1067)数值，dateString 格式化后的字符串stringUtil.language(1067)
                            this.state.searchs.endTime = arithUtil.timeToNumber(dateString);
                            this.initData();
                        }} />
                    </div> */}
                    <div style={{width:100,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#52A056',borderWidth:1,borderRadius:20,cursor:'pointer'}} onClick={()=>{
                        this.state.searchs.sort = this.state.searchs.sort==0?1:0;
                        this.searchDatas();
                    }}><span style={{color:'#52A056',fontSize:14}}>{this.state.searchs.sort==0?stringUtil.language(491):stringUtil.language(492)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(903)}<input style={{width:arithUtil.pxToDp(150),height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} id='keyId' onChange={(event)=>{
                            this.state.searchs.keySearch = event.target.value;
                            this.searchDatas();
                        }} placeholder={stringUtil.language(261)+'..'}/>
                    </div>
                </div>
                {(()=>{this.state.totalInfo = {debtMoneyTotal:0}})()}
                <div style={{width:'100%',height:'92%',paddingTop:5,overflow:'auto'}}>
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        {this.state.customerArrearsDOs.map((item,key)=>{
                            this.state.totalInfo.debtMoneyTotal = (this.state.totalInfo.debtMoneyTotal).add(item.arrears);
                            return(<div style={arithUtil.stylesAdd(styles.itemStyle,{cursor:'pointer'})} key={key} onClick={()=>{
                                if(otherUtil.authorityFindCode("030102")!=null){
                                    this.props.context.props.context.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DebtCollectActivity',mainStacks:[
                                        {title:stringUtil.language(1128),navigatePage:''},
                                        {title:stringUtil.language(916),navigatePage:'DebtCollectActivity',paramsPage:{currCustomerArrears:item,targetItem:{targetType:this.state.searchs.targetType,targetId:this.state.searchs.targetId}}}
                                    ]}});
                                }
                            }}><span style={{fontSize:16,fontWeight:'bold'}}>{stringUtil.subStr(item.customerName,8)}</span>
                                <span style={{color:'#F00',fontSize:16,fontWeight:'bold'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                            </div>)
                        })}
                    </div>
                    <br/><span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(97,this.state.customerArrearsDOs.length,arithUtil.fenToYuan(this.state.totalInfo.debtMoneyTotal))} {stringUtil.currency(1)}</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTitle:{
        width:arithUtil.pxToDp(90),
        height:arithUtil.pxToDp(30),
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        textAlign:'center',
        textAlignVertical:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    tableTd:{
        width:arithUtil.pxToDp(90),
        height:arithUtil.pxToDp(30),
        fontSize:13,
        textAlign:'center',
        textAlignVertical:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:25,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
        paddingLeft:3,
        paddingRight:3
    },
    itemStyle:{
        width:'31%',
        height:40,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        paddingLeft:5,
        paddingRight:5,
        borderStyle:'solid',
        borderColor:'#D3D3D3',
        borderWidth: 1
    },
};